import { Handle, Position } from "react-flow-renderer";

function TextUpdaterNode({ data }) {
  console.log(data);

  return (
    <div className="text-updater-node">
      <Handle type="target" position={Position.Left} />
      <label>{data.label}</label>
      <Handle type="source" position={Position.Right} />
    </div>
  );
}

export default TextUpdaterNode;
