//import axios from "../utils/axios";
const axios = require("axios").default;
const API_URL = process.env.REACT_APP_GENERATE_DOC_URL;

export const TriggerMail = async (Data) => {
  console.log("In Trigger Mail", JSON.stringify(Data));
  let url = API_URL + Data;
  console.log(url);

  const response = await axios
    .post(url, JSON.stringify(Data))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
