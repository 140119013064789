import { useRef } from "react";
import { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import Edit from "../../icons/EditIcon.svg";
import Delete from "../../icons/DeleteIcon.svg";
import { BsPlusLg } from "react-icons/bs";
import { getAirTreatmentLineData } from "../../Services/air-treatment-service";
import axios from "../../utils/axios";
import { alphaNumeric100 } from "../../utils/customValidation";
const AddAirTreatment = ({
  id,
  active,
  compHouse,
  airTreatment,
  setAirTreatment,
  enquiryID,
  compheader,
  compLine,
  airLine,
  setAirLine,
  Name,
  ...props
}) => {
  const [Capacity, setCapacity] = useState([]);
  const [addLine, setAddLine] = useState([]);
  const [editLine, setEditLine] = useState([]);
  const [validateMessage, setValidateMessage] = useState([]);
  const [scrollFlg, setScrollFlag] = useState(false);
  const [showmoreless, setshowmoreless] = useState([]);
  const [errorLine, setErrorLine] = useState({
    al_dryer_make: "",
    al_dryer_model: "",
    al_dryer_type: "",
    al_dryer_capacity_flow: "",
    al_pre_filters_make: "",
    al_pre_filters_model: "",
    al_pre_filters_type: "",
    al_pre_filters_capacity_flow: "",
    al_post_filters_make: "",
    al_post_filters_model: "",
    al_post_filters_type: "",
    al_post_filters_capacity_flow: "",
  });
  const [action, setAction] = useState("");
  const [editAction, setEditAction] = useState(false);
  const [airTreatmentHeader, setAirTreatmentHeader] = useState({
    ah_enquiry_id: enquiryID,
    ah_compressor_id: "",
    ah_air_id: "",
    ah_dryer_unit: "",
    ah_pre_filter_unit: "",
    ah_post_filter_unit: "",
    ch_comp_house_name: Name,
  });

  const textInput = useRef(null);

  let active_ah_air_id = ""; //= airTreatment[active].ah_air_id='';
  let active_ah_dryer_unit = ""; //= airTreatment[active].ah_dryer_unit='';
  let active_ah_pre_filter_unit = ""; // = airTreatment[active].ah_pre_filter_unit='';
  let active_ah_post_filter_unit = ""; //= airTreatment[active].ah_post_filter_unit='';
  let active_ah_compressor_id = ""; //= airTreatment[active].ah_compressor_id='';
  let active_ah_enquiry_id = ""; //= airTreatment[active].ah_enquiry_id;
  // if (airTreatment.length < 0) {
  //   // }

  console.log(Name);
  if (
    airTreatment[active]?.ah_dryer_unit === "" ||
    airTreatment[active]?.ah_pre_filter_unit === "" ||
    airTreatment[active]?.ah_post_filter_unit === ""
  ) {
    console.log("inside blank");
    active_ah_dryer_unit = airTreatmentHeader.ah_dryer_unit;
    active_ah_pre_filter_unit = airTreatmentHeader.ah_pre_filter_unit;
    active_ah_post_filter_unit = airTreatmentHeader.ah_post_filter_unit;
  } else {
    console.log("inside else for not blnk");
    active_ah_dryer_unit = airTreatment[active]?.ah_dryer_unit;
    active_ah_pre_filter_unit = airTreatment[active]?.ah_pre_filter_unit;
    active_ah_post_filter_unit = airTreatment[active]?.ah_post_filter_unit;
    active_ah_air_id = airTreatment[active]?.ah_air_id;
    active_ah_compressor_id = airTreatment[active]?.ah_compressor_id;
    active_ah_enquiry_id = airTreatment[active]?.ah_enquiry_id;
  }
  const activeHeader = {
    active_ah_air_id,
    active_ah_dryer_unit,
    active_ah_pre_filter_unit,
    active_ah_post_filter_unit,
    active_ah_compressor_id,
    active_ah_enquiry_id,
  };
  console.log(airTreatment, airTreatmentHeader, activeHeader);
  //console.log(activeHeader);
  //console.log(airTreatmentHeader);
  const fetchCompMaster = async () => {
    // const resFromUnitCapacity = await getFromUnit("Capacity");

    //setCapacity(resFromUnitCapacity);
    setCapacity(props.Capacity);
    // if (
    //   activeHeader.active_ah_compressor_id === "" ||
    //   activeHeader.active_ah_air_id === "" ||
    //   activeHeader.active_ah_dryer_unit === "" ||
    //   activeHeader.active_ah_pre_filter_unit === ""
    // ) {
    //   console.log("emptyyyy");
    // } else {
    //   const resGetActiveLines = await getAirTreatmentLineData(activeHeader);
    //   // setAirLine(resGetActiveLines);
    //   console.log(resGetActiveLines);
    // }
  };
  const fetchAirTreatMentHouseHeader = () => {
    let tempComp = compHouse[active]?.ch_comp_house_id;
    console.log("tempComp", tempComp);
    if (tempComp != "") {
      axios
        .get(`airTreatment/header/${enquiryID}/${tempComp}`)
        .then((response) => {
          console.log(response);

          if (response.status === 200) {
            console.log("asdasdasdas", response);
            let temp = response.data.data;
            if (temp.length) {
              setAirTreatmentHeader((prevState) => ({
                ...prevState,
                ah_dryer_unit: temp[0]?.ah_dryer_unit || "",
                ah_pre_filter_unit: temp[0]?.ah_pre_filter_unit || "",
                ah_post_filter_unit: temp[0]?.ah_post_filter_unit || "",
              }));
            }
          }
        })
        .catch((e) => {
          console.log("after gte comph- error----------", e);
        })
        .finally(() => {});
    }
  };
  const handleHeaderChange = (e) => {
    const { name, value } = e.target;
    setAirTreatmentHeader((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange = (e, index, type = "") => {
    const { name, value } = e.target;
    if (type === "add") {
      let tmp = [...addLine];
      tmp[0][name] = value;
      setAddLine(() => tmp);
    } else {
      let tmp = [...airLine];
      tmp[index][name] = value;
      setAirLine(tmp);
      if (type === "edit") {
        let tmp = [...editLine];
        tmp[0][name] = value;
        setEditLine(tmp);
      }
    }
  };
  const handleAddClick = (id) => {
    setAction("Add");
    setEditAction(true);
    setAddLine([
      {
        ah_enquiry_id: enquiryID,
        al_air_id: "",
        al_dryer_make: "",
        al_dryer_model: "",
        al_dryer_type: "",
        al_dryer_capacity_flow: "",
        al_pre_filters_make: "",
        al_pre_filters_model: "",
        al_pre_filters_type: "",
        al_pre_filters_capacity_flow: "",
        al_post_filters_make: "",
        al_post_filters_model: "",
        al_post_filters_type: "",
        al_post_filters_capacity_flow: "",
      },
    ]);
    setScrollFlag(true);
  };

  const handleEdit = (row, index) => {
    let temp = [...airLine];
    temp[index].edit = true;
    setAirLine(temp);
    setEditAction(true);
    setErrorLine({
      al_dryer_make: null,
      al_dryer_model: null,
      al_dryer_type: null,
      al_dryer_capacity_flow: null,
      al_pre_filters_make: null,
      al_pre_filters_model: null,
      al_pre_filters_type: null,
      al_pre_filters_capacity_flow: null,
      al_post_filters_make: null,
      al_post_filters_model: null,
      al_post_filters_type: null,
      al_post_filters_capacity_flow: null,
    });
    setEditLine([
      {
        al_srno: index + 1,
        al_air_id: row.al_air_id,
        al_line_id: row.al_line_id,
        al_dryer_make: row.al_dryer_make,
        al_dryer_model: row.al_dryer_model,
        al_dryer_type: row.al_dryer_type,
        al_dryer_capacity_flow: row.al_dryer_capacity_flow,
        al_pre_filters_make: row.al_pre_filters_make,
        al_pre_filters_model: row.al_pre_filters_model,
        al_pre_filters_type: row.al_pre_filters_type,
        al_pre_filters_capacity_flow: row.al_pre_filters_capacity_flow,
        al_post_filters_make: row.al_post_filters_make,
        al_post_filters_model: row.al_post_filters_model,
        al_post_filters_type: row.al_post_filters_type,
        al_post_filters_capacity_flow: row.al_post_filters_capacity_flow,
      },
    ]);
  };
  // const handleDelete = (item, index) => {
  //   if (item?.al_line_id) {
  //     axios
  //       .delete(`/aem/api/airTreatment/line/al_line_id/${item?.al_line_id}`)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           console.log("after delete-----------", response);
  //           props.setShowMessage(
  //             "Great! You have Deleted Air Treatment Successfully"
  //           );
  //           props.closeModal();
  //         }
  //       })
  //       .catch((e) => {
  //         console.log("after delete- error----------", e);
  //       })
  //       .finally(() => {});
  //   }
  // };
  const bodyValidate = () => {
    let error = false;
    Object.entries(errorLine).forEach((value) => {
      if (value[1] !== null) {
        // let key = value[0];
        setErrorLine((prevState) => ({
          ...prevState,
          [value[0]]: "Enter a value",
        }));
        console.log("error key", value);
        error = true;
        // key = "";
      }
    });
    console.log("run--------------------------", error);
    return error;
  };
  const addAirTreatment = (value) => {
    if (compHouse?.length > 0) {
      let bodyData = {
        airHeader: {
          ah_enquiry_id: +addLine[0]?.ah_enquiry_id || "",
          ah_compressor_id: compHouse[active]?.ch_comp_house_id || "",
          ah_dryer_unit: airTreatmentHeader.ah_dryer_unit,
          ah_pre_filter_unit: airTreatmentHeader.ah_pre_filter_unit,
          ah_post_filter_unit: airTreatmentHeader.ah_post_filter_unit,
          ch_comp_house_name: Name,
        },
        airLine: {
          al_air_id: 0,
          al_dryer_make: addLine[0]?.al_dryer_make,
          al_dryer_model: addLine[0]?.al_dryer_model,
          al_dryer_type: addLine[0]?.al_dryer_type,
          al_dryer_capacity_flow: addLine[0]?.al_dryer_capacity_flow,
          al_pre_filters_make: addLine[0]?.al_pre_filters_make,
          al_pre_filters_model: addLine[0]?.al_pre_filters_model,
          al_pre_filters_type: addLine[0]?.al_pre_filters_type,
          al_pre_filters_capacity_flow:
            addLine[0]?.al_pre_filters_capacity_flow,
          al_post_filters_make: addLine[0]?.al_post_filters_make,
          al_post_filters_model: addLine[0]?.al_post_filters_model,
          al_post_filters_type: addLine[0]?.al_post_filters_type,
          al_post_filters_capacity_flow:
            addLine[0]?.al_post_filters_capacity_flow,
        },
      };
      if (bodyData.airHeader.ch_comp_house_name === "") {
        setValidateMessage("*Enter Compressor House Name");
      } else {
        if (
          airTreatmentHeader.ah_dryer_unit &&
          airTreatmentHeader.ah_pre_filter_unit &&
          airTreatmentHeader.ah_post_filter_unit
        ) {
          if (!bodyValidate()) {
            setValidateMessage("");
            axios
              .post("/airTreatment", bodyData)
              .then((response) => {
                console.log(response);
                if (
                  response.data.message.includes(
                    "fails to match the required pattern"
                  ) ||
                  response.data.message.includes("must be")
                ) {
                  let s = response.data.message;
                  s = s.split("_")[1];
                  console.log("In Weird Pattern", s);

                  props.setShowMessageWarning(
                    "Special Characters not allowed in " + s
                  );
                } else {
                  if (response.status === 200) {
                    props.setShowMessage(
                      "Great! You have Added Air Treatment Successfully"
                    );
                    props.closeModal();
                  }
                }
              })
              .catch((e) => {
                console.log("after post- error----------", e);
              })
              .finally(() => {});
          } else {
            setValidateMessage("*Enter All Values");
          }
        } else {
          setValidateMessage("*Select All Headers");
        }
      }
    }
  };
  const editAirTreatment = (value) => {
    let bodyData = {
      airHeader: {
        ah_air_id: +editLine[0]?.al_air_id || "",
        ah_dryer_unit: airTreatmentHeader.ah_dryer_unit,
        ah_pre_filter_unit: airTreatmentHeader.ah_pre_filter_unit,
        ah_post_filter_unit: airTreatmentHeader.ah_post_filter_unit,
        ch_comp_house_name: Name,
      },
      airLine: {
        al_dryer_make: editLine[0]?.al_dryer_make,
        al_dryer_model: editLine[0]?.al_dryer_model,
        al_dryer_type: editLine[0]?.al_dryer_type,
        al_dryer_capacity_flow: editLine[0]?.al_dryer_capacity_flow,
        al_pre_filters_make: editLine[0]?.al_pre_filters_make,
        al_pre_filters_model: editLine[0]?.al_pre_filters_model,
        al_pre_filters_type: editLine[0]?.al_pre_filters_type,
        al_pre_filters_capacity_flow: editLine[0]?.al_pre_filters_capacity_flow,
        al_post_filters_make: editLine[0]?.al_post_filters_make,
        al_post_filters_model: editLine[0]?.al_post_filters_model,
        al_post_filters_type: editLine[0]?.al_post_filters_type,
        al_post_filters_capacity_flow:
          editLine[0]?.al_post_filters_capacity_flow,
        al_line_id: +editLine[0]?.al_line_id,
      },
    };
    if (bodyData.airHeader.ch_comp_house_name === "") {
      setValidateMessage("*Enter Compressor House Name");
    } else {
      if (!bodyValidate()) {
        setValidateMessage("");
        axios
          .post(`/airTreatment/update`, bodyData)
          .then((response) => {
            if (
              response.data.message.includes(
                "fails to match the required pattern"
              ) ||
              response.data.message.includes("must be")
            ) {
              let s = response.data.message;
              s = s.split("_")[1];
              console.log("In Weird Pattern", s);

              props.setShowMessageWarning(
                "Special Characters not allowed in " + s
              );
            } else {
              if (response.status === 200) {
                props.setShowMessage(
                  "Great! You have Updated Air Treatment Successfully"
                );
                props.closeModal();
              }
            }
          })
          .catch((e) => {
            console.log("after put- error----------", e);
          })
          .finally(() => {});
      } else {
        setValidateMessage("*Enter All Values");
      }
    }
  };

  useEffect(() => {
    // let node = document.querySelector("#AddAirTreatment-table-" + id);
    // //console.log(node);
    // node && Scrollbar.init(node);
    fetchCompMaster();
    if (compHouse.length) {
      fetchAirTreatMentHouseHeader();
    }
  }, []);
  useEffect(() => {
    if (action !== "Add") {
      if (airLine.length === 0 && action !== "Add") {
        setAddLine([
          {
            ah_enquiry_id: enquiryID,
            al_air_id: "",
            al_dryer_make: "",
            al_dryer_model: "",
            al_dryer_type: "",
            al_dryer_capacity_flow: "",
            al_pre_filters_make: "",
            al_pre_filters_model: "",
            al_pre_filters_type: "",
            al_pre_filters_capacity_flow: "",
            al_post_filters_make: "",
            al_post_filters_model: "",
            al_post_filters_type: "",
            al_post_filters_capacity_flow: "",
          },
        ]);
      } else {
        setAddLine([]);
      }
    }
  }, [airLine]);

  useEffect(() => {
    if (!scrollFlg) {
      return;
    }
    textInput.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [scrollFlg]);

  return (
    <>
      <div
        className="d-none d-md-block d-lg-block table-container"
        id={`AddAirTreatment-table-${id}`}
        // style={{ width: "1150px" }}
      >
        <Form name="AirTreatmentForm" autoComplete="off">
          <div className="tableWrapperCompressorModal">
            <div className="table-head tableHeadCompressorModal">
              <div className="table-row">
                <div className="table-cell">
                  <span>Sr. No.</span>
                </div>
                <div className="table-cell">
                  <span>Dryer Make</span>
                </div>
                <div className="table-cell">
                  <span>Dryer Model</span>
                </div>
                <div className="table-cell">
                  <span>Dryer Type</span>
                </div>

                <div className="table-cell">
                  <span style={{ width: "100px" }}>
                    Dryer Capacity
                    <div className="filter-input mt-1">
                      <span className="tableHeaderLabel">Parameter</span>
                      <select
                        disabled={!(addLine.length > 0 || editLine.length > 0)}
                        // disabled={addLine.length === 0}
                        className="headerSelect"
                        value={airTreatmentHeader.ah_dryer_unit}
                        onChange={handleHeaderChange}
                        name="ah_dryer_unit"
                        id="ah_dryer_unit"
                      >
                        <option value="">Capacity Flow</option>
                        {/* <option value="KW">KW</option>
                      <option value="ABC">ABC</option> */}
                        {Capacity.map((fromunit, index) => (
                          <option value={fromunit}>{fromunit}</option>
                        ))}
                      </select>
                    </div>
                  </span>
                </div>

                <div className="table-cell">
                  <span>
                    Pre-Filters<br></br>Make
                  </span>
                </div>
                <div className="table-cell">
                  <span>Pre-Filters Model</span>
                </div>
                <div className="table-cell">
                  <span> Pre-Filters Type</span>
                </div>
                <div className="table-cell">
                  <span style={{ width: "110px" }}>
                    Pre-Filters Capacity
                    <div className="filter-input mt-1">
                      <span className="tableHeaderLabel">Parameter</span>
                      <select
                        disabled={!(addLine.length > 0 || editLine.length > 0)}
                        // disabled={addLine.length === 0}
                        className="headerSelect"
                        value={airTreatmentHeader.ah_pre_filter_unit}
                        onChange={handleHeaderChange}
                        name="ah_pre_filter_unit"
                        id="ah_pre_filter_unit"
                      >
                        <option value="">Capacity Flow</option>
                        {/* <option value="ABC">ABC</option> */}
                        {Capacity.map((fromunit, index) => (
                          <option value={fromunit}>{fromunit}</option>
                        ))}
                      </select>
                    </div>
                  </span>
                </div>
                <div className="table-cell">
                  <span>
                    Post Filters<br></br>Make
                  </span>
                </div>
                <div className="table-cell">
                  <span>Post Filters Model</span>
                </div>
                <div className="table-cell">
                  <span> Post Filters Type</span>
                </div>
                <div className="table-cell">
                  <span style={{ width: "110px" }}>
                    Post Filters Capacity
                    <div className="filter-input mt-1">
                      <span className="tableHeaderLabel">Parameter</span>
                      <select
                        // disabled={addLine.length === 0}
                        disabled={!(addLine.length > 0 || editLine.length > 0)}
                        value={airTreatmentHeader.ah_post_filter_unit}
                        className="headerSelect"
                        onChange={handleHeaderChange}
                        name="ah_post_filter_unit"
                        id="ah_post_filter_unit"
                      >
                        <option value="">Capacity Flow</option>
                        {/* <option value="ABC">ABC</option> */}
                        {Capacity.map((fromunit, index) => (
                          <option value={fromunit}>{fromunit}</option>
                        ))}
                      </select>
                    </div>
                  </span>
                </div>
                <div className="table-cell">
                  <span></span>
                </div>
              </div>
            </div>
            <div className="table-body tableBodyCompressorModal scroll-compline">
              {airLine.length > 0 &&
                airLine.map((item, index) => {
                  return (
                    <div
                      className="table-row"
                      key={`row_${index}`}
                      id={`row_${index}`}
                    >
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              maxlength="100"
                              name="srno"
                              className="compTextWidth"
                              id={`srno${index}`}
                              value={`${index + 1}`}
                              onChange={(e) => handleChange(e, index)}
                              readOnly
                            />
                          </>
                        ) : (
                          index + 1
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_dryer_make"
                              id={`al_dryer_make${index}`}
                              value={airLine[index].al_dryer_make}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Dryer Make"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_dryer_make: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_dryer_make && (
                              <div className="text-danger">
                                {errorLine.al_dryer_make}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_dryer_make
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_dryer_model"
                              id={`al_dryer_model${index}`}
                              value={airLine[index].al_dryer_model}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Dryer Model"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_dryer_model: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_dryer_model && (
                              <div className="text-danger">
                                {errorLine.al_dryer_model}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_dryer_model
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_dryer_type"
                              id={`al_dryer_type${index}`}
                              value={airLine[index].al_dryer_type}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Dryer Type"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_dryer_type: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_dryer_type && (
                              <div className="text-danger">
                                {errorLine.al_dryer_type}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_dryer_type
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_dryer_capacity_flow"
                              id={`al_dryer_capacity_flow${index}`}
                              value={airLine[index].al_dryer_capacity_flow}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Dryer Capacity Flow"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_dryer_capacity_flow: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_dryer_capacity_flow && (
                              <div className="text-danger">
                                {errorLine.al_dryer_capacity_flow}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_dryer_capacity_flow
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_pre_filters_make"
                              id={`al_pre_filters_make${index}`}
                              value={airLine[index].al_pre_filters_make}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Pre Filter Make"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_pre_filters_make: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_pre_filters_make && (
                              <div className="text-danger">
                                {errorLine.al_pre_filters_make}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_pre_filters_make
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_pre_filters_model"
                              id={`al_pre_filters_model${index}`}
                              value={airLine[index].al_pre_filters_model}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Pre Filter Model"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_pre_filters_model: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_pre_filters_model && (
                              <div className="text-danger">
                                {errorLine.al_pre_filters_model}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_pre_filters_model
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_pre_filters_type"
                              id={`al_pre_filters_type${index}`}
                              value={airLine[index].al_pre_filters_type}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Pre Filter type"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_pre_filters_type: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_pre_filters_type && (
                              <div className="text-danger">
                                {errorLine.al_pre_filters_type}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_pre_filters_type
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_pre_filters_capacity_flow"
                              id={`al_pre_filters_capacity_flow${index}`}
                              value={
                                airLine[index].al_pre_filters_capacity_flow
                              }
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Pre Filter Capacity Flow"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_pre_filters_capacity_flow: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_pre_filters_capacity_flow && (
                              <div className="text-danger">
                                {errorLine.al_pre_filters_capacity_flow}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_pre_filters_capacity_flow
                        )}
                      </div>

                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_post_filters_make"
                              id={`al_post_filters_make${index}`}
                              value={airLine[index].al_post_filters_make}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Post Filter Make"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_post_filters_make: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_post_filters_make && (
                              <div className="text-danger">
                                {errorLine.al_post_filters_make}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_post_filters_make
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_post_filters_model"
                              id={`al_post_filters_model${index}`}
                              value={airLine[index].al_post_filters_model}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Post Filter Model"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_post_filters_model: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_post_filters_model && (
                              <div className="text-danger">
                                {errorLine.al_post_filters_model}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_post_filters_model
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_post_filters_type"
                              id={`al_post_filters_type${index}`}
                              value={airLine[index].al_post_filters_type}
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Post Filter Type"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_post_filters_type: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_post_filters_type && (
                              <div className="text-danger">
                                {errorLine.al_post_filters_type}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_post_filters_type
                        )}
                      </div>
                      <div className="table-cell">
                        {item.edit ? (
                          <>
                            <input
                              type="text"
                              className="compTextWidth"
                              maxlength="100"
                              name="al_post_filters_capacity_flow"
                              id={`al_post_filters_capacity_flow${index}`}
                              value={
                                airLine[index].al_post_filters_capacity_flow
                              }
                              onChange={(e) => handleChange(e, index, "edit")}
                              onInput={(e) => {
                                let error = alphaNumeric100(
                                  e.target.value,
                                  "Post Filter Capacity Flow"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  al_post_filters_capacity_flow: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.al_post_filters_capacity_flow && (
                              <div className="text-danger">
                                {errorLine.al_post_filters_capacity_flow}
                              </div>
                            )}
                          </>
                        ) : (
                          item.al_post_filters_capacity_flow
                        )}
                      </div>

                      <div className="table-cell cell-action cellActionCustom">
                        {!editAction && !item.edit && (
                          <div className="tableButtons">
                            {/* <BiEdit
                              hidden={props.IsDisabled === false ? true : false}
                              size={32}
                              style={{ padding: "5px" }}
                              color={"blue"}
                              onClick={(e) => handleEdit(item, index)}
                            /> */}
                            <img
                              src={Edit}
                              alt="search"
                              hidden={props.IsDisabled === false ? true : false}
                              style={{ padding: "5px" }}
                              onClick={(e) => handleEdit(item, index)}
                            />
                            {/* <BiTrash
                              hidden={props.IsDisabled === false ? true : false}
                              size={32}
                              style={{ padding: "5px" }}
                              color={"blue"}
                              // onClick={() => handleDelete(item, index)}
                              onClick={() => props.setShow2(true, item)}
                            /> */}
                            <img
                              src={Delete}
                              alt="delete"
                              hidden={props.IsDisabled === false ? true : false}
                              style={{ padding: "5px" }}
                              onClick={() => props.setShow2(true, item)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              {addLine.length > 0 &&
                addLine.map((item, index) => {
                  return (
                    <>
                      <div
                        className="table-row"
                        key={`row_${index}`}
                        id={`row_${index}`}
                      >
                        <div className="table-cell">
                          <input
                            type="text"
                            maxlength="100"
                            name="srno"
                            className="compTextWidth"
                            id={`srno${index}`}
                            value={`${airLine?.length + 1}`}
                            onChange={(e) => handleChange(e, index, "add")}
                            readOnly
                          />
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_dryer_make"
                            id={`al_dryer_make${index}`}
                            //value={}
                            value={addLine[0].al_dryer_make}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Dryer Make"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_dryer_make: error,
                              }));
                            }}
                          />
                          {errorLine.al_dryer_make && (
                            <div className="text-danger">
                              {errorLine.al_dryer_make}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_dryer_model"
                            id={`al_dryer_model${index}`}
                            value={addLine[0].al_dryer_model}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Dryer Model"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_dryer_model: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_dryer_model && (
                            <div className="text-danger">
                              {errorLine.al_dryer_model}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_dryer_type"
                            id={`al_dryer_type${index}`}
                            value={addLine[0].al_dryer_type}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Dryer Type"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_dryer_type: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_dryer_type && (
                            <div className="text-danger">
                              {errorLine.al_dryer_type}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_dryer_capacity_flow"
                            id={`al_dryer_capacity_flow${index}`}
                            value={addLine[0].al_dryer_capacity_flow}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Dryer Capacity Flow"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_dryer_capacity_flow: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_dryer_capacity_flow && (
                            <div className="text-danger">
                              {errorLine.al_dryer_capacity_flow}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_pre_filters_make"
                            id={`al_pre_filters_make${index}`}
                            value={addLine[0].al_pre_filters_make}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Pre Filter Make"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_pre_filters_make: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_pre_filters_make && (
                            <div className="text-danger">
                              {errorLine.al_pre_filters_make}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_pre_filters_model"
                            id={`al_pre_filters_model${index}`}
                            value={addLine[0].al_pre_filters_model}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Pre Filter Model"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_pre_filters_model: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_pre_filters_model && (
                            <div className="text-danger">
                              {errorLine.al_pre_filters_model}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_pre_filters_type"
                            id={`al_pre_filters_type${index}`}
                            value={addLine[0].al_pre_filters_type}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Pre Filter type"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_pre_filters_type: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_pre_filters_type && (
                            <div className="text-danger">
                              {errorLine.al_pre_filters_type}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_pre_filters_capacity_flow"
                            id={`al_pre_filters_capacity_flow${index}`}
                            value={addLine[0].al_pre_filters_capacity_flow}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Pre Filter Capacity Flow"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_pre_filters_capacity_flow: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_pre_filters_capacity_flow && (
                            <div className="text-danger">
                              {errorLine.al_pre_filters_capacity_flow}
                            </div>
                          )}
                        </div>

                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_post_filters_make"
                            id={`al_post_filters_make${index}`}
                            value={addLine[0].al_post_filters_make}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Post Filter Make"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_post_filters_make: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_post_filters_make && (
                            <div className="text-danger">
                              {errorLine.al_post_filters_make}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_post_filters_model"
                            id={`al_post_filters_model${index}`}
                            value={addLine[0].al_post_filters_model}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Post Filter Model"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_post_filters_model: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_post_filters_model && (
                            <div className="text-danger">
                              {errorLine.al_post_filters_model}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_post_filters_type"
                            id={`al_post_filters_type${index}`}
                            value={addLine[0].al_post_filters_type}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Post Filter Type"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_post_filters_type: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_post_filters_type && (
                            <div className="text-danger">
                              {errorLine.al_post_filters_type}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="compTextWidth"
                            maxlength="100"
                            name="al_post_filters_capacity_flow"
                            id={`al_post_filters_capacity_flow${index}`}
                            value={addLine[0].al_post_filters_capacity_flow}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Post Filter Capacity Flow"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_post_filters_capacity_flow: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_post_filters_capacity_flow && (
                            <div className="text-danger">
                              {errorLine.al_post_filters_capacity_flow}
                            </div>
                          )}
                        </div>

                        <div className="table-cell cell-action cellActionCustom">
                          <span></span>
                        </div>
                      </div>
                      {validateMessage && (
                        <div className="text-danger">{validateMessage}</div>
                      )}
                    </>
                  );
                })}
              <div>
                <div ref={textInput} style={{ float: "left", clear: "both" }} />
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div
        className="d-md-none d-lg-none table-container"
        id={`AddCompressor-table-${id}`}
      >
        <Form name="AirTreatmentForm" autoComplete="off">
          <div className="tableWrapperCompressorModal mobileTableModal">
            <div className="table-body tableBodyCompressorModal">
              {airLine.length > 0 &&
                airLine.map((item, index) => {
                  return (
                    <div className="outerTabDiv">
                      <div className="statusHighlight"></div>
                      <div
                        className={
                          "table-row1 " +
                          (item.edit
                            ? "mobileTableRowEdit "
                            : "mobileTableRow ") +
                          (showmoreless.includes(index) ? "showLess" : "showFull")
                        }
                        key={`row_${index}`}
                        id={`row_${index}`}
                      >
                        <div className="table-cell">
                          <span>Sr. No.</span>
                          {item.edit ? (
                            <input
                              type="text"
                              maxlength="100"
                              name="srno"
                              id={`srno${index}`}
                              value={`${index + 1}`}
                              onChange={(e) => handleChange(e, index)}
                              readOnly
                            />
                          ) : (
                            index + 1
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Dryer Make</span>
                          {item.edit ? (
                            <>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_dryer_make"
                                id={`al_dryer_make${index}`}
                                value={airLine[index].al_dryer_make}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Dryer Make"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_dryer_make: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_dryer_make && (
                                <div className="text-danger">
                                  {errorLine.al_dryer_make}
                                </div>
                              )}
                            </>
                          ) : (
                            item.al_dryer_make
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Dryer Model</span>
                          {item.edit ? (
                            <>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_dryer_model"
                                id={`al_dryer_model${index}`}
                                value={airLine[index].al_dryer_model}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Dryer Model"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_dryer_model: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_dryer_model && (
                                <div className="text-danger">
                                  {errorLine.al_dryer_model}
                                </div>
                              )}
                            </>
                          ) : (
                            item.al_dryer_model
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Dryer Type</span>
                          {item.edit ? (
                            <>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_dryer_type"
                                id={`al_dryer_type${index}`}
                                value={airLine[index].al_dryer_type}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Dryer Type"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_dryer_type: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_dryer_type && (
                                <div className="text-danger">
                                  {errorLine.al_dryer_type}
                                </div>
                              )}
                            </>
                          ) : (
                            item.al_dryer_type
                          )}
                        </div>
                        <div className="table-cell">
                          {item.edit ? (
                            <>
                              <span>
                                Dryer Capacity
                                <div className="filter-input mt-1">
                                  <span className="tableHeaderLabel">
                                    Parameter
                                  </span>
                                  <select
                                    disabled={addLine.length === 0}
                                    className="headerSelect"
                                    value={airTreatmentHeader.ah_dryer_unit}
                                    onChange={handleHeaderChange}
                                    name="ah_dryer_unit"
                                    id="ah_dryer_unit"
                                  >
                                    <option value="">Capacity Flow</option>
                                    {/* <option value="KW">KW</option>
                      <option value="ABC">ABC</option> */}
                                    {Capacity.map((fromunit, index) => (
                                      <option value={fromunit}>
                                        {fromunit}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </span>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_dryer_capacity_flow"
                                id={`al_dryer_capacity_flow${index}`}
                                value={airLine[index].al_dryer_capacity_flow}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Dryer Capacity Flow"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_dryer_capacity_flow: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_dryer_capacity_flow && (
                                <div className="text-danger">
                                  {errorLine.al_dryer_capacity_flow}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <span>Dryer Capacity</span>
                              {item.al_dryer_capacity_flow}
                            </>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Pre-Filters Make</span>
                          {item.edit ? (
                            <>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_pre_filters_make"
                                id={`al_pre_filters_make${index}`}
                                value={airLine[index].al_pre_filters_make}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Pre Filter Make"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_pre_filters_make: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_pre_filters_make && (
                                <div className="text-danger">
                                  {errorLine.al_pre_filters_make}
                                </div>
                              )}
                            </>
                          ) : (
                            item.al_pre_filters_make
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Pre-Filters Model</span>
                          {item.edit ? (
                            <>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_pre_filters_model"
                                id={`al_pre_filters_model${index}`}
                                value={airLine[index].al_pre_filters_model}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Pre Filter Model"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_pre_filters_model: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_pre_filters_model && (
                                <div className="text-danger">
                                  {errorLine.al_pre_filters_model}
                                </div>
                              )}
                            </>
                          ) : (
                            item.al_pre_filters_model
                          )}
                        </div>
                        <div className="table-cell">
                          <span> Pre-Filters Type</span>
                          {item.edit ? (
                            <>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_pre_filters_type"
                                id={`al_pre_filters_type${index}`}
                                value={airLine[index].al_pre_filters_type}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Pre Filter type"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_pre_filters_type: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_pre_filters_type && (
                                <div className="text-danger">
                                  {errorLine.al_pre_filters_type}
                                </div>
                              )}
                            </>
                          ) : (
                            item.al_pre_filters_type
                          )}
                        </div>
                        <div className="table-cell">
                          {item.edit ? (
                            <>
                              <span>
                                Pre-Filters Capacity
                                <div className="filter-input mt-1">
                                  <span className="tableHeaderLabel">
                                    Parameter
                                  </span>
                                  <select
                                    disabled={addLine.length === 0}
                                    className="headerSelect"
                                    value={
                                      airTreatmentHeader.ah_pre_filter_unit
                                    }
                                    onChange={handleHeaderChange}
                                    name="ah_pre_filter_unit"
                                    id="ah_pre_filter_unit"
                                  >
                                    <option value="">Capacity Flow</option>
                                    {/* <option value="ABC">ABC</option> */}
                                    {Capacity.map((fromunit, index) => (
                                      <option value={fromunit}>
                                        {fromunit}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </span>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_pre_filters_capacity_flow"
                                id={`al_pre_filters_capacity_flow${index}`}
                                value={
                                  airLine[index].al_pre_filters_capacity_flow
                                }
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Pre Filter Capacity Flow"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_pre_filters_capacity_flow: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_pre_filters_capacity_flow && (
                                <div className="text-danger">
                                  {errorLine.al_pre_filters_capacity_flow}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <span>Pre-Filters Capacity</span>
                              {item.al_pre_filters_capacity_flow}
                            </>
                          )}
                        </div>

                        <div className="table-cell">
                          <span>Post Filters Make</span>
                          {item.edit ? (
                            <>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_post_filters_make"
                                id={`al_post_filters_make${index}`}
                                value={airLine[index].al_post_filters_make}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Post Filter Make"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_post_filters_make: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_post_filters_make && (
                                <div className="text-danger">
                                  {errorLine.al_post_filters_make}
                                </div>
                              )}
                            </>
                          ) : (
                            item.al_post_filters_make
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Post Filters Model</span>
                          {item.edit ? (
                            <>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_post_filters_model"
                                id={`al_post_filters_model${index}`}
                                value={airLine[index].al_post_filters_model}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Post Filter Model"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_post_filters_model: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_post_filters_model && (
                                <div className="text-danger">
                                  {errorLine.al_post_filters_model}
                                </div>
                              )}
                            </>
                          ) : (
                            item.al_post_filters_model
                          )}
                        </div>
                        <div className="table-cell">
                          <span> Post Filters Type</span>
                          {item.edit ? (
                            <>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_post_filters_type"
                                id={`al_post_filters_type${index}`}
                                value={airLine[index].al_post_filters_type}
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Post Filter Type"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_post_filters_type: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_post_filters_type && (
                                <div className="text-danger">
                                  {errorLine.al_post_filters_type}
                                </div>
                              )}
                            </>
                          ) : (
                            item.al_post_filters_type
                          )}
                        </div>
                        <div className="table-cell">
                          {item.edit ? (
                            <>
                              <span>
                                Post Filters Capacity
                                <div className="filter-input mt-1">
                                  <span className="tableHeaderLabel">
                                    Parameter
                                  </span>
                                  <select
                                    disabled={addLine.length === 0}
                                    value={
                                      airTreatmentHeader.ah_post_filter_unit
                                    }
                                    className="headerSelect"
                                    onChange={handleHeaderChange}
                                    name="ah_post_filter_unit"
                                    id="ah_post_filter_unit"
                                  >
                                    <option value="">Capacity Flow</option>
                                    {/* <option value="ABC">ABC</option> */}
                                    {Capacity.map((fromunit, index) => (
                                      <option value={fromunit}>
                                        {fromunit}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </span>
                              <input
                                type="text"
                                maxlength="100"
                                name="al_post_filters_capacity_flow"
                                id={`al_post_filters_capacity_flow${index}`}
                                value={
                                  airLine[index].al_post_filters_capacity_flow
                                }
                                onChange={(e) => handleChange(e, index, "edit")}
                                onInput={(e) => {
                                  let error = alphaNumeric100(
                                    e.target.value,
                                    "Post Filter Capacity Flow"
                                  );
                                  setErrorLine((prevState) => ({
                                    ...prevState,
                                    al_post_filters_capacity_flow: error,
                                  }));
                                }}
                                required
                              />
                              {errorLine.al_post_filters_capacity_flow && (
                                <div className="text-danger">
                                  {errorLine.al_post_filters_capacity_flow}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <span>Post Filters Capacity</span>
                              {item.al_post_filters_capacity_flow}
                            </>
                          )}
                        </div>

                        <div className="table-cell cell-action cellActionCustom">
                          {!editAction && !item.edit && (
                            <div className="tableButtons">
                              {/* <BiEdit
                                hidden={
                                  props.IsDisabled === false ? true : false
                                }
                                size={32}
                                style={{ padding: "5px" }}
                                color={"blue"}
                                onClick={(e) => handleEdit(item, index)}
                              /> */}
                              <img
                                src={Edit}
                                alt="search"
                                hidden={
                                  props.IsDisabled === false ? true : false
                                }
                                style={{ padding: "5px" }}
                                onClick={(e) => handleEdit(item, index)}
                              />
                              {/* <BiTrash
                                hidden={
                                  props.IsDisabled === false ? true : false
                                }
                                size={32}
                                style={{ padding: "5px" }}
                                color={"blue"}
                                // onClick={() => handleDelete(item, index)}
                                onClick={() => props.setShow2(true, item)}
                              /> */}
                              <img
                                src={Delete}
                                alt="delete"
                                hidden={
                                  props.IsDisabled === false ? true : false
                                }
                                style={{ padding: "5px" }}
                                onClick={() => props.setShow2(true, item)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {!item.edit && (
                        <div className="showButton">
                          {!showmoreless.includes(index) && (
                            <span
                              onClick={() => {
                                // let temp = [...airLine];
                                // temp[index].show = true;
                                // setAirLine([...temp]);

                                let temp = [...showmoreless];
                                temp.push(index);
                                setshowmoreless([...temp])
                              }}
                            >
                              Show More
                            </span>
                          )}
                          {showmoreless.includes(index) && (
                            <span
                              onClick={() => {
                                // let temp = [...airLine];
                                // temp[index].show = false;
                                // setAirLine([...temp]);
                                let temp = [...showmoreless];
                                const element = temp.indexOf(index);
                                  if (element > -1) { 
                                    temp.splice(element, 1); 
                                  }
                                setshowmoreless([...temp])
                              }}
                            >
                              Show Less
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              {addLine.length > 0 &&
                addLine.map((item, index) => {
                  return (
                    <>
                      <div
                        className="table-row1 mobileTableRowEdit"
                        key={`row_${index}`}
                        id={`row_${index}`}
                      >
                        <div className="table-cell">
                          <span>Sr. No.</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="srno"
                            id={`srno${index}`}
                            value={`${airLine?.length + 1}`}
                            onChange={(e) => handleChange(e, index, "add")}
                            readOnly
                          />
                        </div>
                        <div className="table-cell">
                          <span>Dryer Make</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_dryer_make"
                            id={`al_dryer_make${index}`}
                            //value={}
                            value={addLine[0].al_dryer_make}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Dryer Make"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_dryer_make: error,
                              }));
                            }}
                          />
                          {errorLine.al_dryer_make && (
                            <div className="text-danger">
                              {errorLine.al_dryer_make}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Dryer Model</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_dryer_model"
                            id={`al_dryer_model${index}`}
                            value={addLine[0].al_dryer_model}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Dryer Model"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_dryer_model: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_dryer_model && (
                            <div className="text-danger">
                              {errorLine.al_dryer_model}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Dryer Type</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_dryer_type"
                            id={`al_dryer_type${index}`}
                            value={addLine[0].al_dryer_type}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Dryer Type"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_dryer_type: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_dryer_type && (
                            <div className="text-danger">
                              {errorLine.al_dryer_type}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span style={{ width: "100%" }}>
                            Dryer Capacity
                            <div className="filter-input mt-1">
                              <span className="tableHeaderLabel">
                                Parameter
                              </span>
                              <select
                                disabled={addLine.length === 0}
                                className="headerSelect"
                                value={airTreatmentHeader.ah_dryer_unit}
                                onChange={handleHeaderChange}
                                name="ah_dryer_unit"
                                id="ah_dryer_unit"
                              >
                                <option value="">Capacity Flow</option>
                                {/* <option value="KW">KW</option>
                      <option value="ABC">ABC</option> */}
                                {Capacity.map((fromunit, index) => (
                                  <option value={fromunit}>{fromunit}</option>
                                ))}
                              </select>
                            </div>
                          </span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_dryer_capacity_flow"
                            id={`al_dryer_capacity_flow${index}`}
                            value={addLine[0].al_dryer_capacity_flow}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Dryer Capacity Flow"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_dryer_capacity_flow: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_dryer_capacity_flow && (
                            <div className="text-danger">
                              {errorLine.al_dryer_capacity_flow}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Pre-Filters Make</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_pre_filters_make"
                            id={`al_pre_filters_make${index}`}
                            value={addLine[0].al_pre_filters_make}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Pre Filter Make"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_pre_filters_make: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_pre_filters_make && (
                            <div className="text-danger">
                              {errorLine.al_pre_filters_make}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Pre-Filters Model</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_pre_filters_model"
                            id={`al_pre_filters_model${index}`}
                            value={addLine[0].al_pre_filters_model}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Pre Filter Model"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_pre_filters_model: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_pre_filters_model && (
                            <div className="text-danger">
                              {errorLine.al_pre_filters_model}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span> Pre-Filters Type</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_pre_filters_type"
                            id={`al_pre_filters_type${index}`}
                            value={addLine[0].al_pre_filters_type}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Pre Filter type"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_pre_filters_type: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_pre_filters_type && (
                            <div className="text-danger">
                              {errorLine.al_pre_filters_type}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span style={{ width: "100%" }}>
                            Pre-Filters Capacity
                            <div className="filter-input mt-1">
                              <span className="tableHeaderLabel">
                                Parameter
                              </span>
                              <select
                                disabled={addLine.length === 0}
                                className="headerSelect"
                                value={airTreatmentHeader.ah_pre_filter_unit}
                                onChange={handleHeaderChange}
                                name="ah_pre_filter_unit"
                                id="ah_pre_filter_unit"
                              >
                                <option value="">Capacity Flow</option>
                                {/* <option value="ABC">ABC</option> */}
                                {Capacity.map((fromunit, index) => (
                                  <option value={fromunit}>{fromunit}</option>
                                ))}
                              </select>
                            </div>
                          </span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_pre_filters_capacity_flow"
                            id={`al_pre_filters_capacity_flow${index}`}
                            value={addLine[0].al_pre_filters_capacity_flow}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Pre Filter Capacity Flow"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_pre_filters_capacity_flow: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_pre_filters_capacity_flow && (
                            <div className="text-danger">
                              {errorLine.al_pre_filters_capacity_flow}
                            </div>
                          )}
                        </div>

                        <div className="table-cell">
                          <span>Post Filters Make</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_post_filters_make"
                            id={`al_post_filters_make${index}`}
                            value={addLine[0].al_post_filters_make}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Post Filter Make"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_post_filters_make: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_post_filters_make && (
                            <div className="text-danger">
                              {errorLine.al_post_filters_make}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Post Filters Model</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_post_filters_model"
                            id={`al_post_filters_model${index}`}
                            value={addLine[0].al_post_filters_model}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Post Filter Model"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_post_filters_model: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_post_filters_model && (
                            <div className="text-danger">
                              {errorLine.al_post_filters_model}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span> Post Filters Type</span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_post_filters_type"
                            id={`al_post_filters_type${index}`}
                            value={addLine[0].al_post_filters_type}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Post Filter Type"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_post_filters_type: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_post_filters_type && (
                            <div className="text-danger">
                              {errorLine.al_post_filters_type}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span style={{ width: "100%" }}>
                            Post Filters Capacity
                            <div className="filter-input mt-1">
                              <span className="tableHeaderLabel">
                                Parameter
                              </span>
                              <select
                                disabled={addLine.length === 0}
                                value={airTreatmentHeader.ah_post_filter_unit}
                                className="headerSelect"
                                onChange={handleHeaderChange}
                                name="ah_post_filter_unit"
                                id="ah_post_filter_unit"
                              >
                                <option value="">Capacity Flow</option>
                                {/* <option value="ABC">ABC</option> */}
                                {Capacity.map((fromunit, index) => (
                                  <option value={fromunit}>{fromunit}</option>
                                ))}
                              </select>
                            </div>
                          </span>
                          <input
                            type="text"
                            maxlength="100"
                            name="al_post_filters_capacity_flow"
                            id={`al_post_filters_capacity_flow${index}`}
                            value={addLine[0].al_post_filters_capacity_flow}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric100(
                                e.target.value,
                                "Post Filter Capacity Flow"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                al_post_filters_capacity_flow: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.al_post_filters_capacity_flow && (
                            <div className="text-danger">
                              {errorLine.al_post_filters_capacity_flow}
                            </div>
                          )}
                        </div>

                        <div className="table-cell cell-action cellActionCustom">
                          <span></span>
                        </div>
                      </div>
                      {validateMessage && (
                        <div className="text-danger">{validateMessage}</div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </Form>
      </div>
      <div className="justify-content-start" id="addcompressorbutton">
        <Row className="gx-2 mx-0 mt-2">
          {addLine.length || editAction ? (
            <Col className="d-flex gap-2">
              <Button
                hidden={props.IsDisabled === false ? true : false}
                type="submit"
                bsPrefix="btn-add"
                onClick={() => {
                  if (editLine.length > 0) {
                    editAirTreatment();
                  } else {
                    addAirTreatment();
                  }
                }}
              >
                {"Save"}
              </Button>
              <Button
                hidden={props.IsDisabled === false ? true : false}
                variant="outline-primary"
                //  className="ml-2"
                // onClick={(e) => props.closeModal()}
                onClick={() => {
                  if (editLine.length > 0) {
                    setEditLine([]);
                    setEditAction(false);
                    props.reloadModal();
                  } else {
                    setAddLine([]);
                    setEditAction(false);
                  }
                  setScrollFlag(false);
                }}
              >
                Cancel
              </Button>
            </Col>
          ) : (
            <Col md={5}>
              <Button
                hidden={props.IsDisabled === false ? true : false}
                variant="outline-primary"
                style={{ lineHeight: "1" }}
                bsPrefix="btn-primary action-btn mt-2"
                onClick={() => {
                  handleAddClick();
                }}
              >
                <BsPlusLg
                  size={32}
                  className="p-2 ms-2"
                  alignmentBaseline="left"
                />
                <span className="btn-text">
                  Add Air Treatment Equipment Data
                </span>
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default AddAirTreatment;
