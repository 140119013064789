import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_DEALER_API_URL;
const API_DL_URL = process.env.REACT_APP_DEALERLOCATION_API_URL;
const API_DL_BKP_URL = process.env.REACT_APP_API_DL_BKP_URL;
//const API_URL = "http://localhost:5000/dealer"

console.log(API_DL_URL, API_URL);
export async function getAll() {
  let url = API_URL;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getDealersPopUp() {
  let url = API_URL + "/popup";
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllActiveDealers() {
  let url = API_URL + "/active";
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getDealer(DealerCode) {
  let url = `${API_URL}/DealerCode/${DealerCode}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function deleteDealer(DealerCode) {
  let url = `${API_URL}/dealercode/${DealerCode}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export const UpdateDealerLocation = async (user) => {
  console.log(JSON.stringify(user));
  let url = `${API_DL_URL}/update/DealerCode/${user.dm_dealercode}`;
  console.log(url);
  const response = await axios
    .post(url, JSON.stringify(user))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateDealer = async (user) => {
  console.log(JSON.stringify(user));
  let url = `${API_URL}/update/DealerCode/${user.dm_dealercode}`;
  const response = await axios
    .post(url, JSON.stringify(user))
    .then(function (resp) {
      console.log(resp);
      return resp;
    })
    .catch(function (error) {
      console.log(error);
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

// export async function deleteDealer(DealerCode) {
//   //console.log("deleteDealer");

//   let url = `${API_URL}/DealerCode/${DealerCode}`;
//   const resp = await axios
//     .delete(url)
//     .then(function (response) {
//       return response;
//     })
//     .catch(function (error) {
//       return error.message;
//     });

//   //console.log(resp);
//   return resp;
// }

export const enrollnewdealerlocbkp = async (User) => {
  console.log(JSON.stringify(User));
  let url = `${API_URL}/dealerlocationbkp`;
  console.log(url);
  const response = await axios
    .post(url, JSON.stringify(User))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function deletefromdealerlocation(DealerCode) {
  let url = `${API_DL_URL}/dealercode/${DealerCode}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export const enrollnewdealerloc = async (User) => {
  console.log(JSON.stringify(User));
  let url = API_DL_URL;
  console.log(API_DL_URL);
  const response = await axios
    .post(url, JSON.stringify(User))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//toget location from dealer_location while selecting location code

export const EnrollNewDealer = async (User) => {
  console.log(User);
  console.log(JSON.stringify(User));
  let url = API_URL;
  const response = await axios
    .post(url, JSON.stringify(User))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getUserByCode = async (UserID) => {
  let url = `${API_URL}/userId/${UserID}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  return resp;
};

export async function getDealerEnqLocation(dealer) {
  let url = `${API_URL}/locid/${dealer}`;
  console.log(url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}
