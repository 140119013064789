import { BsPlusLg, BsX } from "react-icons/bs";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import html2canvas from "html2canvas";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

import React from "react";
import { toPng } from "html-to-image";
import * as htmlToImage from "html-to-image";
import axios from "../../utils/axios";
import { Col, Nav, Row, Tab, Alert, Button } from "react-bootstrap";
import ReactFlow, {
  Controls,
  MarkerType,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";
import {
  GetNetwork,
  SaveCoordinateFourth,
} from "../../Services/new-solution-service";
import {
  CallAlgorithm,
  CallVFDAlgorithm,
  GetSolConnection,
} from "../../Services/sol-connection-service";
import CompressorNode from "./CompressorNode";
import TextUpdaterNode from "./TextUpdaterNode";
import ApplicationNode from "./ApplicationNode";
import UPCNode from "./UPCNode";
import { decimalFloat } from "../../utils/twoDecimal";
import {
  getEnquiryById,
  deleteIfcUpc,
  getNetworkDetails,
  getIfcUpcDetails,
} from "../../../src/Services/UpcIfcSolution-service";
import { getSumOfCompressorHeader } from "../../../src/Services/compressor-house-service";
import { getAllProductModels } from "../../../src/Services/product-service";
import IFCDetails from "./IFCDetails";
import UPCDetails from "./UPCDetails";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useHistory, withRouter } from "react-router-dom";
import { getLinkedNetworkDetails } from "../../Services/network-service";
import { getAllEnquiryCustomers } from "../../Services/enquiry-customer-service";
import WarningModal from "../Modals/warningModal";
import { uploadPresignedImg } from "../../utils/presignUpload";

const UpcIfcSoln = forwardRef(
  ({ userId, page4key, IsDisabled, enquirydata, EnquiryNumber, ...props }, ref) => {
    // enquiryid = 129;
    let enquiryid = props.enquiryid
      ? props.enquiryid
      : localStorage.getItem("enqkey");
    let enqstatus = enquirydata?.ed_enqstatus;
    console.log("enqstatus", enqstatus);
    console.log("propsdetailsIFCUPC", props);
    const [type, setType] = useState("");
    const [flag, setFlag] = useState(false);
    const [network, setNetwork] = useState([]);
    const [node, setNode, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [Currency, setCurrency] = useState("");
    const [NodeCode, setNodeCode] = useState("");
    const [NodeType, setNodeType] = useState("");

    const [IFC_n2App, setIFC_n2App] = useState("");
    const [nominalFlow, setNominalFlow] = useState("");
    const [model, setModel] = useState("");
    const [rfInstance, setRfInstance] = useState(null);
    const [imgPath, setImgPath] = useState(enquirydata?.ed_imgpath);
    const nodeTypes = useMemo(
      () => ({
        textUpdater: TextUpdaterNode,
        application: ApplicationNode,
        upcnode: UPCNode,
        compressor: CompressorNode,
      }),
      []
    );
    let imagepath = "";

    const API_ENDPOINT = process.env.REACT_APP_PRESIGNED_ENDPOINT;

    //"https://9zc9mapgv5.execute-api.us-east-1.amazonaws.com/default/getPresignedFileURL";
    //  "http://localhost:5020/aem/api/common/getPresignedUrl";
    let formName = "UPC/IFC Solution";
    const [reload, setReload] = useState(false);
    const [showForm, setShowForm] = useState("");
    const [showUPC, setShowUPC] = useState(false);
    const [action, setAction] = useState("");
    const [applications, setApplications] = useState([]);

    const [amb_temp, setAmb_temp] = useState("");
    const [networkDetail, setNetworkDetail] = useState("");
    const [showMessage, setShowMessage] = useState("");
    const [showWarning, setShowWarning] = useState("");
    const [networkDetl, setNetworkDetl] = useState("");
    const [product, setProduct] = useState([]);
    const [CHObj, setCHObj] = useState("");
    const [ifcUpcDetail, setIfcUpcDetail] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [show1, setShow1] = useState(false);
    const [msg, setMsg] = useState("");
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [nodeUpdateStatus, setNodeUpdateStatus] = useState(false);
    // const [diaHeight, setDiaHeight] = useState("200px");

    const [algObj, setAlgObj] = useState({
      enquiryid: enquiryid,
      individualOrCommonIFC: enquirydata?.ed_ifc_type
        ? enquirydata?.ed_ifc_type
        : "Common",
      solutionType: "",
      userId: userId,
    });
    const networkGroup = {
      enquiryid: enquiryid,
      userId: userId,
    };
    const [ifcCalculations, setIfcCalculations] = useState({});
    const fetchEnquiryTemp = async () => {
      const result = await getEnquiryById(enquiryid);
      setAmb_temp(result);
      setCurrency(result[0]?.ed_currencyvalue);
      console.log("getSumOfCompressorHeader1");
      const resSum = await getSumOfCompressorHeader(enquiryid, "IFC");
      console.log(resSum?.ch_avg_icfm);
      setCHObj(resSum?.ch_avg_icfm);
      console.log(CHObj);
      const resNtwrkDtl = await getNetworkDetails(enquiryid);
      console.log("resNtwrkDtl", resNtwrkDtl);
      setNetworkDetail(resNtwrkDtl);
      console.log("ifcUpcDetail", ifcUpcDetail);
    };

    const fetchProduct = async () => {
      const resProduct = await getAllProductModels();
      console.log("products", resProduct);
      setProduct(resProduct);
    };

    // const downloadImage1 = async () => {
    //   let domEl = document.getElementById("flowbackground1");
    //  let height=  props.maxApp > props.maxComp ? props.maxApp : props.maxComp;
    //  height=parseInt( height.substring(0,height.length-2));
    //  console.log("height",height)
    //   const dataUrl = await htmlToImage.toPng(domEl, { quality: 1, canvasWidth: 3000, canvasHeight: height*2 });

    //   debugger;
    //   // download image
    //   const a = document.createElement("a");

    //   a.setAttribute("download", "reactflow.png");
    //   a.setAttribute("href", dataUrl);
    //   a.click();
    //   // link.download = "html-to-img.png";
    //   // link.href = dataUrl;
    //   // link.click();
    // };

    const fetchAppDetails = (sc_source) => {
      axios
        .get(`/ifcupcAlgorithm/getSolnConnection/${enquiryid}/${sc_source}`)
        .then((response) => {
          if (response.status === 200) {
            let temp = response.data;
            if (!temp) {
              setIFC_n2App("No");
            } else {
              setIFC_n2App("Yes");
            }
            //setApplications(temp);
            console.log("log", IFC_n2App);
          }
        })
        .catch((e) => {
          console.log("soln connectn--", e);
        })
        .finally(() => { });
    };

    const [compHouse, setCompHouse] = useState([
      {
        ch_enquiry_id: enquiryid,
        ch_controlsettings_unit: "",
        ch_capacity_unit: "",
        ch_power_unit: "",
        ch_energy_consumption_per_day: "",
        ch_avg_icfm: "",
        ch_comp_code: "",
        ch_comp_house_id: "",
        ch_sequence_number: "",
      },
    ]);
    const [airTreatment, setAirTreatment] = useState([
      {
        ah_enquiry_id: enquiryid,
        ah_compressor_id: "",
        ah_air_id: "",
        ah_dryer_unit: "",
        ah_pre_filter_unit: "",
        ah_post_filter_unit: "",
      },
    ]);

    const fetchCompHouseHeader = () => {
      axios
        .get(`/maintenance/compDetail/header/${enquiryid}`)
        .then((response) => {
          if (response.status === 200) {
            let temp = response.data.data;
            if (temp.length) {
              const max = Math.max.apply(
                Math,
                temp.map(function (o) {
                  return o.ch_y_axis;
                }),
                0
              );
              props.setMaxComp(max + 200);
              console.log("TAB4comp", temp, max);
              setCompHouse([...temp]);
              addNode(temp);
            }
          }
        })
        .catch((err) => console.log(err));
    };

    const fetchAirTreatmentHeader = () => {
      axios
        // .get(`/airTreatment/header/ah_enquiry_id/${enquiryid}`)
        .get(`/appDetail/ad_enquiry_id/${enquiryid}`)
        .then((response) => {
          if (response.status === 200) {
            let temp = response?.data?.data;
            if (temp && temp?.length) {
              const max = Math.max.apply(
                Math,
                temp.map(function (o) {
                  return o.ad_y_axis;
                }),
                0
              );
              props.setMaxApp(max + 200);
              console.log("TAB4app", temp, max);
              setAirTreatment([...temp]);
              addApplicationNode(temp);
            }
          }
        })
        .catch((err) => console.log(err));
    };

    const fetchNetworkDetails = () => {
      getLinkedNetworkDetails(enquiryid)
        .then((response) => {
          console.log("ntwkDtl", response);
          let res = { ...algObj };
          res.solutionType = enquirydata?.ed_algorithm_type
            ? enquirydata?.ed_algorithm_type
            : response.tableRows.length > 1
              ? "UPC+IFC"
              : "IFC";
          res.enquiryid = props.enquiryid
            ? props.enquiryid
            : localStorage.getItem("enqkey");
          setAlgObj(res);
          setNetworkDetl(response.tableRows);
        })
        .catch((err) => console.log(err));
    };

    const fetchSolConnection = () => {
      GetSolConnection(enquiryid)
        .then((response) => {
          if (response.status === 200) {
            console.log("GetNetwork", response.data);
            let temp = response?.data;
            if (temp && temp?.length) {
              console.log("GetNetworkIF");
              setNetwork((prev) => {
                return temp.concat(prev);
              });
              addSolEdge(temp);
              fetchCompHouseHeader();
              fetchAirTreatmentHeader();
              fetchIfcUpcDetail();
              fetchEnquiryTemp();
            } else {
              setNode([]);
            }
          }
        })
        .catch((err) => console.log(err));
    };

    const fetchIfcUpcDetail = async () => {
      await getIfcUpcDetails(enquiryid, false)
        .then((response) => {
          console.log("ifcUpcDetailres", response.IFCdetails);
          setIfcUpcDetail(response.IFCdetails);
          addIfcUpcNode(response.IFCdetails);
        })
        .catch((err) => console.log(err));
    };

    const validateIFC = () => {
      console.log("imgPathSol",imgPath);
      let flag = true;
      console.log("ifcUpcDetail", ifcUpcDetail);
      if (ifcUpcDetail.length === 0) {
        flag = false;
        setShowMessage("Click on Simulate button first");
        setShowWarning(true);
      }
      ifcUpcDetail.forEach((value) => {
        if (value.id_ifc_code.includes("UPC")) {
          if (
            value.id_product_model === null ||
            value.id_nominalflow === null
          ) {
            flag = false;
            setShowMessage(
              `Enter values in ${value.id_ifc_code} ${"-"} ${value.id_product_model
              }`
            );
            setShowWarning(true);
          }
        } else if (value.id_ifc_code.includes("IFC")) {
          if (
            value.id_product_model === null ||
            value.id_nominalflow === null ||
            value.id_set_pressure === null ||
            value.id_inlet_pressure === null
          ) {
            flag = false;

            setShowMessage(
              `Enter values in  ${value.id_ifc_code} ${"-"} ${value.id_product_model
              }`
            );
            setShowWarning(true);
          }
        }
      });
      return flag;
    };

    const validateIFCforTab = (result) => {
      console.log("imgPath",imgPath);
      let flag = true;
      result.forEach((value) => {
        if (value.id_ifc_code.includes("UPC")) {
          if (
            value.id_product_model === null ||
            value.id_nominalflow === null
          ) {
            flag = false;
            setShowMessage(
              `Enter values in ${value.id_ifc_code} ${"-"} ${value.id_product_model
              }`
            );
            setShowWarning(true);
          }
        } else if (value.id_ifc_code.includes("IFC")) {
          if (
            value.id_product_model === null ||
            value.id_nominalflow === null ||
            value.id_set_pressure === null ||
            value.id_inlet_pressure === null
          ) {
            flag = false;

            setShowMessage(
              `Enter values in  ${value.id_ifc_code} ${"-"} ${value.id_product_model
              }`
            );
            setShowWarning(true);
          }
        }
      });

      if(!imgPath)
      {
        setShowMessage("Solution diagram not saved. Please click on next button.");
        setShowWarning(true);
        flag = false;
      }
      return flag;
    };

    useImperativeHandle(ref, () => ({
      runValidate3: async () => {
        console.log("valid 4");
        let result = await validateIFC();
        if (result) {
          let downloadresponse = await downloadImage();
          let saveNodeResponse = await SaveNodes();
          console.log("downloadresponse", downloadresponse);
          console.log("saveNodeResponse", saveNodeResponse);

          if (downloadresponse && saveNodeResponse) {
            return true;
          }
          else {
            return false;
          }
        }
        //        return result;
      },
      TempRunValidate3: async () => {
        let saveNodeResponse = await SaveNodes();
        // let downloadimageResp = await downloadImage();
        setMsg("Enquiry Updated Successfully!");
        setShowSuccess(true);
      },
      tabCheck4: async () => {
        let result1;
        let result = await getIfcUpcDetails(enquiryid, false);
        if (result.IFCdetails.length > 0) {
          result1 = await validateIFCforTab(result.IFCdetails);
        } else {
          result1 = false;
        }
        return result1;
      },
    }));

    useEffect(() => {
      console.log("flag1", flag);
      if (flag && rfInstance) {
        setTimeout(() => {
          rfInstance.fitView();
          console.log("flag", rfInstance, node.length);
          setFlag(false);
        }, 10);
      }
    }, [flag]);

    useEffect(async () => {
      if (page4key === 4) {
        console.log("fetchSolConnection");
        fetchSolConnection();
        let saveNodeResponse = await SaveNodes();
      }
    }, [enquiryid, page4key, NodeCode]);

    useEffect(() => {
      if (nodeUpdateStatus) {
        fetchIfcUpcDetail();
        setNodeUpdateStatus(false);
      }
    }, [nodeUpdateStatus])

    // useEffect(() => {
    //   fetchAppDetails();
    //   //  fetchEnquiryTemp();
    // }, []);

    useEffect(() => {
      if (page4key === 4) {
        setLoading(true);
        fetchNetworkDetails();
        setLoading(false);
      }
    }, [page4key]);

    // useEffect(() => {
    //   if (network?.length > 1 && page4key === 4) {
    //     fetchCompHouseHeader();
    //     fetchAirTreatmentHeader();
    //     fetchIfcUpcDetail();
    //     fetchEnquiryTemp();
    //   }
    // }, [NodeCode, page4key]);

    const modalOnClick = () => {
      history.push({
        pathname: "/",
        data: "setUser",
      });
    };

    const handlechange = (e) => {
      let result = { ...algObj };
      if (e.target.name === "solution") {
        result.solutionType = e.target.value;
      }
      if (e.target.name === "individualOrCommon") {
        result.individualOrCommonIFC = e.target.value;
      }
      console.log("algObj", result);
      setAlgObj(result);
    };

    const handleCloseSuccess = () => setShowSuccess(false);

    const handleClose = () => {
      setShow1(false);
    };

    const handleAdd = async (val, nodeId, nodeaction, nodeType) => {
      console.log(val);
      setNodeCode(nodeId);
      setNodeType(nodeType);

      //setModel(model);
      //setNominalFlow(nominalFlow);

      if (val === "UPC" && nodeaction === "Edit") {
        setShowForm("UPC");
        setShowUPC(true);
      } else if (val === "IFC" && nodeaction === "Edit") {
        fetchAppDetails(nodeId);
        setShowForm("IFC");
        setShowUPC(true);
      } else if (action === "Delete") {
        //alert("Delete");
        setShow1(true);
      }
    };
    const handleDelete = async (e) => {
      let isError = false;
      let result = await deleteIfcUpc(enquiryid);
      console.log("result for delete", result);
      if (result) {
        setMsg(result.message);
        setShowSuccess(true);
        setShow1(false);
      } else {
        isError = true;
        console.log(result.message);
        setAlertMessage(result.message);
        setShow1(false);
        //setShowSuccess(true);
        return isError;
      }
    };
    let returnUnique = (itemsCollection, uniqueKey) => {
      const itemsMap = new Map();
      itemsCollection.forEach((item) => {
        if (itemsMap.size === 0) {
          itemsMap.set(item[uniqueKey], item);
        } else if (!itemsMap.has(item.id)) {
          itemsMap.set(item[uniqueKey], item);
        }
      });
      return [...new Set(itemsMap.values())];
    };

    const addNode = async (compHouse) => {
      let temp = [];
      compHouse.forEach((value, index) => {
        console.log("value1", value);
        temp.push({
          // id: "CH" + (index + 1),
          id: value.ch_comp_code,
          data: {
            label: (
              <div
                className="centertext"
                style={{ display: "grid", fontSize: "11px" }}
              >
                <span>{value?.ch_comp_house_name}</span>
                <span>{decimalFloat(value?.ch_avg_icfm)} CFM</span>
              </div>
            ),
          },
          type: "compressor",
          // position: { x: 60, y: (index + 1) * 100 },
          position: {
            // x: value?.ch_x_axis ? parseInt(value.ch_x_axis) < 0 ? 5 : parseInt(value.ch_x_axis) : 180,
            // y: value?.ch_y_axis
            //   ? parseInt(value.ch_y_axis) < 0 ? 5 : parseInt(value.ch_y_axis) + 0
            //   : (index + 1) * 100,
            x: value?.ch_x_axis ? parseInt(value.ch_x_axis) + 0 : 180,
            y: value?.ch_y_axis
              ? parseInt(value.ch_y_axis) + 0
              : (index + 1) * 100,
          },
          style: {
            borderRadius: "8px",
          },
          sourcePosition: "right",
          targetPosition: "left",
        });
      });
      setNode((prev) => {
        return returnUnique(temp.concat(prev), "id");
      });
      console.log("compNode", node);
    };
    const addApplicationNode = async (appHouse) => {
      let temp = [];
      appHouse.forEach((value, index) => {
        console.log("value2", value);
        temp.push({
          //  id: "APL" + (index + 1),
          id: value.ad_applicationcode,
          //data: { label: value?.ad_applicationname },
          data: {
            label: (
              <div
                className="centertext"
                style={{ display: "grid", fontSize: "11px", color: (value?.ad_n2application ? "#E5C204" : "#EF802F") }}
              >
                <span>{value?.ad_applicationname}</span>
                <span>
                  {value?.ad_maxflowrequired} {value?.ad_maxflowrunit}
                </span>
              </div>
            ),
          },
          type: "application",
          position: {
            // x: value?.ad_x_axis ? parseInt(value.ad_x_axis) < 0 ? 5 : parseInt(value.ad_x_axis) : 420,
            // y: value?.ad_y_axis
            //   ? parseInt(value.ad_y_axis) < 0 ? 5 : parseInt(value.ad_y_axis) + 0
            //   : (index + 1) * 100,

            x: value?.ad_x_axis ? parseInt(value.ad_x_axis) + 0 : 420,
            y: value?.ad_y_axis
              ? parseInt(value.ad_y_axis) + 0
              : (index + 1) * 100,
          },
          style: {
            borderRadius: "50%",
            border: "2px solid " + (value?.ad_n2application ? "#E5C204" : "#EF802F"),
            background: (value?.ad_n2application ? "#FDF599" : "#FFE3C3") + " 0% 0% no-repeat padding-box",
          },
          targetPosition: "left",
          sourcePosition: "right",
        });
      });
      setNode((prev) => {
        return returnUnique(temp.concat(prev), "id");
      });
      console.log("appNode", node);
    };

    const addIfcUpcNode = async (ifcUpcDetail) => {
      let temp = [];
      ifcUpcDetail.forEach((value, index) => {
        console.log("value3", value);
        temp.push({
          id: value?.id_ifc_code,
          data: {
            label: (
              <div
                className="centertext1"
                style={{ display: "grid", fontSize: "9px" }}
              >
                <span>{value?.id_ifc_code}</span>
                <span>{value?.id_product_model}</span>
              </div>
            ),
          },
          type: value?.id_is_ifc ? "textUpdater" : "upcnode",
          position: {
            x: value?.id_is_ifc
              ? value.id_x_axis
                ? parseInt(value.id_x_axis) + 0
                : 350
              : value.id_x_axis
                ? parseInt(value.id_x_axis) + 0
                : 60,
            // y: value.id_y_axis
            //   ? parseInt(value.id_y_axis) + 0
            //   : (index + 1) * 100,
            y: value?.id_is_ifc
              ? value.id_y_axis
                ? parseInt(value.id_y_axis) + 0
                : (index) * 100
              : value.id_y_axis
                ? parseInt(value.id_y_axis) + 0
                : 100,
          },
          style: {
            borderRadius: "30%",
          },
          targetPosition: "left",
          sourcePosition: "right",
        });
      });
      setNode((prev) => {
        return returnUnique(temp.concat(prev), "id");
      });
      console.log("ifcNode", node);
      setFlag(true);
    };

    const addSolEdge = (network) => {
      console.log("network", network);
      let temp = [];
      setEdges([]);
      network.forEach((value, index) => {
        console.log("value5", value);
        temp.push({
          id: "ifc-" + (index + 1),
          source: value.sc_source,
          target: value.sc_destination,
          animated: value.sc_ifccolor ? false : true,
          style: {
            stroke: value.sc_ifccolor ? value.sc_ifccolor : "black",
            strokeWidth: 2,
          },
          markerEnd: {
            // type: MarkerType.ArrowClosed,
            type: MarkerType.Arrow,
          },
          type: "step",
          label: "ab",
          labelStyle: {
            fill: value.sc_isolation ? "#FF0000" : "#33C037",
            // fontWeight: 1,
            display: value.sc_valve ? "" : "none",
          },
          labelBgBorderRadius: 50,
          labelBgStyle: {
            fill: value.sc_isolation ? "#FF0000" : "#33C037",
            fillOpacity: 1,
            border: "1px solid black",
            display: value.sc_valve ? "" : "none",
          },
        });
      });
      setEdges((prev) => {
        return returnUnique(temp.concat(prev), "id");
      });
      console.log("edges", edges);
    };

    const onNodeClick = (event, node) => {
      console.log("ifcUpcDetailonNodeClickdataifcUpcDetail", ifcUpcDetail, node);

      let nodeId = node.id;
      let data = ifcUpcDetail?.filter((item) => {
        return item.id_ifc_code.toString().indexOf(nodeId) >= 0;
      });
      let nodeType = data[0].id_prodtype;
      console.log("ifcUpcDetailonNodeClickdata", data, nodeType);
      console.log("ifcUpcDetailonNodeClickifcCalculations", ifcCalculations);
      setIfcCalculations(data[0]);

      if (nodeId.includes("IFC")) {
        // alert("node" + nodeId);
        setAction("Edit");
        //setAction("Delete");
        handleAdd("IFC", nodeId, "Edit", nodeType);
      } else if (nodeId.includes("UPC")) {
        // alert("node" + nodeId);
        setAction("Edit");
        //setAction("Delete");
        handleAdd("UPC", nodeId, "Edit", nodeType);
      }
      else {
        setShowForm("");
      }
    };

    const SaveNodes = async () => {
      if (rfInstance) {
        const flow = rfInstance.toObject();
        let temp = [];
        let saveResponse;
        flow.nodes.forEach((item) => {
          temp.push({
            id: item.id,
            x_axis: item.position.x,
            y_axis: item.position.y,
          });
        });

        let data = {
          network: temp,
          imgpath: imagepath,
        };
        if (temp.length > 0)
        saveResponse =  SaveCoordinateFourth(data, enquiryid);
        return saveResponse;
      }
    };


    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }

    async function downloadImage() {

      // const downloadImage = async () => {
      debugger;
      // htmlToImage.toPng(document.getElementById("flowbackground1"), {
      //   width: 1278,
      //   height: `${
      //     props.maxApp > props.maxComp ? props.maxApp : props.maxComp
      //   }`,
      // }).then(async function (canvas) {
      //  let a = document.createElement("a");
      //  a.download = "IFC-UPC-" + enquiryid + ".png";
      //  a.href = canvas.toDataURL("image/png");
      //  a.click();
      // var data = canvas.toDataURL("image/png");
      let dataUrl = "";
      let domEl = document.getElementById("flowbackground1");
      let maxApp = parseInt(props.maxApp);
      let maxComp = parseInt(props.maxComp);
      let height = maxApp > maxComp ? maxApp : maxComp;
      console.log("height", height, maxApp, maxComp)
      if (parseInt(height) < 1000) {
        dataUrl = await htmlToImage.toPng(domEl, { quality: 1 });
      }
      else {
        dataUrl = await htmlToImage.toPng(domEl, { quality: 1, canvasWidth: 3000, canvasHeight: height * 2 });
      }

      var file = dataURLtoFile(dataUrl, "IFC-UPC-" + enquiryid + ".png");
      console.log("CanvasDataFile", file);
      let fileName = "IFC-UPC-" + enquiryid + ".png";
      let uploadImgResponse;
      await axios
        .get(
          API_ENDPOINT +
          "/type/" +
          "UploadIFCUPC" +
          "/name/" +
          fileName +
          "/size/" +
          100000
        )
        .then(async function (response) {
          imagepath = response?.data?.uploadUrl.substring(
            0,
            response?.data?.uploadUrl.indexOf("?")
          );
          setImgPath(imagepath);
          uploadImgResponse = await uploadPresignedImg(file, response?.data?.uploadUrl).then((res) => {
            console.log("upload res", res);
            if (res.status === 200) {
              // alert("Uploaded successfully");
              console.log("Uploaded successfully");
              imagepath = response?.data?.uploadUrl.substring(
                0,
                response?.data?.uploadUrl.indexOf("?")
              );
              return true;
            } else {
              setShowMessage("Problem in saving solution diagram. Please try again!!");
              setShowWarning(true);
              return false;

            }
          });
        })
        .catch((e) => {
          setShowMessage("Problem in saving solution diagram. Please try again!");
          setShowWarning(true);
          console.log("error", e);
          return false;

        });
      return uploadImgResponse;
    };

    const createAlgorithm = (algObj, networkGroup) => {
      setImgPath(null);
      setLoading(true);
      console.log("algObj", algObj);
      console.log("networkGroup", networkGroup);
      CallVFDAlgorithm(networkGroup)
        .then((response) => {
          console.log("AlgoRespout", response);
          if (response.data.success === true) {
            CallAlgorithm(algObj)
              .then((response) => {
                console.log("AlgoResp", response);
                if (response.data.success === true) {
                  if (response.data.msg !== "") {
                    setShowMessage(response.data.msg);
                    setShowWarning(true);
                  }
                  setNode([]);
                  fetchCompHouseHeader();
                  fetchAirTreatmentHeader();

                  getIfcUpcDetails(enquiryid, false).then((response) => {
                    console.log("ifcUpcData", response);
                    setIfcUpcDetail(response.IFCdetails);
                    addIfcUpcNode(response.IFCdetails);
                  });
                  fetchEnquiryTemp();
                  GetSolConnection(enquiryid).then((response) => {
                    if (response.status === 200) {
                      console.log("GetNetwork", response.data);
                      let temp = response?.data;
                      // if (temp && temp?.length) {
                      setNetwork(temp);
                      addSolEdge(temp);
                      // }
                    }
                  });
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <>
        <WarningModal
          message={showMessage}
          show={showWarning}
          handleClose={() => {
            setShowWarning(false);
          }}
        />
        <SuccessAlertModal
          clickFunction={modalOnClick}
          //message={`Great! You’ve Deleted Role and Authorization successfully.`}
          msg={msg}
          show={showSuccess}
          handleClose={handleCloseSuccess}
        />

        <ConfirmAlertModal
          action={action}
          handleYes={handleDelete}
          formName={formName}
          show={show1}
          handleClose={handleClose}
        />
        <div classname="App">
          {/* <button onClick={() => downloadImage1()} >Download Image</button> */}
        </div>
        <div id="CompressorAppDetails">
          {alertMessage && (
            <Alert className="alertBox" variant="warning">
              {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
              {alertMessage}
            </Alert>
          )}
          <Tab.Container>
            <Nav
              bsPrefix="test-tabs"
              as={Row}
              className="m-0 g-0 solun-tab1-height"
              variant="tabs"
            // style={{
            //   height: "95px",
            //   // boxShadow: "2px 3px 4px #00000040",
            // }}
            >
              <div
                style={{
                  borderRadius: "8px 8px 0 0",
                  background:
                    "0% 0% no-repeat padding-box padding-box rgb(104, 104, 104)",
                  boxShadow: "2px 3px 4px #00000040",
                }}
              >
                <div className="d-none d-md-block d-lg-block">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      padding: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    <span
                      className="me-2 ms-1"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        height: "14px",
                        marginTop: "5px",
                      }}
                    >
                      Algorithm
                    </span>
                    <div className="input-group-sm me-2">
                      <select
                        className="custom-select"
                        name="solution"
                        style={{
                          fontSize: "14px",
                          width: "121px",
                          marginTop: "5px",
                          borderRadius: "4px",
                        }}
                        onChange={(e) => handlechange(e)}
                        value={algObj.solutionType}
                      >
                        {networkDetl?.length > 1 && (
                          <option value="UPC+IFC">UPC + IFC</option>
                        )}
                        <option value="IFC">IFC</option>
                      </select>
                    </div>
                    <div className="input-group-sm me-2">
                      <select
                        className="custom-select"
                        name="individualOrCommon"
                        style={{
                          fontSize: "14px",
                          width: "121px",
                          marginTop: "5px",
                          borderRadius: "4px",
                        }}
                        onChange={(e) => handlechange(e)}
                        value={algObj.individualOrCommonIFC}
                      >
                        <option value="Common">Common</option>
                        <option value="Individual">Individual</option>
                      </select>
                    </div>
                    <div>
                      <Button
                        disabled={IsDisabled === false ? true : false}
                        style={{
                          fontSize: "14px",
                          backgroundColor: IsDisabled === false ? "grey" : "",
                          borderColor: IsDisabled === false ? "grey" : "",
                        }}
                        onClick={() => createAlgorithm(algObj, networkGroup)}
                        size="sm"
                      >
                        Simulate
                      </Button>
                    </div>
                  </div>
                </div>

                <div
                  className="d-md-none d-lg-none"
                  style={{
                    display: "block",
                    gap: "5px",
                    padding: "5px",
                    marginLeft: "10px",
                  }}
                >
                  <span
                    className="me-2 ms-1"
                    style={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      height: "14px",
                      marginTop: "5px",
                    }}
                  >
                    Algorithm
                  </span>
                  <div className="input-group-sm me-2 place-center1  ">
                    <select
                      className="custom-select"
                      name="solution"
                      style={{
                        fontSize: "14px",
                        width: "48%",
                        marginTop: "5px",
                        borderRadius: "4px",
                      }}
                      onChange={(e) => handlechange(e)}
                      value={algObj.solutionType}
                    >
                      {networkDetl?.length > 1 && (
                        <option value="UPC+IFC">UPC + IFC</option>
                      )}
                      <option value="IFC">IFC</option>
                    </select>
                    {/* </div>
                  <div className="input-group-sm me-2"> */}
                    <select
                      className="custom-select"
                      name="individualOrCommon"
                      style={{
                        fontSize: "14px",
                        width: "48%",
                        marginTop: "5px",
                        borderRadius: "4px",
                      }}
                      onChange={(e) => handlechange(e)}
                      value={algObj.individualOrCommonIFC}
                    >
                      <option value="Common">Common</option>
                      <option value="Individual">Individual</option>
                    </select>
                  </div>
                  <div className="place-end mx-2 mb-5 mb-md-0">
                    <Button
                      className="mb-2"
                      disabled={IsDisabled === false ? true : false}
                      style={{
                        fontSize: "14px",
                        backgroundColor: IsDisabled === false ? "grey" : "",
                        borderColor: IsDisabled === false ? "grey" : "",
                      }}
                      onClick={() => createAlgorithm(algObj, networkGroup)}
                      size="sm"
                    >
                      Simulate
                    </Button>
                  </div>
                </div>
              </div>
            </Nav>
          </Tab.Container>
          {loading ? (
            <div className="loader-coPntainer">
              <div className="spinner"></div>
            </div>
          ) : (
            <Row
              id="flowbackground1"
              style={{
                height: "100%",
                background: "#686868 0% 0% no-repeat padding-box",
                border: "1px solid #707070",
              }}
              className="gx-3 mx-0 px-2 py-3"
            >
              <Col>
                <div id="flowBackground" className="react-flow-container">
                  <div className="place-center-eq">
                    <span className="eq-text">{EnquiryNumber}</span>
                  </div>
                  <div className="bottomright">
                    <div>
                      <span
                        className="dot-circle"
                        style={{ backgroundColor: "#FF0000" }}
                      ></span>
                      <span className="dot-text">Isolated</span>
                    </div>
                    <div>
                      <span
                        className="dot-circle"
                        style={{ backgroundColor: "#33C037" }}
                      ></span>
                      <span className="dot-text">Non Isolated</span>
                    </div>
                  </div>
                  {node && edges && <ReactFlow
                    // fitView={true}
                    nodes={node}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    nodeTypes={nodeTypes}
                    onNodeClick={onNodeClick}
                    onInit={setRfInstance}
                    zoomOnScroll={false}
                    zoomOnPinch={false}

                  // defaultZoom={0.7}
                  >
                    <Controls />
                  </ReactFlow>
                  }
                </div>
              </Col>
            </Row>
          )}
        </div>
        {/* )} */}
        {showUPC && showForm === "UPC" ? (
          <UPCDetails
            currency={Currency}
            show={showUPC}
            action={action}
            setAction={setAction}
            enquiryid={enquiryid}
            enqstatus={enqstatus}
            onHide={() => {
              setShowUPC(false);
              setNodeCode("");
              setNodeType("");
            }}
            amb_temp={amb_temp}
            ifcCalculations={ifcCalculations}
            setIfcCalculations={setIfcCalculations}
            setNodeUpdateStatus={setNodeUpdateStatus}
            // ifcUpcDetail={ifcUpcDetail}
            // setIfcUpcDetail={setIfcUpcDetail}
            networkDetail={networkDetail}
            formName={formName}
            NodeCode={NodeCode}
            NodeType={NodeType}
            setNodeCode={setNodeCode}
            IsDisabled={IsDisabled}
          />
        ) : showForm === "IFC" ? (
          <IFCDetails
            currency={Currency}
            NodeCode={NodeCode}
            NodeType={NodeType}
            setNodeCode={setNodeCode}
            show={showUPC}
            action={action}
            setAction={setAction}
            ifcN2App={IFC_n2App}
            applications={applications}
            enquiryid={enquiryid}
            enqstatus={enqstatus}
            amb_temp={amb_temp}
            ifcCalculations={ifcCalculations}
            setIfcCalculations={setIfcCalculations}
            setNodeUpdateStatus={setNodeUpdateStatus}
            onHide={() => {
              setShowUPC(false);
              setNodeCode("");
              setNodeType("");
            }}
            formName={formName}
            IsDisabled={IsDisabled}
          />
        ) : (
          ""
        )}
      </>
    );
  }
);

export default UpcIfcSoln;
