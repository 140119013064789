export default function Error403Dashboard({ errorTitle, ...props }) {
  return (
    <>
      <div id="fourOFour">
        <div className="fof">
          <h1>{"Access Denied"}</h1>
        </div>
      </div>
    </>
  );
}
