import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_COMPRESSOR_API_URL;

console.log(API_URL);
export async function getAllCompressorParameterMaster() {
  const response = await axios
    .get(API_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
  //return fetch(API_URL).then((response) => response.json());
}

export async function getAllAirCompMake() {
  let url = `${API_URL}/aircompmake`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export async function getAllAirCompType() {
  let url = `${API_URL}/aircomptype`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export async function getAllNonlubCompressor() {
  let url = `${API_URL}/lubCompressor`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllAirCompMakeYes() {
  console.log("infuncgetallaircomp");
  let url = `${API_URL}/aircompmakeyes`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllAirCompTypeYes() {
  //console.log("infuncgetallaircomp");
  let url = `${API_URL}/aircomptypeyes`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllAirCompLubYes() {
  //console.log("infuncgetallaircomp");
  let url = `${API_URL}/aircomplubyes`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getCompressorWithYes(Default, Parameter) {
  if (Parameter === "Lubricated / Non-Lubricated") {
    Parameter = "LubricatedNonLubricated";
  }
  let url = `${API_URL}/Default/${Default}/Parameter/${Parameter}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response.data);
      //alert('resp:: '+JSON.stringify(response.data))

      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export const AddNewCompressorParameter = async (CP) => {
  //alert('inside enroll--> '+JSON.stringify(User));

  const resp = await axios
    .post(API_URL, JSON.stringify(CP))
    .then(function (response) {
      console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });

  console.log(resp);

  return resp;
};
export const UpdateCompressorParameter = async (CP) => {
  console.log(JSON.stringify(CP));

  //JSON.stringify(item)
  let url = `${API_URL}/update/Parameter/${CP.Parameter}/CompressorId/${CP.CompressorId}`;
  //alert(url +JSON.stringify(item.Parameter) + JSON.stringify(item.CompressorId));
  const resp = await axios
    .post(url, JSON.stringify(CP))
    .then(function (response) {
      console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });

  console.log(resp);

  return resp;
};

export async function DeleteCompressor(comp) {
  let url = `${API_URL}/CompressorId/${comp.comp_id}`;
  const resp = await axios
    .post(url, comp)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

// , {
//   headers: {
//     "Content-Type": "application/json",
//   },
// }
