import { useState, useEffect, forwardRef } from "react";
import { Alert, Col, Container, Form, Row, Table } from "react-bootstrap";
import { removeComa } from "../../../functions/functions";
import {
  isNumberWithDecimalPM,
  isNumberWithDecimal,
  validDecimals,
  validINRCurrency,
  validUSDCurrency,
  isAlphanumericWithSpecialChars,
} from "../../../functions/validations";
//import { getIfcUpcDetails } from "../../../Services/UpcIfcSolution-service";

const TableBNew = forwardRef((props, ref) => {
  const tableB = props.DataTableB;
  const NetworkDetails = props.NetworkDetails;
  const currency = props.currency;
  // console.log(props.currency);
  const [FlagIV, setFlagIV] = useState([]);
  const [showmoreless, setshowmoreless] = useState([]);

  // const [Investment_B_Value, setInvestment_B_Value] = useState([]);
  // const [Investment_C_Value, setInvestment_C_Value] = useState([]);
  // const [Investment_D_Value, setInvestment_D_Value] = useState([]);
  // const [currentTableDataError, setCurrentTableDataError] = useState([]);

  //const [Investment_B_Value, setInvestment_B_Value] = useState([]);
  //const [alertMessage, setAlertMessage] = useState("");
  const [Inx, setInx] = useState("");

  // console.log(tableB, Investment_B_Value);

  // const handleChange = (e, index) => {};

  // const [error, setError] = useState({
  //   id_table_b_receiver: {},
  //   id_table_b_invest_value: {},
  //   id_table_c_receiver: {},
  //   id_table_c_invest_value: {},
  //   id_table_d_receiver: {},
  //   id_table_d_invest_value: {},
  //   id_remarks: {},
  //   index: {},
  // });
  // console.log(props.b1, props.b2);

  // let areNetName = new Array();
  // let are = new Array();
  // let count = 0;


  // const removeComaIV = (e, index, name) => {
  //   console.log(name, e);
  //   setFlagIV(true);
  //   console.log(e);
  //   var str = e.target.value.toString().split(".");
  //   // setInvestment_B_Value(0);
  //   console.log(str[0], str[1]);
  //   if (str[0].indexOf(",") >= 0) {
  //     let value = str[0].replace(/\,/g, ""); // 1125, but a string, so convert it to number
  //     value = parseInt(value, 10);
  //     console.log(value + "." + str[1]);
  //     if (str[1] !== undefined) {
  //       e.target.value = value + "." + str[1];
  //       if (name === "id_table_b_invest_value") {
  //         setInvestment_B_Value[index](value + "." + str[1]);
  //       } else if (name === "id_table_c_invest_value") {
  //         setInvestment_C_Value[index](value + "." + str[1]);
  //       } else if (name === "id_table_d_invest_value") {
  //         setInvestment_D_Value[index](value + "." + str[1]);
  //       }
  //     } else {
  //       e.target.value = value;
  //       if (name === "id_table_b_invest_value") {
  //         setInvestment_B_Value[index](value);
  //       } else if (name === "id_table_c_invest_value") {
  //         setInvestment_C_Value[index](value);
  //       } else if (name === "id_table_d_invest_value") {
  //         setInvestment_D_Value[index](value);
  //       }
  //     }

  //     // setInvestment_B_Value(0);
  //     //setUSDCurrency(0);
  //   }
  // };
  useEffect(() => {
    setshowmoreless([]);
  }, [props.page5key]);

  return (
    <div className="roiTable">
      <div className="d-none d-md-block d-lg-block">
        <div className="TableB">
          <Row className="ps-3 pe-2">
            <Col md={1} className="tableB-header">
              Sr. No.
            </Col>
            <Col md={1} className="tableB-header">
              Network
            </Col>
            <Col md={2} className="tableB-header">
              Additional Receiver Capacity Size(m<sup>3</sup>)
            </Col>
            <Col md={2} className="tableB-header">
              Additional Receiver Capacity Cost(
              {currency})
            </Col>
            <Col md={2} className="tableB-header">
              Installation Cost(
              {currency})
            </Col>
            <Col md={2} className="tableB-header">
              Other Cost(
              {currency})
            </Col>
            <Col md={2} className="tableB-header">
              Remarks
            </Col>
          </Row>
        </div>
        {tableB.length > 0 &&
          tableB.map((item, index) => {
            return (
              <div className="tableB-row">
                <Row className="ps-3 pe-2">
                  <Col md={1} className="tableB-text">
                    {index + 1}
                  </Col>
                  <Col md={1} className="tableB-text">
                    {"(" + NetworkDetails[index].networkname + ")"}
                  </Col>
                  <Col md={2} className="tableB-input">
                    <input
                      disabled={props.Disabled === false ? true : false}
                      type="text"
                      name="id_table_b_receiver"
                      className={props?.Error[index]?.id_table_b_receiver?.status ? "text-field-with-error " : ""}
                      value={item.id_table_b_receiver}
                      onChange={(e) => props.handleChange(e, index)}
                      onInput={(e) => {
                        let tempError = [...props?.Error];
                        let error = isNumberWithDecimal(e.target.value);
                        if (error.status) {
                          tempError[index].id_table_b_receiver = error;
                        } else {
                          error = validDecimals(e.target.value);
                          tempError[index].id_table_b_receiver = error;
                        }
                        props?.setError([...tempError]);
                      }}
                    // onBlur={(e) => {
                    //   if (error.id_table_b_receiver.status == true) {
                    //     item.id_table_b_receiver = "";
                    //     e.target.value = "";
                    //     tableB.id_table_b_receiver = "";
                    //     error.id_table_b_receiver.message = "";
                    //   }
                    // }}
                    />
                    {props?.Error[index]?.id_table_b_receiver?.status && (
                      <span className="req-input">
                        {props?.Error[index]?.id_table_b_receiver?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={2} className="tableB-input">
                    <input
                      disabled={props.Disabled === false ? true : false}
                      type="text"
                      name="id_table_b_invest_value"
                      className={props?.Error[index]?.id_table_b_invest_value?.status ? "text-field-with-error " : ""}
                      onFocus={() => setFlagIV((prev) => [...prev, "id_table_b_invest_value".concat(index)])}
                      onBlur={() => {
                        var array = [...FlagIV];
                        var ind = array.indexOf("id_table_b_invest_value".concat(index))
                        if (ind !== -1) {
                          array.splice(ind, 1);
                          setFlagIV(array);
                        }
                      }}
                      value={item.id_table_b_invest_value ?
                        props?.Error[index]?.id_table_b_invest_value?.status ? removeComa(item.id_table_b_invest_value) :
                          !FlagIV.includes("id_table_b_invest_value".concat(index))
                            ? currency === "INR"
                              ? validINRCurrency(item.id_table_b_invest_value)
                              : validUSDCurrency(item.id_table_b_invest_value)
                            : removeComa(item.id_table_b_invest_value)
                        : ""
                      }
                      onChange={(e) => { props.handleChange(e, index); }}
                      onInput={(e) => {
                        let tempError = [...props?.Error];
                        let error = isNumberWithDecimalPM(e.target.value);
                        if (error.status) {
                          tempError[index].id_table_b_invest_value = error;
                        } else {
                          error = validDecimals(e.target.value);
                          tempError[index].id_table_b_invest_value = error;
                        }
                        props?.setError([...tempError]);
                      }}
                    // onBlur={(e) => {
                    //   props.handleChange(e, index);
                    //   setFlagIV(false);
                    //   if (error.id_table_b_invest_value.status == true) {
                    //     item.id_table_b_invest_value = "";
                    //     e.target.value = "";
                    //     tableB.id_table_b_invest_value = "";
                    //     error.id_table_b_invest_value.message = "";
                    //   }
                    // }}
                    />
                    {props?.Error[index]?.id_table_b_invest_value?.status && (
                      <span className="req-input">
                        {props?.Error[index]?.id_table_b_invest_value?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={2} className="tableB-input">
                    <input
                      disabled={props.Disabled === false ? true : false}
                      type="text"
                      name="id_table_c_invest_value"
                      className={props?.Error[index]?.id_table_c_invest_value?.status ? "text-field-with-error " : ""}
                      onFocus={() => setFlagIV((prev) => [...prev, "id_table_c_invest_value".concat(index)])}
                      onBlur={() => {
                        var array = [...FlagIV];
                        var ind = array.indexOf("id_table_c_invest_value".concat(index))
                        if (ind !== -1) {
                          array.splice(ind, 1);
                          setFlagIV(array);
                        }
                      }}
                      value={item.id_table_c_invest_value ?
                        props?.Error[index]?.id_table_c_invest_value?.status ? removeComa(item.id_table_c_invest_value) :
                          !FlagIV.includes("id_table_c_invest_value".concat(index))
                            ? currency === "INR"
                              ? validINRCurrency(item.id_table_c_invest_value)
                              : validUSDCurrency(item.id_table_c_invest_value)
                            : removeComa(item.id_table_c_invest_value)
                        : ""
                      }
                      onChange={(e) => { props.handleChange(e, index); }}
                      onInput={(e) => {
                        let tempError = [...props?.Error];
                        let error = isNumberWithDecimalPM(e.target.value);
                        if (error.status) {
                          tempError[index].id_table_c_invest_value = error;
                        } else {
                          error = validDecimals(e.target.value);
                          tempError[index].id_table_c_invest_value = error;
                        }
                        props?.setError([...tempError]);
                      }}
                    // onBlur={(e) => {
                    //   props.handleChange(e, index);
                    //   setFlagIV(false);
                    //   if (error.id_table_c_invest_value.status == true) {
                    //     item.id_table_c_invest_value = "";
                    //     e.target.value = "";
                    //     tableB.id_table_c_invest_value = "";
                    //     error.id_table_c_invest_value.message = "";
                    //   }
                    // }}
                    />
                    {props?.Error[index]?.id_table_c_invest_value?.status && (
                      <span className="req-input">
                        {props?.Error[index]?.id_table_c_invest_value?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={2} className="tableB-input">
                    <input
                      disabled={props.Disabled === false ? true : false}
                      type="text"
                      name="id_table_d_invest_value"
                      className={props?.Error[index]?.id_table_d_invest_value?.status ? "text-field-with-error " : ""}
                      onFocus={() => setFlagIV((prev) => [...prev, "id_table_d_invest_value".concat(index)])}
                      onBlur={() => {
                        var array = [...FlagIV];
                        var ind = array.indexOf("id_table_d_invest_value".concat(index))
                        if (ind !== -1) {
                          array.splice(ind, 1);
                          setFlagIV(array);
                        }
                      }}
                      value={item.id_table_d_invest_value ?
                        props?.Error[index]?.id_table_d_invest_value?.status ? removeComa(item.id_table_d_invest_value) :
                          !FlagIV.includes("id_table_d_invest_value".concat(index))
                            ? currency === "INR"
                              ? validINRCurrency(item.id_table_d_invest_value)
                              : validUSDCurrency(item.id_table_d_invest_value)
                            : removeComa(item.id_table_d_invest_value)
                        : ""
                      }
                      onChange={(e) => { props.handleChange(e, index); }}
                      onInput={(e) => {
                        let tempError = [...props?.Error];
                        let error = isNumberWithDecimalPM(e.target.value);
                        if (error.status) {
                          tempError[index].id_table_d_invest_value = error;
                        } else {
                          error = validDecimals(e.target.value);
                          tempError[index].id_table_d_invest_value = error;
                        }
                        props?.setError([...tempError]);
                      }}
                    // onBlur={(e) => {
                    //   props.handleChange(e, index);
                    //   setFlagIV(false);
                    //   if (error.id_table_d_invest_value.status == true) {
                    //     item.id_table_d_invest_value = "";
                    //     e.target.value = "";
                    //     tableB.id_table_d_invest_value = "";
                    //     error.id_table_d_invest_value.message = "";
                    //   }
                    // }}
                    />
                    {props?.Error[index]?.id_table_d_invest_value?.status && (
                      <span className="req-input">
                        {props?.Error[index]?.id_table_d_invest_value?.message}
                      </span>
                    )}
                  </Col>
                  <Col md={2} className="tableB-input">
                    <input
                      disabled={props.Disabled === false ? true : false}
                      type="text"
                      name="id_remarks"
                      className={props?.Error[index]?.id_remarks?.status ? "text-field-with-error " : ""}
                      value={item.id_remarks}
                      onInput={(e) => {
                        let tempError = [...props?.Error];
                        let error = isAlphanumericWithSpecialChars(
                          e.target.value
                        );
                        if (error.status === true) {
                          tempError[index].id_remarks = error;
                        }
                        props?.setError([...tempError]);
                      }}
                      onChange={(e) => {
                        props.handleChange(e, index);
                      }}
                    />
                    {props?.Error[index]?.id_remarks?.status && (
                      <span className="req-input">
                        {props?.Error[index]?.id_remarks?.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            );
          })}
      </div>
      <div className="d-md-none d-lg-none table-container">
        <Form name="AirTreatmentForm" autoComplete="off">
          <div className="tableWrapperCompressorModal mobileTableModal">
            <div className="table-body tableBodyCompressorModal">
              {tableB.length > 0 &&
                tableB.map((item, index) => {
                  return (
                    <div className="outerTabDiv">
                      <div
                        className={
                          "table-row1 mobileTableRow " +
                          //(item.show ? "showLess" : "showFull")
                          (showmoreless.includes(index) ? "showLess" : "showFull")
                        }
                        key={`row_${index}`}
                        id={`row_${index}`}
                      >
                        <div className="table-cell">
                          <span>Sr. No.</span>
                          <div class="align-text2">{index + 1}</div>
                        </div>
                        <div className="table-cell">
                          <span>Network</span>
                          <div class="align-text">
                            {"(" + NetworkDetails[index].networkname + ")"}
                          </div>
                        </div>

                        <div className="table-cell">
                          <span>
                            Additional Receiver Capacity Size{" ("}m<sup>3</sup>
                            {")"}
                          </span>
                        </div>
                        <div className="table-cell table-cell-mobile-input1 ">
                          <input
                            disabled={props.Disabled === false ? true : false}
                            required
                            type="text"
                            name="id_table_b_receiver"
                            className={props?.Error[index]?.id_table_b_receiver?.status ? "text-field-with-error " : ""}
                            value={item.id_table_b_receiver}
                            onChange={(e) => props.handleChange(e, index)}
                            onInput={(e) => {
                              let tempError = [...props?.Error];
                              let error = isNumberWithDecimal(e.target.value);
                              if (error.status) {
                                tempError[index].id_table_b_receiver = error;
                              } else {
                                error = validDecimals(e.target.value);
                                tempError[index].id_table_b_receiver = error;
                              }
                              props?.setError([...tempError]);
                            }}
                          // onBlur={(e) => {
                          //   //props.handleChange(e, index);
                          //   if (error.id_table_b_receiver.status == true) {
                          //     item.id_table_b_receiver = "";
                          //     e.target.value = "";
                          //     tableB.id_table_b_receiver = "";
                          //     error.id_table_b_receiver.message = "";
                          //   }
                          // }}
                          />
                          {/* {props.b1 !== undefined &&
                            props.b1.includes(index) && (
                              <span className="req-input">{"required"}</span>
                            )} */}
                        </div>
                        {props?.Error[index]?.id_table_b_receiver?.status && (
                          <div className="ms-3 req-input">
                            {props?.Error[index]?.id_table_b_receiver?.message}
                          </div>
                        )}
                        <div className="table-cell">
                          <span>
                            Additional Receiver Capacity Cost{" ("}
                            {currency}
                            {")"}
                          </span>
                        </div>
                        <div className="table-cell table-cell-mobile-input1 ">
                          <input
                            disabled={props.Disabled === false ? true : false}
                            required
                            type="text"
                            name="id_table_b_invest_value"
                            className={props?.Error[index]?.id_table_b_invest_value?.status ? "text-field-with-error " : ""}
                            onFocus={() => setFlagIV((prev) => [...prev, "id_table_b_invest_value".concat(index)])}
                            onBlur={() => {
                              var array = [...FlagIV];
                              var ind = array.indexOf("id_table_b_invest_value".concat(index))
                              if (ind !== -1) {
                                array.splice(ind, 1);
                                setFlagIV(array);
                              }
                            }}
                            value={item.id_table_b_invest_value ?
                              props?.Error[index]?.id_table_b_invest_value?.status ? removeComa(item.id_table_b_invest_value) :
                                !FlagIV.includes("id_table_b_invest_value".concat(index))
                                  ? currency === "INR"
                                    ? validINRCurrency(item.id_table_b_invest_value)
                                    : validUSDCurrency(item.id_table_b_invest_value)
                                  : removeComa(item.id_table_b_invest_value)
                              : ""
                            }
                            onChange={(e) => { props.handleChange(e, index); }}
                            onInput={(e) => {
                              let tempError = [...props?.Error];
                              let error = isNumberWithDecimalPM(e.target.value);
                              if (error.status) {
                                tempError[index].id_table_b_invest_value = error;
                              } else {
                                error = validDecimals(e.target.value);
                                tempError[index].id_table_b_invest_value = error;
                              }
                              props?.setError([...tempError]);
                            }}
                          // onBlur={(e) => {
                          //   props.handleChange(e, index);
                          //   setFlagIV(false);
                          //   if (
                          //     error.id_table_b_invest_value.status == true
                          //   ) {
                          //     item.id_table_b_invest_value = "";
                          //     e.target.value = "";
                          //     tableB.id_table_b_invest_value = "";
                          //     error.id_table_b_invest_value.message = "";
                          //   }
                          // }}
                          />
                        </div>
                        {props?.Error[index]?.id_table_b_invest_value?.status && (
                          <span className="ms-3 req-input">
                            {props?.Error[index]?.id_table_b_invest_value?.message}
                          </span>
                        )}
                        <div className="table-cell">
                          <span>
                            Installation Cost{" ("}
                            {currency}
                            {")"}
                          </span>
                        </div>
                        <div className="table-cell table-cell-mobile-input1 ">
                          <input
                            disabled={props.Disabled === false ? true : false}
                            required
                            type="text"
                            name="id_table_c_invest_value"
                            className={props?.Error[index]?.id_table_c_invest_value?.status ? "text-field-with-error " : ""}
                            onFocus={() => setFlagIV((prev) => [...prev, "id_table_c_invest_value".concat(index)])}
                            onBlur={() => {
                              var array = [...FlagIV];
                              var ind = array.indexOf("id_table_c_invest_value".concat(index))
                              if (ind !== -1) {
                                array.splice(ind, 1);
                                setFlagIV(array);
                              }
                            }}
                            value={item.id_table_c_invest_value ?
                              props?.Error[index]?.id_table_c_invest_value?.status ? removeComa(item.id_table_c_invest_value) :
                                !FlagIV.includes("id_table_c_invest_value".concat(index))
                                  ? currency === "INR"
                                    ? validINRCurrency(item.id_table_c_invest_value)
                                    : validUSDCurrency(item.id_table_c_invest_value)
                                  : removeComa(item.id_table_c_invest_value)
                              : ""
                            }
                            onChange={(e) => { props.handleChange(e, index); }}
                            onInput={(e) => {
                              let tempError = [...props?.Error];
                              let error = isNumberWithDecimalPM(e.target.value);
                              if (error.status) {
                                tempError[index].id_table_c_invest_value = error;
                              } else {
                                error = validDecimals(e.target.value);
                                tempError[index].id_table_c_invest_value = error;
                              }
                              props?.setError([...tempError]);
                            }}
                          // onBlur={(e) => {
                          //   props.handleChange(e, index);
                          //   setFlagIV(false);
                          //   if (
                          //     error.id_table_c_invest_value.status == true
                          //   ) {
                          //     item.id_table_c_invest_value = "";
                          //     e.target.value = "";
                          //     tableB.id_table_c_invest_value = "";
                          //     error.id_table_c_invest_value.message = "";
                          //   }
                          // }}
                          />
                        </div>
                        {props?.Error[index]?.id_table_c_invest_value?.status && (
                          <span className="ms-3 req-input">
                            {props?.Error[index]?.id_table_c_invest_value?.message}
                          </span>
                        )}
                        <div className="table-cell">
                          <span>
                            Other Cost{" ("}
                            {currency}
                            {")"}
                          </span>
                        </div>
                        <div className="table-cell table-cell-mobile-input1 ">
                          <input
                            disabled={props.Disabled === false ? true : false}
                            required
                            type="text"
                            name="id_table_d_invest_value"
                            className={props?.Error[index]?.id_table_d_invest_value?.status ? "text-field-with-error " : ""}
                            onFocus={() => setFlagIV((prev) => [...prev, "id_table_d_invest_value".concat(index)])}
                            onBlur={() => {
                              var array = [...FlagIV];
                              var ind = array.indexOf("id_table_d_invest_value".concat(index))
                              if (ind !== -1) {
                                array.splice(ind, 1);
                                setFlagIV(array);
                              }
                            }}
                            value={item.id_table_d_invest_value ?
                              props?.Error[index]?.id_table_d_invest_value?.status ? removeComa(item.id_table_d_invest_value) :
                                !FlagIV.includes("id_table_d_invest_value".concat(index))
                                  ? currency === "INR"
                                    ? validINRCurrency(item.id_table_d_invest_value)
                                    : validUSDCurrency(item.id_table_d_invest_value)
                                  : removeComa(item.id_table_d_invest_value)
                              : ""
                            }
                            onChange={(e) => { props.handleChange(e, index); }}
                            onInput={(e) => {
                              let tempError = [...props?.Error];
                              let error = isNumberWithDecimalPM(e.target.value);
                              if (error.status) {
                                tempError[index].id_table_d_invest_value = error;
                              } else {
                                error = validDecimals(e.target.value);
                                tempError[index].id_table_d_invest_value = error;
                              }
                              props?.setError([...tempError]);
                            }}
                          // onBlur={(e) => {
                          //   props.handleChange(e, index);
                          //   setFlagIV(false);
                          //   if (
                          //     error.id_table_d_invest_value.status == true
                          //   ) {
                          //     item.id_table_d_invest_value = "";
                          //     e.target.value = "";
                          //     tableB.id_table_d_invest_value = "";
                          //     error.id_table_d_invest_value.message = "";
                          //   }
                          // }}
                          />
                        </div>
                        {props?.Error[index]?.id_table_d_invest_value?.status && (
                          <span className="ms-3 req-input">
                            {props?.Error[index]?.id_table_d_invest_value?.message}
                          </span>
                        )}

                        <div className="table-cell">
                          <span>Remarks</span>
                        </div>
                        <div className="table-cell table-cell-mobile-input1 ">
                          <input
                            disabled={props.Disabled === false ? true : false}
                            type="text"
                            name="id_remarks"
                            className={props?.Error[index]?.id_remarks?.status ? "text-field-with-error " : ""}
                            //value={Investment_B_Value[index]}
                            //investmenTValue ====0 ? tem.id_table_d_invest_value : investmenTValue
                            value={item.id_remarks}
                            //onChange={(e) => props.handleChange(e, index)}
                            onInput={(e) => {
                              let tempError = [...props?.Error];
                              let error = isAlphanumericWithSpecialChars(
                                e.target.value
                              );
                              if (error.status === true) {
                                tempError[index].id_remarks = error;
                              }
                              props?.setError([...tempError]);
                            }}
                            onChange={(e) => {
                              props.handleChange(e, index);
                            }}
                          />
                        </div>
                        {props?.Error[index]?.id_remarks?.status && (
                          <span className="ms-3 req-input">
                            {props?.Error[index]?.id_remarks?.message}
                          </span>
                        )}
                      </div>
                      <div className="showButton1">
                        {!showmoreless.includes(index) && (
                          <span
                            onClick={() => {
                              // let temp = [...tableB];
                              // temp[index].show = true;
                              // props.setTableB([...temp]);
                              let temp = [...showmoreless];
                              temp.push(index);
                              setshowmoreless([...temp])
                            }}
                          >
                            Show More
                          </span>
                        )}
                        {showmoreless.includes(index) && (
                          <span
                            onClick={() => {
                              // let temp = [...tableB];
                              // temp[index].show = false;
                              // props.setTableB([...temp]);

                              let temp = [...showmoreless];
                              const element = temp.indexOf(index);
                                if (element > -1) { 
                                  temp.splice(element, 1); 
                                }
                              setshowmoreless([...temp])
                            }}
                          >
                            Show Less
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
});

export default TableBNew;
