import { tab } from "@testing-library/user-event/dist/tab";
import { useState, useEffect, forwardRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Scrollbar from "smooth-scrollbar";
import { removeComa } from "../../../functions/functions";
import {
  checkNullWithMsg,
  isNumberWithDecimalPM,
  validDecimals,
  validINRCurrency,
  validUSDCurrency,
} from "../../../functions/validations";
import { getIfcUpcDetails } from "../../../Services/UpcIfcSolution-service";
import EnergySavingROI from "../EnergySavingROI";

const TableViewA = forwardRef((props, ref) => {
  const tableA = props.DataTableA;
  const NetworkDetails = props.NetworkDetails;
  console.log("props.currency", props.Currency);
  console.log(props);
  // console.log(props.ApplName);
  console.log(props.arr);
  const [FlagIV, setFlagIV] = useState([]);
  const [showmoreless, setshowmoreless] = useState([]);


  const id_saved_compresed_air_flow = props.Total_id_saved_compresed_air_flow;
  const id_eng_saved_kwh = props.Total_id_eng_saved_kwh;
  const id_eng_saved_per_day = props.Total_id_eng_saved_per_day;
  const id_cost_saving_per_year = props.Total_id_cost_saving_per_year;
  const id_total_product_cost = props.Total_id_product_cost;
  const [showInput1, setShowInput1] = useState(
    "form-control1-padding form-control1 d-block"
  );
  // const enquirykey = localStorage.getItem("enqkey");
  // console.log("enqid for sending to upc", enquirykey);

  console.log(tableA);
  let areNetName = new Array();
  let are = new Array();
  let count = 0;

  console.log(areNetName);
  //props.setareNetName(areNetName);

  console.log("are", are);
  const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
  });

  const numberFormatter1 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    setshowmoreless([]);
  }, [props.page5key]);
  return (
    <div className="roiTable">
      <div className="d-none d-md-block d-lg-block table-container">
        <Form name="AirTreatmentForm">
          <div className="tableWrapperCompressorModal">
            <div className="table-head tableHeadCompressorModal">
              <div className="table-row" style={{ borderRadius: "4px" }}>
                <div
                  className="table-cell align-self-center"
                  style={{ minWidth: "55px" }}
                >
                  <span>Sr. No.</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>Network</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>IFC/UPC Model</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>Product Cost ({props.Currency})</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>Connected Applications</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>Average iCFM (CFM)</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>IFC/UPC Inlet Pressure (psig)</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>IFC/UPC Set Pressure (psig)</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>Ambient Temperature (°C)</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>Compressed Air Flow Saved (CFM)</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>% Compressed Air Flow Saving</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>Energy Saved(in KWh)</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>Energy Saved per Day, KWh/day</span>
                </div>
                <div className="table-cell align-self-center">
                  <span>Cost Saving per Year ({props.Currency})</span>
                </div>
              </div>
            </div>
            <div className="table-body table-body-custom tableBodyCompressorModal">
              {tableA.length > 0 &&
                tableA.map((item, index) => {
                  return (
                    <div
                      className={"table-row"}
                      key={`row_${index}`}
                      id={`row_${index}`}
                      style={{ borderRadius: "4px", marginTop: "7px" }}
                    >
                      <div className="table-cell" style={{ minWidth: "55px" }}>
                        <span>{index + 1}</span>
                      </div>
                      <div className="table-cell">
                        <span>
                          {"(" + NetworkDetails[index].networkname +
                            ")"}
                        </span>
                      </div>
                      <div className="table-cell">
                        <span>{item.id_product_model}</span>
                      </div>
                      <div className="table-cell">
                        <span>
                          <Form.Control
                            type="text"
                            disabled={props.Disabled === false ? true : false}
                            className={props?.Error[index]?.id_price?.status ? "text-field-with-error " + showInput1 : showInput1}
                            name="id_price"
                            onFocus={() => setFlagIV((prev)=>[...prev,index])}
                            onBlur={()=>{
                              var array = [...FlagIV]; 
                              var ind = array.indexOf(index)
                              if (ind !== -1) {
                                array.splice(ind, 1);
                                setFlagIV(array);
                              }
                            }}
                            onChange={(e)=>{props.handleChange(e, index);}}
                            value={ props?.Error[index]?.id_price?.status ? removeComa(tableA[index].id_price) :
                              !FlagIV.includes(index)
                                ? props.Currency === "INR"
                                  ? validINRCurrency(item.id_price)
                                  : validUSDCurrency(item.id_price)
                                : removeComa(tableA[index].id_price)
                            }
                            onInput={(e) => {
                              let tempError = [...props?.Error];
                              let error = isNumberWithDecimalPM(e.target.value);
                              if(error.status){
                                tempError[index].id_price = error;
                              }
                              if (!error.status) {
                                error = validDecimals(e.target.value);
                                tempError[index].id_price = error;
                              } 
                              if (!error.status){
                                error = checkNullWithMsg(e.target.value);
                                tempError[index].id_price = error;
                              }
                              props?.setError([...tempError]);
                            }}
                          />
                        </span>
                        {props?.Error[index]?.id_price?.status && (
                          <div className="text-danger">
                          {props?.Error[index]?.id_price?.message }
                        </div>
                        )}
                      </div>
                      <div className="table-cell">
                        <span>{NetworkDetails[index].connected_applications}</span>
                      </div>
                      <div className="table-cell">
                        <span>{parseFloat(item.id_avg_icfm).toFixed(4)}</span>
                      </div>
                      <div className="table-cell">
                        <span>{parseInt(item.id_inlet_pressure)}</span>
                      </div>
                      <div className="table-cell">
                        <span>{parseInt(item.id_set_pressure)}</span>
                      </div>
                      <div className="table-cell">
                        <span>{item.id_amb_temp}</span>
                      </div>
                      <div className="table-cell">
                        <span>{item.id_saved_compresed_air_flow}</span>
                      </div>
                      <div className="table-cell">
                        <span>{item.id_saved_compressed_air_flow_per}</span>
                      </div>
                      <div className="table-cell">
                        <span>{item.id_eng_saved_kwh}</span>
                      </div>
                      <div className="table-cell">
                        <span>{item.id_eng_saved_per_day}</span>
                      </div>
                      <div className="table-cell">
                        <span>
                          {props.Currency == "INR"
                            ? validINRCurrency(parseFloat(item.id_cost_saving_per_year).toFixed(2))
                            : validUSDCurrency(parseFloat(item.id_cost_saving_per_year).toFixed(2))}
                        </span>
                      </div>
                    </div>
                  );
                })}
              {/* {<EnergySavingROI dataTable1={tableA} />} */}
            </div>

            <div className="table-row no-hover" style={{border:'none'}}>
              <div className="energyPercent">
                <div className="table-cell" style={{ minWidth: "65px" }}>
                  <span>Total</span>
                </div>
                <div className="table-cell">
                  <span></span>
                </div>
                <div className="table-cell" style={{ minWidth: "120px" }}>
                  <span></span>
                </div>
                <div className="table-cell">
                  <span>
                    {props.Currency === "INR"
                      ? validINRCurrency(
                        (id_total_product_cost)
                      )
                      : validUSDCurrency(
                        (id_total_product_cost)
                      )}
                  </span>
                </div>
                <div className="table-cell">
                  <span></span>
                </div>
                <div className="table-cell">
                  <span></span>
                </div>
                <div className="table-cell">
                  <span></span>
                </div>
                <div className="table-cell">
                  <span></span>
                </div>
                <div className="table-cell">
                  <span></span>
                </div>
                <div className="table-cell">
                  <span>
                    {(id_saved_compresed_air_flow)}
                  </span>
                </div>
                <div className="table-cell">
                  <span></span>
                </div>
                <div className="table-cell">
                  <span>{(id_eng_saved_kwh)}</span>
                </div>
                <div className="table-cell">
                  <span>{(id_eng_saved_per_day)}</span>
                </div>
                <div className="table-cell">
                  <span>
                    {props.Currency === "INR"
                      ? validINRCurrency(
                        (id_cost_saving_per_year)
                      )
                      : validUSDCurrency(
                        (id_cost_saving_per_year)
                      )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="d-md-none d-lg-none table-container">
        <Form name="AirTreatmentForm">
          <div className="tableWrapperCompressorModal mobileTableModal">
            <div className="table-body tableBodyCompressorModal">
              {tableA.length > 0 &&
                tableA.map((item, index) => {
                  return (
                    <div className="outerTabDiv">
                      <div
                        className={
                          "table-row1 mobileTableRow " +
                          // (item.show ? "showLess" : "showFull")
                          (showmoreless.includes(index) ? "showLess" : "showFull")
                        }
                        key={`row_${index}`}
                        id={`row_${index}`}
                      >
                        <div className="table-cell">
                          <span>Sr. No.</span>&nbsp;
                          <div class="align-text2">{index + 1}</div>&nbsp;
                        </div>
                        <div className="table-cell">
                          <span>Network</span>&nbsp;
                          <div class="align-text">
                            {"(" + NetworkDetails[index].networkname +
                              ")"}
                          </div>
                          &nbsp;
                        </div>
                        <div className="table-cell">
                          <span>IFC/UPC Model</span>&nbsp;
                          <div class="align-text">{item.id_product_model}</div>
                          &nbsp;
                        </div>
                        <div className="table-cell">
                          <span>Product Cost ({props.Currency})</span>&nbsp;
                          <div class="align-text">
                            {" "}
                            <Form.Control
                              type="text"
                              className={props?.Error[index]?.id_price?.status ? "text-field-with-error " + showInput1 : showInput1}
                              name="id_price"
                              onFocus={() => setFlagIV((prev)=>[...prev,index])}
                              onBlur={()=>{
                                var array = [...FlagIV]; 
                                var ind = array.indexOf(index)
                                if (ind !== -1) {
                                  array.splice(ind, 1);
                                  setFlagIV(array);
                                }
                              }}
                              onChange={(e)=>{props.handleChange(e, index);}}
                              value={ props?.Error[index]?.id_price?.status ? removeComa(tableA[index].id_price) :
                                !FlagIV.includes(index)
                                  ? props.Currency === "INR"
                                    ? validINRCurrency(item.id_price)
                                    : validUSDCurrency(item.id_price)
                                  : removeComa(tableA[index].id_price)
                              }
                              onInput={(e) => {
                                let tempError = [...props?.Error];
                                let error = isNumberWithDecimalPM(e.target.value);
                                if(error.status){
                                  tempError[index].id_price = error;
                                }
                                if (!error.status) {
                                  error = validDecimals(e.target.value);
                                  tempError[index].id_price = error;
                                } 
                                if (!error.status){
                                  error = checkNullWithMsg(e.target.value);
                                  tempError[index].id_price = error;
                                }
                                props?.setError([...tempError]);
                              }}
                            />
                            {props?.Error[index]?.id_price?.status && (
                          <div className="text-danger">
                          {props?.Error[index]?.id_price?.message }
                          </div>
                            )}
                          </div>
                          &nbsp;
                        </div>
                        <div className="table-cell">
                          <span>Connected Applications</span>&nbsp;
                          <div class="align-text">{NetworkDetails[index].connected_applications}</div>&nbsp;
                        </div>
                        <div className="table-cell">
                          <span>Average iCFM (CFM)</span>&nbsp;
                          <div class="align-text">{parseFloat(item.id_avg_icfm).toFixed(4)}</div>&nbsp;
                        </div>
                        <div className="table-cell">
                          <span>IFC/UPC Inlet Pressure (psig)</span>&nbsp;
                          <div class="align-text">{parseInt(item.id_inlet_pressure)}</div>
                          &nbsp;
                        </div>
                        <div className="table-cell">
                          <span>IFC/UPC Set Pressure (psig)</span>&nbsp;
                          <div class="align-text">{parseInt(item.id_set_pressure)}</div>
                          &nbsp;
                        </div>
                        <div className="table-cell">
                          <span>Ambient Temperature (°C)</span>&nbsp;
                          <div class="align-text">{item.id_amb_temp}</div>&nbsp;
                        </div>
                        <div className="table-cell">
                          <span>Compressed Air Flow Saved (CFM)</span>&nbsp;
                          <div class="align-text">
                            {item.id_saved_compresed_air_flow}
                          </div>
                          &nbsp;
                        </div>
                        <div className="table-cell">
                          <span>% Compressed Air Flow Saving</span>&nbsp;
                          <div class="align-text">
                            {item.id_saved_compressed_air_flow_per}
                          </div>
                          &nbsp;
                        </div>
                        <div className="table-cell">
                          <span>Energy Saved(in KWh)</span>&nbsp;
                          <div class="align-text">{item.id_eng_saved_kwh}</div>
                          &nbsp;
                        </div>
                        <div className="table-cell">
                          <span>Energy Saved per Day, KWh/day</span>&nbsp;
                          <div class="align-text">
                            {item.id_eng_saved_per_day}
                          </div>
                          &nbsp;
                        </div>
                        <div className="table-cell">
                          <span>Cost Saving per Year ({props.Currency})</span>
                          &nbsp;
                          <div class="align-text">
                            {props.Currency == "INR"
                              ? validINRCurrency(parseFloat(item.id_cost_saving_per_year).toFixed(2))
                              : validUSDCurrency(parseFloat(item.id_cost_saving_per_year).toFixed(2))}
                          </div>
                          &nbsp;
                        </div>
                      </div>
                      <div className="showButton1">
                        {!showmoreless.includes(index) && (
                          <span
                            onClick={() => {
                              // let temp = [...tableA];
                              // temp[index].show = true;
                              // props.setTableA([...temp]);
                              let temp = [...showmoreless];
                              temp.push(index);
                              setshowmoreless([...temp])
                            }}
                          >
                            Show More
                          </span>
                        )}
                        {showmoreless.includes(index)  && (
                          <span
                            onClick={() => {
                              // let temp = [...tableA];
                              // temp[index].show = false;
                              // props.setTableA([...temp]);
                              let temp = [...showmoreless];
                              const element = temp.indexOf(index);
                              if (element > -1) { 
                                temp.splice(element, 1); 
                              }
                              setshowmoreless([...temp])
                            }}
                          >
                            Show Less
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="tableWrapperCompressorModal mobileTableModal">
            <div className="outerTabDiv">
              <div className="energyPercent">

                <div className="mobileTableRow ">
                  <div className="table-cell" style={{ minWidth: "60px" }}>
                    <span>Total</span>
                  </div>
                  <div className="table-cell">
                    <span></span>
                  </div>

                  <div className="table-cell">
                    <span>Product Cost</span>&nbsp;
                    <div class="align-text">
                      {props.Currency === "INR"
                      ? validINRCurrency(
                        (id_total_product_cost)
                      )
                      : validUSDCurrency(
                        (id_total_product_cost)
                      )}
                    </div>
                  </div>
                  <div className="table-cell">
                    <span>Compressed Air Flow Saved (CFM)</span>&nbsp;
                    <div class="align-text">
                      {(id_saved_compresed_air_flow)}
                    </div>
                  </div>
                  <div className="table-cell">
                    <span>Energy Saved(in KWh)</span>&nbsp;
                    <div class="align-text">
                      {(id_eng_saved_kwh)}
                    </div>
                  </div>
                  <div className="table-cell">
                    <span>Energy Saved per Day (KWh/day)</span>&nbsp;
                    <div class="align-text">
                      {(id_eng_saved_per_day)}
                    </div>
                  </div>
                  <div className="table-cell">
                    <span>Cost Saving Per Year ({props.Currency})</span>&nbsp;
                    <div class="align-text">
                      {(id_cost_saving_per_year)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
});

export default TableViewA;
