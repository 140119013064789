import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_Enquiry_API_URL;
const API_URL1 = process.env.REACT_APP_App_API_URL;
const API_URL_ALGORITHM = process.env.REACT_APP_API_URL_IFC_UPC_DETAILS;


export async function getAllEnquiryCustomers() {
  // return fetch(API_URL).then((response) => response.json());
  const response = await axios
    .get(API_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export const getmaxEnqId = async () => {
  let url = API_URL + "/maxenqId";
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const AddNewEnquiryCustomer = async (customer) => {
  let url = API_URL;
  const response = await axios
    .post(url, customer)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
};

export const AddNewEnquiryTemp = async (customer) => {
  let url = `${API_URL}/temp`;
  const response = await axios
    .post(url, customer)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getEnquiryId = async (ed_enquirynumber) => {
  let url = `${API_URL}/ed_enquirynumber/${ed_enquirynumber}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

////updating enquirydata after roi calculations
export const updateEnquiry = async (data) => {
  console.log("updateEnquiry", JSON.stringify(data));
  console.log(data.ed_enquiryid);
  let url = `${API_URL}/ed_enquiryid/${data.ed_enquiryid}`;
  const response = await axios
    .post(url, JSON.stringify(data))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const updateEnquiryTab2 = async (data) => {
  console.log("updateEnquiryTab2", JSON.stringify(data));
  console.log(data.ed_enquiryid);
  let url = `${API_URL}/tab2/ed_enquiryid/${data.ed_enquiryid}`;
  console.log('url',url);
  const response = await axios
    .post(url, JSON.stringify(data))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getEnquiryIdByName = async (ed_custname) => {
  let url = `${API_URL}/ed_custname/${ed_custname}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const AddNewappDetail = async (customer) => {
  let url = API_URL1;
  const response = await axios
    .post(url, JSON.stringify(customer))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function getAppCode() {
  let url = API_URL1 + "/ad_enquirynumber/:ad_enquirynumber";
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const getAppDetail = async (ad_enquiry_id) => {
  let url = `${API_URL1}/ad_enquiry_id/${ad_enquiry_id}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

// export const DeleteApp = async (App) => {
//   let url = `${API_URL1}/ad_appid/${App.ad_appid}`;
//   const response = await axios
//     .delete(url)
//     .then(function (resp) {
//       return resp;
//     })
//     .catch(function (error) {
//       return error.message;
//     });

//   //console.log(resp);
//   return response.data;
// };

export const deleteByEnquiryNumber = async (eqNo) => {
  let url = `${API_URL}/ed_enquirynumber/${eqNo}`;
  const response = await axios
    .post(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return response.data;
};

export const getCorrectionfactor = async (inlet) => {
  let url = API_URL_ALGORITHM + `/correction_factor/${inlet}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};