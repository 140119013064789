import { Button, Modal, Alert } from "react-bootstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { BsX } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState, useEffect } from "react";
import { getDealersPopUp } from "../../Services/dealer-service";
import { getActiveRoles } from "../../Services/role-service";
import {
  getActiveLocations,
  getDistinctCountry,
} from "../../Services/location-service";
import GolbalSearch from "./GolbalSearch";
import CustomPopUpTable from "./../layout/CustomPopUpTable";
import { PropTypes } from "react";
import Loader from "./Loader";

const MultiValuePopUp = (props) => {
  let count = 1;
  let arr = props.masterName.split(":");
  let masterName = arr[0];
  const gridRef = useRef();
  const [dataMethod, setDataMethod] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [parameters, setParams] = useState(null);
  const [ready, setReady] = useState(false);
  const [loc_comp, setLoc_comp] = useState([]);
  const [filteredCol, setFilteredCol] = useState([]);
  const [globalText, setGlobalText] = useState("");
  const [filteredResult, setFilteredResult] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoader, setLoader] = useState(true);
  //let selectedData=[];

  const [alertMessage, setAlertMessage] = useState("");
  const fetchData = async (param) => {
    const result = dataMethod && (await dataMethod(param));
    console.log(result);
    setRowData(result);
    setFilteredResult(result);
  };

  useEffect(() => {
    if (filteredResult?.length > 0) {
      setLoader(false);
    }
  }, [filteredResult]);

  useEffect(() => {
    console.log("in selectedData", selectedData);
    console.log(
      "in filtered selectedData",
      selectedData.filter((selectedData) => selectedData.lm_loccode !== "111")
    );

    if (selectedData.length > 0 && count === 1) {
      //const temp = props.locationData.split(",");
      gridRef?.current?.api?.forEachNode((node) => {
        //  if (temp.includes(node?.data.lm_loccode)) {
        // let index = selectedData.indexOf(node?.data.lm_loccode.toString())
        if (
          selectedData.filter(
            (selectedData) =>
              selectedData.lm_loccode == node?.data.lm_loccode.toString()
          ).length > 0
        ) {
          console.log("match found");
          // if (index >= 0) {
          node.setSelected(true);
          count = count + 1;
        }
      });
    }
  }, [filteredResult]);

  useEffect(() => {
    switch (masterName) {
      case "Dealer Master":
        {
          setDataMethod(() => getDealersPopUp);
          setColumnDefs([
            {
              width: 25,
              checkboxSelection: true,
            },
            {
              field: "dm_dealercode",
              headerName: "Company Code",
              tooltipField: "dm_dealercode",
            },
            {
              field: "dm_dealercompanyname",
              headerName: "Company Name",
              tooltipField: "dm_dealercompanyname",
            },
            {
              field: "dm_dealercategory",
              headerName: "Dealer Category",
              tooltipField: "dm_dealercategory",
            },
          ]);
        }
        break;
      case "User Role":
        {
          setDataMethod(() => getActiveRoles);
          setColumnDefs([
            {
              width: 25,
              checkboxSelection: true,
            },
            {
              field: "ram_rolecode",
              headerName: "Role Code",
              tooltipField: "ram_rolecode",
            },
            {
              field: "ram_rolename",
              headerName: "Role Name",
              tooltipField: "ram_rolename",
            },
          ]);
        }
        break;
      case "Location":
        {
          setParams(arr);
          setDataMethod(() => getActiveLocations);
          console.log(dataMethod);
          setColumnDefs([
            // {
            //   width: 25,
            //   checkboxSelection: true,
            // },
            {
              field: "lm_loccode",
              headerName: "Location Code",
              checkboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              headerCheckboxSelection: true,

              width: 150,
              suppressSizeToFit: true,
              editable: false,
              cellRenderer: (params) => {
                let loc_ch = Number(params.data.lm_loccode);

                if (props?.locationData && count === 1) {
                  const temp = props.locationData.split(",");
                  gridRef?.current?.api?.forEachNode((node) => {
                    // console.log("node ", node.data.lm_loccode);
                    //  if (temp.includes(node?.data.lm_loccode)) {
                    let index = temp.indexOf(node?.data.lm_loccode.toString());
                    if (index >= 0) {
                      node.setSelected(true);
                      count = count + 1;
                    }
                  });
                }
                return params.value;
              },
              tooltipField: "lm_loccode",
            },
            {
              field: "lm_loc",
              headerName: "Location",
              tooltipField: "lm_loc",
            },
            {
              field: "lm_loctype",
              headerName: "Location Type",
              tooltipField: "lm_loctype",
            },
            {
              field: "lm_country",
              headerName: "Country",
              tooltipField: "lm_country",
            },
          ]);
        }
        break;
      case "Country Master":
        {
          setParams(arr[1]);
          setDataMethod(() => getDistinctCountry);
          console.log(dataMethod);
          setColumnDefs([
            {
              field: "lm_country",
              headerName: "Country",
              checkboxSelection: true,
              headerCheckboxSelection: true,
              width: 300,
              suppressSizeToFit: true,
              editable: false,
              cellRenderer: (params) => {
                console.log(params);
                console.log(props);
                console.log(props?.locationData);
                if (props?.locationData && count === 1) {
                  let temp = props.locationData.split(",");
                  console.log(temp);
                  gridRef?.current?.api?.forEachNode((node) => {
                    if (temp.includes(node?.data.lm_country)) {
                      node.setSelected(true);
                      count = count + 1;
                    }
                  });
                }
                return params.value;
              },
              tooltipField: "lm_country",
            },

            {
              field: "lm_loctype",
              headerName: "Location Type",
              tooltipField: "lm_loctype",
            },
          ]);
        }
        break;
    }
    fetchData(parameters);
    console.log(dataMethod);
  }, [dataMethod]);

  const defaultColDef = {
    resizable: true,
    suppressMovable: true,
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["reset", "apply"],
      closeOnApply: true,
      defaultJoinOperator: "OR",
    },
  };

  function isSubset(arr1, arr2, m, n) {
    let i = 0;
    let j = 0;
    for (i = 0; i < n; i++) {
      for (j = 0; j < m; j++) if (arr2[i] == arr1[j]) break;

      if (j == m) return false;
    }
    return true;
  }

  const assignData = () => {
    // let selectedRows = gridRef.current.api.getSelectedRows();
    // console.log(selectedRows);
    // let selectedRowsCount = gridRef.current.api.getSelectedRows().length;
    let selectedRows = selectedData;
    let selectedRowsCount = selectedData.length;
    console.log("Selected Rows Count: ", selectedRowsCount);
    if (selectedRowsCount > 0) {
      props.setSelected({ name: props.masterName, result: selectedRows });
      props.onHide();
    } else
      setAlertMessage("Please select record before clicking on Assign button.");
  };

  const assignDataCustom = () => {
    let selectedRows = selectedCard;
    let selectedRowsCount = selectedCard.length;
    if (selectedRowsCount > 0) {
      props.setSelected({ name: props.masterName, result: selectedRows });
      props.onHide();
    } else
      setAlertMessage("Please select record before clicking on Assign button.");
  };

  const assignData1 = () => {
    // let selectedRows = gridRef.current.api.getSelectedRows();
    // let selectedRowsCount = gridRef.current.api.getSelectedRows().length;
    let selectedRows = selectedData;
    let selectedRowsCount = selectedData.length;
    console.log("sel ", selectedRows);
    console.log("Selected Rows Count: ", selectedRowsCount);
    let locationUsedInEnquiry = props?.locSelect;
    console.log("Enquiry Locations : ", locationUsedInEnquiry);
    for (let i = 0; i < selectedRows.length; i++) {
      //let loc_comp=[];
      console.log("sel ", selectedRows[i].lm_loc);

      let loc_in = selectedRows[i].lm_loc;
      console.log("loc", loc_in);
      loc_comp.push(loc_in);
      console.log("loc", loc_comp);
      //res=!loc_cp.includes(loc_in);
    }
    let m = loc_comp.length;
    let n = locationUsedInEnquiry.length;

    if (selectedRowsCount > 0) {
      if (isSubset(loc_comp, locationUsedInEnquiry, m, n)) {
        props.setSelected({ name: props.masterName, result: selectedRows });
        props.onHide();
      } else {
        console.log("arr2[] is not a subset of arr1[]");
        var unique = locationUsedInEnquiry.filter(
          (v, i, a) => a.indexOf(v) === i
        );
        console.log(unique);
        props.setShowMessage(
          `For selected User, Enquiry is in process for location ${unique}.`
        );
        props.setShowWarning(true);
      }
    } else {
      setAlertMessage("Please select record before clicking on Assign button.");
    }

    // if (isSubset(loc_comp, loc_cp, m, n)) {
    //   console.log("arr2[] is subset of arr1[] ");
    //   if (selectedRowsCount > 0) {
    //     props.setSelected({ name: props.masterName, result: selectedRows });
    //     props.onHide();
    //   } else
    //     setAlertMessage(
    //       "Please select record before clicking on Assign button."
    //     );
    // } else {
    //   console.log("arr2[] is not a subset of arr1[]");
    //   props.setShowMessage(
    //     `For selected User, Enquiry is in process for location ${loc_cp}.`
    //   );
    //   props.setShowWarning(true);
    // }
  };

  const assignDataCustom1 = () => {
    let selectedRows = selectedCard;
    console.log("sel ", selectedRows);
    let selectedRowsCount = selectedCard.length;
    console.log("Count of selected rows: ", selectedRowsCount);
    let loc_cp = props?.locSelect;
    console.log("loc", loc_cp);
    for (let i = 0; i < selectedRows.length; i++) {
      //let loc_comp=[];
      console.log("sel ", selectedRows[i].lm_loc);

      let loc_in = selectedRows[i].lm_loc;
      console.log("loc", loc_in);
      loc_comp.push(loc_in);
      console.log("loc", loc_comp);
      //res=!loc_cp.includes(loc_in);
    }
    let m = loc_comp.length;
    let n = loc_cp.length;
    if (isSubset(loc_comp, loc_cp, m, n)) {
      console.log("arr2[] is subset of arr1[] ");
      if (selectedRowsCount > 0) {
        props.setSelected({ name: props.masterName, result: selectedRows });
        props.onHide();
      } else
        setAlertMessage(
          "Please select record before clicking on Assign button."
        );
    } else {
      console.log("arr2[] is not a subset of arr1[]");
      var unique = loc_cp.filter((v, i, a) => a.indexOf(v) === i);
      console.log(unique);
      props.setShowMessage(
        `For selected User, Enquiry is in process for location ${loc_cp}.`
      );
      props.setShowWarning(true);
    }
  };

  const onGridReady = (value) => {
    setReady(!ready);
    gridRef.current.api.sizeColumnsToFit();
  };

  const handleGlobalSearch = (text) => {
    console.log("in handleGlobalSearch");
    let searchText = text.toLowerCase();
    setGlobalText(searchText);
    let arr = props.masterName.split(":");
    let masterName = arr[0];
    if (searchText) {
      let result = rowData?.filter((item) => {
        switch (masterName) {
          case "Dealer Master":
            return (
              item.lm_loccode.toString().indexOf(searchText) >= 0 ||
              item.lm_loc.toLowerCase().indexOf(searchText) >= 0 ||
              item.lm_loctype.toLowerCase().indexOf(searchText) >= 0 ||
              item.lm_country.toLowerCase().indexOf(searchText) >= 0
            );
          case "User Role":
            return (
              item.ram_rolecode.toString().indexOf(searchText) >= 0 ||
              item.ram_rolename.toLowerCase().indexOf(searchText) >= 0
            );
          case "Location":
            return (
              item.lm_loccode.toString().indexOf(searchText) >= 0 ||
              item.lm_loc.toLowerCase().indexOf(searchText) >= 0 ||
              item.lm_loctype.toLowerCase().indexOf(searchText) >= 0 ||
              item.lm_country.toLowerCase().indexOf(searchText) >= 0
            );
          case "Country Master":
            return (
              item.lm_country.toLowerCase().indexOf(searchText) >= 0 ||
              item.lm_loctype.toLowerCase().indexOf(searchText) >= 0
            );
        }
      });
      setFilteredResult(result);
    } else {
      setFilteredResult(rowData);
    }
  };

  const resetAllFilter = () => {
    gridRef.current.api.setFilterModel(null);
  };

  function resetFilter(item) {
    let item1 = item.substring(0, item.indexOf(":") - 1);

    let col = gridRef.current.api.getFilterModel();
    for (const key of Object.keys(col)) {
      if (key === feildName(item1)) {
        delete col[key];
      }
    }
    gridRef.current.api.setFilterModel(col);
  }

  const headerName = (field) => {
    var name;
    switch (field) {
      case "dm_dealercode":
        name = "Company Code";
        break;
      case "dm_dealercompanyname":
        name = "Company Name";
        break;
      case "dm_dealercategory":
        name = "Dealer Category";
        break;
      case "ram_rolecode":
        name = "Role Code";
        break;
      case "ram_rolename":
        name = "Role Name";
        break;
      case "lm_loccode":
        name = "Location Code";
        break;
      case "lm_loc":
        name = "Location Name";
        break;
      case "lm_loctype":
        name = "Location Type";
        break;
      case "lm_country":
        name = "Country";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const feildName = (header) => {
    var name;
    switch (header) {
      case "Company Code":
        name = "dm_dealercode";
        break;
      case "Company Name":
        name = "dm_dealercompanyname";
        break;
      case "Dealer Category":
        name = "dm_dealercategory";
        break;
      case "Role Code":
        name = "ram_rolecode";
        break;
      case "Role Name":
        name = "ram_rolename";
        break;
      case "Location Code":
        name = "lm_loccode";
        break;
      case "Location Name":
        name = "lm_loc";
        break;
      case "Location Type":
        name = "lm_loctype";
        break;
      case "Country":
        name = "lm_country";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const onRowSelected = (event) => {
    console.log("event.node", event.node);
    if (event.node.isSelected()) {
      //      selectedData.push(event.node.data);
      setSelectedData((data) => [...data, event.node.data]);
    } else {
      setSelectedData(
        selectedData.filter(
          (selectedData) =>
            selectedData.lm_loccode !== event.node.data.lm_loccode
        )
      );
    }

    console.log("selectedData", selectedData);
  };
  const onFilterChanged = (param) => {
    let a = document.getElementById("filterDisplayPop");
    console.log("a", a);
    setFilteredCol([]);
    let col = param.api.getFilterModel();

    if (Object.keys(col).length === 0) {
      a.classList.add("d-none");
    } else {
      a.classList.remove("d-none");
      for (const key of Object.keys(col)) {
        let filter;
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName(key) + " : " + col[key].filter;
        }
        setFilteredCol((prev) => {
          return [...prev, filter];
        });
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <Modal {...props} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{props.masterName.split(":")[0]} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="global-search-container mt-3">
              <GolbalSearch filterFunc={handleGlobalSearch} />
            </div>

            <div
              className="d-none mt-3 d-flex"
              id="filterDisplayPop"
              style={{ gap: "10px" }}
            >
              {filteredCol &&
                filteredCol.map((item) => {
                  return (
                    <div className="filter-col pr-0 me-2">
                      <div>
                        <span className="ps-2 btn-text">{item}</span>
                      </div>
                      <div onClick={() => resetFilter(item)}>
                        <BsX size={32} />
                      </div>
                    </div>
                  );
                })}

              <Button
                variant="outline-primary"
                className="ms-2 lh-1 clear-all-btn"
                onClick={resetAllFilter}
              >
                Clear All
              </Button>
            </div>
            <div
              className="ag-theme-material d-none d-md-block d-lg-block"
              style={{ height: 350, width: "100%" }}
            >
              {isLoader ? (
                <Loader />
              ) : (
                <AgGridReact
                  onRowSelected={onRowSelected}
                  ref={gridRef}
                  rowData={filteredResult}
                  columnDefs={columnDefs}
                  rowSelection={"multiple"}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  headerHeight={50}
                  rowHeight={50}
                  pagination={true}
                  paginationPageSize={5}
                  onFilterChanged={onFilterChanged}
                  enableBrowserTooltips={true}
                  domLayout="autoHeight"
                ></AgGridReact>
              )}
            </div>
            <div className="d-md-none d-lg-none table-container1">
              <CustomPopUpTable
                masterName={masterName}
                data={filteredResult}
                setSelectedCard={setSelectedCard}
                locationData={
                  props.locationData ? props.locationData.split(",") : []
                }
                type="multiple"
              />
            </div>
          </div>
        </Modal.Body>

        {alertMessage && (
          <Alert className="alertBox" variant="warning">
            {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
            {alertMessage}
          </Alert>
        )}

        <Modal.Footer className="justify-content-center justify-content-sm-start">
          <Button
            size="sm"
            className="float-start me-2 d-none d-md-block d-lg-block"
            onClick={props.formName === "User" ? assignData1 : assignData}
          >
            Assign
          </Button>
          <Button
            size="sm"
            className="float-start me-2 d-md-none d-lg-none"
            onClick={
              props.formName === "User" ? assignDataCustom1 : assignDataCustom
            }
          >
            Assign
          </Button>
          <Button
            size="sm"
            className="float-start me-2"
            variant="outline-primary"
            onClick={() => props.onHide()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MultiValuePopUp;
