import {  Modal } from "react-bootstrap";
import seccessImage from "../../styles/images/download.png";

const WarningModal = ({
  clickFunction,
  handleClose,
  show = false,
  message = "",
  ...props
}) => {
  return (
    <>
      <Modal
        className="forgotPasswordModal"
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header
          closeButton
          style={{
            color: "#264AE4",
            // fontWeight: "600",
            fontSize: "18px",
            border: "none",
          }}
        >
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <img src={seccessImage} alt="seccessImage" />
          </div>
          <div className="modalHeading">Warning</div>
          <div className="modalText mt-2">{message || ""}</div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WarningModal;
