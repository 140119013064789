import { useEffect, useState } from "react";
import { Col, Form, Row, Button, Modal, Alert } from "react-bootstrap";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useHistory, withRouter } from "react-router-dom";
import WarningModal from "../Modals/warningModal";

import axios from "../../utils/axios";
import {
  validDecimalDigits,
  isNumberWithDecimal,
  validUSDCurrency,
} from "../../functions/validations";
import { getSumOfCompressorHeader } from "../../Services/compressor-house-service";
import { getUpcCapacity } from "../../Services/product-service";
import {
  updateIFC_Details,
  deleteIfcUpc,
} from "../../Services/UpcIfcSolution-service";
import {
  getCorrectionfactor
} from "../../Services/enquiry-customer-service";
import { getAllProductModels } from "../../../src/Services/product-service";
import { store } from "../../redux/store";
import Loader from "../utility/Loader";

const UPCDetails = ({
  ifcCalculations,
  setIfcCalculations,
  networkDetail,
  // ifcUpcDetail,
  // setIfcUpcDetail,
  action,
  setAction,
  NodeCode,
  setNodeCode,
  IsDisabled,
  ...props
}) => {
  console.log("Action--", networkDetail, ifcCalculations, action);
  let type = "UPC";
  const [product, setProduct] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [avgIcfm, setAvgIcfm] = useState("");
  const [capacity, setUpcCapacity] = useState("");
  const [totalRunningCapacity, setTotalRunningCapacity] = useState("");
  const [ifc_powerMax, setifc_powerMax] = useState("");
  const [ifc_powerMin, setifc_powerMin] = useState("");
  const [Patm, setPatm] = useState("");
  const [W14_7, setW14] = useState("");
  const [W1, setW1] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [showwarning, setshowwarning] = useState(false);
  const [W2, setW2] = useState("");
  const [massVolume, setmassvolume] = useState("");
  const [compressedAirFlowSaved, setcompressedAirFlowSaved] = useState("");
  const [compressedAirFlowSavedper, setcompressedAirFlowSavedper] =
    useState("");
  const [energySaved, setEnergySaved] = useState("");
  const [EnergySavedInHP, setEnergySavedInHP] = useState("");
  const [EnergySavedPerYear, setEnergySavedPerYear] = useState("");
  const [CostSavedPerYear, setCostSavedPerYear] = useState("");
  const [upc_set_pressure, setupc_set_pressure] = useState("");

  const [actualICFM, setActualICFM] = useState("");
  const [loading, setLoading] = useState(false);

  const [runningHrs, setRunningHrs] = useState({
    avgMinRuninngHrsPerDay: "",
    avgMaxRuninngHrsPerDay: "",
  });

  const history = useHistory();
  let enquiryid = props.enquiryid;
  let amb_temp = props?.amb_temp;
  let amb_temp_c = amb_temp[0]?.ed_ambienttemp_c;
  let amb_temp_f = amb_temp[0]?.ed_ambienttemp_f;
  let workingdays = amb_temp[0]?.ed_workingdays;
  let electricityunitrate = amb_temp[0]?.ed_electricityunitrate;
  let currencyValue = amb_temp[0]?.ed_currencyvalue;
  let AvgGenPressure = networkDetail[0]?.rwn_rate;
  const ifcupccalc = store.getState().global?.userDetails.ram_ifcupccalc;
  //* networkDetail[0]?.rwn_rate;
  //let upc_set_pressure = networkDetail[0]?.nd_acceptablepressure;

  const fetchProduct = async () => {
    const resProduct = await getAllProductModels(type);
    console.log("products:::: ", resProduct);
    setProduct(resProduct);
  };
  const fetchUPCDetails = () => {
    // let index = ifcUpcDetail.findIndex((obj) => obj.id_ifc_code === NodeCode);
    // console.log("fetchUPCDetails", ifcUpcDetail[index]);
    setAvgIcfm(ifcCalculations.id_avg_icfm);
    setupc_set_pressure(ifcCalculations.id_set_pressure);
    setPatm(ifcCalculations.id_patm);
    setW14(ifcCalculations.id_w);
    setActualICFM(ifcCalculations.id_actual_cfm);
    setW1(ifcCalculations.id_air_wt_w1);
    setW2(ifcCalculations.id_air_wt_w2);
    setmassvolume(ifcCalculations.id_mass_volume);
    setcompressedAirFlowSaved(ifcCalculations.id_saved_compresed_air_flow);
    setcompressedAirFlowSavedper(
      ifcCalculations.id_saved_compressed_air_flow_per
    );
    setEnergySaved(ifcCalculations.id_upc_excessflow);
    setEnergySavedInHP(ifcCalculations.id_eng_saved_kwh);
    setEnergySavedPerYear(ifcCalculations.id_eng_saved_per_year);
    setCostSavedPerYear(ifcCalculations.id_cost_saving_per_year);

    // Energy saving in highest pressure network (kWh/day)
    // Energy saved per year (kWh/year)
    // Cost saving per year (INR)
  };

  // const fetchConversionDetails = () => {
  //   console.log("inside fetch");
  //   axios
  //     .get(`aem/api/maintenance/compDetail/header/getUpcAvgIcfm/${enquiryid}`)
  //     .then((response) => {
  //       console.log("responseee----", response);
  //       console.log(response.status);
  //       if (response.status === 200) {
  //         let temp = response.data;
  //         console.log(temp);
  //         setAvgIcfm(temp);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("soln connectn--", e);
  //     })
  //     .finally(() => {});
  // };
  const getSpecificPower = async () => {
    const resSum = await getSumOfCompressorHeader(enquiryid, "UPC", "UPC");
    console.log(resSum);
    let r = resSum.data.split(",");
    console.log("total-", r[0], r[1]);
    setIfcCalculations((prevState) => ({
      ...prevState,
      ifc_power: Number(r[1]).toFixed(4),
    }));
    setifc_powerMax(r[1]);
    setifc_powerMin(r[0]);

    const resCapacity = await getUpcCapacity(enquiryid);
    console.log(resCapacity?.data[0]?.pm_capacity);
    setUpcCapacity(resCapacity?.data[0]?.pm_capacity);
  };
  const fetchCorrectionfactor = async (inlet) => {
    const res = await getCorrectionfactor(inlet);
    //console.log("getspecificPower");
    // sum_ch_avg_icfm=resSum?.ch_avg_icfm
    console.log(res?.data[0])
    setIfcCalculations((prevState) => ({
      ...prevState,
      id_correctionfactor: Number(res?.data[0])
    }));  
  };
  const fetchConversionRate = () => {
    console.log("inside upc conversion fetch");

    axios
      .get(
        `/appDetail/getConversionRate/${props.enquiryid}/${type}/${NodeCode}`
      )

      .then((response) => {
        console.log("responseee inside upc conversion fetch-", response);
        console.log(response.status);
        if (response.status === 200) {
          let temp = response.data.data;
          console.log("responseee inside upc", temp);
          setTotalRunningCapacity(temp);
        }
      })
      .catch((e) => {
        console.log("soln connectn--", e);
      })
      .finally(() => {});
  };
  const fetchRunningHrs = () => {
    //console.log("inside fetch");
    let type = "UPC";
    axios
      .get(
        `/maintenance/compDetail/header/getUpcRunningHrs/${props.enquiryid}`
      )
      .then((response) => {
        console.log("responseee hrs----", response);
        console.log(response.data);
        if (response.status === 200) {
          let temp = response.data;
          console.log(temp);
          let t = temp.split(",");
          console.log("total-", t[0], t[1]);
          setRunningHrs((prevState) => ({
            ...prevState,
            avgMinRuninngHrsPerDay: t[0],
            avgMaxRuninngHrsPerDay: t[1],
          }));
        }
      })
      .catch((e) => {
        console.log("soln connectn--", e);
      })
      .finally(() => {});
  };

  useEffect(() => {
    (async () => {
      if (NodeCode) {
        setLoading(true);
        await fetchProduct();
        await getSpecificPower();
        //    fetchConversionDetails();
        fetchUPCDetails();
        // fetchConversionRate();
        await fetchRunningHrs();
        setLoading(false);
      }
    })();
    
    if(props.enqstatus=="Closed" && ifcCalculations.id_correctionfactor==null){
      setIfcCalculations((prevState) => ({
        ...prevState,
        id_correctionfactor: 1
      })); 
    }
    else{
    console.log("not closed")
    handleCorrectionFactor();
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [NodeCode]);
  const handleSubmit = async (e) => {
    // e.preventDefault();
    //alert("inside submit");
    console.log("In submit");
    setShow1(true);
  };
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setUser",
    });
  };
  const handleCloseSuccess = () => {
    props.onHide();
    setShow1(false);
    setShow(false);
    setAction("");
  };
  const handleClose = () => {
    setShow1(false);
    setAction("Edit");
  };
  const handleCorrectionFactor = () => {
       
      console.log("value",AvgGenPressure); 
      let cf = fetchCorrectionfactor(AvgGenPressure);
      console.log("CorrectionFactor",cf);
             
  };
  const handleAdd = async (e) => {
    const upcdata = { avgIcfm, upc_set_pressure };
    console.log(
      "inside update ---- ",
      action,
      enquiryid,
      upcdata,
      ifcCalculations,
      "UPC",
      "",
      Patm,
      W14_7,
      amb_temp_c,
      amb_temp_f
    );
    if (action === "Edit") {
      let flag = "UpdateWithUPC";
      console.log("updateIFC_Details", ifcCalculations);
      let result = await updateIFC_Details(
        [],
        flag,
        enquiryid,
        upcdata,
        ifcCalculations,
        //"UPC",
        "",
        Patm,
        W14_7,
        amb_temp_c,
        amb_temp_f,
        NodeCode
      );
      console.log("result----", result);
      console.log("result1----", result.data);
      if (
        result.data.message.includes("fails to match the required pattern") ||
        result.data.message.includes("must be")
      ) {
        let s = result.data.message;
        s = s.split(":")[0];
        console.log("In Weird Pattern", s);

        setShowMessage(s);
        setshowwarning(true);
      } else if (
        result &&
        (!result.data.message.includes("fails to match the required pattern") ||
          !result.data.message.includes("must be"))
      ) {
        console.log(result.data.message);

        setMsg(result.data.message);
        setShow(true);
        props.setNodeUpdateStatus(true);
        // setNodeCode("");
      } else {
        console.log(result.data.message);
        setAlertMessage(result.data.message);
        setShow1(false);
        props.setNodeUpdateStatus(true);
        // alert(result.message);
        // props.setReload(!props.reload);
      }
    } else if (action === "Delete") {
      let isError = false;
      let result = await deleteIfcUpc(enquiryid, type);
      console.log("result for delete", result);
      if (result) {
        setMsg(result.message);
        setShow(true);
        setShow1(false);
      } else {
        isError = true;
        console.log(result.message);
        setAlertMessage(result.message);
        setShow1(false);
        //setShowSuccess(true);
        return isError;
      }
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 5000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={show}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        handleYes={handleAdd}
        action={action}
        formName={props.formName}
        show={show1}
        handleClose={handleClose}
      />

      <WarningModal
        // clickFunction={modalOnClick}
        message={showMessage}
        show={showwarning}
        handleClose={() => {
          setshowwarning(false);
        }}
      />

      <Modal {...props} size="lg" backdrop="static" style={{ zIndex: 0 }}>
        <Modal.Header closeButton>
          <Modal.Title>UPC Details </Modal.Title>
        </Modal.Header>
        <fieldset id="upc_Dets" disabled={IsDisabled === false ? true : false}>
          <Form id="upcDetails" name="upcDetails">
            {/* <div className="action-area">
            &nbsp;{" "}
            <Button
              type="button"
              class="btn btn-primary btn-sm"
              style={{ width: "200px" }}
              onClick={() => {
                setAction("Delete");
                handleSubmit();
              }}
            >
              Delete UPC Details
            </Button>
          </div> */}

            <Modal.Body
              className={ifcupccalc ? "modalDefaultHeight" : "modalHeight"}
              id="upcModal"
            >
              {/* <span className="req-input">All Fields are Mandatory</span> */}
              <span>
                {" "}
                {alertMessage && (
                  <Alert className="alertBox" variant="warning">
                    {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                    {alertMessage}
                  </Alert>
                )}
              </span>
              {loading ? (
                <Loader />
              ) : (
                <div className="p-2">
                  <Row className="gx-3 mt-2">
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          Product Model Nos.(Godrej IFC Models)
                        </Form.Label>
                        <Form.Select
                          name="id_product_model"
                          value={ifcCalculations.id_product_model}
                          onChange={(e) => {
                            for (let i = 0; i < product.length; i++) {
                              if (
                                product[i].pm_prodmodelno === e.target.value
                              ) {
                                console.log(
                                  "updateddetails",
                                  e.target.value,
                                  product[i].pm_capacity
                                );
                                setIfcCalculations((prevState) => ({
                                  ...prevState,
                                  id_product_model: e.target.value,
                                  id_nominalflow: product[i].pm_capacity,
                                }));

                                // let index = ifcUpcDetail.findIndex(
                                //   (obj) => obj.id_ifc_code === NodeCode
                                // );
                                // ifcUpcDetail[index].id_product_model =
                                //   e.target.value;
                                // ifcUpcDetail[index].id_nominalFlow =
                                //   product[i].pm_capacity;
                              }
                            }
                            // setIfcCalculations((prevState) => ({
                            //   ...prevState,
                            //   id_product_model: e.target.value,
                            // }));
                          }}
                        >
                          <option value="">
                            {ifcCalculations.id_product_model}
                          </option>

                          {product?.map((value, index) => (
                            <option value={value.pm_prodmodelno}>
                              {value.pm_prodmodelno}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Product Type</Form.Label>
                        <Form.Control
                          id="type"
                          name="type"
                          type="text"
                          value={type}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Capacity/Nominal Flow (CFM)</Form.Label>
                        <Form.Control
                          id="id_nominalFlow"
                          name="id_nominalFlow"
                          type="text"
                          value={ifcCalculations.id_nominalflow}
                          readOnly
                          // onChange={(e) => {
                          //   setIfcCalculations((prevState) => ({
                          //     ...prevState,
                          //     id_nominalFlow: e.target.value,
                          //   }));
                          // }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="gx-3 mt-2">
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Average iCFM (CFM)</Form.Label>
                        <Form.Control
                          type="text"
                          name="avgIcfm"
                          maxLength="50"
                          value={parseFloat(avgIcfm).toFixed(4)}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          Avg. Gen. Pressure of HP network (psig)
                        </Form.Label>
                        <Form.Control
                          id="AvgGenPressure"
                          name="AvgGenPressure"
                          type="text"
                          value={AvgGenPressure}
                          onChange={(e)=>{handleCorrectionFactor();}}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>UPC Set Pressure (psig)</Form.Label>
                        <Form.Control
                          id="upc_set_pressure"
                          name="upc_set_pressure"
                          type="text"
                          value={upc_set_pressure}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div hidden={!ifcupccalc}>
                    <Row className="gx-3 mt-2">
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>
                            P<sub>atm</sub> (psig)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="Patm"
                            maxLength="50"
                            value={Patm}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>
                            W<sub>14.7</sub> (lbs/cft)
                          </Form.Label>
                          <Form.Control
                            id="W14_7"
                            name="W14_7"
                            type="text"
                            value={W14_7}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Specific Power (kW/CFM)</Form.Label>
                          <Form.Control
                            id="ifc_power"
                            name="ifc_power"
                            type="text"
                            value={ifcCalculations.ifc_power}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Form.Label>Ambient Temperature (°F)</Form.Label>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="amb_tempF"
                            maxLength="50"
                            value={amb_temp_f}
                          />
                        </Form.Group>
                      </Col>
                      <Form.Label>Ambient Temperature (°C)</Form.Label>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            id="amb_tempC"
                            name="amb_tempC"
                            type="text"
                            value={amb_temp_c}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Form.Label>Actual CFM currently used (CFM)</Form.Label>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="ifc_actual_avg_icfm"
                            maxLength="50"
                            // value={
                            //   (ifcCalculations.ifc_actual_avg_icfm = Number(
                            //     (avgIcfm * Patm) / (AvgGenPressure * 1 + Patm)
                            //   ).toFixed(4))
                            // }
                            value={
                              (ifcCalculations.ifc_actual_avg_icfm =
                                Number(actualICFM).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col>
                        <Form.Label>
                          {" "}
                          Weight of air at 32<sup>o</sup>F And 14.7 psig, W
                          <sub>14.7</sub> (lbs/cft)
                        </Form.Label>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="W14_7"
                            maxLength="50"
                            value={W14_7}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col>
                        <Form.Label>
                          Weight of air at temperature T And Pressure
                          P.Considering Temp 104<sup>o</sup>F And UPC Upstream
                          Pressure, W1 (lbs/cft)
                        </Form.Label>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="ifc_W1"
                            maxLength="50"
                            // value={
                            //   (ifcCalculations.ifc_W1 = Number(
                            //     W14_7 /
                            //       ((((460 + amb_temp_f * 1) / (460 + 32)) * Patm) /
                            //         (AvgGenPressure * 1 + Patm))
                            //   ).toFixed(4))
                            // }
                            value={
                              (ifcCalculations.ifc_W1 = Number(W1).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col>
                        <Form.Label>
                          Weight of air at temperature T And Pressure
                          P.Considering Temp 104<sup>o</sup>F And UPC Upstream
                          Pressure, W2 (lbs/cft)
                        </Form.Label>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="ifc_W2"
                            maxLength="50"
                            // value={
                            //   (ifcCalculations.ifc_W2 = Number(
                            //     W14_7 /
                            //       ((((460 + amb_temp_f * 1) / (460 + 32)) * Patm) /
                            //         (upc_set_pressure * 1 + Patm))
                            //   ).toFixed(4))
                            // }
                            value={
                              (ifcCalculations.ifc_W2 = Number(W2).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col>
                        <Form.Label>
                          The Mass volume of air saved due to pressure
                          optimization is Diference in weight (lbs/cft)
                        </Form.Label>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="ifc_diffInWt"
                            maxLength="50"
                            value={
                              (ifcCalculations.ifc_diffInWt =
                                Number(massVolume).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                        <Col>
                          <Form.Label>Correction Factor</Form.Label>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="id_correctionfactor"
                              maxLength="50"
                              value={props.enqstatus=="Closed"? '': Number(ifcCalculations.id_correctionfactor)}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>
                            Compressed Air Flow Saved (CFM)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="ifc_airFlowSaved"
                            maxLength="50"
                            // value={
                            //   (ifcCalculations.ifc_airFlowSaved = Number(
                            //     (ifcCalculations.ifc_diffInWt *
                            //       ifcCalculations.ifc_actual_avg_icfm) /
                            //       W14_7
                            //   ).toFixed(4))
                            // }
                            value={
                              (ifcCalculations.ifc_airFlowSaved = Number(
                                compressedAirFlowSaved
                              ).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>
                            % Compressed Air Flow Saving (%)
                          </Form.Label>
                          <Form.Control
                            id="ifc_airFlowinPercent"
                            name="ifc_airFlowinPercent"
                            type="text"
                            // value={
                            //   (ifcCalculations.ifc_airFlowinPercent = Number(
                            //     (ifcCalculations.ifc_airFlowSaved / avgIcfm) * 100
                            //   ).toFixed(4))
                            // }
                            value={
                              (ifcCalculations.ifc_airFlowinPercent = Number(
                                compressedAirFlowSavedper
                              ).toFixed(4))
                            }
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Group>
                          <Form.Label>
                            Excess flow passing through UPC (CFM)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="excessFlowThroughUPC"
                            maxLength="50"
                            // value={
                            //   (ifcCalculations.id_upc_excessFlow = Number(
                            //    (totalRunningCapacity * 1 -
                            //       avgIcfm * 1 )+
                            //       ifcCalculations.ifc_airFlowSaved * 1
                            //   ).toFixed(4))
                            // }
                            value={
                              (ifcCalculations.id_upc_excessFlow =
                                Number(energySaved).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Group>
                          <Form.Label>
                            Energy saving in highest pressure network (kWh/day)
                          </Form.Label>
                          <Form.Control
                            name="ifc_energySavedPerDay"
                            maxLength="50"
                            // value={ totalRunningCapacity+'+'+ avgIcfm +"+"+ifc_powerMax+"++"+runningHrs.avgMaxRuninngHrsPerDay+"+"+
                            // ifcCalculations.id_upc_excessFlow +"++"+ifc_powerMin+"+" +runningHrs.avgMinRuninngHrsPerDay}

                            value={
                              (ifcCalculations.ifc_energySavedPerDay =
                                Number(EnergySavedInHP).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Group>
                          <Form.Label>
                            Energy saved per year (kWh/year)
                          </Form.Label>
                          <Form.Control
                            id="ifc_energySavedPerYear"
                            name="ifc_energySavedPerYear"
                            type="text"
                            value={
                              (ifcCalculations.ifc_energySavedPerYear =
                                Number(EnergySavedPerYear).toFixed(4))
                            }
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Group>
                          <Form.Label>
                            Cost saving per year ({currencyValue})
                          </Form.Label>
                          <Form.Control
                            id="ifc_costSavedPerYear"
                            name="ifc_costSavedPerYear"
                            type="text"
                            value={validUSDCurrency(
                              (ifcCalculations.ifc_costSavedPerYear =
                                Number(CostSavedPerYear).toFixed(4))
                            )}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <br />
                </div>
              )}
            </Modal.Body>
            {action === "Edit" ? (
              <Modal.Footer className="justify-content-start">
                <Button
                  hidden={IsDisabled === false ? true : false}
                  type="button"
                  class="btn btn-primary btn-sm"
                  style={{ width: "80px" }}
                  onClick={() => {
                    setAction("Edit");
                    handleSubmit();
                  }}
                >
                  {action === "Edit" ? "Update" : "Save"}
                </Button>
                <Button
                  class="btn btn-primary btn-sm"
                  variant="outline-primary"
                  onClick={props.onHide}
                  style={{ width: "80px" }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            ) : (
              <div></div>
            )}
          </Form>
        </fieldset>
      </Modal>
    </>
  );
};
export default UPCDetails;
