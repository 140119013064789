import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_UNIT_CONVERSION_API_URL;
console.log(API_URL);
//const axios = require("../utils/axios");

export async function getParameter() {
  let url = `${API_URL}/parameter`;
  console.log("reached -- " + url);
  //alert(url);
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export async function getAll() {
  console.log("reached here" + API_URL);
  // return fetch(API_URL).then((response) => response.json());
  const response = await axios
    .get(API_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function checkDataInEnquirySession(ucm_from_unit) {
  let url = `${API_URL}/checkDataInEnquirySession/${ucm_from_unit}`;
  console.log(url);
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export async function checkDataInAppSession(ucm_from_unit) {
  let url = `${API_URL}/checkDataInAppSession/${ucm_from_unit}`;
  console.log(url);
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export async function getFromUnit(param) {
  let url = `${API_URL}/getFromUnit/${param}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;

  //let url = `${API_URL}/getFromUnit/${param}`;
  //console.log("reached here" + url);
  // return fetch(url).then((response) => response.json());
}

export async function getUnitConversionRate(fromunit, parameterType) {
  let body = {
    ucm_From_unit: fromunit,
  };
  let url = `${API_URL}/parameter/getUnitConversionRate/ucm_parameter/${parameterType}`;
  const response = await axios
    .post(url, JSON.stringify(body))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;

  //let url = `${API_URL}/getFromUnit/${param}`;
  //console.log("reached here" + url);
  // return fetch(url).then((response) => response.json());
}

export const AddNewUnit_bkp = (Unit) => {
  // console.log(Unit);
  let url = `${API_URL}/unit`;
  console.log("url--", url);
  console.log("Unit----", Unit);
  let response = "";
  axios
    .post(API_URL, Unit)
    .then((resp) => {
      console.log("response---- ", resp);
      response = resp;
      //return resp;
    })
    .catch(function (error) {
      console.log("error---- ", error);
      response = error;
      //return { message: "Unable to find request", status: false };
    });
  return response.data;
};
export const AddNewUnit = async (Unit) => {
  // console.log(Unit);
  let url = API_URL;
  //alert(url);
  const response = await axios
    .post(url, JSON.stringify(Unit))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("responseeee---", response);
  return response?.data || "";
};

export const UpdateUnit = async (item) => {
  console.log(JSON.stringify(item));
  //let url = `${API_URL}/ucm_from_unit/${item.ucm_from_unit}/ucm_to_unit/${item.ucm_to_unit}`;
  let url = `${API_URL}/update/ucm_id/${item.ucm_id}`;
  console.log("URL--- ", url);
  const response = await axios
    .post(url, item)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function deleteUnit(data) {
  console.log(data);
  let url = `${API_URL}/ucm_id/${data.ucm_id}`;
  console.log(url);
  const resp = await axios
    .post(url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return resp.data;
}
