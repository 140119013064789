import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_PRODUCT_API_URL;

export async function getAllProducts() {
  let url = API_URL;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export async function getAllProductModels(type) {
  let url = `${API_URL}/getAllProductModels/${type}`;
  console.log(url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getProduct(ProdModelNo) {
  let url = `${API_URL}/prodmodelno/${ProdModelNo}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function getUpcCapacity(enquiryid) {
  let url = `${API_URL}/getUpcCapacity/${enquiryid}`;
  console.log("product url", url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function deleteProduct(ProdCategory, ProdModelNo) {
  let url = `${API_URL}/pm_prodcategory/${ProdCategory}/pm_prodmodelno/${ProdModelNo}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export const UpdateProduct = async (Product) => {
  console.log(JSON.stringify(Product));
  let url = `${API_URL}/update/pm_prodcategory/${Product.pm_prodcategory}/pm_prodmodelno/${Product.pm_prodmodelno}`;
  const response = await axios
    .post(url, JSON.stringify(Product))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const EnrollNewProduct = async (Product) => {
  console.log(JSON.stringify(Product));
  let url = API_URL;
  const response = await axios
    .post(url, JSON.stringify(Product))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
