import { useState, useEffect } from "react";
import { Button, Form, Modal, NavLink } from "react-bootstrap";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { AgGridReact } from "ag-grid-react";
//import { EnrollNewDealer, UpdateDealer } from "../../../Services/dealer-service";
import { EnrollNewProduct } from "../../../Services/product-service";
import PaginationComp from "../../utility/PaginationComp";

import { getAllLocationMaster } from "../../../Services/location-service";
import { Meta } from "react-bootstrap-icons";
import { 
  downloadFileDirect,
  
} from "../../../utils/presignUpload";
const API_URL_Loc = process.env.REACT_APP_LOC_API_URL;
const API_URL = process.env.REACT_APP_READ_PRESIGNED_ENDPOINT;

//var CryptoJS = require("crypto-js");

const Pic_Preview = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loc, setloc] = useState([]);
  const [FileUrl, setFileUrl] = useState("");
  const [FileUrl1, setFileUrl1] = useState("");
  const [DispAttach, setDispAttach] = useState(false);
  const [n, setn] = useState();
  const axios = require("axios").default;

  // useEffect(() => {
  //   // setn(0);
  //   // setCurrentPage(1);
  //   // setPicCountValue();
  // }, []);

  const [ProductObj, setProductObj] = useState(props.data);
  const [PicCount, setPicCount] = useState(ProductObj.length);
  var count = 0;


let testvar="";
console.log(testvar);
function logTestVar(){
  console.log('logTestVar',testvar);
}

  console.log(ProductObj.length);
  function functonextpic(page) {
    console.log("hi");
    setCurrentPage(page);
    setn(page - 1);
    //console.log("hi")
  }

  console.log(ProductObj);
  let key = "";
  if (ProductObj[0].includes(".net/")) {
    key = ProductObj[0].split(".net/")[1];
  } else if (ProductObj[0].includes(".com/")) {
    key = ProductObj[0].split(".com/")[1];
  } else {
    key = ProductObj[0].replace(/\s+/g, "");
  }
  console.log(key);

  let url = API_URL;
  console.log(url + "/" + key);
  axios
    .get(url + "/" + key)
    .then(function (response) {
      console.log(response);
      if (
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".JPG") ||
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".JPEG") ||
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".jpg") ||
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".jpeg") ||
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".bmp") ||
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".png")
      ) {
        //window.open(response.data.url);
        setDispAttach(true);
        setFileUrl(response.data.url);
      } else if (
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".pdf") ||
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".txt")
      ) {
        window.open(response.data.url);
        // downloadFileDirect(response.data.url);
      } else if (
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".hproj")) {
            downloadFileDirect(response.data.url, '.hproj');
      }
      else {
        //downloadFile(response.data.url);
      }

      return response;
    })
    .catch(function (error) {
      console.log(error);
    });



  
  console.log(FileUrl);

  return (
    <>

      <div>
        <iframe
          style={{
            height: 500,
            width: "100%",
            alignContent: "center",
            overflow: "auto",
          }}
          src={FileUrl1}
        />
      </div>
 
      
        <Modal {...props} size="lg" >
          <Modal.Header closeButton>
            <Modal.Title>Attachments</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <div
                className="ag-theme-material"
                style={{ height: 500, width: "100%" }}
              >
                <img
                  style={{ height: 450, width: "95%", alignContent: "center" }}
                  src={FileUrl}
                />
              </div>
            </Modal.Body>

            <Modal.Footer className="justify-content-right">
              <PaginationComp
                currentPage={currentPage}
                totalCount={PicCount}
                pageSize={1}
                onPageChange={(page) => functonextpic(page)}
              //onChange = {setn(n+1) }
              />
            </Modal.Footer>
          </Form>
        </Modal>

        
    </>
  );
};

export default Pic_Preview;
