import axios from "../utils/axios"
const API_URL = process.env.REACT_APP_NEW_SOLN_API_URL;


export const AddNewNetwork = async (Networks) => {
  const response = await axios
    .post(API_URL, JSON.stringify(Networks))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
};

export const SaveCoordinate = async (Networks,enquiryId) => {
  let url = `${API_URL}/addCoord/${enquiryId}`;
  console.log("url",url);
  console.log("net",Networks);
  const response = await axios
    .post(url, JSON.stringify(Networks))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
};

export const SaveCoordinateFourth = async (Networks,enquiryId) => {
  let url = `${API_URL}/saveCoord/${enquiryId}`;
  const response = await axios
    .post(url, JSON.stringify(Networks))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
};

export const GetNetwork = async (enquiryId) =>{
  let url = `${API_URL}/connection/${enquiryId}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  return resp;
}

export const UpdateNetwork = async (Network) => {
  let url = `${API_URL}/update`;
  console.log(JSON.stringify(Network));
  const response = await axios
    .post(url, JSON.stringify(Network), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
};

export const DeleteNetwork = async (connID) => {
  let url = `${API_URL}/nc_conn_id/${connID}`;
  const resp = await axios
    .delete(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}

