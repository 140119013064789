import { Handle, Position } from "react-flow-renderer";

function ApplicationNode({ data }) {
  console.log(data);
  return (
    <div className="application-node">
      <Handle type="target" position={Position.Left} />
      <label>{data.label}</label>
      <Handle type="source" position={Position.Right} />
    </div>
  );
}

export default ApplicationNode;
