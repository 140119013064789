import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import PlusIcon from "../../styles/images/white-plus.svg";
import { getAllCompressorHouseHeader } from "../../Services/compressor-house-service";

import AddCompressor from "./AddCompressor";
import axios from "../../utils/axios";
import CommonModal from "../Modals/commonModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import {
  getAllAirCompMakeYes,
  getAllAirCompTypeYes,
  getAllAirCompLubYes,
} from "../../Services/compressor-parameter-service";
import {
  getFromUnit,
  getUnitConversionRate,
} from "../../Services/unit-conversion-service";
import WarningModal from "../Modals/warningModal";
import Loader from "../utility/Loader";
const CompressorDetails = forwardRef((props, ref) => {
  let enquiryID = props.enquiryid
    ? props.enquiryid
    : localStorage.getItem("enqkey");
  // let enquiryID = props.enquiryid;
//enquiryID = 4; //comment this later
  const [showCompForm, setshowCompForm] = useState(false);
  const [active, setActive] = useState(0);
  const [show, setShow] = useState(false);
  const [tabValue, setTabValue] = useState([]);
  const [show1, setShow1] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("Delete Compressor Line");
  const [deleteLast, setDeleteLast] = useState(false);
  const [show2, setShow2] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [deleteAirData, setDeleteAirData] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [ShowMessageWarning, setShowMessageWarning] = useState("");
  const[showwarning, setshowwarning] = useState(false)

  const [alertMessage, setAlertMessage] = useState("");
  const [Pressure, setPressure] = useState([]);
  const [Capacity, setCapacity] = useState([]);
  const [CompressorParameters, setCompressorParameters] = useState([]);
  const [Power, setPower] = useState([]);
  const [listDot, setListDot] = useState(false);
  const [listDotActive, setListDotActive] = useState(false);
  const [isLoader, setLoader] = useState(false);


  const [compHouse, setCompHouse] = useState([
    {
      ch_enquiry_id: enquiryID,
      ch_controlsettings_unit: "",
      ch_capacity_unit: "",
      ch_power_unit: "",
      ch_energy_consumption_per_day: "",
      ch_avg_icfm: "",
      ch_comp_code: "",
      ch_comp_house_id: "",
      ch_sequence_number: "",
      ch_comp_house_name: "",
      ch_x_axis: "",
      ch_y_axis: "",
      ch_controlsettings_unit_rate: "",
      ch_capacity_unit_rate: "",
      ch_power_unit_rate: "",
    },
  ]);
  const [airTreatment, setAirTreatment] = useState([
    {
      ah_enquiry_id: enquiryID,
      ah_compressor_id: "",
      ah_air_id: "",
      ah_dryer_unit: "",
      ah_pre_filter_unit: "",
      ah_post_filter_unit: "",
    },
  ]);
  const fetchAirTreatmentHeader = async() => {
    await axios
      .get(
        `/airTreatment/header/${enquiryID}/${compHouse[active]?.ch_comp_house_id}`
      )
      .then((response) => {
        if (response.status === 200) {
          let temp = response?.data?.data;
          if (temp && temp?.length) {
            setAirTreatment([...temp]);
          }
         
        }
      })
      .catch((e) => {
        console.log("fetchAirTreatmentHeader",e)
        return "error";
      })
      .finally(() => {});
  };
  // useImperativeHandle(ref, () => ({
  //   runValidate() {
  //     let temp = validateCompressor();
  //     return temp;
  //   },
  // }));
  const getcompYes = async () => {
    let compressors = new Array();
    let result = await getAllAirCompMakeYes();

    //typeyes
    let resultTypeYes = await getAllAirCompTypeYes();

    //let resultlubyes = await getAllAirCompTypeYes
    let resultLubYes = await getAllAirCompLubYes();

    compressors.push(result, resultTypeYes, resultLubYes);
    setCompressorParameters(compressors);
    return compressors;
  };

  const getUnits = async () => {
    const resFromUnitPressure = await getFromUnit("Pressure");
    setPressure(resFromUnitPressure);
    const resFromUnitCapacity = await getFromUnit("Capacity");
    setCapacity(resFromUnitCapacity);
    const resFromUnitPower = await getFromUnit("Power");
    setPower(resFromUnitPower);
    return resFromUnitPower;
  };

  const fetchCompHouseHeader = () => {
    let tempData = "";
    axios
      .get(`/maintenance/compDetail/header/${enquiryID}`)
      .then((response) => {
        if (response.status === 200 && response.data.data.length) {
          let temp = response.data.data;
          tempData = temp;
          if (temp.length) {
            setCompHouse([...temp]);
            props.setDeleteNumber(temp?.length || 0);
            setListDotActive(true);
            console.log("comphouse",compHouse,"-",temp);
          }
          if (props.IsDisabled === true) {
            setCompHouse((prev) => {
              return [
                ...prev,
                {
                  ch_enquiry_id: enquiryID,
                  ch_controlsettings_unit: "",
                  ch_capacity_unit: "",
                  ch_power_unit: "",
                  ch_energy_consumption_per_day: "",
                  ch_avg_icfm: "",
                  ch_comp_code: "",
                  ch_comp_house_id: "",
                  ch_sequence_number: "",
                  ch_comp_house_name: "",
                  ch_x_axis: "",
                  ch_y_axis: "",
                  ch_controlsettings_unit_rate: "",
                  ch_capacity_unit_rate: "",
                  ch_power_unit_rate: "",
                },
              ];
            });
          }
        } else {
          setCompHouse([
            {
              ch_enquiry_id: enquiryID,
              ch_controlsettings_unit: "",
              ch_capacity_unit: "",
              ch_power_unit: "",
              ch_energy_consumption_per_day: "",
              ch_avg_icfm: "",
              ch_comp_code: "",
              ch_comp_house_id: "",
              ch_sequence_number: "",
              ch_comp_house_name: "",
              ch_x_axis: "",
              ch_y_axis: "",
              ch_controlsettings_unit_rate: "",
              ch_capacity_unit_rate: "",
              ch_power_unit_rate: "",
            },
          ]);

          
        }
      })
      .catch((e) => {
        //console.log("In Catch", e);
        // setCompHouse([
        //   {
        //     ch_enquiry_id: enquiryID,
        //     ch_controlsettings_unit: "",
        //     ch_capacity_unit: "",
        //     ch_power_unit: "",
        //     ch_energy_consumption_per_day: "",
        //     ch_avg_icfm: "",
        //     ch_comp_code: "",
        //     ch_comp_house_id: "",
        //     ch_sequence_number: "",
        //     ch_comp_house_name: "",
        //     ch_x_axis: "",
        //     ch_y_axis: "",
        //     ch_controlsettings_unit_rate: "",
        //     ch_capacity_unit_rate: "",
        //     ch_power_unit_rate: "",
        //   },
        // ]);
      })
      .finally(() => {
        return tempData;
      });
  };

  const DeleteCompressor = () => {
    let data = {
      enquiry_id: compHouse[active]?.ch_enquiry_id,
      comp_house_id: compHouse[active]?.ch_comp_house_id,
      application_id: 0,
      net_conn_id: 0,
    };
    if (
      compHouse[active]?.ch_enquiry_id &&
      compHouse[active]?.ch_comp_house_id
    ) {
      axios
        .post(`/maintenance/compAppNetwork/delete`, {
          //  .delete(`/maintenance/compAppNetwork`, {
          data: {
            enquiry_id: compHouse[active]?.ch_enquiry_id,
            comp_house_id: compHouse[active]?.ch_comp_house_id,
            application_id: 0,
            net_conn_id: 0,
          },
        })
        .then(async(response) => {
          if (response.status === 200) {
            setShowMessage(
              `Great! You’ve Deleted Compressor ${compHouse[active].ch_comp_house_name} successfully`
            );
            await fetchCompHouseHeader();
            //fetchAirTreatmentHeader();
            props.calculateEnergy();
            setShow(true);
            // setshowCompForm(true);
            // setShow1(false);
          }
        })
        .catch((e) => {
          console.log("after delete- error----------", e);
          console.error(e);
          //alert(e);
          if (e == "Error: Request failed with status code 400") {
            //alert("You cannot delete compressors with network connection!");
            setAlertMessage(
              "Please delete corresponding connections before deleting application"
            );
          }
        })
        .finally(() => {});
    } else if (compHouse[active]?.ch_comp_house_id === "") {
      let temp = [...compHouse];
      temp.splice(active, 1);
      setCompHouse([...temp]);
    }
    props.setModule("Compressor");
  };
  useEffect(async () => {
    //debugger;
   // setLoader(true);
    // fetchCompHouseHeader();
   // let airTreatment= await fetchAirTreatmentHeader();
    let comp=await getcompYes();
    let units=await getUnits();
   // if(airTreatment && comp && units)
    //setLoader(false);
  }, [props?.enquiryid, props.IsDisabled]);
  useEffect(() => {
    DeleteCompressor();
  }, [props.deleteKey]);
  useEffect(() => {
    console.log("delete-comp-name-------------");
    console.log(compHouse[active]);
    if (compHouse[active] !== undefined) {
      props.setModule(`Compressor ${compHouse[active].ch_comp_house_name}`);
      //compHouse[active].ch_comp_house_name===undefined?"":compHouse[active].ch_comp_house_name
    }
  }, [active]);
  useEffect(() => {
    console.log("test log comphouse---", compHouse);
    if (compHouse.length > 0) {
      props.addNode(compHouse);
    } else {
      console.log("test log comphouse else casse---", compHouse);
      props.addNode(compHouse);
      handleAddClick(0);
    }
  }, [compHouse]);

  useEffect(() => {
    fetchCompHouseHeader();
    setListDot(false);
    
  }, []);

  useEffect(() => {
    if (!listDotActive) {
      return;
    }
    if (listDot === true) {
      setActive(compHouse.length - 1);
    } else {
      setActive(compHouse.length);
    }
  }, [listDot, listDotActive]);

  console.log("listDot",listDot);
  console.log("listDotActive",listDotActive);

  const handleAddClick = (id) => {
    if (!validateCompressor()) {
      // setActive(id);
      setCompHouse((prev) => {
        return [
          ...prev,
          {
            ch_enquiry_id: enquiryID,
            ch_controlsettings_unit: "",
            ch_capacity_unit: "",
            ch_power_unit: "",
            ch_energy_consumption_per_day: "",
            ch_avg_icfm: "",
            ch_comp_code: "",
            ch_comp_house_id: "",
            ch_sequence_number: "",
            ch_comp_house_name: "",
            ch_x_axis: "",
            ch_y_axis: "",
            ch_controlsettings_unit_rate: "",
            ch_capacity_unit_rate: "",
            ch_power_unit_rate: "",
          },
        ];
      });
      setAirTreatment((prev) => {
        return [
          ...prev,
          {
            ah_enquiry_id: enquiryID,
            ah_compressor_id: "",
            ah_air_id: "",
            ah_dryer_unit: "",
            ah_pre_filter_unit: "",
            ah_post_filter_unit: "",
          },
        ];
      });
    }
  };

  const validateCompressor = () => {
    let error = false;
    let temp = [...compHouse];
    temp.forEach((value, index) => {
      if (!value.ch_comp_house_id) {
        temp[index].error = true;
        error = true;
      }
    });
    setCompHouse([...temp]);
    return error;
  };
  const handleDelete = (item) => {
    if (item?.cl_line_id) {
      axios
        .post(`/maintenance/compDetail/delete`, {
          data: {
            cl_line_id: item?.cl_line_id,
            enquiry_id: item?.cl_enquiry_id,
            compressor_id: item?.cl_compressor_id,
          },
        })
        .then(async (response) => {
          if (response.status === 200) {
            if (deleteLast === true) {
              await fetchCompHouseHeader();
              setShow1(false);
              props.calculateEnergy();
            } else {
              await fetchCompHouseHeader();
              setShow1(false);
              setshowCompForm(true);
              props.calculateEnergy();
            }
            await setshowCompForm(false);
            await setShowMessage(response.data.message);
            setShow(true);
          }
        })
        .catch((e) => {
          console.log("after delete- error----------", e);
        })
        .finally(() => {
          setDeleteLast(false);
        });
    }
  };
  const handleAirDelete = (item) => {
    if (item?.al_line_id) {
      axios
        .post(`/airTreatment/delete`, {
          data: {
            air_id: item.al_air_id,
            line_id: item?.al_line_id,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setshowCompForm(true);
            setShow2(false);
          }
        })
        .catch((e) => {
          console.log("after delete- error----------", e);
        })
        .finally(() => {});
    }
  };
  const deleteMethod = () => {
    handleDelete(deleteData);
  };
  const deleteAirMethod = () => {
    handleAirDelete(deleteAirData);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);
  return (
    <>
      <CommonModal
        // clickFunction={modalOnClick}
        message={showMessage}
        show={show}
        handleClose={() => {
          setShow(false);
          setshowCompForm(true);
          fetchCompHouseHeader();
        }}
      />
      <WarningModal
        // clickFunction={modalOnClick}
        message={ShowMessageWarning}
        show={showwarning}
        handleClose={() => {
          setshowwarning(false);
          //setshowCompForm(true);
        }}
      />
      <ConfirmAlertModal
        handleYes={() => deleteMethod()}
        formName={deleteMessage}
        show={show1}
        handleClose={() => {
          setShow1(false);
          setshowCompForm(true);
        }}
      />
      <ConfirmAlertModal
        handleYes={() => deleteAirMethod()}
        formName="Delete Air Treatment Line"
        show={show2}
        handleClose={() => {
          setShow2(false);
          setshowCompForm(true);
        }}
      />
        {isLoader ? (
        <Loader />
      ) : null}
        <div id="CompressorDets" style={{ paddingBottom: "16px" }}>
          <div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                {alertMessage}
              </Alert>
            )}
          </div>
          <ul id="app-add-btns" className="m-0 list-unstyled">
            {compHouse.map((item, id) => {
              return (
                <div key={"list-" + id}>
                  <li id={"list-item-" + id} className="mt-3">
                    <Row className="p-0 m-0" style={{ position: "relative" }}>
                      <Col
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        className="p-0 d-flex align-items-center justify-content-center"
                      >
                        <div
                          className={
                            active === id
                              ? showCompForm
                                ? "d-none"
                                : "list-bullet active"
                              : "list-bullet"
                          }
                        ></div>
                      </Col>
                      <Col
                        xs={11}
                        sm={11}
                        md={11}
                        lg={11}
                        id={"app-add-btn-" + id}
                        onClick={() => {
                          setTabValue([]);
                          setActive(id);
                          setshowCompForm(!showCompForm);
                        }}
                        className={`${
                          showCompForm && active === id
                            ? "app-add-btn active "
                            : "app-add-btn "
                        } ${item?.error === true ? "errorBorder" : ""} ${active === id ? "activeComp" : ""}`}
                      >
                        <span>
                          {" "}
                          {compHouse[id].ch_comp_house_name === ""
                            ? "Compressor House"
                            : compHouse[id].ch_comp_house_name}
                        </span>

                        {id + 1 <= compHouse.length ? (
                          <div className="dot prev"></div>
                        ) : (active === id && !showCompForm) ||
                          active !== id ? (
                          ""
                        ) : (
                          <div className="dot active"></div>
                        )}
                      </Col>
                      {id + 1 < compHouse.length ? (
                        ""
                      ) : (active === id && !showCompForm) || active !== id ? (
                        <img
                          // onClick={() => togglePassword()}
                          hidden={props.IsDisabled === false ? true : false}
                          src={PlusIcon}
                          alt="plusIcon"
                          onClick={() => {
                            handleAddClick();
                            setshowCompForm(!showCompForm);
                          }}
                          style={{
                            position: "absolute",
                            width: "46px",
                            right: "0px",
                            marginTop: "5px",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Row>
                  </li>
                </div>
              );
            })}
          </ul>
          {showCompForm && (
            <AddCompressor
            setActive={setActive}
              Pressure={Pressure}
              setListDot={setListDot}
              Capacity={Capacity}
              Power={Power}
              CompressorParameters={CompressorParameters}
              page2key={props.page2key}
              enquiryID={enquiryID}
              addNode={props.addNode}
              calculateEnergy={props.calculateEnergy}
              setModule={props.setModule}
              compHouse={compHouse}
              setCompHouse={setCompHouse}
              fetchCompHouseHeader={fetchCompHouseHeader}
              airTreatment={airTreatment}
              setTabValue={(value) => {
                if (value) {
                  let temp = [...tabValue];
                  temp.push(active);
                  setTabValue([...temp]);
                } else {
                  let temp = [...tabValue];
                  while (temp.findIndex((e) => e === active) >= 0)
                    temp.splice(
                      temp.findIndex((f) => f === active),
                      1
                    );
                  setTabValue([...temp]);
                }
              }}
              tabValue={
                tabValue.length
                  ? tabValue.includes(active)
                    ? true
                    : false
                  : false
              }
              setAirTreatment={setAirTreatment}
              setShow1={async (flag, value, lastCheck) => {
                await setshowCompForm(false);
                await setDeleteData(value);
                if (lastCheck == "last") {
                  setDeleteLast(true);
                  setDeleteMessage(
                    "Delete Compressor Line, This is Last Compressor Line if deleted whole compressor will be deleted"
                  );
                } else {
                  setDeleteLast(false);
                  setDeleteMessage("Delete Compressor Line");
                }
                setShow1(flag);
              }}
              setShow2={async (flag, value) => {
                await setshowCompForm(false);
                await setDeleteAirData(value);
                setShow2(flag);
              }}
              active={active}
              // id={id}
              setShowMessage={async (data) => {
                await setshowCompForm(false);
                await setShowMessage(data);
                setShow(true);
              }}
              setShowMessageWarning={async (data) => {
                //await setshowCompForm(false);
                await setShowMessageWarning(data);
                setshowwarning(true);}}
              closeModal={() => setshowCompForm(false)}
              show={{
                show: showCompForm,
                onHide: () => setshowCompForm(false),
              }}
              IsDisabled={props.IsDisabled}
              setshowCompForm={setshowCompForm}
            />
          )}
        </div>
    </>
  );
});

export default CompressorDetails;
