import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_USER_API_URL;
const API_UL_URL = process.env.REACT_APP_USERLOCATION_API_URL;
//const API_UL_URL='http://localhost:5005/aem/api/maintenance/userLocation';
const API_ULbkp_URL = process.env.REACT_APP_USERLOCATION_bkp_API_URL;
const API_URL_DATA_MASK = process.env.REACT_APP_DATA_MASK_API_URL;
console.log(API_URL, API_UL_URL);
export async function getAllUserMaster() {
  const response = await axios
    .get(API_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
  //return fetch(API_URL).then((response) => response.json());
}

export async function getApprovalStatusByID() {
  const response = await axios
    .get(API_URL + "/approval")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
  //return fetch(API_URL).then((response) => response.json());
}

export async function getApprovalStatus(user) {
  const response = await axios
    .get(`${API_URL}/approval/${user}`)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
  //return fetch(API_URL).then((response) => response.json());
}

export async function getENEUsers() {
  const response = await axios
    .get(API_URL + "/ene")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
  // return fetch(API_URL + "/ene").then((response) => response.json());
}
// , {
//   headers: { "Content-Type": "application/json" },
// }
export const AddNewUser = async (User) => {
  console.log(JSON.stringify(User));
  let url = API_URL;
  const response = await axios
    .post(url, JSON.stringify(User))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
export const UpdateUser = async (User) => {
  console.log(User);
  let url = `${API_URL}/update/um_id/${User.um_id}`;
  console.log(url);
  const response = await axios
    .post(url, JSON.stringify(User))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function getAll() {
  const response = await axios
    .get(API_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
  //return fetch(API_URL).then((response) => response.json());
}
export async function getUser(Location, UserID) {
  let url = `${API_URL}/Location/${Location}/UserID/${UserID}`;
  console.log(url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function getUserEnqLocation(user) {
  let url = `${API_URL}/id/${user}`;
  console.log(url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function DeleteUser(um_id) {
  console.log(um_id);
  let url = `${API_URL}/um_id/${um_id}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export const EnrollNewUser = async (User) => {
  console.log(JSON.stringify(User));

  const resp = await axios
    .post(API_URL, JSON.stringify(User))
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

  console.log(resp);

  return resp;
};

export const getUserByCode = async (UserID) => {
  let url = `${API_URL}/userId/${UserID}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  return resp;
};
// Added By Binal Savla - START

export async function getUserRoleCode() {
  let url = `${API_URL}/RoleCode`;
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const enrollnewuserloc = async (User) => {
  console.log(JSON.stringify(User));
  let url = `${API_UL_URL}`;
  console.log(API_UL_URL);
  const response = await axios
    .post(url, JSON.stringify(User))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateUserLocation = async (user) => {
  console.log(JSON.stringify(user));
  let url = `${API_UL_URL}/update/UserId/${user.ul_userId}`;
  const response = await axios
    .post(url, JSON.stringify(user))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function deletefromuserlocation(UserId) {
  let url = `${API_UL_URL}/userid/${UserId}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export const enrollnewuserlocbkp = async (User) => {
  console.log(JSON.stringify(User));
  let url = `${API_ULbkp_URL}`;
  console.log(API_ULbkp_URL);
  const response = await axios
    .post(url, JSON.stringify(User))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
// Added By Binal Savla - START

export const getDataMaskStatus = async (UserRole) => {
  let url = `${API_URL_DATA_MASK}/um_role/${UserRole}`;
  try {
    const resp = await axios.get(url);
    return resp.data[0];
  } catch (error) {
    console.error(error);
  }
};
