import axios from "../../utils/axios";
import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { logDOM } from "@testing-library/react";
import Loader from "../../Components/utility/Loader";
export default function AllSolutionHoc(ComposedComponent) {
  class HOC extends Component {
    state = {
      loading: false,
      tableData: [],
      offset: 0,

      //BOC Vikas K on 12/09/2022
      // limit: 10,
      limit: 200000,
      //EOC Vikas K on 12/09/2022
    };
    getData = () => {
      console.log(
        "test data-getAllLatestSolution----------",
        this.props.user.um_role
      );
      this.setState({
        loading: true,
      });

      let apiurl = "";
      if (this.props.user.um_role === "Dealer") {
        apiurl = `/dashboard/latestsolution?type=getAllLatestSolutionofUser&limit=${this.state.limit}&offset=${this.state.offset}`;
      } else {
        apiurl = `/dashboard/latestsolution?type=getAllLatestSolution&limit${this.state.limit}&offset=${this.state.offset}`;
      }
      axios
        .get(apiurl)
        .then((res) => {
          let data = res?.data.data.Data;
          console.log("solutionData---------------", res);
          this.setState({
            tableData: [...data],
          });
        })
        .catch((err) => {
          this.setState({
            tableData: [],
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    };
    // getData = () => {
    //   console.log("test data-----------");
    //   this.setState({
    //     loading: true,
    //   });
    //   axios
    //   .get(
    //     `/dashboard/latestsolution?type=getAllLatestSolutionForApproval&limit=${
    //     this.state.limit
    //   }&offset=${this.state.offset}`
    //   )
    //     .then((res) => {
    //       let data = res?.data.data.Data;
    //       console.log("solutionData---------------", res);
    //       this.setState({
    //         tableData: [...data],
    //       });
    //     })
    //     .catch((err) => {
    //       this.setState({
    //         tableData: [],
    //       });
    //     })
    //     .finally(() => {
    //       this.setState({
    //         loading: false,
    //       });
    //     });
    // };
    // getData = () => {
    //   console.log("test data-----------");
    //   this.setState({
    //     loading: true,
    //   });
    //   axios
    //   .get(
    //     `/dashboard/latestsolution?type=getReportData&limit=${
    //     this.state.limit
    //   }&offset=${this.state.offset}`
    //   )
    //     .then((res) => {
    //       let data = res?.data.data.Data;
    //       console.log("ReportData---------------", res);
    //       this.setState({
    //         tableData: [...data],
    //       });
    //     })
    //     .catch((err) => {
    //       this.setState({
    //         tableData: [],
    //       });
    //     })
    //     .finally(() => {
    //       this.setState({
    //         loading: false,
    //       });
    //     });
    // };
    componentDidMount() {
      this.getData();
    }
    renderLoading = () => {
      return (
        <ComposedComponent
          {...this.props}
          getData={this.getData}
          offset={this.state.offset}
          tableData={this.state.tableData}
        />
      );
    };
    render() {
      return <>{this.state.loading ? <Loader /> : this.renderLoading()}</>;
    }
  }
  const mapStateToProps = createStructuredSelector({
    // account: makeSelectMyAccount(),
    // dimension: makeSelectDeviceDimension(),
    // device:makeSelectDevice(),
    // user:makeSelectUserDetails()
  });
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  return compose(withConnect, memo)(HOC);
}
