import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Nav,
  Row,
  Tab,
  Alert,
} from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { decimalFloat } from "../../utils/twoDecimal";
import ADC from "./ADC";
import AddAirTreatment from "./AddAirTreatment";
import axios from "../../utils/axios";
import { FolderMinus } from "react-bootstrap-icons";
import { isAlphanumericWithSpace } from "../../functions/validations";
import { getcustName } from "../../Services/customer-service";
import { getCompHouseName } from "../../Services/compressor-house-service";
import Loader from "../utility/Loader";

const AddCompressor = (props) => {
  let enquiryID = props.enquiryID;
  // let enquiryID = 4;
  let active = props.active;
  console.log("ADD COMPRESSOR ");
  const [Name, setName] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [ch_energyconsumptionperday, setCh_energyconsumptionperday] = useState("");
  //let ch_energyconsumptionperday="";
  const [compheader, setcompheader] = useState({
    ch_enquiry_id: enquiryID,
    ch_controlsettings_unit: "",
    ch_capacity_unit: "",
    ch_power_unit: "",
    ch_energy_consumption_per_day: "1",
    ch_avg_icfm: "1",
    ch_comp_code: "",
    ch_comp_house_id: "",
    ch_sequence_number: "",
    ch_comp_house_name: "",
    ch_controlsettings_unit_rate: "",
    ch_capacity_unit_rate: "",
    ch_power_unit_rate: "",
  });
  const [compLine, setCompLine] = useState([]);
  const [airLine, setAirLine] = useState([]);
  const [key, setKey] = useState("first");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState({
    ch_comp_house_name: {},
  });


  const fetchCompLine = (data) => {
    if (data?.ch_comp_house_id) {
    setLoader(true);

      axios
        .get(
          `/maintenance/compDetail/line/${data?.ch_enquiry_id}/${data?.ch_comp_house_id}`
        )
        .then((response) => {
          if (response.status === 200) {
            let temp = response.data.data;
            setCompLine([...temp]);
          }
        })
        .catch((e) => {
          if (!e.response) {
            fetchCompLine(props?.compHouse[props?.active])
          }
          else {

            props.setShowMessageWarning("Problem in fetching compressor lines details. Please try again!")
          }
          //setCompLine([]);
        })
        .finally(() => {
          // setLoading(false);
          setLoader(false);
        });
    }
  };
  const fetchAirLine = (data) => {
    if (data?.ch_comp_house_id) {
      setLoader(true);

      axios
        .get(
          `/airTreatment/line/${data?.ch_enquiry_id}/${data?.ch_comp_house_id}`
        )
        .then((response) => {
          if (response.status === 200) {
            let temp = response.data.data;
            setAirLine([...temp]);
          }
        })
        .catch((e) => {
          setAirLine([]);
        })
        .finally(() => {
    setLoader(false);

          // setLoading(false);
        });
    }
  };

  const handleChangeName = async (e) => {
    console.log("compheader", compheader);
    const { name, value } = e.target;
    if (name === "ch_comp_house_name") {
      setcompheader((prevState) => ({
        ...prevState,
        ch_comp_house_name: value,
      }));

      // ch_enquiry_id: enquiryID,
      // ch_controlsettings_unit: "",
      // ch_capacity_unit: "",
      // ch_power_unit: "",
      // ch_energy_consumption_per_day: "1",
      // ch_avg_icfm: "1",
      // ch_comp_code: "",
      // ch_comp_house_id: "",
      // ch_sequence_number: "",
      // ch_comp_house_name: value,
      // ch_controlsettings_unit_rate:"",
      // ch_capacity_unit_rate:"",
      // ch_power_unit_rate:""
      setName(value);
    }
  };

  const getName = async (e) => {
    console.log("inside validate");
    let exisitngdata = await getCompHouseName(enquiryID);
    console.log(exisitngdata.data);
    for (let i = 0; i < exisitngdata.data.length; i++) {
      console.log(exisitngdata.data[i]);
      console.log(Name);
      if (exisitngdata.data[i].node_name === Name) {
        setAlertMessage(
          "Compressor house or Application with same Name already exists!"
        );
        //alert("Comp house Name already exist!");
        setName("");
        e.target.value = "";
        break;
      } else {
        setName(e.target.value);
      }
    }
  };

  const setData = (data) => {
    setCompLine(data);
  };
  const setAirData = (data) => {
    setAirLine(data);
  };
  useEffect(() => {
    props.addNode(props?.compHouse);
    if (props?.compHouse.length) {
      setcompheader({ ...props?.compHouse[props?.active] });
      setCh_energyconsumptionperday(compheader.ch_energy_consumption_per_day);
      console.log("key", key);
      if (key === "first") {
        fetchCompLine(props?.compHouse[props?.active]);
      }
      else if (key === "second") {
        fetchAirLine(props?.compHouse[props?.active]);
      }
      else {
        fetchCompLine(props?.compHouse[props?.active]);
      }
      
    }
    console.log("comphead",compheader.ch_energy_consumption_per_day, "-" ,props?.compHouse[props?.active]);
    
  }, [props?.compHouse,key]);
  useEffect(() => {
    
     console.log("comphead",compheader.ch_energy_consumption_per_day);
    //  setcompheader((prevState) => ({
    //   // setCompHouse((prevState) => ({
    //   ...prevState,
    //   ch_energy_consumption_per_day: compheader.ch_energy_consumption_per_day,
    //   ch_avg_icfm: compheader.ch_avg_icfm,
    // }));
    
  }, [compheader]);
  useEffect(() => {
    console.log("props.tabValue",props.tabValue);
    if (props.tabValue) {
      setKey("second");
    } 
    //else {
    //   setKey("first");
    // }   
    console.log("comphead 1",compheader.ch_energy_consumption_per_day);
    
  }, []);
  // useEffect(() => {
  //   getName();
  // }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  console.log("blundrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", props.IsDisabled);
  return (
    <>
     
      <Modal
        {...props.show}
        size="lg"
        id="AddCompressor"
        dialogClassName="modal-90w"
        backdrop="static"
        className="modalIndex"
      // style={{ zIndex: 999 }}
      >
        <div className="compressorModalHeader">
          <Modal.Header
            closeButton
            style={{
              color: "Black",
              // fontWeight: "600",
              fontSize: "18px",
              border: "none",
            }}

          ></Modal.Header>
        </div>

        <Modal.Body>
          <div style={{ display: "grid" }}>
            <span className="trans-all-man-fields">
              All fields are mandatory
              
            </span>
            <Row>
              <Col md={4} sm={12} className="mt-3">
                <span> Compressor House Name </span>
                <Form.Control
                  disabled={props.IsDisabled === false ? true : false}
                  required
                  style={{
                    //   color: "Black",
                    //   fontWeight: "200",
                    width: "100%",
                    //   border: "none",
                    //   marginTop:"5px"
                  }}
                  type="text"
                  className="placeHolderInput"
                  name="ch_comp_house_name"
                  id="ch_comphouse_nameAC"
                  placeholder="Add Compressor House Name"
                  autoComplete="off"
                  value={compheader.ch_comp_house_name}
                  onInput={(e) => {
                    let error = isAlphanumericWithSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ch_comp_house_name: error,
                    }));
                  }}
                  onChange={(e) => {
                    handleChangeName(e);
                  }}
                  onBlur={(e) => {
                    getName(e);
                  }}
                ></Form.Control>
                {error.ch_comp_house_name.status && (
                  <span className="req-input">
                    {error.ch_comp_house_name.message}
                  </span>
                )}
              </Col>
              <Col md={4} sm={12} className="mt-3">
                <span> Energy Consumption Per Day (kWH/day)</span>
                <Form.Control
                  readOnly
                  style={{
                    width: "100%",
                    }}
                  type="text"
                  className="placeHolderInput"
                  name="ch_energyconsumptionperday"
                  id="ch_comphouse_energyconsumptionperday"
                  placeholder=" "
                  autoComplete="off"
                  value={compheader.ch_energy_consumption_per_day?decimalFloat(compheader.ch_energy_consumption_per_day):0}
                  onInput={(e) => {
                    
                  }}    
                                
                ></Form.Control>
              </Col>
              <Col md={4} sm={12} className="mt-3">
                <span> Avg iCFM </span>
                <Form.Control
                  readOnly
                  style={{
                    width: "100%",                    
                  }}
                  type="text"
                  className="placeHolderInput"
                  name="ch_avgicfm"
                  id="ch_comphouse_avgicfm"
                  placeholder=" "
                  autoComplete="off"
                  value={compheader.ch_avg_icfm?decimalFloat(compheader.ch_avg_icfm):0}
                  onInput={(e) => {
                    
                  }}                 
                ></Form.Control>
              </Col>
              {alertMessage && (
                <Alert className="alertBox" variant="warning">
                  {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                  {alertMessage}
                </Alert>
              )}
            </Row>
          </div>
          <br />
          {isLoader ? (
        <Loader />
      ) : null}
          {console.log("compkey", key)}
         
          <Tab.Container
            onSelect={(k) => {
              setKey(k);
            }}
            activeKey={key}
          >
            
            <Nav as={Row} className="g-0">
              <Nav.Link
                bsPrefix="pb-3"
                as={Col}
                xs={3}
                sm={4}
                md={2}
                lg={2}
                eventKey="first"
              >
                Add Compressors
              </Nav.Link>
              {compLine.length > 0 ? (
                <Nav.Link
                  bsPrefix="ms-5"
                  as={Col}
                  xs={5}
                  sm={6}
                  md={3}
                  lg={3}
                  eventKey="second"
                >
                  Air Treatment Equipment Data
                </Nav.Link>
              ) : (
                <Nav.Link
                  bsPrefix="ms-5"
                  as={Col}
                  xs={5}
                  sm={6}
                  md={3}
                  lg={3}
                  disabled
                  eventKey="second"
                >
                  Air Treatment Equipment Data
                </Nav.Link>
              )}
            </Nav>
            
            <Tab.Content>
         
              <Tab.Pane id="first" eventKey="first">
                <ADC
                  active={props.active}
                  setActive={props.setActive}
                  Pressure={props.Pressure}
                  Capacity={props.Capacity}
                  Power={props.Power}
                  DefaultPressure={compheader.ch_controlsettings_unit}
                  DefaultPower={compheader.ch_power_unit}
                  DefaultCapacity={compheader.ch_capacity_unit}
                  CompressorParameters={props.CompressorParameters}
                  fetchCompHouseHeader={props.fetchCompHouseHeader}
                  setShowMessage={props.setShowMessage}
                  setShowMessageWarning={props.setShowMessageWarning}
                  calculateEnergy={props.calculateEnergy}
                  //closeModal={props.closeModal}
                  closeModal={() => {
                    props.closeModal();
                    props.setTabValue(false);
                  }}

                  reloadModal={() =>
                    fetchCompLine(props?.compHouse[props?.active])
                  }
                  compLine={compLine}
                  addNode={props.addNode}
                  setCompLine={setData}
                  compheader={compheader}
                  ch_comp_house_name={compheader.ch_comp_house_name}
                  setcompheader={setcompheader}
                  compHouse={props.compHouse}
                  setShow1={props.setShow1}
                  setCompHouse={props.setCompHouse}
                  setListDot={props.setListDot}
                  id={props.id}
                  enquiryID={enquiryID}
                  airTreatment={props.airTreatment}
                  setAirTreatment={props.setAirTreatment}
                  Name={Name || compheader.ch_comp_house_name}
                  IsDisabled={props.IsDisabled}
                  setshowCompForm={props.setshowCompForm}
                />
              </Tab.Pane>
              <Tab.Pane id="second" eventKey="second">
                <AddAirTreatment
                  Capacity={props.Capacity}
                  fetchCompHouseHeader={props.fetchCompHouseHeader}
                  setShowMessage={props.setShowMessage}
                  closeModal={() => {
                    props.closeModal();
                    props.setTabValue(true);
                  }}
                  active={props.active}
                  setShow2={(value1, value2) => {
                    props.setShow2(value1, value2);
                    props.setTabValue(true);
                  }}
                  setShowMessageWarning={props.setShowMessageWarning}
                  reloadModal={() =>
                    fetchAirLine(props?.compHouse[props?.active])
                  }
                  compLine={compLine}
                  airLine={airLine}
                  setAirLine={setAirData}
                  compheader={compheader}
                  addNode={props.addNode}
                  // id={props.id}
                  enquiryID={props.enquiryID}
                  airTreatment={props.airTreatment}
                  setAirTreatment={props.setAirTreatment}
                  //ah_comp_id={ah_comp_id}
                  compHouse={props.compHouse}
                  Name={Name || compheader.ch_comp_house_name}
                  IsDisabled={props.IsDisabled}
                  setshowCompForm={props.setshowCompForm}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-start border-0">
          <Button bsPrefix="btn-primary action-btn">
            <BsPlusLg size={32} className="p-2 ms-2" />
            <span className="btn-text">Add Compressor</span>
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default AddCompressor;
