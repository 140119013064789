import axios from "../utils/axios";
//const API_URL = process.env.REACT_APP_LOC_API_URL;
const API_S_URL = process.env.REACT_APP_SECTOR_API_URL;

export async function getActiveSector(params) {
  let url = API_S_URL + "/active/" + params;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
