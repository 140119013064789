import axios from "../utils/axios-trans";
const APITRANS_URL = process.env.REACT_APP_TRANSACTIONSAPI_URL;


export const downloadEnquirydoc =async(enquirykey)=>{
  // return fetch(API_URL).then((response) => response.json());
  const response = await axios
  .post(APITRANS_URL + enquirykey + "/preview/true") //set preview=true for sending emails
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
}

export const downloadEnquirydocApproval =async(EnqId,approval_yn)=>{
  // return fetch(API_URL).then((response) => response.json());
  const response = await axios
  .post(APITRANS_URL + EnqId + "/preview/" + approval_yn) //set preview=true for sending emails
  .then(function (resp) {
      return resp;
    })
  .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
}
