export const LOGIN_GODREJ_EMPLOYEE = "Godrej Employee"; // for Login Screen
export const LOGIN_DEALER = "Dealer"; // for Login Screen
export const ENCRYPTION_KEY = "AEM_SOLUTION_ENQUIRY_WEB_PORTAL"; // for Encryption

export const APPROVAL_HIEARCHY_MASTER = "Approval Hiearchy Master"
export const COMPRESSOR_MASTER = "Compressor Master"
export const DEALER_MASTER = "Dealer Master"
export const PRODUCT_MASTER = "Product Master"
export const ROLE_AUTHORIZATION_MASTER = "Role Authorization Master"
export const UNIT_CONVERSION_MASTER = "Unit Conversion Master"
export const USER_MASTER = "User Master"
export const LOCATION_MASTER = "Location Master"
export const CUSTOMER_MASTER = "Customer Master"
export const COUNTRY_MASTER = "Currency Master"
export const MAIL_REMINDER = "Mail Reminder"
export const CREATE_NEW_SOLUTION = "Create New Solution"
export const DASHBOARD = "DashBoard"
export const REPORTS = "Reports"
export const ALL_SOLUTION_RECORDS = "All Solution Records"
export const PENDING_FOR_APPROVAL = "Solutions Pending For Approval"

export const START_YEAR = 2021

export const PASSWORD_VALIDATION = ["012", "123", "234", "345", "456", "567", "678", "789", "210", "321", "432", "543", "654", "765", "876", "987", "godrej", "jerdog"]
