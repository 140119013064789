import axios from "../utils/axios"
const API_URL = process.env.REACT_APP_NEW_SOLN_CONN_API_URL;
const API_URL1 = process.env.REACT_APP_NETWORK_ALGO_API_URL;
const API_NETWORK_GROUP_API = process.env.REACT_APP_NETWORK_GROUP_API_URL;

export const GetSolConnection = async(enquiryId)=>{
  console.log("GetSolConnection");
    let url = `${API_URL}/solution/${enquiryId}`;
    console.log("url",url);
    const resp = await axios
      .get(url)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
    return resp;
  }

  export const CallAlgorithm = async(algObj)=>{
    let url = `${API_URL1}`;
    const response = await axios
    .post(url, JSON.stringify(algObj))
    .then(function (resp) {
      console.log("AlgoResp", resp);
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
  }

  export const CallVFDAlgorithm = async(algObj)=>{
    let url = `${API_NETWORK_GROUP_API}`;
    const response = await axios
    .post(url, JSON.stringify(algObj))
    .then(function (resp) {
      console.log("AlgoResp", resp);
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
  }

  