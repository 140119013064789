export function getInitials(userName) {
  if (userName) {
    var names = userName.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
}

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null;
};

export function formatDateDD_MM_YYYY(input) {
  let input1 = new Date(input);
  const newDate = `${pad2(input1.getDate())}-${pad2(
    input1.getMonth() + 1
  )}-${pad2(input1.getFullYear())}`;
  console.log("dates", input, newDate);

  return newDate;
}

export function removeduplicate(input) {
  return input
    .split(",")
    .filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    })
    .join(",");
}

export function formatDate(input) {
  if (input !== "" && input !== null && input.includes("T")) {
    let input1 = new Date(input);
    const newDate = `${pad2(input1.getFullYear())}-${pad2(
      input1.getMonth() + 1
    )}-${pad2(input1.getDate())}`;

    return newDate;
  } else return input;
}

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

export function getDefaultDate() {
  const current = new Date();
  const defaultDate = `${pad2(current.getFullYear())}-${pad2(
    current.getMonth() + 1
  )}-${pad2(current.getDate())}`;
  return defaultDate;
}

// export const FilterChange = (param, setFilteredCol, headerName, setFilteredData, filteredData) => {
export const FilterChange = (param, setFilteredCol, headerName) => {
 console.log("FilterChange");
  let a = document.getElementById("filterDisplay");
  setFilteredCol([]);
  let col = param.api.getFilterModel();
  // let filteredData = [];
  // //  filteredData.splice(0, filteredData.length);
  // param.api.forEachNodeAfterFilter((node) => {
  //   filteredData.push(node.data);
  // });
  // setFilteredResult(filteredData);

  if (param.api.getDisplayedRowCount() === 0) {
    param.api.showNoRowsOverlay();
  } else {
    param.api.hideOverlay();
  }
  if (Object.keys(col).length === 0) {
    a.classList.add("d-none");
  } else {
    a.classList.remove("d-none");
    for (const key of Object.keys(col)) {
      let filter;
      if (col[key].filterType === "date") {
        if (col[key].condition1) {
          let fromDate1 = col[key].condition1.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition1.dateFrom)
            : col[key].condition1.dateFrom;
          let toDate1 = col[key].condition1.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition1.dateTo)
            : col[key].condition1.dateTo;
          let fromDate2 = col[key].condition2.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition2.dateFrom)
            : col[key].condition2.dateFrom;
          let toDate2 = col[key].condition2.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition2.dateTo)
            : col[key].condition2.dateTo;
          filter =
            headerName(key) +
            " : " +
            fromDate1 +
            (toDate1 ? " To " + toDate1 : "") +
            ", " +
            fromDate2 +
            (toDate2 ? " To " + toDate2 : "");
          console.log("true date");
        } else {
          filter =
            headerName(key) +
            " : " +
            col[key].dateFrom?.substring(0, 10) +
            (col[key].dateTo ? " To " + col[key].dateTo?.substring(0, 10) : "");
          console.log("false date", col[key].dateFrom?.substring(0, 10));
        }
      } else if (col[key].filterType === "number") {
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            (col[key].condition1.filterTo
              ? " To " + col[key].condition1.filterTo
              : "") +
            ", " +
            col[key].condition2.filter +
            (col[key].condition2.filterTo
              ? " To " + col[key].condition2.filterTo
              : "");
        } else {
          filter =
            headerName(key) +
            " : " +
            col[key].filter +
            (col[key].filterTo ? " To " + col[key].filterTo : "");
        }
      } else {
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName(key) + " : " + col[key].filter;
        }
      }
      setFilteredCol((prev) => {
        return [...prev, filter];
      });
    }
  }
};

export const ExportFilterData = (
  param1,
  setFilteredCol1,
  headerName1,
  setFilteredData,
  filteredData
) => {
  let a = document.getElementById("filterDisplay");
  setFilteredCol1([]);
  let col = param1.api.getFilterModel();
  console.log("col", col);

  filteredData.splice(0, filteredData.length);
  console.log("SFD ", filteredData);
  param1.api.forEachNodeAfterFilter((node) => {
    filteredData.push(node.data);
  });
  setFilteredData(filteredData);
  console.log("Row data ", filteredData);

  if (param1.api.getDisplayedRowCount() === 0) {
    param1.api.showNoRowsOverlay();
  } else {
    param1.api.hideOverlay();
  }
  if (Object.keys(col).length === 0) {
    a.classList.add("d-none");
  } else {
    a.classList.remove("d-none");
    for (const key of Object.keys(col)) {
      let filter;
      if (col[key].filterType === "date") {
        if (col[key].condition1) {
          let fromDate1 = col[key].condition1.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition1.dateFrom)
            : col[key].condition1.dateFrom;
          let toDate1 = col[key].condition1.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition1.dateTo)
            : col[key].condition1.dateTo;
          let fromDate2 = col[key].condition2.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition2.dateFrom)
            : col[key].condition2.dateFrom;
          let toDate2 = col[key].condition2.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition2.dateTo)
            : col[key].condition2.dateTo;
          filter =
            headerName1(key) +
            " : " +
            fromDate1 +
            (toDate1 ? " To " + toDate1 : "") +
            ", " +
            fromDate2 +
            (toDate2 ? " To " + toDate2 : "");
        } else {
          filter =
            headerName1(key) +
            " : " +
            col[key].dateFrom?.substring(0, 10) +
            (col[key].dateTo ? " To " + col[key].dateTo?.substring(0, 10) : "");
        }
      } else if (col[key].filterType === "number") {
        if (col[key].condition1) {
          filter =
            headerName1(key) +
            " : " +
            col[key].condition1.filter +
            (col[key].condition1.filterTo
              ? " To " + col[key].condition1.filterTo
              : "") +
            ", " +
            col[key].condition2.filter +
            (col[key].condition2.filterTo
              ? " To " + col[key].condition2.filterTo
              : "");
        } else {
          filter =
            headerName1(key) +
            " : " +
            col[key].filter +
            (col[key].filterTo ? " To " + col[key].filterTo : "");
        }
      } else {
        if (col[key].condition1) {
          filter =
            headerName1(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName1(key) + " : " + col[key].filter;
        }
      }
      console.log("SF ", filter);
      setFilteredCol1((prev) => {
        return [...prev, filter];
      });
    }
  }
};

export const resetAllFilter = (gridRef) => {
  gridRef.current.api.setFilterModel(null);
};

export function resetFilter(gridRef, item, feildName) {
  console.log("resetFilter");
  let item1 = item.substring(0, item.indexOf(":") - 1);
  let col = gridRef.current.api.getFilterModel();
  for (const key of Object.keys(col)) {
    if (key === feildName(item1)) {
      delete col[key];
    }
  }
  gridRef.current.api.setFilterModel(col);
}

export function removeComa(data) {
  var str = data.toString().split(".");
  if (str[0].indexOf(",") >= 0) {
    let value = str[0].replace(/\,/g, "");
    value = parseInt(value, 10);
    if (str[1] !== undefined) {
      return value + "." + str[1];
    } else {
      return value;
    }
  } else
    return data;
};
