import { React, useState } from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import CustomerDetails from "../NewSolution/CustomerDetails";

const UnitConfirmModal = (props) => {
  const [bool, setBool] = useState(false);
  const history = useHistory;
  let formName = props.formName;

  //sessionStorage.setItem("KeyforBool", bool);

  console.log(bool);
  if (bool === true) {
    sessionStorage.setItem("Keybool", bool);
  }

  const closeTab = () => {
    window.close();
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal
        className="confirmAlertModal"
        show={props.show}
        onHide={props.handleClose}
        animation={false}
        backdrop="static"
      >
        <br></br>
        <Modal.Body className="confirmcss">
          Changing Units might affect Calculations! Do you intend to change
          Units?
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button
            variant="primary"
            id="Yes"
            onClick={() => setBool(props.handleYes())}
          >
            Yes
          </Button>
          <Button
            variant="outline-primary"
            id="No"
            onClick={() => setBool(props.handleClose())}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 
      <CustomerDetails bool={bool} /> */}
    </>
  );
};

export default UnitConfirmModal;
