import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { setLogout } from "../../redux/redux/global/action";
const AutoLogOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { instance } = useMsal();

  const [signoutTime, setSignoutTime] = useState(900000);
  let logoutTimeout;

  const logout = () => {
    handleLogout();
  };

  const setTimeouts = () => {
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  const clearTimeouts = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  function handleLogout() {
    if (localStorage.getItem("Type") === "Non Employee") {
      localStorage.setItem("Type", "");
      dispatch(setLogout());
      history.push("/");
    } else {
      localStorage.setItem("Type", "");
      dispatch(setLogout());
      history.push("/");
      handleLogout(instance);
    }
  }
  return null;
};

export default AutoLogOut;
