import React, { useState } from "react";
import { Col, Dropdown, Form, Row, Button, Alert } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { getAllCompressorApplication } from "../../../Services/compressor-house-service";
import { useEffect } from "react";
import {
  AddNewNetwork,
  UpdateNetwork,
} from "../../../Services/new-solution-service";
import { checkNull } from "../../../functions/validations";
import CommonModal from "../../Modals/commonModal";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import WarningModal from "../../Modals/warningModal";
const NetworkForm = ({
  formData,
  setFormData,
  id,
  active,
  setActive,
  showAppForm,
  setshowAppForm,
  enquiryid,
  error,
  setError,
  addEdge,
  fetchNetwork,
  setReload,
  reload,
  IsDisabled,
}) => {
  const [compHouse, SetCompHouse] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("Connection");
  const [alertMessage, setAlertMessage] = useState("");
  const [showwarning, setshowwarning] = useState(false);
  const [loading, setLoading] = useState(false);
  //const [reload, setReload] = useState(false);

  useEffect(() => {
    enquiryid &&
      getAllCompressorApplication(enquiryid)
        .then((res) => {
          SetCompHouse(res);
          console.log("SetCompHouse", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
  }, [enquiryid, reload]);

  const formLenght = formData.length;

  useEffect(() => {
    if (formLenght > 0) {
      addEdge(formData);
    }
  }, [formData]);

  function validate() {
    let isError = false;
    console.log("formData", formData);
    console.log("formData", formData[id].nc_source_name);
    if (
      formData[id].nc_source_name === "" &&
      formData[id].nc_destination_name === ""
    ) {
      isError = true;
      setError({
        status: true,
        message: "Please select From and To",
      });
    }
    if (
      formData[id].nc_source_name === "" &&
      formData[id].nc_destination_name !== ""
    ) {
      isError = true;
      setError({
        status: true,
        message: "Please select From",
      });
    }
    if (
      formData[id].nc_destination_name === "" &&
      formData[id].nc_source_name !== ""
    ) {
      isError = true;
      setError({
        status: true,
        message: "Please select To",
      });
    }
    if (
      formData[id].nc_source_name !== "" &&
      formData[id].nc_destination_name !== "" &&
      formData[id].nc_source_id === formData[id].nc_destination_id
    ) {
      isError = true;
      setError({
        status: true,
        message: "From and To cannot be same",
      });
    }

    if (
      formData[id].nc_source_type === "application" &&
      formData[id].nc_destination_type === "application" &&
      (formData[id].nc_source_n2application === true ||
        formData[id].nc_destination_n2application === true)
    ) {
      isError = true;
      setError({
        status: true,
        message: "Cannot connect N2 application",
      });
    }

    if (
      formData[id].nc_source_type === "application" &&
      formData[id].nc_destination_type === "compressor"
    ) {
      isError = true;
      setError({
        status: true,
        message: "Please select From as Compressor House and To as Application",
      });
    }

    for (let counter = 0; counter < formData.length; counter++) {
      let element = formData[counter];
      if (counter === active) {
        continue;
      } else {
         //comphouse/app name changes by ritu	
         if (	
          (formData[active].nc_source_id=== element.nc_source_id||	
            formData[active].nc_source_id=== element.nc_destination_id) &&	
          (formData[active].nc_destination_id=== element.nc_source_id||	
            formData[active].nc_destination_id===	
              element.nc_destination_id)	
        ){
          isError = true;
          setError({
            status: true,
            message: "connection already exists",
          });
          break;
        }
      }
    }

    if (error.status === true) {
      isError = true;
    }

    return isError;
  }

  const CustomToggleFrom = React.forwardRef(
    ({ onClick, id, "data-index": index }, ref) => {
      return (
        <Form.Control
          ref={ref}
          type="text"
          readOnly
          id={id}
          value={formData[index].nc_source_name}
          bsPrefix="my-drop-btn drop-from"
          placeholder="From"
          name="nc_source"
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        />
      );
    }
  );

  const CustomToggleTo = React.forwardRef(
    ({ onClick, id, "data-index": index }, ref) => {
      return (
        <Form.Control
          ref={ref}
          type="text"
          readOnly
          id={id}
          value={formData[index].nc_destination_name}
          bsPrefix="my-drop-btn"
          placeholder="To"
          name="nc_destination"
          className="drop-to mt-3"
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        />
      );
    }
  );

  const handleChangeMenu = (
    e,
    index,
    type,
    nodeid,
    n2application,
    nodename
  ) => {
    const { name, value } = e.target;
    let tmp = [...formData];
    console.log("n2application", n2application);
    tmp[index][`${name}_name`] = nodename;
    tmp[index][`${name}_type`] = type;
    tmp[index][`${name}_id`] = nodeid;
    tmp[index][`${name}_n2application`] = n2application;
    tmp[index][`${name}`] = value;
    console.log("tmp", tmp);
    let isError = false;
    for (let counter = 0; counter < formData.length; counter++) {
      let element = formData[counter];
      if (counter === active) {
        continue;
      } else {
        if (	
          element.nc_source_id === formData[active].nc_source_id &&	
          element.nc_destination_id === formData[active].nc_destination_id	
        ) {
          isError = true;
          setError({
            status: true,
            message: "Connection already exists",
          });

          break;
        }
      }
    }

    if (!isError) {
      setFormData(() => tmp);
    }
  };

  const handleChangeSwitch = (e, index) => {
    const { name, checked } = e.target;
    let tmp = [...formData];
    console.log(name, checked);
    if (name === "nc_valve") {
      tmp[index].nc_valve = checked;
      !checked && (tmp[index].nc_isolation = checked);
    } else {
      tmp[index][name] = checked;
    }
    setFormData(() => tmp);
  };

  const deleteMethod = () => {
    // handleDelete(deleteData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      setLoading(true);
      let result;
      console.log("formData", formData[active]);
      if (formData[active].nc_conn_id > 0) {
        result = UpdateNetwork(formData[active]);
        result
          .then((resp) => {
            console.log(resp);
            if (
              resp.data.message.includes(
                "fails to match the required pattern"
              ) ||
              resp.data.message.includes("must be")
            ) {
              let s = resp.data.message;

              s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
              console.log("In Weird Pattern", s);
              setAlertMessage(s);
              setshowwarning(true);
              setLoading(false);
            } else{
            setShowMessage("Great! You have Updated Network Connection Successfully");
            // setShowSuccess(true);
            // setReload(!reload);
            setShow(true);
            // addEdge(formData);
            // fetchNetwork();
            setshowAppForm(false);
            setLoading(false);
          }
          })
          .catch((error) => {
            console.log("Unable to process request" + error);
            setLoading(false);
          });
      } else {
        result = AddNewNetwork(formData[active]);
        result
          .then((resp) => {
            console.log(resp);
            console.log(resp.data.message);
            if (
              resp.data.message.includes(
                "fails to match the required pattern"
              ) ||
              resp.data.message.includes("must be")
            ) {
              let s = resp.data.message;

              s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
              console.log("In Weird Pattern", s);
              setAlertMessage(s);
              setshowwarning(true);
              setLoading(false);
            } else {
              setShowMessage(
                "Great! You have Added Network Connection Successfully"
              );
              // setShowSuccess(true);
              // setReload(!reload);
              formData[active].nc_conn_id = resp.data.data;
              setShow(true);
              // addEdge(formData);
              setshowAppForm(false);
              setActive(active + 1);
              // fetchNetwork();
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log("Unable to process request" + error);
            setLoading(false);
          });
      }
    }
    // setLoading(false);
  };

  return (
    <>
      <CommonModal
        message={showMessage}
        show={show}
        handleClose={() => {
          setShow(false);
          addEdge(formData);
          fetchNetwork();
        }}
      />
      <WarningModal
        // clickFunction={modalOnClick}
        message={alertMessage}
        show={showwarning}
        handleClose={() => {
          setshowwarning(false);
          //setshowCompForm(true);
        }}
      />
      <ConfirmAlertModal
        action="Delete"
        handleYes={deleteMethod()}
        formName={deleteMessage}
        show={show1}
        handleClose={() => {
          setShow1(false);
          // setshowCompForm(true);
        }}
      />

      <div
        id={"network-form-" + id}
        className={
          showAppForm && active === id ? "d-block mt-3" : "d-none mt-3"
        }
      >
        {error.status && (
          <Row className="g-0">
            <Col xs={1} sm={1} md={1} lg={1} style={{ height: "60px" }}></Col>
            <Col xs={11} sm={11} md={11} lg={11} style={{ height: "60px" }}>
              <div className="loginErrorDiv">
                <div className="loginErrorDivBody">{error.message}</div>
              </div>
            </Col>
          </Row>
        )}
        <div>
          <span className="trans-all-man-fields">All fields are mandatory</span>
        </div>
        <Row className="g-0">
          <Col xs={1} sm={1} md={1} lg={1} style={{ height: "80px" }}></Col>
          <Col xs={5} sm={5} md={5} lg={5} style={{ height: "80px" }}>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggleFrom} data-index={id}>
                <BsChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Menu as={"ul"} className="my-drop-menu">
                <li>
                  {compHouse?.map((item, index) => {
                    return (
                      <Form.Check type="radio">
                        <Form.Check.Input
                          disabled={IsDisabled === false ? true : false}
                          key={index}
                          type="radio"
                          name="nc_source"
                          value={item.nodevalue}
                          data-nw-type={item.nodetype}
                          onChange={(e) => {
                            handleChangeMenu(
                              e,
                              id,
                              item.nodetype,
                              item.nodeid,
                              item.n2application,
                              item.nodename
                            );
                            let error = checkNull(e.target.value);
                            setError({
                              status: error.status,
                              message: error.message,
                            });
                          }}
                        />
                        <Form.Check.Label>{item.nodename}</Form.Check.Label>
                      </Form.Check>
                    );
                  })}
                </li>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggleTo} data-index={id}>
                <BsChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Menu as={"ul"} className="my-drop-menu">
                <li>
                  {compHouse?.map((item, index) => {
                    return (
                      <Form.Check type="radio">
                        <Form.Check.Input
                          disabled={IsDisabled === false ? true : false}
                          key={index}
                          type="radio"
                          name="nc_destination"
                          value={item.nodevalue}
                          data-nw-type={item.nodetype}
                          onChange={(e) => {
                            handleChangeMenu(
                              e,
                              id,
                              item.nodetype,
                              item.nodeid,
                              item.n2application,
                              item.nodename
                            );
                            let error = checkNull(e.target.value);
                            setError({
                              status: error.status,
                              message: error.message,
                            });
                          }}
                        />
                        <Form.Check.Label>{item.nodename}</Form.Check.Label>
                      </Form.Check>
                    );
                  })}
                </li>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
            style={{ height: "80px" }}
            className="d-flex flex-row"
          >
            <Form.Group className="d-flex flex-column px-3 w-50">
              <Form.Label
                style={{
                  color: "#FFF",
                  fontSize: "14px",
                  // fontWeight: "500",
                }}
              >
                Valve
              </Form.Label>
              <Form.Switch
                disabled={IsDisabled === false ? true : false}
                name="nc_valve"
                checked={formData[id].nc_valve}
                onChange={(e) => handleChangeSwitch(e, id)}
              />
              <Form.Switch.Label>
                {formData[id].nc_valve ? "Yes" : "No"}
              </Form.Switch.Label>
            </Form.Group>
            <Form.Group className="d-flex flex-column">
              <Form.Label
                style={{
                  color: "#FFF",
                  fontSize: "14px",
                  // fontWeight: "500",
                }}
              >
                Isolation
              </Form.Label>
              <Form.Switch
                disabled={
                  IsDisabled === false || !formData[id].nc_valve ? true : false
                }
                name="nc_isolation"
                checked={formData[id].nc_isolation}
                onChange={(e) => handleChangeSwitch(e, id)}
              />
              <Form.Switch.Label>
                {formData[id].nc_isolation ? "Isolated" : "Non Isolated"}
              </Form.Switch.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row className="place-center m-0 p-0">
          <Col xs={4} sm={4} md={4} lg={4} className="place-center">
            <Button
              hidden={IsDisabled === false ? true : false}
              disabled={loading}
              bsPrefix="save-btn"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NetworkForm;
