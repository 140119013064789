import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import seccessImage from "../../styles/images/success-tick-logo.svg";

const UpdatePwdModal = (props) => {
  const history = useHistory();
  const closeTab = () => {
    window.open("about:blank", "_self");
    window.close();
  };
  return (
    <>
      <Modal
        className="forgotPasswordModal"
        show={props.show}
        onHide={() => props.handleClose}
        backdrop="static"
      >
        <Modal.Body>
          <div className="">
            <img src={seccessImage} alt="seccessImage" />
          </div>
          <div className="modalHeading">Success</div>
          <div className="modalText mt-2">
            {props.message}
            {/* Great! You’ve Updated Password successfully for user id - {props.userName} */}
          </div>
        </Modal.Body>
        <Modal.Footer className="loginModal">
          <Button
            variant="primary"
            onClick={() => props.clickFunction()}
          >
            Login
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              closeTab();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePwdModal;
