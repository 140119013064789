import { React, useState } from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const ConfirmWindow = (props) => {
  const history = useHistory;
  let formName = props.formName;
  const closeTab = () => {
    window.close();
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal
        className="ConfirmWindow"
        show={props.show}
        onHide={props.handleClose}
        animation={false}
        backdrop="static"
      >
        <br></br>
        <Modal.Body className="confirmcss">
        Customer already exists!
          Do you want to fetch data ?
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button variant="primary" onClick={() => props.handleYes()}>
            Yes
          </Button>
          <Button variant="outline-primary" onClick={() => props.handleClose()}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmWindow;
