import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_NETWORK_API_URL;
const API_URL_NETWORKGROUPS = process.env.REACT_APP_NETWORK_GROUPS_API_URL;
export async function getLinkedNetworkDetails(enquirykey) {
  // return fetch(API_URL).then((response) => response.json());
  console.log("key", enquirykey);
  let url = `${API_URL_NETWORKGROUPS}/enquirykey/${enquirykey}`;
  // return fetch(url).then((response) => {
  //    response.json();
  // });
  const resp = await axios
    .get(url)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log("NetworkServ", resp);
  return resp;
}

export const insertNetwork = async (Network, fileuploadObj) => {
  console.log("Networks", Network, Network.length);
  console.log("fileuploadObj", fileuploadObj);

  let newNetwork = Network.map((Network) => {
    if (Network.nd_acceptablepressure === "") {
      Network = { ...Network, nd_acceptablepressure: null };
    }
    if (Network.nd_unitvalue === "") {
      Network = { ...Network, nd_unitvalue: null };
    }
    if (Network.nd_avg_gen_pressure === "") {
      Network = { ...Network, nd_avg_gen_pressure: null };
    }
    if (Network.nd_unit === "") {
      Network = { ...Network, nd_unit: null };
    }
    if (Network.nd_energy === "") {
      Network = { ...Network, nd_energy: null };
    }
    if (Network.nd_networkname === "") {
      Network = { ...Network, nd_networkname: null };
    }

    return Network;
  });

  let fu_additional1 = fileuploadObj.fu_additional1;
  let fu_additional2 = fileuploadObj.fu_additional2;
  let fu_additional3 = fileuploadObj.fu_additional3;
  let fu_attch1filename = fileuploadObj.fu_attch1filename;
  let fu_attch2filename = fileuploadObj.fu_attch2filename;
  let fu_attch3filename = fileuploadObj.fu_attch3filename;
  let fu_enquiryid = fileuploadObj.fu_enquiryid;
  let fu_hobofilename = fileuploadObj.fu_hobofilename;
  let fu_hoboproject = fileuploadObj.fu_hoboproject;
  let fu_schematiclayout = fileuploadObj.fu_schematiclayout;
  let fu_schematiclayoutfilename = fileuploadObj.fu_schematiclayoutfilename;
  let fu_receivercapacity =
    fileuploadObj.fu_receivercapacity === ""
      ? null
      : fileuploadObj.fu_receivercapacity;

  const Networks = {
    newNetwork,
    fu_additional1,
    fu_additional2,
    fu_additional3,
    fu_attch1filename,
    fu_attch2filename,
    fu_attch3filename,
    fu_enquiryid,
    fu_hobofilename,
    fu_hoboproject,
    fu_schematiclayout,
    fu_schematiclayoutfilename,
    fu_receivercapacity,
  };
  console.log("after netwrks", Networks);
  const response = await axios
    .post(API_URL, JSON.stringify(Networks))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
};
