import { Handle, Position } from "react-flow-renderer";

function CompressorNode({ data }) {
  return (
    <div className="compressor-node">
      <Handle type="target" position={Position.Left} />
      <label>{data.label}</label>
      <Handle type="source" position={Position.Right} />
    </div>
  );
}

export default CompressorNode;
