import { useState } from "react";
import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_App_API_URL;
const API_URL_IFC_UPC_DETAILS = process.env.REACT_APP_API_URL_IFC_UPC_DETAILS;
const API_URL_COMP_APP_NETWORK = process.env.REACT_APP_NEW_SOLN_API_URL;

const Enq_API = process.env.REACT_APP_Enq_API;

console.log(API_URL, Enq_API);

export async function getEnquiryById(ed_enquiryid) {
  // debugger;
  let url = `${Enq_API}/ed_enquiryid/${ed_enquiryid}`;
  console.log(url);
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      console.log("getEnquiryById--", resp);
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("getEnquiryById--", response);
  return response.data.data;
}
//getNetworkDetails
export async function getNetworkDetails(nd_enquiryid) {
  let url = `${API_URL_COMP_APP_NETWORK}/appDetail/getNetworkDetails/${nd_enquiryid}`;
  console.log("getNetworkDetails--", url);
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data.data;
}

export async function getIfcUpcDetails(nd_enquiryid,connected_app) {
  console.log("in getupcifc");
  let url = `${API_URL_IFC_UPC_DETAILS}/getIfcUpcDetails/${nd_enquiryid}/${connected_app}`;
  console.log("getIfcUpcDetails--", url);
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const updateIFC_Details = async (
  tableBdetails,
  type,
  enquiryid,
  ifcObj,
  item,
  sum_ch_avg_icfm,
  Patm,
  W14_7,
  amb_temp_c,
  amb_temp_f,
  NodeCode
) => {
  console.log(
    "ifcdetails--",
    enquiryid,
    ifcObj,
    item,
    type,
    sum_ch_avg_icfm,
    Patm,
    W14_7,
    amb_temp_c,
    amb_temp_f,
    NodeCode
  );
  // let compLine = new Array();
  let avgIcfm;
  let inletPressure;
  let setPressure;
  let ifc_actual_avg_icfm;
  let ifc_W1;
  let ifc_W2;
  let ifc_diffInWt;
  let ifc_nitrogenSaved;
  let id_saved_compresed_air_flow;
  let id_saved_compressed_air_flow_per;
  let id_eng_saved_kwh;
  let id_eng_saved_per_day;
  let ifc_energySavedPerYear;
  let id_cost_saving_per_year;
  let ifc_power;
  let id_upc_excessFlow;
  let id_product_model;
  let id_nominalFlow;
  let id_ifc_code;
  let id_correctionfactor;
  // let id_upc_excessflow;
  // let id_power;
  // let id_product_code;
  // let id_set_pressure;
  // let id_x_axis;
  // let id_y_axis;
  // let id_actual_cfm;
  // let id_air_wt;
  // let id_air_wt_w1;
  // let id_air_wt_w2;
  // let id_amb_temp;
  // let id_avg_icfm;
  // let id_created_by;
  // let id_created_on;
  // let id_eng_saved_per_year;
  // let id_enquiry_id;
  // //let id_ifc_id;
  // let id_inlet_pressure;
  // let id_is_ifc;
  // let id_is_n2;
  // let id_mass_volume;
  // let id_modified_by;
  // let id_modified_on;
  // let id_network;
  // let id_patm;
  // let id_price;
  // let id_roi;
  // let id_saved_nitrogen_flow;
  // let id_w;

  if (type === "UpdateWithROI" && tableBdetails.length > 0) {
    console.log("It ifc details");

    console.log("in updated mayurs update gfds");
    let url = `${API_URL_IFC_UPC_DETAILS}/IfcUpcRoiCalc/EnquiryId/${tableBdetails[0].id_enquiry_id}`;
    console.log("URL--- ", url);
    const response = await axios
      .post(url, tableBdetails)
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return { message: "Unable to find request", status: false };
      });
    return response;
  }
  // console.log("ifc_power = item.ifc_power", (ifc_power = item.ifc_power));
  if (type === "UpdateWithUPC") {
    avgIcfm = ifcObj.avgIcfm;
    inletPressure = item.id_inlet_pressure;
    setPressure = ifcObj.upc_set_pressure;
    ifc_actual_avg_icfm = item.id_avg_icfm;
    ifc_W1 = item.id_air_wt_w1;
    ifc_W2 = item.id_air_wt_w2;
    ifc_diffInWt = item.id_mass_volume;
    ifc_nitrogenSaved = 0;
    id_saved_compresed_air_flow = item.id_saved_compresed_air_flow;
    id_saved_compressed_air_flow_per = item.id_saved_compressed_air_flow_per;
    id_eng_saved_kwh = item.id_eng_saved_per_day;
    id_eng_saved_per_day = item.id_eng_saved_per_day;
    ifc_energySavedPerYear = item.id_eng_saved_per_year;
    id_cost_saving_per_year = item.id_cost_saving_per_year;
    ifc_power = item.ifc_power;
    id_upc_excessFlow = item.id_upc_excessFlow;
    id_product_model = item.id_product_model;
    id_nominalFlow = item.id_nominalflow;
    id_correctionfactor = item.id_correctionfactor;
    id_ifc_code = NodeCode;
  } else {
    avgIcfm = ifcObj.avgIcfm;
    inletPressure = item.id_inlet_pressure;
    setPressure = item.id_set_pressure;
    ifc_actual_avg_icfm = item.id_avg_icfm;
    ifc_W1 = item.id_air_wt_w1;
    ifc_W2 = item.id_air_wt_w2;
    ifc_diffInWt = item.id_mass_volume;
    ifc_nitrogenSaved = item.id_saved_nitrogen_flow?item.id_saved_nitrogen_flow:"";
    id_saved_compresed_air_flow = item.id_saved_compresed_air_flow;
    id_saved_compressed_air_flow_per = item.id_saved_compressed_air_flow_per;
    id_eng_saved_kwh = item.id_eng_saved_kwh;
    id_eng_saved_per_day = item.id_eng_saved_per_day;
    ifc_energySavedPerYear = item.id_eng_saved_per_year;
    id_cost_saving_per_year = item.id_cost_saving_per_year;
    ifc_power = sum_ch_avg_icfm;
    id_upc_excessFlow = 0;
    id_product_model = item.id_product_model;
    id_nominalFlow = item.id_nominalflow;
    id_correctionfactor = item.id_correctionfactor
    id_ifc_code = NodeCode;
  }

  if (type === undefined) {
    console.log(enquiryid);
  }
  const ifcdata = {
    // flag,
    avgIcfm,
    inletPressure,
    setPressure,
    ifc_actual_avg_icfm,
    ifc_W1,
    ifc_W2,
    ifc_diffInWt,
    ifc_nitrogenSaved,
    id_saved_compresed_air_flow,
    id_saved_compressed_air_flow_per,
    id_eng_saved_kwh,
    id_eng_saved_per_day,
    ifc_energySavedPerYear,
    id_cost_saving_per_year,
    ifc_power,
    id_upc_excessFlow,
    id_product_model,
    id_nominalFlow,
    id_correctionfactor,
    //sum_ch_avg_icfm,
    Patm,
    W14_7,
    amb_temp_c,
    amb_temp_f,

    id_ifc_code,
  };
  console.log(ifcdata);
  console.log("getIfcUpcDetailsidenquiryid", enquiryid, type);
  //let url = `${API_URL}/ucm_from_unit/${item.ucm_from_unit}/ucm_to_unit/${item.ucm_to_unit}`;
  let url = `${API_URL_IFC_UPC_DETAILS}/IfcUpc/${enquiryid}/${type}`;
  console.log("getIfcUpcDetails", url);
  const response = await axios
    .post(url, ifcdata)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
};

export async function deleteIfcUpc(enquiryid, type) {
  //console.log("deleteUser");
  let url = `${API_URL}/deleteIfcUpc/${enquiryid}/${type}`;
  console.log("url", url);
  const resp = await axios
    .delete(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return resp.data;
}
