import React from "react";
import { usePagination } from "../../hooks/usePagination";

import {
  AiOutlineForward,
  AiFillStepBackward,
  AiFillStepForward,
  AiOutlineBackward,
} from "react-icons/ai";
const PaginationComp = (props) => {
  const { onPageChange, totalCount, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });

  let lastPage = paginationRange.totalPageCount;
  let pageText = `${paginationRange.endInd} of ${totalCount}`;

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const onFirstPage = () => {
    onPageChange(1);
  };

  const onLastPage = () => {
    onPageChange(lastPage);
  };

  return (
    <div className="pagintion-bar">
      <AiFillStepBackward
        onClick={onFirstPage}
        className={currentPage === 1 ? "disabled" : ""}
      />
      <AiOutlineBackward
        size={20}
        onClick={onPrevious}
        className={currentPage === 1 ? "disabled" : ""}
      />
      <span>{pageText}</span>
      <AiOutlineForward
        size={20}
        onClick={onNext}
        className={currentPage === lastPage ? "disabled" : ""}
      />
      <AiFillStepForward
        onClick={onLastPage}
        className={currentPage === lastPage ? "disabled" : ""}
      />
    </div>
  );
};

export default PaginationComp;
