import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_WORKFLOW_API_URL;
const API_URL_COMPRESSOR = process.env.REACT_APP_COMP_HOUSE_API_URL;



export async function getWorkFlowRoleByLevel(wm_level, enquiryid) {
  let url = `${API_URL}/wm_level/${wm_level}/enquiryId/${enquiryid}`;
  console.log("workflow", url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function getWorkFlowRole() {
  let url = `${API_URL}`;
  console.log("workflow", url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function getenergySavedPerDaySummation(enquiryid) {
  let url = `${API_URL}/energySavedPerDay/enquiryId/${enquiryid}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log("energySavedPerDay res", response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  return resp;
}

export async function getenergyConsumptionSummation(enquiryid) {
  let url = `${API_URL_COMPRESSOR}/energyConsumption/enquiryId/${enquiryid}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log("energyConsumption res", response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  return resp;
}
