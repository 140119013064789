import React, { useEffect, useImperativeHandle, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PlusIcon from "../../styles/images/white-plus.svg";
import NetworkForm from "./Forms/NetworkForm";
import { GetNetwork } from "../../Services/new-solution-service";
import { forwardRef } from "react";
import CommonModal from "../Modals/commonModal";
import axios from "../../utils/axios";

const NetworkDets = forwardRef(
  ({
    active,
    setActive,
    enquiryid,
    addEdge,
    deleteKey,
    setModule,
    setDeleteNumber,
    IsDisabled,
  }) =>
  //  ref
  {
    if (!enquiryid) {
      enquiryid = localStorage.getItem("enqkey");
    }
    const [showAppForm, setshowAppForm] = useState(false);
    const [error, setError] = useState({});
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("Delete App");
    const [show2, setShow2] = useState(false);
    const [deleteData, setDeleteData] = useState("");
    const [action, setAction] = useState("");
    const [reload, setReload] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [listDot, setListDot] = useState(false);

    console.log("enquiryid", enquiryid);
    const [formData, setFormData] = useState([
      {
        nc_conn_id: "",
        nc_enquiry_id: enquiryid,
        nc_source_id: "",
        nc_destination_id: "",
        nc_source_name: "",
        nc_source_type: "",
        nc_destination_name: "",
        nc_destination_type: "",
        nc_valve: true,
        nc_isolation: false,
        nc_source_n2application: "",
        nc_destination_n2application: "",
        nc_source: "",
        nc_destination: "",
      },
    ]);

    useEffect(() => {
      fetchNetwork();
    }, []);

    useEffect(() => {
      if (!listDot) {
        return;
      }
      setActive(formData.length - 1);
    }, [listDot]);

    const fetchNetwork = () => {
      let tempData = "";
      GetNetwork(enquiryid)
        .then((response) => {
          console.log("fetchNetwork res", response);
          if (response.status === 200) {
            let temp = response.data;
            tempData = temp;
            console.log("length", temp.length);
            if (temp.length > 0) {
              setFormData([]);
              // setFormData([...temp]);
              temp?.forEach((item) => {
                setFormData((prev) => [
                  ...prev,
                  {
                    nc_conn_id: item.nc_conn_id,
                    nc_enquiry_id: item.nc_enquiry_id,
                    nc_source_id: item.nc_source_id,
                    nc_destination_id: item.nc_destination_id,
                    nc_source_name: item.nc_source_name,
                    nc_source_type: item.nc_source_type,
                    nc_destination_name: item.nc_destination_name,
                    nc_destination_type: item.nc_destination_type,
                    nc_valve: item.nc_valve,
                    nc_isolation: item.nc_isolation,
                    nc_source: item.nc_source,
                    nc_destination: item.nc_destination,
                  },
                ]);
              });
              setDeleteNumber(temp?.length || 0);
              setFormData((prev) => {
                return [
                  ...prev,
                  {
                    nc_conn_id: "",
                    nc_enquiry_id: enquiryid,
                    nc_source_id: "",
                    nc_destination_id: "",
                    nc_source_name: "",
                    nc_source_type: "",
                    nc_destination_name: "",
                    nc_destination_type: "",
                    nc_valve: true,
                    nc_isolation: false,
                    nc_source_n2application: "",
                    nc_destination_n2application: "",
                    nc_source: "",
                    nc_destination: "",
                  },
                ];
              });
              setListDot(true);
            } else {
              setFormData([
                {
                  nc_conn_id: "",
                  nc_enquiry_id: enquiryid,
                  nc_source_id: "",
                  nc_destination_id: "",
                  nc_source_name: "",
                  nc_source_type: "",
                  nc_destination_name: "",
                  nc_destination_type: "",
                  nc_valve: true,
                  nc_isolation: false,
                  nc_source_n2application: "",
                  nc_destination_n2application: "",
                  nc_source: "",
                  nc_destination: "",
                },
              ]);
            }
          }
        })
        .catch((e) => { })
        .finally(() => {
          return tempData;
        });
    };

    useEffect(() => {
      fetchNetwork();
    }, [reload, IsDisabled]);

    const DeleteNetwork = () => {
      let data = {
        enquiry_id: formData[active]?.nc_enquiry_id,
        comp_house_id: 0,
        application_id: 0,
        net_conn_id: formData[active]?.nc_conn_id,
      };
      console.log("inside delete");
      if (formData[active]?.nc_enquiry_id && formData[active]?.nc_conn_id) {
        axios
          .post(`/maintenance/compAppNetwork/delete`, {
          //.delete(`/aem/api/maintenance/compAppNetwork`, {
            data: {
              enquiry_id: formData[active]?.nc_enquiry_id,
              comp_house_id: 0,
              application_id: 0,
              net_conn_id: formData[active]?.nc_conn_id,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              //GetNetwork(formData[active]?.nc_enquiry_id)
              //fetchNetwork();
              setShowMessage("Great! You’ve Deleted Connection successfully");
              setShow1(false);
              setReload(!reload);
              setShow(true);
            }
          })
          .catch((e) => {
            console.log("after delete- error----------", e);
            console.error(e);
            //alert(e);
            if (e == "Error: Request failed with status code 400") {
              //alert("You cannot delete compressors with network connection!");
              setAlertMessage(
                "Please delete corresponding connections before deleting application"
              );
            }
          })
          .finally(() => { });
      } else if (formData[active]?.nc_conn_id === "") {
        let temp = [...formData];
        temp.splice(active, 1);
        setFormData([...temp]);
      }
      setModule("Network");
    };

    useEffect(() => {
      setModule("Network");
      let enquiry_id = formData[active]?.nc_enquiry_id;
      let net_conn_id = formData[active]?.nc_conn_id;
      console.log(enquiry_id, net_conn_id);
      if (net_conn_id !== "") {
        DeleteNetwork();
        console.log("delete-network----------");
      } else if (net_conn_id === "") {
        fetchNetwork();
        console.log("splice");
        setReload(!reload);
        // let temp = [...formData];
        // temp.splice(active, 1);
        // setFormData([...temp]);
      }

      console.log(deleteKey);
    }, [deleteKey]);

    const handleBtnClick = (id) => {
      if (active === id) {
        setshowAppForm(!showAppForm);
        setError(!showAppForm);
      } else {
        setActive(id);
        setshowAppForm(true);
      }
    };

    const handleAddClick = (id) => {
      if (!validateConnection()) {
        setFormData([
          ...formData,
          {
            nc_conn_id: "",
            nc_enquiry_id: enquiryid,
            nc_source_id: "",
            nc_destination_id: "",
            nc_source_name: "",
            nc_source_type: "",
            nc_destination_name: "",
            nc_destination_type: "",
            nc_valve: true,
            nc_isolation: false,
            nc_source_n2application: "",
            nc_destination_n2application: "",
            nc_source: "",
            nc_destination: "",
          },
        ]);
      }
    };

    const validateConnection = () => {
      let error = false;
      let temp = [...formData];
      temp.forEach((value, index) => {
        if (!value.nc_conn_id) {
          // temp[index].error = true;
          error = true;
        }
      });
      setFormData([...temp]);
      return error;
    };

    // const handleSubmit = (e) => {
    //   console.log(formData[active]);
    //   let result = AddNewNetwork(formData[active]);
    //   result
    //     .then((resp) => {
    //       console.log(resp);
    //       alert(resp.data.message);
    //     })
    //     .catch((error) => {
    //       console.log("Unable to process request" + error);
    //     });
    // };
    return (
      <>
        <CommonModal
          // clickFunction={modalOnClick}
          message={showMessage}
          show={show}
          handleClose={() => {
            setShow(false);
            // setshowCompForm(true);
          }}
        />
        <div
          id="NetworkDets"
          style={{
            overflowY: "scroll",
            height: "380px",
            backgroundColor: "#A8ABB9",
            borderRadius: "0 0 4px 4px",
            boxShadow: "1px 2px 6px #2E2E2E66",
            padding: "8px",
          }}
        >
          <ul
            id="app-add-btns"
            style={{ listStyleType: "none", margin: 0, padding: 0 }}
          >
            {formData.map((item, id) => (
              <div key={"list-" + id}>
                <li id={"list-item-" + id} className="mt-3">
                  <Row
                    className="p-0 m-0"
                    style={{
                      height: "32px",
                      position: "relative",
                    }}
                  >
                    <Col
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{
                        display: "grid",
                        placeItems: "center",
                        padding: 0,
                      }}
                    >
                      <div
                        className={
                          active === id
                            ? showAppForm
                              ? "d-none"
                              : "list-bullet active"
                            : "list-bullet"
                        }
                      ></div>
                    </Col>
                    <Col
                      xs={11}
                      sm={11}
                      md={11}
                      lg={11}
                      id={"app-add-btn-" + id}
                      onClick={() => handleBtnClick(id)}
                      className={`${showAppForm && active === id
                          ? "app-add-btn active"
                          : "app-add-btn"
                        } ${item?.error === true ? "errorBorder" : ""}`}
                    >
                      <span>
                        {item.nc_source_name == ""
                          ? "Network Connection"
                          : item.nc_source_name +
                          "-" +
                          item.nc_destination_name}
                      </span>
                      {id + 1 <= formData.length ? (
                        <div className="dot prev"></div>
                      ) : (active === id && !showAppForm) || active !== id ? (
                        ""
                      ) : (
                        <div className="dot active"></div>
                      )}
                    </Col>
                    {id + 1 < formData.length ? (
                      ""
                    ) : (active === id && !showAppForm) || active !== id ? (
                      <img
                        // onClick={() => togglePassword()}
                        hidden={IsDisabled === false ? true : false}
                        src={PlusIcon}
                        alt="plusIcon"
                        onClick={() => {
                          handleAddClick();
                          setshowAppForm(!showAppForm);
                        }}
                        style={{
                          position: "absolute",
                          width: "46px",
                          right: "0px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Row>
                </li>
                <NetworkForm
                  formData={formData}
                  setFormData={setFormData}
                  active={active}
                  setActive={setActive}
                  showAppForm={showAppForm}
                  setshowAppForm={setshowAppForm}
                  error={error}
                  setError={setError}
                  id={id}
                  enquiryid={enquiryid}
                  fetchNetwork={fetchNetwork}
                  addEdge={addEdge}
                  setReload={setReload}
                  reload={reload}
                  IsDisabled={IsDisabled}
                />
              </div>
            ))}
          </ul>
        </div>
      </>
    );
  }
);

export default NetworkDets;
