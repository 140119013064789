///==========================dealer B2C authConfig=================================================

export const b2cConfig = {
  
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENTID,
    tenantId: process.env.REACT_APP_B2C_TENANTID,
    authority: "https://goderjb2c.b2clogin.com/goderjb2c.onmicrosoft.com/B2C_1_SIGNIN", //"Enter_the_Cloud_Instance_Id_Here/Enter_the_Tenant_Info_Here", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // authority: "https://login.microsoftonline.com/ da59f9d5-03d3-4b92-b5e8-bbf5cba6ec38",
    policyName: "B2C_1_SIGNIN",
    redirectUri: process.env.REACT_APP_B2C_REDIRECTURI,
    knownAuthorities: ['goderjb2c.b2clogin.com'],
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  }
};
export const loginRequest = {
  scopes: ["openid"]
 };
 