const getMaskedNumber = (number) => {
  // console.log('process.env.REACT_APP_MASK_DATA',process.env.REACT_APP_MASK_DATA);
  // if(process.env.REACT_APP_MASK_DATA===true || process.env.REACT_APP_MASK_DATA==="true"){
    const endDigits = number.slice(-4);
    return endDigits.padStart(number.length, '*');
  // }
  // else {
  //   return number;
  // }
}
export default function escapeExcelData(data, maskData) {
  if (data == null) return data;
  console.log('Inside escapeExcelData====================>', data,maskData);
  if (maskData === "true") {
    data = getMaskedNumber(data);
    console.log('Masked Data : ', data);
  }
  if ((data.toString().startsWith("=") || data.toString().startsWith("+") || data.toString().startsWith("@") || data.toString().startsWith("-") || data.toString().startsWith("0x09") || data.toString().startsWith("0x0D"))) 
    return "'" + data;
  else
    return data;
}
export function alphaNumeric50(value, message) {
  let regex = /^([a-zA-Z0-9\s\-+/.]){1,50}$/;
  // if (!value) return `${message ? message : "Field"} is required`;
  if (!value) return "";
  if (!regex.test(value)) return "Enter only alphanumeric";
  return null;
}
export function alphaNumeric100(value, message) {
  let regex = /^[A-Za-z0-9]*$/;
  // if (!value) return `${message ? message : "Field"} is required`;
  if (!value) {
    return "";
  }
  else if (!regex.test(value)) {
    return "Enter only alphanumeric";
  }
  else
    return null;
}
export function isAlphanumericWithDash(value, message) {
  let regex = /^[A-Za-z0-9\-./]*$/;
  if (!value)
    return "";

  else if (!regex.test(value)) {
    return "Enter only alphanumeric";
  }
  else {
    return null;
  }
}

export function alphaNumericWithSpace(value, message) {
  let regex = /^([a-zA-Z0-9\s]){1,100}$/;
  // if (!value) return `${message ? message : "Field"} is required`;
  if (!value) return "";
  if (!regex.test(value)) return "Enter only alphanumeric";
  return null;
}

export function percent100(value, message) {
  let regex = /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/;
  if (!value) return message;
  if (!regex.test(value)) return message;
  return null;
}
export function number24(value, message) {
  let regex = /^((?:[0-9]|1[0-9]|2[0-3])(?:\.\d{1,2})?|24(?:\.00?)?)$/;
  if (!value) return message;
  if (!regex.test(value)) return message;
  return null;
}

export function validTwoDecimals(value, message) {
  if (!value) {
    // return `${message ? message : "Field"} is required`;
    return "";
  } else {
    let temp = isNaN(value);
    if (temp) {
      return "Enter only numbers";
    }
    if (value.indexOf(".") >= 0) {
      var n = value.split(".");
      if (n[1].length > 2) {
        return "Enter only 2 digits after decimal";
      } else return null;
    }
    return null;
  }
}
export function validFourDecimals(value, message) {
  let temp = isNaN(value);
  if (temp) {
    return "Enter only numbers";
  }
  if (!temp) {
    if (value.length > 4) {
      return "Enter only 4 digits";
    } else return null;
  }
  return null;
}

export function validTenDecimals(value, message) {
  let temp = isNaN(value);
  if (temp) {
    return "Enter only numbers";
  }
  if (!temp) {
    if (value.length > 10) {
      return "Enter only 10 digits";
    } else return null;
  }
  return null;
}
export function isSelectEmpty(value, message) {
  if (!value) return message;
  return null;
}

