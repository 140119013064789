import { Button, Modal, Alert } from "react-bootstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { BsX } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState, useEffect, useMemo } from "react";
//import { getAllActiveDealers } from "../../Services/dealer-service";
import { getDealersPopUp } from "../../Services/dealer-service";

import { getActiveRoles } from "../../Services/role-service";
import { getActiveLocations, getDistinctLocation } from "../../Services/location-service";
import { getENEUsers } from "../../Services/user-service";
import {
  getActiveCustomers,
  getAllCustomers,
} from "../../Services/customer-service";
import GolbalSearch from "./GolbalSearch";
import {
  getAllAirCompMake,
  getAllAirCompType,
  getAllNonlubCompressor,
} from "../../Services/compressor-parameter-service";
import CustomPopUpTable from "../layout/CustomPopUpTable";
import Loader from "./Loader";

const SingleValuePopUp = (props) => {
  const gridRef = useRef();
  const [dataMethod, setDataMethod] = useState(() => {});
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [parameters, setParams] = useState(null);
  const [filteredCol, setFilteredCol] = useState([]);
  const [globalText, setGlobalText] = useState("");
  const [filteredResult, setFilteredResult] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoader, setLoader] = useState(true);
  let result = [];
  const fetchData = async (param) => {
    console.log("paramcust", param,props);
    result = dataMethod && (await dataMethod(param));
    var first = props.paramvalue;
    result?.sort((x, y) =>
      x[props.paramkey] === first ? -1 : y[props.paramkey] === first ? 1 : 0
    );
    setRowData(result);
    setFilteredResult(result);
  };

  let arr = props.masterName.split(":");
  let masterName = arr[0];

  useEffect(() => {
    if (filteredResult?.length > 0) {
      setLoader(false);
    }
  }, [filteredResult]);

  useEffect(() => {
    switch (masterName) {
      case "Dealer Company":
        {
          setDataMethod(() => getDealersPopUp);
          setColumnDefs([
            {
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "dm_dealercode",
              headerName: "Dealer Company Code",
              tooltipField: "dm_dealercode",
            },
            {
              field: "dm_dealercompanyname",
              headerName: "Dealer Company Name",
              tooltipField: "dm_dealercompanyname",
            },
            {
              field: "dm_dealercategory",
              headerName: "Dealer Category",
              tooltipField: "dm_dealercategory",
            },
            // {
            //   field: "dm_location",
            //   headerName: "Location",
            // },
          ]);
        }
        break;
      case "User Role":
        {
          setDataMethod(() => getActiveRoles);
          setColumnDefs([
            {
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "ram_rolecode",
              headerName: "Role Code",
              tooltipField: "ram_rolecode",
            },
            {
              field: "ram_rolename",
              headerName: "Role Name",
              tooltipField: "ram_rolename",
            },
          ]);
        }
        break;
      case "Location"://for user linked locations
        {
          console.log("locationarr",arr);
          setParams(arr[1]);
          console.log("inside location switch");
          //setDataMethod(() => getActiveLocations);
         
          setDataMethod(() => getDistinctLocation);
         setColumnDefs([
            {
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "lm_loccode",
              headerName: "Location Code",
              tooltipField: "lm_loccode",
            },
            {
              field: "lm_loc",
              headerName: "Location Name",
              tooltipField: "lm_loc",
            },
            {
              field: "lm_loctype",
              headerName: "Location Type",
              tooltipField: "lm_loctype",
            },

            {
              field: "lm_country",
              headerName: "Country",
              tooltipField: "lm_country",
            },
          ]);
        }
        break;
      case "ENE User":
        {
          setDataMethod(() => getENEUsers);
          setColumnDefs([
            {
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "empid",
              headerName: "Employee Code",
              tooltipField: "empid",
            },
            {
              field: "emailid",
              headerName: "Email ID",
              tooltipField: "emailid",
            },
            {
              field: "empname",
              headerName: "Employee Name",
              tooltipField: "empname",
            },
          ]);
        }
        break;
      case "Customer":
        {
          setDataMethod(() => getActiveCustomers);
          setColumnDefs([
            {
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "cm_custcode",
              headerName: "Customer Code",
            },
            // {
            //   field: "cm_custcode",
            //   headerName: "Customer Code",
            // },
            {
              field: "cm_custname",
              headerName: "Name",
              tooltipField: "cm_custname",
            },
            {
              field: "cm_loctype",
              headerName: "Location Type",
              tooltipField: "cm_loctype",
            },

            {
              field: "cm_productsmanufactured",
              headerName: "Products",
            },
          ]);
        }
        break;
      case "Air Compressor Make":
        {
          setParams(arr);
          // console.log("inside CompressorHouse switch");
          // console.log(arr);
          setDataMethod(() => getAllAirCompMake);
          setColumnDefs([
            {
              width: 20,
              cellRenderer: radioCellRenderer1,
            },

            {
              field: "comp_parameter",
              headerName: "Parameter",
            },
            {
              field: "comp_parametervalue",
              headerName: "Parameter Value",
            },
            {
              field: "comp_default",
              headerName: "Default",
            },
          ]);
        }
        break;
      case "Lub/Non Lub":
        {
          setParams(arr);
          console.log("inside CompressorHouse switch");
          setDataMethod(() => getAllNonlubCompressor);
          setColumnDefs([
            {
              width: 20,
              cellRenderer: radioCellRenderer,
            },

            {
              field: "comp_parameter",
              headerName: "Parameter",
            },
            {
              field: "comp_parametervalue",
              headerName: "Parameter Value",
            },
            {
              field: "comp_default",
              headerName: "Default",
            },
          ]);
        }
        break;
      case "Air Compressor Type":
        {
          setParams(arr);
          console.log("inside CompressorHouse switch");
          setDataMethod(() => getAllAirCompType);
          setColumnDefs([
            {
              width: 20,
              cellRenderer: radioCellRenderer,
            },

            {
              field: "comp_parameter",
              headerName: "Parameter",
            },
            {
              field: "comp_parametervalue",
              headerName: "Parameter Value",
            },
            {
              field: "comp_default",
              headerName: "Default",
            },
          ]);
        }
        break;
    }
    fetchData(parameters);
    // console.log(dataMethod);
  }, [dataMethod]);

  
  const radioCellRenderer = (params) => {
   console.log("evals", eval("params.data." + props.paramkey));
    console.log("params",params, props.paramkey, props.paramvalue);
    let cellValue = (
      <div>
        <input
          name="selection"
          type="radio"
          id={`selection${params.node.rowIndex}`}
          defaultChecked={
            eval("params.data." + props.paramkey).toString() === props.paramvalue.toString()
              ? true
              : false
          }
        ></input>
      </div>
    );
    eval("params.data." + props.paramkey).toString() === props.paramvalue.toString()
      ? params.node.setSelected(true)
      : params.node.setSelected(false);

      console.log("cellValue",cellValue);
    return cellValue;
  };

  const radioCellRenderer1 = (params) => {
    let cellValue = (
      <div>
        <input
          name="selection"
          type="radio"
          id={`selection${params.node.rowIndex}`}
          defaultChecked={
            eval("params.data." + props.paramkey) === props.paramvalue ||
            eval("params.data." + "comp_default") === props.paramvalue1
              ? true
              : false
          }
        ></input>
      </div>
    );
    eval("params.data." + props.paramkey) === props.paramvalue
      ? params.node.setSelected(true)
      : params.node.setSelected(false);
    return cellValue;
  };

  const defaultColDef = {
    resizable: true,
    suppressMovable: true,
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["reset", "apply"],
      closeOnApply: true,
      defaultJoinOperator: "OR",
    },
  };
  const assignData = () => {
    let selectedRows = gridRef.current.api.getSelectedRows();
    let selectedRowsCount = gridRef.current.api.getSelectedRows().length;
    console.log("Selected Rows Count: ", selectedRowsCount, gridRef);
    if (selectedRowsCount > 0) {
      props.setSelected({ name: props.masterName, result: selectedRows[0] });
      props.onHide();
    } else
      setAlertMessage(
        "Please select a record before clicking on Assign button."
      );
  };

  const assignCustomData = () => {
    let selectedRows = selectedCard;
    if (selectedRows.length > 0) {
      console.log("SR ", selectedRows);
      props.setSelected({ name: props.masterName, result: selectedRows[0] });
      props.onHide();
    } else {
      setAlertMessage(
        "Please select a record before clicking on Assign button."
      );
    }
  };

  const onGridReady = () => {
    gridRef.current.api.sizeColumnsToFit();
  };
  const handleRadioSelection = () => {
    let nodeEl = gridRef.current.api.getSelectedNodes();
    let node = document.getElementById(`selection${nodeEl[0].rowIndex}`);
    node.checked = true;
  };

  const handleGlobalSearch = (text) => {
    // debugger;
    let searchText = text.toLowerCase();
    setGlobalText(searchText);
    let arr = props.masterName.split(":");
    let masterName = arr[0];
    if (searchText) {
      let result = rowData?.filter((item) => {
        switch (masterName) {
          case "User Role":
            return (
              item.ram_rolecode.toString().indexOf(searchText) >= 0 ||
              item.ram_rolename.toLowerCase().indexOf(searchText) >= 0
            );
          case "Dealer Company": {
            return (
              item.dm_dealercode.toString().toLowerCase().indexOf(searchText) >=
                0 ||
              item.dm_dealercompanyname.toLowerCase().indexOf(searchText) >=
                0 ||
              item.dm_dealercategory.toLowerCase().indexOf(searchText) >= 0
            );
          }
          case "Location":
            return (
              item.lm_loccode.toString().indexOf(searchText) >= 0 ||
              item.lm_loc.toLowerCase().indexOf(searchText) >= 0 ||
              item.lm_loctype.toLowerCase().indexOf(searchText) >= 0 ||
              item.lm_country.toLowerCase().indexOf(searchText) >= 0
            );
          case "ENE User":
            return (
              item.empid.toString().indexOf(searchText) >= 0 ||
              item.emailid.toLowerCase().indexOf(searchText) >= 0 ||
              item.empname.toLowerCase().indexOf(searchText) >= 0
            );
          case "Customer":
            return (
              item.cm_custid.toString().indexOf(searchText) >= 0 ||
              item.cm_custname.toLowerCase().indexOf(searchText) >= 0 ||
              item.cm_loctype.toLowerCase().indexOf(searchText) >= 0 ||
              item.cm_productsmanufactured.toLowerCase().indexOf(searchText) >=
                0
            );

          case "Air Compressor Make":
            return (
              item.comp_parameter.toString().indexOf(searchText) >= 0 ||
              item.comp_parametervalue.toLowerCase().indexOf(searchText) >= 0 ||
              item.comp_default.toString().toLowerCase().indexOf(searchText) >=
                0
            );
          case "Lub/Non Lub":
            return (
              item.comp_parameter.toString().indexOf(searchText) >= 0 ||
              item.comp_parametervalue.toLowerCase().indexOf(searchText) >= 0 ||
              item.comp_default.toString().toLowerCase().indexOf(searchText) >=
                0
            );
          case "Air Compressor Type":
            return (
              item.comp_parameter.toString().indexOf(searchText) >= 0 ||
              item.comp_parametervalue.toLowerCase().indexOf(searchText) >= 0 ||
              item.comp_default.toString().toLowerCase().indexOf(searchText) >=
                0
            );
        }
      });
      setFilteredResult(result);
    } else {
      setFilteredResult(rowData);
    }
  };

  const resetAllFilter = () => {
    gridRef.current.api.setFilterModel(null);
  };

  function resetFilter(item) {
    let item1 = item.substring(0, item.indexOf(":") - 1);

    let col = gridRef.current.api.getFilterModel();
    for (const key of Object.keys(col)) {
      if (key === feildName(item1)) {
        delete col[key];
      }
    }
    gridRef.current.api.setFilterModel(col);
  }

  const headerName = (field) => {
    var name;
    switch (field) {
      case "dm_dealercode":
        name = "Company Code";
        break;
      case "dm_dealercompanyname":
        name = "Company Name";
        break;
      case "dm_dealercategory":
        name = "Dealer Category";
        break;
      case "ram_rolecode":
        name = "Role Code";
        break;
      case "ram_rolename":
        name = "Role Name";
        break;
      case "lm_loccode":
        name = "Location Code";
        break;
      case "lm_loc":
        name = "Location Name";
        break;
      case "lm_loctype":
        name = "Location Type";
        break;
      case "lm_country":
        name = "Country";
        break;
      case "empid":
        name = "Employee Code";
        break;
      case "emailid":
        name = "Email ID";
        break;
      case "empname":
        name = "Employee Name";
        break;
      case "cm_custid":
        name = "Customer Id";
        break;
      case "cm_custname":
        name = "Name";
        break;
      case "cm_loctype":
        name = "Location Type";
        break;
      case "cm_productsmanufactured":
        name = "Products";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const feildName = (header) => {
    var name;
    switch (header) {
      case "Company Code":
        name = "dm_dealercode";
        break;
      case "Company Name":
        name = "dm_dealercompanyname";
        break;
      case "Dealer Category":
        name = "dm_dealercategory";
        break;
      case "Role Code":
        name = "ram_rolecode";
        break;
      case "Role Name":
        name = "ram_rolename";
        break;
      case "Location Code":
        name = "lm_loccode";
        break;
      case "Location Name":
        name = "lm_loc";
        break;
      case "Location Type":
        name = "lm_loctype";
        break;
      case "Country":
        name = "lm_country";
        break;
      case "Employee Code":
        name = "empid";
        break;
      case "Email ID":
        name = "emailid";
        break;
      case "Employee Name":
        name = "empname";
        break;
      case "Customer Id":
        name = "cm_custid";
        break;
      case "Name":
        name = "cm_custname";
        break;
      case "Location Type":
        name = "cm_loctype";
        break;
      case "Products":
        name = "cm_productsmanufactured";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };
  const onFilterChanged = (param) => {
    let a = document.getElementById("filterDisplayPop");
    console.log("a", a);
    setFilteredCol([]);
    let col = param.api.getFilterModel();

    if (Object.keys(col).length === 0) {
      a.classList.add("d-none");
    } else {
      a.classList.remove("d-none");
      for (const key of Object.keys(col)) {
        let filter;
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName(key) + " : " + col[key].filter;
        }
        setFilteredCol((prev) => {
          return [...prev, filter];
        });
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <Modal {...props} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {props.masterName.split(":")[0] === "Lub/Non Lub"
              ? "Lubricated / Non Lubricated"
              : props.masterName.split(":")[0]}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="global-search-container mt-3">
              <GolbalSearch filterFunc={handleGlobalSearch} />
            </div>
            <div
              className="d-none mt-3 d-flex"
              id="filterDisplayPop"
              style={{ gap: "10px" }}
            >
              {filteredCol &&
                filteredCol.map((item) => {
                  return (
                    <div className="filter-col pr-0  me-2">
                      <span className="ps-2 btn-text">{item}</span>
                      <div onClick={() => resetFilter(item)}>
                        <BsX size={32} />
                      </div>
                    </div>
                  );
                })}

              <Button
                variant="outline-primary"
                className="ms-2 lh-1 clear-all-btn"
                onClick={resetAllFilter}
              >
                Clear All
              </Button>
            </div>
            <div
              className="ag-theme-material d-none d-md-block d-lg-block"
              style={{ height: 350, width: "100%" }}
            >
              {isLoader ? (
                <Loader />
              ) : (
                <AgGridReact
                  ref={gridRef}
                  rowData={filteredResult}
                  columnDefs={columnDefs}
                  rowSelection={"single"}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  headerHeight={50}
                  rowHeight={50}
                  pagination={true}
                  paginationPageSize={5}
                  onSelectionChanged={handleRadioSelection}
                  onFilterChanged={onFilterChanged}
                  enableBrowserTooltips={true}
                  domLayout="autoHeight"
                ></AgGridReact>
              )}
            </div>
            <div className="d-md-none d-lg-none table-container1">
              <CustomPopUpTable
                masterName={masterName}
                data={filteredResult}
                setSelectedCard={setSelectedCard}
                paramvalue={props.paramvalue}
                paramkey={props.paramkey}
                type="single"
              />
            </div>
          </div>
        </Modal.Body>

        {alertMessage && (
          <Alert className="alertBox" variant="warning">
            {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
            {alertMessage}
          </Alert>
        )}

        <Modal.Footer className="justify-content-center justify-content-sm-start">
          <Button
            size="sm"
            className="float-start me-2 d-none d-md-block d-lg-block"
            onClick={assignData}
          >
            Assign
          </Button>
          <Button
            size="sm"
            className="float-start me-2 d-md-none d-lg-none"
            onClick={assignCustomData}
          >
            Assign
          </Button>
          <Button
            size="sm"
            className="float-start me-2"
            variant="outline-primary"
            onClick={() => props.onHide()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SingleValuePopUp;
