import Network from "../../styles/images/Network.svg";
import Accordion from "react-bootstrap/Accordion";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { Check2, X } from "react-bootstrap-icons";
import { getInitials } from "../../functions/functions";
import axios from "../../utils/axios";
// import axios from "../../utils/axios-trans";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment/moment";
import SuccessAlertModal from "../Modals/successAlertModal";
import { checkNull } from "../../functions/validations";
import WarningModal from "../Modals/warningModal";
import Track from "../../icons/Track.svg";
import { useSelector } from "react-redux";
import DownArrowBlue from "../../styles/images/DownArrowBlue.svg";
import UpArrow from "../../styles/images/UpArrow.svg";
import { event } from "jquery";
import { useHistory } from "react-router-dom";
import Loader from "../utility/Loader";
import {downloadEnquirydoc} from "../../Services/enquiry-transaction-service"

const ClosureRemarks = (props) => {
  console.log("propsClosureRemarks", props);
  console.log("approval true/false", props.approval);
  console.log("ed status", props.ed_status);

  let enquiryid = props.enquiryid;
  // let enquiryid = 3;
  let a = [];
  const [isLoader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [approvalDetails, setApprovalDetails] = useState("");
  const [msg, setmsg] = useState("");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MailSentStatus, setMailSentStatus] = useState(false);

  const [showMessage, setShowMessage] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState({
    ap_remark: {},
  });
  const [remark, setRemark] = useState({
    ap_enquiry_id: enquiryid,
    ap_req_approved_by: props.userId,
    ap_req_status: "Approved",
    ap_remark: "",
    ap_prop_status: "Won",
  });
  const [approve_level, setApprove_level] = useState(0);
  const [collapse, setCollapse] = useState({});

  const sidebarFlag = useSelector((state) => state.global.openSideBar);
  const APITRANS_URL = process.env.REACT_APP_TRANSACTIONSAPI_URL;
  const history = useHistory();
  console.log("pending", props.pendingWith);
  const getMailSentApprovalRejectionStatus = () => {
    axios
      .get(`/mail/mr_mailcode/Mail_Approve`)
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          setMailSentStatus(response.data.data[0].mr_status);
        }
      });
  }

  const fetchApprovalDetails = (enquiryid) => {
    axios
      .get(`/enquiry/approval/${enquiryid}`)
      .then((response) => {
        console.log("approvalRes", response);
        if (response.status === 200) {
          let temp = response.data.data;
          console.log("approval", temp);

          if (temp[0].ap_rev_id !== 0) {
            let index = 1;
            let b = [];
            temp?.filter((item) => {
              if (item.ap_rev_id === index) {
                b.push(item);
              } else {
                a.push(b);
                index = item.ap_rev_id;
                b = [];
                b.push(item);
              }
            });
            a.push(b);
            if (temp[0].is_approver) {
              a[a.length - 1].push({
                ap_req_status: "In-Progress",
                ap_name: props.userId,
                ap_role: props.userRole,
              });
            }
          } else {
            let b = [];
            a.push(b);
            if (props.userRole === props.pendingWith) {
              a[a.length - 1].push({
                ap_req_status: "In-Progress",
                ap_name: props.userId,
                ap_role: props.userRole,
              });
            }
          }
          setApprovalDetails(a);
          setCollapse({ activeItem: a.length - 1 });
          fetchApprovalLevel(enquiryid);
        }
      })
      .catch((e) => {
        console.log("approval details--", e);
      })
      .finally(() => { });
  };

  const validateDetails = () => {
    let flag = false;
    if (remark.ap_remark === "" && props.pendingWith !== "Initiator") {
      setError({
        ap_remark: {
          status: true,
          message: "Please Enter Remark",
        },
      });
      flag = true;
    }
    return flag;
  };

  const fetchApprovalLevel = (enquiryid) => {
    console.log("approval details");
    axios
      .get(`/enquiry/approvallevel/${enquiryid}`)
      .then((res) => {
        console.log("approval details", res);
        if (res.data.data.rowCount !== 0) {
          console.log("approval details", res.data.data.rows[0].wm_level);
          setApprove_level(res.data.data.rows[0].wm_level);
          console.log(approve_level);
        }
        // if (res.status === 200 && res.data.success === true) {
        //   setmsg(res.data.message);
        //   setShowSuccess(true);
        //   window.location = "/allsolutionapproval";
        // } else if (res.status === 200 && res.data.success === false) {
        //   setShowMessage(res.data.message);
        //   setShow(true);
        // }
      })
      .catch((e) => {
        console.log("approval details--", e);
      });
  };
  // if(props.approval==true){
  //   fetchApprovalLevel(enquiryid);
  // }

  const addApprovalDetails = (details) => {
    console.log("details", details);
    if (!validateDetails()) {
      axios
        .post("/enquiry/approval", JSON.stringify(details))
        .then((res) => {
          console.log("approval details", res);
          if (res.status === 200 && res.data.success === true) {
            setmsg(res.data.message);
            setShowSuccess(true);
            console.log("send mail on level 4", MailSentStatus);
            console.log(approve_level, details.ap_req_status);
            if (approve_level === 4 && details.ap_req_status === "Approved" && MailSentStatus) {
              // axios.post(APITRANS_URL + enquiryid + "/preview/true");
              let response = downloadEnquirydoc(enquiryid);
              console.log("trans data", response);
              // .then(function (response) {
              //   console.log("Response from trans", response);
              //   if (response.data === "File Generated successfully") {
              //     // window.location = "/allsolutionapproval";
              //   }
              // });
            }
            // else {
            //   window.location = "/allsolutionapproval";
            // }
          } else if (res.status === 200 && res.data.success === false) {
            setShowMessage(res.data.message);
            setShow(true);
          }
        })
        .catch((e) => {
          console.log("approval details--", e);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const functionCount = (e) => {
    setCount(e.target.value.length);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.target.checked = true;
    setRemark((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log("remark", remark);
  };

  const handleCollapsible = (collpasedItem) => {
    if (collapse.activeItem === collpasedItem) {
      setCollapse({ activeItem: -1 });
    } else {
      setCollapse({ activeItem: collpasedItem });
    }
  };

  useEffect(() => {
    fetchApprovalDetails(enquiryid);
    getMailSentApprovalRejectionStatus();
  }, [enquiryid]);

  return (
    <>
      <WarningModal
        message={showMessage}
        show={show}
        handleClose={() => {
          setShow(false);
        }}
      />
      <SuccessAlertModal
        msg={msg}
        show={ShowSuccess}
        handleClose={() => {
          setShowSuccess(false);
          history.push({
            pathname: "/allsolutionapproval",
          });
        }}
      />
      <>
        <Accordion defaultActiveKey="1" style={{ marginTop: "20px" }}>
          {approvalDetails &&
            approvalDetails.map((value, index) => {
              let lastValue = value[value.length - 1];
              let activeTL =
                lastValue.ap_req_status === "In-Progress" ||
                  (props.pendingWith === "Initiator" &&
                    props.ed_status == "Closed" &&
                    props.ed_insertedby == props.userId)
                  ? true
                  : false;
              return (
                <Accordion.Item
                  eventKey={index}
                  style={{
                    backgroundColor: "#DFE5F8",
                    borderRadius: "8px",
                    marginBottom: "2rem",
                  }}
                  key={index}
                // onClick={() => handleCollapsible(index)}
                >
                  <Accordion.Header
                    className={
                      collapse?.activeItem === index
                        ? `dispaly-none accordion-header`
                        : `accordion-header`
                    }
                    onClick={() => handleCollapsible(index)}
                  >
                    <Image
                      src={Track}
                      alt="Track"
                      className="profile-initials top-18 left43"
                    ></Image>
                    <label
                      className="remarkLabel size-20"
                      style={{ marginLeft: "3rem" }}
                    >
                      Track Report - Rev.{index}
                    </label>
                    <Image
                      src={DownArrowBlue}
                      alt="DownArrowBlue"
                      //onClick={decoratedOnClick}
                      className="blue-arrow downarrow"
                    ></Image>
                  </Accordion.Header>
                  <Accordion.Body
                    className={
                      collapse?.activeItem !== index
                        ? `dispaly-none`
                        : `show-block`
                    }
                  >
                    {/* <CustomToggle eventKey={index}>Click me!</CustomToggle> */}
                    <Row>
                      <Col md={12} style={{ padding: "0" }}>
                        <div className="container history-box">
                          <ul className="cbp_tmtimeline">
                            <li
                              className={
                                value.length === 1 && activeTL
                                  ? "cbp_dottedline"
                                  : "cbp_verticalline height6"
                              }
                            >
                              <div className="flex-row d-flex">
                                {/* <div class="profile-initials top-18">{""}</div> */}
                                <Image
                                  src={Track}
                                  className="profile-initials top-18"
                                />
                              </div>
                              <div class="cbp_label empty timeloneBody collapsible-header">
                                <Row className="collapse-header">
                                  <Col md={8} className="trackreportheading">
                                    <label className="remarkLabel">
                                      Track Report - Rev.{index}
                                    </label>
                                    {/* <CustomToggle eventKey={index}>
                                      Click me!
                                    </CustomToggle> */}
                                    <img
                                      src={UpArrow}
                                      alt="UpArrow"
                                      onClick={() => handleCollapsible(index)}
                                      className="blue-arrow uparrow"
                                    ></img>
                                  </Col>
                                </Row>
                              </div>
                            </li>
                            {value.map((item, index) => {
                              let a, b, c, d;
                              let greenTick = "green-tick";
                              let greenFixedTick = "green-tick tick-fix";
                              let greenFixedTickOpen =
                                "green-tick tick-fix tick-side-open";
                              let greenSideOpen = "green-tick tick-side-open";
                              let redFixedTick = "red-tick tick-fix";
                              let redFixedTickOpen =
                                "red-tick tick-fix tick-side-open";
                              let redSideOpen = "red-tick tick-side-open";
                              let redTick = "red-tick";
                              console.log(
                                "item.ap_req_status",
                                item.ap_req_status
                              );
                              console.log("item", item);

                              switch (item.ap_req_status) {
                                case "Initiated":
                                  a = "";
                                  b = "timHedTrd";
                                  c = false;
                                  d = "";
                                  break;
                                case "Approved":
                                  a = (
                                    <Check2
                                      className={
                                        sidebarFlag ? greenSideOpen : greenTick
                                      }
                                    />
                                  );
                                  b = "timHedTrdA";
                                  c = false;
                                  d = (
                                    <Check2
                                      className={
                                        sidebarFlag
                                          ? greenFixedTickOpen
                                          : greenFixedTick
                                      }
                                    />
                                  );
                                  break;
                                case "Rejected":
                                  a = (
                                    <X
                                      className={
                                        sidebarFlag ? redSideOpen : redTick
                                      }
                                    />
                                  );
                                  b = "timHedTrdR";
                                  c = false;
                                  d = (
                                    <X
                                      className={
                                        sidebarFlag
                                          ? redFixedTickOpen
                                          : redFixedTick
                                      }
                                    />
                                  );
                                  break;
                                case "In-Progress":
                                  a = "";
                                  b = sidebarFlag
                                    ? "timHedTrdI timHedTrdI-width"
                                    : "timHedTrdI";
                                  c = true;
                                  d = "";
                                  break;
                                case "Closed":
                                  a = (
                                    <Check2
                                      className={
                                        sidebarFlag ? greenSideOpen : greenTick
                                      }
                                    />
                                  );
                                  b = "timHedTrdA";
                                  c = "";
                                  d = (
                                    <Check2
                                      className={
                                        sidebarFlag
                                          ? greenFixedTickOpen
                                          : greenFixedTick
                                      }
                                    />
                                  );
                                  break;
                                default:
                                  a = "";
                                  b = "";
                                  c = "";
                                  d = "";
                              }
                              return (
                                <li
                                  className={
                                    index === value.length - 1
                                      ? ""
                                      : activeTL && index === value.length - 2
                                        ? "cbp_dottedline"
                                        : "cbp_verticalline"
                                  }
                                  style={
                                    index === 0 ? { marginTop: "-14px" } : {}
                                  }
                                >
                                  <div className="flex-row d-flex">
                                    <div
                                      className={
                                        !c && item.ap_remark != ""
                                          ? "profile-initials"
                                          : "profile-initials top20"
                                      }
                                    >
                                      {getInitials(item.ap_name)}
                                    </div>
                                    {!c && item.ap_remark != "" ? a : d}
                                  </div>
                                  <div class="cbp_label empty timeloneBody">
                                    <Col md={1} style={{ float: "right" }}>
                                      <div className={b}>
                                        {item.ap_req_status}
                                      </div>
                                    </Col>
                                    <Row className="">
                                      <Col md={8}>
                                        <div className="timHed">
                                          {item.ap_name}
                                        </div>
                                        <div className="timHedSma">
                                          {item.ap_rolename}
                                        </div>
                                        {!c && item.ap_remark != "" && (
                                          <div className="timHedRem mt-2">
                                            <span className="italic">
                                              Remarks - {item.ap_remark}
                                            </span>
                                          </div>
                                        )}
                                        {!c &&
                                          props.ed_proposalstatus != "" &&
                                          item.ap_req_status == "Closed" && (
                                            <div className="timHedRem mt-2">
                                              <span>
                                                Proposal -{" "}
                                                {props.ed_proposalstatus ==
                                                  "Won" ||
                                                  props.ed_proposalstatus ==
                                                  "Lost"
                                                  ? props.ed_proposalstatus
                                                  : "Pending"}
                                              </span>
                                            </div>
                                          )}
                                      </Col>
                                      <Col className="timHedSec mt-3" md={3}>
                                        {moment(item.ap_approved_on).format(
                                          "Do MMMM | h:mm a"
                                        )}
                                      </Col>

                                      {c && (
                                        <Form autoComplete="off">
                                          <Row>
                                            <div className="timHedRemHed mt-2">
                                              Remarks
                                            </div>
                                            <Form.Control
                                              type="text"
                                              id="ap_remark"
                                              name="ap_remark"
                                              className="remark_text mt-2"
                                              maxLength="1000"
                                              onChange={(e) => {
                                                handleChange(e);
                                                let error = checkNull(
                                                  e.target.value
                                                );
                                                setError((prevState) => ({
                                                  ...prevState,
                                                  ap_remark: error,
                                                }));
                                              }}
                                              onInput={(e) => {
                                                functionCount(e);
                                              }}
                                            />
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "row-reverse",
                                              }}
                                            >
                                              <div className="remark_length">
                                                {count}/1000 char
                                              </div>
                                              {error.ap_remark.status && (
                                                <div className="text-danger">
                                                  {error.ap_remark.message}
                                                </div>
                                              )}
                                            </div>

                                            <div className="timHedRemHed">
                                              Action
                                            </div>
                                            <Row className="mt-2 d-flex justify-content-start">
                                              <Col
                                                md={3}
                                                className="status-check-radios"
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  label="Reject"
                                                  id="reject"
                                                  name="ap_req_status"
                                                  className="tim-status d-flex align-items-center col-gap-8"
                                                  value="Rejected"
                                                  checked={
                                                    remark.ap_req_status ===
                                                      "Rejected"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                  }}
                                                />
                                                <Form.Check
                                                  type="radio"
                                                  label="Approve"
                                                  id="approve"
                                                  name="ap_req_status"
                                                  className="tim-status d-flex align-items-center col-gap-8"
                                                  value="Approved"
                                                  checked={
                                                    remark.ap_req_status ===
                                                      "Approved"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </Row>
                                        </Form>
                                      )}
                                      {props.pendingWith === "Initiator" &&
                                        props.ed_status === "Closed" &&
                                        ((item.ap_req_status == "Initiated" &&
                                          props.approval == false) ||
                                          (item.ap_req_status == "Closed" &&
                                            props.approval == true)) &&
                                        props.ed_insertedby == props.userId && (
                                          <>
                                            <div className="timHedRemHed mt-2">
                                              Proposal Status
                                            </div>
                                            <Row className="mt-2 d-flex justify-content-start">
                                              <Col
                                                md={3}
                                                className="status-check-radios"
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  label="Won"
                                                  id="won"
                                                  name="ap_prop_status"
                                                  className="tim-status d-flex align-items-center col-gap-8"
                                                  value="Won"
                                                  checked={
                                                    remark.ap_prop_status ===
                                                      "Won"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                  }}
                                                />
                                                <Form.Check
                                                  type="radio"
                                                  label="Lost"
                                                  id="lost"
                                                  name="ap_prop_status"
                                                  className="tim-status d-flex align-items-center col-gap-8"
                                                  value="Lost"
                                                  checked={
                                                    remark.ap_prop_status ===
                                                      "Lost"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </>
                                        )}
                                    </Row>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
        {isLoader ? (
          <Loader />
        ) : null}
        <div style={{ position: "relative" }} className="mt-3">
          <button
            hidden={
              props.userRole === props.pendingWith ||
                (props.pendingWith === "Initiator" &&
                  props.ed_insertedby == props.userId &&
                  props.ed_status == "Closed")
                ? false
                : true
            }
            style={{ position: "inherit", left: "50%" }}
            className="mt-2"
            type="button"
            class="btn btn-outline-primary"
            variant="outline-primary"
            onClick={() => addApprovalDetails(remark)}
          >
            Submit Solution
          </button>
        </div>
      </>
    </>
  );
};

export default ClosureRemarks;
