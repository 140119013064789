import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_LOC_API_URL;
const API_UL_URL = process.env.REACT_APP_USERLOCATION_API_URL;
console.log(API_URL);
export async function getAllLocationMaster() {
  let url = API_URL;

  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export async function getActiveLocations(params) {
  let url = API_URL + "/active/" + params;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export async function getLocationComboForUser(params) {
  console.log(params);
  let url = API_UL_URL + "/UserId/" + params;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export async function getDistinctCountry(params) {
  let url = API_URL + "/country/" + params;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getDistinctLocation(value) {
  let url = API_URL + "/LocationType/" + value;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getcountrybylocation(value) {
  let url = API_URL + "/countr/" + value;
  const response = await axios
    .get(url)
    .then(function (resp) {
      console.log(resp);
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const AddNewLocation = async (Location) => {
  let url = API_URL;
  const response = await axios
    .post(url, JSON.stringify(Location))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateLocation = async (Location) => {
  let url = `${API_URL}/update/LocationCode/${Location.lm_loccode}`;
  const response = await axios
    .post(url, JSON.stringify(Location))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const DeleteLocation = async (Location) => {
  let url = `${API_URL}/LocationCode/${Location.lm_loccode}`;
  const response = await axios
    .post(url, JSON.stringify(Location))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const checkLocationForDelete = async (LocationCode) => {
  let url = `${API_URL}/CheckLocation/${LocationCode}`;
  console.log(url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      console.log(resp.data);
      return resp.data;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response;
};
