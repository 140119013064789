import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import seccessImage from "../../styles/images/success-tick-logo.svg";

const SuccessAlertModal = (props) => {
  const history = useHistory();

  return (
    <>
      <Modal
        className="forgotPasswordModal"
        show={props.show}
        onHide={() => props.handleClose}
        backdrop="static"
      >
        <div class="me-2"></div>
        <div class="d-flex flex-column align-items-end me-2">
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            data-dismiss="modal"
            onClick={() => props.handleClose()}
          ></button>
        </div>
        <Modal.Body>
          <div className="">
            <img src={seccessImage} alt="seccessImage" />
          </div>
          <div className="modalHeading">Success</div>
          <div className="modalText mt-2">
            {/* Great! You’ve Updated user successfully. */}
            {props.msg}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessAlertModal;
