import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_ROLE_API_URL;

console.log(API_URL);
export async function getAll() {
  console.log("reached here" + API_URL);
  //return fetch(API_URL).then((response) => response.json());
  const response = await axios
    .get(API_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getActiveRoles() {
  //console.log('reached here'+API_URL)
  //return fetch(API_URL + "/active").then((response) => response.json());
  const response = await axios
    .get(API_URL + "/active")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getUserRoleCode(rolecode) {
  let url = `${API_URL}/RoleCode/${rolecode}`;
  console.log(url);
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getRoleDownload(rolecode) {
  let url = `${API_URL}/RoleDwnld/${rolecode}`;
  console.log(url);
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const AddNewRole = async (Employee) => {
  //console.log(JSON.stringify(Employee));
  let url = API_URL;
  const response = await axios
    .post(url, JSON.stringify(Employee))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response?.data || "";
};

export const UpdateRole = async (item) => {
  console.log(item);
  let url = `${API_URL}/update/RoleCode/${item.ram_rolecode}`;
  const response = await axios
    .post(url, JSON.stringify(item))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function deleteRole(RoleCode) {
  //console.log("deleteUser");
  //console.log(UserID, UserName);
  let url = `${API_URL}/RoleCode/${RoleCode}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return resp.data;
}

// export const UpdateRoleAsInActive = async (item) => {
//   console.log(item);
//   let url = `${API_URL}/userRole/${item}`;
//   const response = await axios
//     .put(url, {
//   headers: {
//     "Content-Type": "application/json",
//   },
// })
//     .then(function (resp) {
//       return resp;
//     })
//     .catch(function (error) {
//       return { message: "Unable to find request", status: false };
//     });
//   return response.data;
// };
