/**
 *
 * Error
 *
 */

 import React from 'react';
 
 export default function Error({ errorTitle , ...props }) {
   return (
     <>
       <div id="fourOFour">
         <div className="fof">
           <h1>Error - {errorTitle || '404 Page Not Found'}</h1>
         </div>
       </div>
     </>
   );
 }
 