import { Col, Form, Row, Button, Alert } from "react-bootstrap";
import { useState, useEffect } from "react";

import { AddNewappDetail } from "../../../Services/enquiry-customer-service";
import {
  checkNull,
  isAlphanumericWithSpace,
  isNumber,
  validDecimals,
  isNumberWithDecimal,
  number24,
} from "../../../functions/validations.js";
import { getFromUnit } from "../../../Services/unit-conversion-service";
import CommonModal from "../../Modals/commonModal";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { getCompHouseName } from "../../../Services/compressor-house-service";
import { useSelector } from "react-redux";

const ApplicationForm = ({
  appDetail,
  setAppDetail,
  id,
  setModule,
  active,
  setActive,
  showAppForm,
  setshowAppForm,
  addApplicationNode,
  getAplDets,
  enquiryID,
  allRecs,
  edges,
  props,
  IsDisabled,
}) => {
  const [Name, setName] = useState("");
  const handleChangeval = (e, index) => {
    const { name, value } = e.target;
    //console.log(name, value);
    let tmp = [...appDetail];
    tmp[index][name] = value;
    setAppDetail(() => tmp);
    //console.log(appDetail);
    setName(value);
  };

  //handleChangeN2val
  const handleChangeN2val = (e, index) => {
    const { name, value } = e.target;
    console.log(name, value);

    let tmp = [...appDetail];
    tmp[index][name] = value;
    setAppDetail(() => tmp);
  };

  const [Pressure, setPressure] = useState([]);
  const [Capacity, setCapacity] = useState([]);
  const fetchCompMaster = async () => {
    const resFromUnitPressure = await getFromUnit("Pressure");
    setPressure(resFromUnitPressure);
    const resFromUnitCapacity = await getFromUnit("Capacity");
    setCapacity(resFromUnitCapacity);
  };
  const [error, setError] = useState({
    ad_applicationcode: {},
    ad_applicationname: {},
    ad_maxflowrequired: {},
    ad_maxflowrunit: {},
    ad_avgflowrequired: {},
    ad_avgflowunit: {},
    ad_pressurerequired: {},
    ad_pressureunit: {},
    ad_workinghours: {},
    ad_n2application: {},
  });
  const [action, setAction] = useState("Add");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const sidebarFlag = useSelector((state) => state.global.openSideBar);

  const validate = () => {
    let isError = false;
    let result = false;
    if (appDetail[id].ad_applicationcode === "") {
      isError = true;
      error.ad_applicationcode = {
        status: true,
        message: "Application code is required",
      };
    }
    if (appDetail[id].ad_applicationname === "") {
      isError = true;
      error.ad_applicationname = {
        status: true,
        message: "Application Name is required",
      };
    }
    if (appDetail[id].ad_maxflowrequired === "") {
      isError = true;
      error.ad_maxflowrequired = {
        status: true,
        message: "Maximum flow required is required",
      };
    }
    if (appDetail[id].ad_maxflowrunit === "") {
      isError = true;
      error.ad_maxflowrunit = {
        status: true,
        message: "Maximum flow unit is required",
      };
    }
    if (appDetail[id].ad_avgflowrequired === "") {
      isError = true;
      error.ad_avgflowrequired = {
        status: true,
        message: "Average flow required is required",
      };
    }
    if (appDetail[id].ad_avgflowunit === "") {
      isError = true;
      error.ad_avgflowunit = {
        status: true,
        message: "Average flow unit is required",
      };
    }
    if (appDetail[id].ad_pressurerequired === "") {
      isError = true;
      error.ad_pressurerequired = {
        status: true,
        message: "Pressure required is required",
      };
    }
    if (appDetail[id].ad_pressureunit === "") {
      isError = true;
      error.ad_pressureunit = {
        status: true,
        message: "Pressure unit required",
      };
    }
    if (appDetail[id].ad_workinghours === "") {
      isError = true;
      error.ad_workinghours = {
        status: true,
        message: "Working hours is required",
      };
    }
    if (appDetail[id].ad_n2application === "") {
      isError = true;
      error.ad_n2application = {
        status: true,
        message: "Select Yes/No.",
      };
    }

    // if (appDetail) {
    //   console.log(allRecs);
    //   result = allRecs.find(
    //     (item) =>
    //       item.ad_enquiry_id === appDetail.ad_enquiry_id &&
    //       item.ad_applicationname.toLowerCase() === appDetail.lm_loctype.toLowerCase()
    //   );
    //   console.log(result);
    //   // if (result) {
    //   //   setAlertMessage("Application with same Name Already Exists!");
    //   //   isError = true;
    //   // } else {
    //   //   isError = false;
    //   // }
    // }

    // if (result) {
    //   setAlertMessage("Application with same name Already Exists!");
    //   isError = true;
    // }

    if (
      error.ad_applicationcode.status ||
      error.ad_maxflowrunit.status ||
      error.ad_avgflowrequired.status ||
      error.ad_avgflowunit.status ||
      error.ad_pressurerequired.status ||
      error.ad_pressureunit.status ||
      error.ad_workinghours.status ||
      error.ad_n2application.status ||
      error.ad_maxflowrequired.status ||
      error.ad_applicationname.status
    )
      isError = true;

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));

    return isError;
  };

  const validateInput = async (appdata) => {
    let result = false;
    let exisitngdata = await getCompHouseName(enquiryID);
    console.log(exisitngdata.data);
    //if (action == "Add") {
    let namecount = 0;
    for (let i = 0; i < exisitngdata.data.length; i++) {
      console.log(exisitngdata.data[i]);
      if (
        exisitngdata.data[i].ch_comp_house_name === appdata.ad_applicationname
      ) {
        //alert("Comp house Name already exist!");
        namecount = 1;
      }
    }
    console.log(namecount);
    if (namecount != 0) {
      setAlertMessage("App with same Name Already Exists!");
      return false;
    } else {
      return true;
    }
    // console.log(allRecs);
    // console.log(appdata);
    // result = allRecs.find(
    //   (item) =>
    //     item.ad_appid != appdata.ad_appid &&
    //     item.ad_applicationname === appdata.ad_applicationname
    // );
    // console.log(result);
    // if (result) {
    //   setAlertMessage("App with same Name Already Exists!");
    //   return false;
    // } else {
    //   return true;
    // }
    //}
  };

  const getName = async (e) => {
    console.log("inside validate");
    let exisitngdata = await getCompHouseName(enquiryID);
    console.log(exisitngdata.data);
    console.log(e.target.value);
    console.log(appDetail[id].ad_applicationcode);
    //if (action == "Add") {
    for (let i = 0; i < exisitngdata.data.length; i++) {
      console.log(exisitngdata.data[i].ch_comp_house_name);
      if (
        exisitngdata.data[i].node_name === Name &&
        appDetail[id].ad_applicationcode != exisitngdata.data[i].node_code
      ) {
        //alert("Comp house Name already exist!");
        setAlertMessage(
          "Application or Compressor house with same Name Already Exists!"
        );
        error.ad_applicationname = {
          status: true,
          message: "",
        };
        setName("");
        e.target.value = "";
        //if (action == "Add") {
        //e.target.value = appDetail[id].ad_applicationname;
        //}
        break;
      } else {
        setName(e.target.value);
        error.ad_applicationname = {
          status: false,
          message: "",
        };
      }
    }
  };

  const handleSubmit = (e, index) => {
    e.preventDefault();
    let data = [];
    let app = appDetail[index];
    //console.log(appDetail[0]);
    console.log(appDetail[index]);
    setModule(`Application ${appDetail[index]?.ad_applicationname}`);

    data.push(app);
    console.log(data);

    let result;
    let net = edges;
    console.log(net);
    console.log(appDetail[index]);
    if (net == undefined) {
      console.log("no network conn");
      if (!validate()) {
        result = AddNewappDetail(data);
        console.log("valid");
        result
          .then((resp) => {
            console.log(resp.data.rowCount);
            //alert(resp.message);
            console.log("302", resp.message);
            if (
              resp.message.includes("fails to match the required pattern") ||
              resp.message.includes("must be")
            ) {
              let s = resp.message;
              s = s.split('"')[1];
              console.log("In Weird Pattern", s);

              setAlertMessage("Special Characters not allowed in " + s);
            } else {
              //props.setshowAppForm(!props.showAppForm);
              setShowMessage("Great! You have Saved Application Successfully");
              if (resp.message === "Application details added successfully!") {
                // addApplicationNode(appDetail);
                getAplDets(enquiryID);
                setShow(true);
                setshowAppForm(false);
                setActive(active + 1);
              }
            }
          })
          .catch((error) => {
            console.log("Unable to process request" + error);
            setAlertMessage("Error in adding data or invalid data.");
          });
      }
    } else {
      let app_val = "APL-" + appDetail[index].ad_appid;
      console.log(app_val);
      let obj_s = net.find((o) => o.source === app_val);
      let obj_t = net.find((o) => o.target === app_val);
      console.log(obj_s);
      console.log(obj_t);

      if (appDetail[index].ad_n2application == "true" && obj_t != undefined) {
        setAlertMessage(
          "Cannot change N2 status to Yes as it is present in network connection."
        );
        appDetail[index].ad_n2application = "false";
        getAplDets(enquiryID);
        // setAppDetail((prevState) => ({
        //   ...prevState,
        //   ad_n2application: "false",
        // }));
      } else if (
        appDetail[index].ad_n2application == "true" &&
        obj_s != undefined
      ) {
        setAlertMessage(
          "Cannot change N2 status to Yes as it is present in network connection."
        );
        appDetail[index].ad_n2application = "false";
        getAplDets(enquiryID);
        // setAppDetail((prevState) => ({
        //   ...prevState,
        //   ad_n2application: "false",
        // }));
      } else {
        if (!validate()) {
          //if (validateInput(appDetail[index])) {
          result = AddNewappDetail(data);
          console.log("valid");
          result
            .then((resp) => {
              console.log(resp.data.rowCount);
              //alert(resp.message);
              console.log("357", resp.message);
              if (
                resp.message.includes("fails to match the required pattern") ||
                resp.message.includes("must be")
              ) {
                let s = resp.message;
                s = s.split('"')[1];
                console.log("In Weird Pattern", s);

                setAlertMessage("Special Characters not allowed in " + s);
              } else {
                //props.setshowAppForm(!props.showAppForm);
                setShowMessage(
                  "Great! You have Saved Application Successfully"
                );
                if (
                  resp.message === "Application details added successfully!"
                ) {
                  // addApplicationNode(appDetail);
                  getAplDets(enquiryID);
                  setShow(true);
                  setshowAppForm(false);
                  setActive(active + 1);
                }
              }
            })
            .catch((error) => {
              console.log("Unable to process request" + error);
              setAlertMessage("Error in adding data or invalid data.");
            });
          //}
        }
      }
    }
  };

  useEffect(() => {
    fetchCompMaster();
    //console.log(edges);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <CommonModal
        // clickFunction={modalOnClick}
        message={showMessage}
        show={show}
        handleClose={() => {
          setShow(false);
          // setshowCompForm(true);
        }}
      />
      <fieldset id="appform" disabled={IsDisabled === false ? true : false}>
        <div className="place-center">
          <div
            id={"application-form-" + id}
            className={
              showAppForm && active === id
                ? "application-form d-block"
                : "application-form d-none"
            }
          >
            {" "}
            <div>
              <span className="trans-all-man-fields">
                All fields are mandatory
              </span>
            </div>
            <div>
              {alertMessage && (
                <Alert className="alertBox" variant="warning">
                  {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                  {alertMessage}
                </Alert>
              )}
            </div>
            <Col className="d-none">
              <Form.Group>
                <Form.Label className="my-label">Application Code</Form.Label>

                <Form.Control
                  disabled={IsDisabled === false ? true : false}
                  readOnly
                  type="text"
                  placeholder="Application Code"
                  name="ad_applicationcode"
                  // value={"APL"+(id+1)}
                  value={appDetail[id].ad_applicationcode}
                  // onChange={(e) => handleChangeval(e, id)}
                  onInput={(e) => {
                    let error = isAlphanumericWithSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ad_applicationcode: error,
                    }));
                  }}
                  className={
                    error.ad_applicationcode.status
                      ? "placeHolderInput1 inputTextWhite text-field-with-error"
                      : "placeHolderInput1 inputTextWhite"
                  }
                />
              </Form.Group>
            </Col>
            <Form autoComplete="off">
              <Row className="g-0 mt-1">
                <Col xs={2} sm={2} md={2} lg={2} className="place-center">
                  <div
                    className={
                      sidebarFlag
                        ? `list-bullet bulletStyle`
                        : `list-bullet left-margin-none bulletStyle`
                    }
                  ></div>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="my-label">
                      Application Name
                    </Form.Label>
                    <Form.Control
                      disabled={IsDisabled === false ? true : false}
                      type="text"
                      placeholder="Application Name"
                      name="ad_applicationname"
                      maxLength="100"
                      value={appDetail[id].ad_applicationname}
                      onChange={(e) => handleChangeval(e, id)}
                      onBlur={(e) => {
                        getName(e);
                      }}
                      onInput={(e) => {
                        let error = isAlphanumericWithSpace(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          ad_applicationname: error,
                        }));
                      }}
                      className={
                        error.ad_applicationname.status
                          ? "placeHolderInput form-control2 inputTextWhite text-field-with-error"
                          : "placeHolderInput form-control2 inputTextWhite"
                      }
                    />
                    {error.ad_applicationname.status && (
                      <Form.Text className="text-danger">
                        {error.ad_applicationname.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-0 mt-1">
                <Col xs={2} sm={2} md={2} lg={2} className="place-center">
                  <div
                    className={
                      sidebarFlag
                        ? `list-bullet bulletStyle`
                        : `list-bullet left-margin-none bulletStyle`
                    }
                  ></div>
                </Col>
                <Col md={4} lg={5} className="me-1 me-md-3 col-6">
                  <Form.Group>
                    <Form.Label className="my-label">
                      Max Flow Requirement
                    </Form.Label>
                    <Form.Control
                      disabled={IsDisabled === false ? true : false}
                      type="text"
                      placeholder="Maximum Flow Requirement"
                      name="ad_maxflowrequired"
                      value={appDetail[id].ad_maxflowrequired}
                      onChange={(e) => handleChangeval(e, id)}
                      onInput={(e) => {
                        let error = isNumberWithDecimal(e.target.value);
                        if (error.status) {
                          setError((prevState) => ({
                            ...prevState,
                            ad_maxflowrequired: error,
                          }));
                        } else {
                          error = validDecimals(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            ad_maxflowrequired: error,
                          }));
                        }
                      }}
                      className={
                        error.ad_maxflowrequired.status
                          ? "placeHolderInput form-control2 inputTextWhite text-field-with-error"
                          : "placeHolderInput form-control2 inputTextWhite"
                      }
                    />
                    {error.ad_maxflowrequired.status && (
                      <Form.Text className="text-danger">
                        {error.ad_maxflowrequired.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                &nbsp;
                <Col md={3} lg={4} className="col-2">
                  <Form.Group>
                    <Form.Label className="my-label">Unit</Form.Label>

                    <Form.Select
                      disabled={IsDisabled === false ? true : false}
                      required
                      name="ad_maxflowrunit"
                      placeholder="Unit"
                      value={appDetail[id].ad_maxflowrunit}
                      onChange={(e) => {
                        handleChangeval(e, id);
                        let error = checkNull(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          ad_maxflowrunit: error,
                        }));
                      }}
                      className={
                        error.ad_maxflowrunit.status
                          ? "placeHolderInput form-control2 form-select1 inputTextWhite text-field-with-error"
                          : "placeHolderInput form-control2 form-select1 inputTextWhite unitBox"
                      }
                    >
                      <option
                        value=""
                        className="placeHolderInput form-control2 form-select1 inputTextWhite unitBox"
                      >
                        Select
                      </option>
                      {Capacity.map((fromunit, index) => (
                        <option value={fromunit}>{fromunit}</option>
                      ))}
                    </Form.Select>
                    {error.ad_maxflowrunit.status && (
                      <Form.Text className="text-danger">
                        {error.ad_maxflowrunit.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-0 mt-1">
                <Col xs={2} sm={2} md={2} lg={2} className="place-center">
                  <div
                    className={
                      sidebarFlag
                        ? `list-bullet bulletStyle`
                        : `list-bullet left-margin-none margin-bottom-30 bulletStyle`
                    }
                  ></div>
                </Col>
                <Col lg={5} md={4} className="me-1  me-md-3 col-6">
                  <Form.Group>
                    <Form.Label className="my-label">
                      Avg Flow Requirement
                    </Form.Label>
                    <Form.Control
                      disabled={IsDisabled === false ? true : false}
                      type="text"
                      placeholder="Average Flow Requirement"
                      name="ad_avgflowrequired"
                      value={appDetail[id].ad_avgflowrequired}
                      onChange={(e) => handleChangeval(e, id)}
                      onInput={(e) => {
                        let error = isNumberWithDecimal(e.target.value);
                        if (error.status) {
                          setError((prevState) => ({
                            ...prevState,
                            ad_avgflowrequired: error,
                          }));
                        } else {
                          error = validDecimals(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            ad_avgflowrequired: error,
                          }));
                        }
                      }}
                      className={
                        error.ad_avgflowrequired.status
                          ? "placeHolderInput form-control2 inputTextWhite text-field-with-error"
                          : "placeHolderInput form-control2 inputTextWhite"
                      }
                    />
                    {error.ad_avgflowrequired.status && (
                      <Form.Text className="text-danger">
                        {error.ad_avgflowrequired.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                &nbsp;
                <Col lg={4} md={3} className="col-2">
                  <Form.Group>
                    <Form.Label className="my-label">Select</Form.Label>

                    <Form.Select
                      disabled={IsDisabled === false ? true : false}
                      required
                      name="ad_avgflowunit"
                      placeholder="Unit"
                      value={appDetail[id].ad_avgflowunit}
                      onChange={(e) => {
                        handleChangeval(e, id);
                        let error = checkNull(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          ad_avgflowunit: error,
                        }));
                      }}
                      className={
                        error.ad_avgflowunit.status
                          ? "placeHolderInput form-control2 form-select1 inputTextWhite text-field-with-error"
                          : "placeHolderInput form-control2 form-select1 inputTextWhite unitBox"
                      }
                    >
                      <option value="">Select</option>
                      {Capacity.map((fromunit, index) => (
                        <option value={fromunit}>{fromunit}</option>
                      ))}
                    </Form.Select>
                    {error.ad_avgflowunit.status && (
                      <Form.Text className="text-danger">
                        {error.ad_avgflowunit.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-0 mt-1">
                <Col xs={2} sm={2} md={2} lg={2} className="place-center">
                  <div
                    className={
                      sidebarFlag
                        ? `list-bullet bulletStyle`
                        : `list-bullet left-margin-none margin-bottom-30 bulletStyle`
                    }
                  ></div>
                </Col>
                <Col lg={5} md={4} className="me-1  me-md-3  col-6">
                  <Form.Group>
                    <Form.Label className="my-label">
                      Pressure Requirement
                    </Form.Label>
                    <Form.Control
                      disabled={IsDisabled === false ? true : false}
                      type="text"
                      placeholder="Pressure Requirement"
                      name="ad_pressurerequired"
                      value={appDetail[id].ad_pressurerequired}
                      onChange={(e) => handleChangeval(e, id)}
                      onInput={(e) => {
                        let error = isNumberWithDecimal(e.target.value);
                        if (error.status) {
                          setError((prevState) => ({
                            ...prevState,
                            ad_pressurerequired: error,
                          }));
                        } else {
                          error = validDecimals(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            ad_pressurerequired: error,
                          }));
                        }
                      }}
                      className={
                        error.ad_pressurerequired.status
                          ? "placeHolderInput form-control2 inputTextWhite text-field-with-error"
                          : "placeHolderInput form-control2 inputTextWhite"
                      }
                    />
                    {error.ad_pressurerequired.status && (
                      <Form.Text className="text-danger">
                        {error.ad_pressurerequired.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                &nbsp;
                <Col lg={4} md={3} className="col-2">
                  <Form.Group>
                    <Form.Label className="my-label">Select</Form.Label>

                    <Form.Select
                      disabled={IsDisabled === false ? true : false}
                      required
                      name="ad_pressureunit"
                      placeholder="Unit"
                      value={appDetail[id].ad_pressureunit}
                      onChange={(e) => {
                        handleChangeval(e, id);
                        let error = checkNull(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          ad_pressureunit: error,
                        }));
                      }}
                      className={
                        error.ad_pressureunit.status
                          ? "placeHolderInput form-control2 form-select1 inputTextWhite text-field-with-error"
                          : "placeHolderInput form-control2 form-select1 inputTextWhite unitBox"
                      }
                    >
                      <option value="">Select</option>
                      {Pressure.map((fromunit, index) => (
                        <option value={fromunit}>{fromunit}</option>
                      ))}
                    </Form.Select>
                    {error.ad_pressureunit.status && (
                      <Form.Text className="text-danger">
                        {error.ad_pressureunit.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-0 mt-1">
                <Col xs={2} sm={2} md={2} lg={2} className="place-center">
                  <div
                    className={
                      sidebarFlag
                        ? `list-bullet bulletStyle`
                        : `list-bullet left-margin-none bulletStyle`
                    }
                  ></div>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="my-label">Working Hrs</Form.Label>
                    <Form.Control
                      disabled={IsDisabled === false ? true : false}
                      type="text"
                      placeholder="Working Hrs"
                      name="ad_workinghours"
                      value={appDetail[id].ad_workinghours}
                      onChange={(e) => handleChangeval(e, id)}
                      onInput={(e) => {
                        let error = number24(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          ad_workinghours: error,
                        }));
                      }}
                      className={
                        error.ad_workinghours.status
                          ? "placeHolderInput form-control2 inputTextWhite text-field-with-error"
                          : "placeHolderInput form-control2 inputTextWhite"
                      }
                    />
                    {error.ad_workinghours.status && (
                      <Form.Text className="text-danger">
                        {error.ad_workinghours.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-0 mt-1 lastRow">
                <Col xs={2} sm={2} md={2} lg={2} className="place-center">
                  <div
                    className={
                      sidebarFlag
                        ? `list-bullet bulletStyle`
                        : `list-bullet left-margin-none margin-top-15 bulletStyle`
                    }
                  ></div>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="my-label">N2 Application</Form.Label>
                    {/* <Form.Control
                disabled={IsDisabled === false ? true : false}
                type="text"
                placeholder="N2 Application"
                name="ad_n2application"
                value={appDetail[id].ad_n2application}
                onChange={(e) => handleChangeval(e, id)}
              /> */}
                    <Form.Select
                      disabled={IsDisabled === false ? true : false}
                      required
                      name="ad_n2application"
                      placeholder="N2 Application"
                      value={appDetail[id].ad_n2application}
                      onChange={(e) => {
                        handleChangeval(e, id);
                        let error = checkNull(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          ad_n2application: error,
                        }));
                      }}
                      className={
                        error.ad_n2application.status
                          ? "placeHolderInput form-control2 form-select1 inputText text-field-with-error"
                          : "placeHolderInput form-control2 form-select1 inputText"
                      }
                    >
                      <option value="">Select</option>
                      <option value="true">Yes</option>
                      <option value="false" selected>
                        No
                      </option>
                    </Form.Select>
                    {error.ad_n2application.status && (
                      <Form.Text className="text-danger">
                        {error.ad_n2application.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <div>
                  {alertMessage && (
                    <Alert className="alertBox" variant="warning">
                      {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                      {alertMessage}
                    </Alert>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div
          id={"application-form-" + id}
          className={
            showAppForm && active === id
              ? "application-form d-block"
              : "application-form d-none"
          }
        >
          <div className="place-center display-block">
            <Col md={3} className="col-xs-6">
              <Button
                hidden={IsDisabled === false ? true : false}
                bsPrefix={sidebarFlag ? "save-btn" : "save-btn"}
                onClick={(e) => handleSubmit(e, id)}
              >
                Save
              </Button>
            </Col>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default ApplicationForm;
