import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_EXCEL_API_URL;

console.log(API_URL);

export const ExcelDownloadLog = async (Data) => {
    console.log(JSON.stringify(Data));

    // let url = API_URL;
    // console.log(url);
    const response = await axios
      .post(API_URL, JSON.stringify(Data))
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return { message: "Unable to find request", status: false };
      });
    return response?.data || "";
  };