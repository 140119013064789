function decimalFloat(numb) {
    numb = parseFloat(numb)
    var rounded = Math.round((numb + Number.EPSILON) * 100) / 100;
    return rounded;
}

function multipleOfFifty(numb) {
    numb = parseFloat(numb)
    var rounded = Math.round((numb)/50) *50;
    return rounded;
}

export { decimalFloat,multipleOfFifty };