import { Breadcrumb, Button, Form, Row, Col, Modal } from "react-bootstrap";
import { BsPlusLg, BsX } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import Scrollbar from "smooth-scrollbar";
import { useEffect, useMemo, useRef, useState } from "react";
import GolbalSearch from "../../Components/utility/GolbalSearch";
import exportFromJSON from "export-from-json";
import Export from "../../icons/Export.svg";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import escapeExcelData from "../../utils/customValidation.js"
import {
  FilterChange,
  formatDateDD_MM_YYYY,
  resetAllFilter,
  resetFilter,
} from "../../functions/functions";
//import ReportsHoc from "./ReportsHoc";
import Pic_Preview from "../../Components/Masters/ProductMaster/Pic_Preview";
import AllSolutionHoc from "../AllSolution/AllSolutionHoc";
import {
  validINRCurrency,
  validUSDCurrency,
} from "../../functions/validations";
import Customtable from "../../Components/layout/customTable";
import { downloadFileDirect } from "../../utils/presignUpload";
import { store } from "../../redux/store";
import { ExcelDownloadLog } from "../../Services/excel-download-service";
import { getDataMaskStatus } from "../../Services/user-service";

const Reports = (props) => {
  console.log("Reports props:", props);

  //BOC Vikas K on 19/09/2022
  let filteredResult1 = props.tableData;
  console.log(filteredResult1);
  let flag = false;
  //EOC Vikletas K on 19/09/2022
  
  let customerExcelDownload = store.getState().global?.userDetails.ram_custdownload;
  let userExcelDownload = store.getState().global?.userDetails.ram_userdownload;
  let dealerExcelDownload = store.getState().global?.userDetails.ram_dealerdownload;
  const userRole = store.getState().global?.userDetails?.um_role;

  const gridRef = useRef();
  const [globalText, setGlobalText] = useState("");
  const [customers, setCustomer] = useState(null);
  const [filteredResult, setFilteredResult] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredCol, setFilteredCol] = useState([]);
  const [datapic, setdatapic] = useState({});
  const [showForm1, setShowForm1] = useState(false);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [dataMask, setDataMask] = useState(false);
  const [repObj, setRepObj] = useState({
    ed_enqstatus: "",
    ed_enquirynumber: "",
    ed_insertedon: "",
    dm_dealercompanyname: "",
    ed_custid: "",
    ed_custcode: "",
    ed_custname: "",
    ed_custlocation: "",
    ed_country: "",
    ed_hoaddress: "",
    ed_telno: "",
    ed_webaddress: "",
    ed_productsmanufactured: "",
    ed_sector: "",
    ed_techname: "",
    ed_techdesignation: "",
    ed_techcontactno: "",
    ed_techemailid: "",
    ed_dmname: "",
    ed_dmdesignation: "",
    ed_dmcontactno: "",
    ed_dmemailid: "",
    ed_status: "",
    ed_files: "",
    ed_currencyvalue: "",
    ed_workingdays: "",
    ed_electricityunitrate: "",
    fu_schematiclayoutfilename: "",
    fu_hobofilename: "",
    fu_attch1filename: "",
    fu_attch2filename: "",
    fu_attch3filename: "",
    avgicfm: "",
    id_price: "",
    engsavedkwh: "",
    cost_saved_per_year_inr: "",
    ed_roi: "",
    ed_roi_months: "",
    username: "",
    cost_saved_per_year_usd: "",
    cost_saved_per_year_local: "",
    energyconsumption: "",
    solutiontype: "",
  });
  const [reload, setReload] = useState(false);
  const [action, setAction] = useState("");
  const [row_Data, setRow_Data] = useState({});
  const [data, setdata] = useState("");
  let formName = "Reports";
  const [filterDisplay, setFilterDisplay] = useState("filter-input d-none");
  const fetchReports = async () => {
    //const result = await getAllEnquiryCustomers();
    //setCustomer(result);

    console.log("tabledata", props.tableData);
    console.log(filteredResult1);
    setFilteredResult(filteredResult1);
    Scrollbar.init(document.querySelector(".app-content"));
  };

  const fetchDataMaskingSts = async () => {
    const result = await getDataMaskStatus(userRole);
    console.log("getDataMaskStatus",result.result,userRole);
    setDataMask(result.result);
  }

  useEffect(() => {
    fetchDataMaskingSts();
    fetchReports();
  }, []);

  // useEffect(() => {
  //     customers && globalText && handleGlobalSearch(globalText);
  // }, [customers]);


  const IsPicPreview = (url) => {
    console.log('inside IsPicPreview',url)
    if (url == null || url == undefined || url == 'undefined') {
      return "";
    }
    if (url.includes(".JPG") ||
      url.includes(".JPEG") ||
      url.includes(".jpg") ||
      url.includes(".jpeg") ||
      url.includes(".bmp") ||
      url.includes(".png")) {
      return true;
    }
    else {
      return false;
    }
  }
  const handleSchematicLayout = (row) => {
    if (row.fu_schematiclayoutfilename == "") {
      alert("No docs attached");
    } else {
      const arrpic = new Array();
      arrpic.push(row.fu_schematiclayoutfilename);
      console.log(arrpic);

      console.log(arrpic);

      setdatapic(arrpic);
      if (IsPicPreview(row.fu_schematiclayoutfilename)) {
        setDownloadFlag(false);
        setShowForm1(true);
      }
      else {
        setShowForm1(false);
        setDownloadFlag(true);
      }
    }
  };

  const handleHoboProject = (row) => {
    if (row.fu_hobofilename == "") {
      alert("No docs attached");
    } else {
      const arrpic = new Array();
      arrpic.push(row.fu_hobofilename);
      console.log(arrpic);

      console.log(arrpic);

      setdatapic(arrpic);
      if (IsPicPreview(row.fu_hobofilename)) {
        setDownloadFlag(false);
        setShowForm1(true);
      }
      else {
        setShowForm1(false);
        setDownloadFlag(true);
      }
    }
  };

  const handleattachdoc1 = (row) => {
    if (row.fu_attch1filename == "") {
      alert("No docs attached");
    } else {
      const arrpic = new Array();
      arrpic.push(row.fu_attch1filename);
      console.log(arrpic);

      console.log(arrpic);

      setdatapic(arrpic);
      if (IsPicPreview(row.fu_attch1filename)) {
        setDownloadFlag(false);
        setShowForm1(true);
      }
      else {
        setShowForm1(false);
        setDownloadFlag(true);
      }
    }
  };

  const handleattachdoc2 = (row) => {
    if (row.fu_attch2filename == "") {
      alert("No docs attached");
    } else {
      const arrpic = new Array();
      arrpic.push(row.fu_attch2filename);
      console.log(arrpic);

      console.log(arrpic);

      setdatapic(arrpic);
      if (IsPicPreview(row.fu_attch2filename)) {
        setDownloadFlag(false);
        setShowForm1(true);
      }
      else {
        setShowForm1(false);
        setDownloadFlag(true);
      }
    }
  };

  const handleattachdoc3 = (row) => {
    if (row.fu_attch3filename == "") {
      alert("No docs attached");
    } else {
      const arrpic = new Array();
      arrpic.push(row.fu_attch3filename);
      console.log(arrpic);

      console.log(arrpic);

      setdatapic(arrpic);
      if (IsPicPreview(row.fu_attch3filename)) {
        setDownloadFlag(false);
        setShowForm1(true);
      }
      else {
        setShowForm1(false);
        setDownloadFlag(true);
      }
    }
  };

  const handleGlobalSearch = (text) => {
    flag = true;
    let searchText = text.toLowerCase();
    setGlobalText(searchText);
    console.log(searchText);
    if (searchText) {
      let result = props.tableData.filter((item) => {
        console.log(item, searchText);
        console.log(item.ed_custlocation?.toLowerCase().indexOf(searchText));
        return (
          item.ed_enqstatus?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_enquirynumber?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_insertedon?.toLowerCase().indexOf(searchText) >= 0 ||
          item.dm_dealercompanyname?.toLowerCase().indexOf(searchText) >= 0 ||
          //item.ed_status?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_proposalstatus?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_location_?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_custcode?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_custname?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_custlocation?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_country?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_hoaddress?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_telno?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_webaddress?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_productsmanufactured?.toLowerCase().indexOf(searchText) >=
          0 ||
          item.ed_techname?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_techdesignation?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_techcontactno?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_techemailid?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_dmname?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_dmdesignation?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_dmcontactno?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_dmemailid?.toLowerCase().indexOf(searchText) >= 0 ||
          item.fu_schematiclayoutfilename?.toLowerCase().indexOf(searchText) >= 0 ||
          item.fu_hobofilename?.toLowerCase().indexOf(searchText) >= 0 ||
          item.fu_attch1filename?.toLowerCase().indexOf(searchText) >= 0 ||
          item.fu_attch2filename?.toLowerCase().indexOf(searchText) >= 0 ||
          item.fu_attch3filename?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_currencyvalue?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_workingdays?.toString().toLowerCase().indexOf(searchText) >=
          0 ||
          item.ed_electricityunitrate?.toLowerCase().indexOf(searchText) >= 0 ||
          item.avgicfm?.toLowerCase().indexOf(searchText) >= 0 ||
          item.id_price?.toLowerCase().indexOf(searchText) >= 0 ||
          item.engsavedkwh?.toLowerCase().indexOf(searchText) >= 0 ||
          item.cost_saved_per_year_inr?.toLowerCase().indexOf(searchText) >=
          0 ||
          item.ed_roi?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_roi_months?.toLowerCase().indexOf(searchText) >= 0 ||
          item.username?.toLowerCase().indexOf(searchText) >= 0 ||
          item.cost_saved_per_year_usd?.toLowerCase().indexOf(searchText) >=
          0 ||
          item.cost_saved_per_year_local?.toLowerCase().indexOf(searchText) >=
          0 ||
          item.energyconsumption?.toLowerCase().indexOf(searchText) >= 0 ||
          item.solutiontype?.toLowerCase().indexOf(searchText) >= 0
        );
      });
      setFilteredResult(result);
    } else {
      setFilteredResult(props.tableData);
    }
  };

  const resetForm = () => {
    document.filterForm.reset();
    setRepObj({
      ed_enqstatus: "",
      ed_enquirynumber: "",
      ed_insertedon: "",
      dm_dealercompanyname: "",
      //ed_status: "",
      ed_proposalstatus: "",
      ed_location: "",
      ed_custcode: "",
      ed_custname: "",
      ed_custlocation: "",
      ed_country: "",
      ed_hoaddress: "",
      ed_telno: "",
      ed_webaddress: "",
      ed_productsmanufactured: "",
      ed_sector: "",
      ed_techname: "",
      ed_techdesignation: "",
      ed_techcontactno: "",
      ed_techemailid: "",
      ed_dmname: "",
      ed_dmdesignation: "",
      ed_dmcontactno: "",
      ed_dmemailid: "",
      ed_status: "",
      fu_schematiclayout: "",
      fu_hoboproject: "",
      fu_attch1filename: "",
      fu_attch2filename: "",
      fu_attch3filename: "",
      ed_currencyvalue: "",
      ed_workingdays: "",
      ed_electricityunitrate: "",
      avgicfm: "",
      id_price: "",
      engsavedkwh: "",
      cost_saved_per_year_inr: "",
      ed_roi: "",
      ed_roi_months: "",
      username: "",
      cost_saved_per_year_usd: "",
      cost_saved_per_year_local: "",
      energyconsumption: "",
      solutiontype: "",
    });
    setFilteredResult(customers);
  };

  const handleExport = () => {
    let gridData=[];
    gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
      gridData.push(node.data);
    });
    const data = [];
    const userids=[];

    // if (gridData.length > 0) {
      var filesUploaded = '';
      for (let n = 0; n < gridData.length; n++) {
        // "Files Uploaded": gridData[n].fu_schematiclayoutfilename,
        // "Files Uploaded": gridData[n].fu_hobofilename,
        // "Files Uploaded": gridData[n].fu_attch1filename,
        // "Files Uploaded": gridData[n].fu_attch2filename,
        // "Files Uploaded": gridData[n].fu_attch3filename,

        if (gridData[n].fu_schematiclayoutfilename != null && gridData[n].fu_schematiclayoutfilename != '') {
          if (filesUploaded == '') {
            filesUploaded = gridData[n].fu_schematiclayoutfilename
          } else {
            filesUploaded = filesUploaded + " " + gridData[n].fu_schematiclayoutfilename
          }
        }

        if (gridData[n].fu_hobofilename != null && gridData[n].fu_hobofilename != '') {
          if (filesUploaded == '') {
            filesUploaded = gridData[n].fu_hobofilename
          } else {
            filesUploaded = filesUploaded + " " + gridData[n].fu_hobofilename
          }
        }

        if (gridData[n].fu_attch1filename != null && gridData[n].fu_attch1filename != '') {
          if (filesUploaded == '') {
            filesUploaded = gridData[n].fu_attch1filename
          } else {
            filesUploaded = filesUploaded + " " + gridData[n].fu_attch1filename
          }
        }

        if (gridData[n].fu_attch2filename != null && gridData[n].fu_attch2filename != '') {
          if (filesUploaded == '') {
            filesUploaded = gridData[n].fu_attch2filename
          } else {
            filesUploaded = filesUploaded + " " + gridData[n].fu_attch2filename
          }
        }

        if (gridData[n].fu_attch3filename != null && gridData[n].fu_attch3filename != '') {
          if (filesUploaded == '') {
            filesUploaded = gridData[n].fu_attch3filename
          } else {
            filesUploaded = filesUploaded + " " + gridData[n].fu_attch3filename
          }
        }
        console.log("FU ", filesUploaded);
          userids.push({
            "id": escapeExcelData(gridData[n].ed_enquiryid)
          });

        data.push({
          "Transaction Status": escapeExcelData(gridData[n].ed_enqstatus),
          "Enquiry number": escapeExcelData(gridData[n].ed_enquirynumber),
          "Enquiry Date ": formatDateDD_MM_YYYY(escapeExcelData(gridData[n].ed_insertedon)),
          "Enquiry Created By": escapeExcelData(gridData[n].username),
          "Company/Dealer ": escapeExcelData(gridData[n].dm_dealercompanyname),
          //"Enquiry Status": escapeExcelData(gridData[n].ed_status),
          "Proposal Status": escapeExcelData(gridData[n].ed_proposalstatus),
          Location: escapeExcelData(gridData[n].ed_location_),
          "Customer Code": escapeExcelData(gridData[n].ed_custcode),
          "Customer Name": escapeExcelData(gridData[n].ed_custname),
          "Location type": escapeExcelData(gridData[n].ed_custlocation),
          Country: escapeExcelData(gridData[n].ed_country),
          "HO Address": escapeExcelData(gridData[n].ed_hoaddress),
          "Tel No":escapeExcelData(gridData[n].ed_telno,dataMask),
          "Website Address": escapeExcelData(escapeExcelData(gridData[n].ed_webaddress)),
          "Products Manufactured": escapeExcelData(gridData[n].ed_productsmanufactured),
          Sector: escapeExcelData(gridData[n].ed_sector),
          "Tech Person Name": escapeExcelData(gridData[n].ed_techname),
          "Tech Person Designation": escapeExcelData(gridData[n].ed_techdesignation),
          "Tech Person Contact No": escapeExcelData(gridData[n].ed_techcontactno,dataMask),
          "Tech Person Email ID": escapeExcelData(gridData[n].ed_techemailid),
          "Decision Making Person Name": escapeExcelData(gridData[n].ed_dmname),
          "Decision Making Person Designation": escapeExcelData(gridData[n].ed_dmdesignation),
          "Decision Making Person Contact No": escapeExcelData(gridData[n].ed_dmcontactno,dataMask),
          "Decision Making Person Email ID": escapeExcelData(gridData[n].ed_dmemailid),
          // "Files Uploaded": gridData[n].fu_schematiclayoutfilename,
          // "Files Uploaded": gridData[n].fu_hobofilename,
          // "Files Uploaded": gridData[n].fu_attch1filename,
          // "Files Uploaded": gridData[n].fu_attch2filename,
          // "Files Uploaded": gridData[n].fu_attch3filename,
          "Files Uploaded": escapeExcelData(filesUploaded),
          Currency: escapeExcelData(gridData[n].ed_currencyvalue),
          "No. Of Working Days in a Year ": escapeExcelData(gridData[n].ed_workingdays),
          "Electricity Unit Rate": escapeExcelData(gridData[n].ed_electricityunitrate),
          "Energy Consumption": escapeExcelData(gridData[n].energyconsumption),
          "Average iCFM": escapeExcelData(gridData[n].avgicfm),
          "Solution Type": escapeExcelData(gridData[n].solutiontype),
          "Proposal Value": escapeExcelData(gridData[n].id_price),
          "Energy Saved (in kWh)": escapeExcelData(gridData[n].engsavedkwh),
          "Cost Saved Per Year (INR)": escapeExcelData(gridData[n].cost_saved_per_year_inr),
          "Cost Saved Per Year (USD)": escapeExcelData(gridData[n].cost_saved_per_year_usd),
          "Cost Saved Per Year (local currency)":escapeExcelData(gridData[n].cost_saved_per_year_local),
          "ROI (in Years)": escapeExcelData(gridData[n].ed_roi),
          "ROI (in Months)": escapeExcelData(gridData[n].ed_roi_months),
        });
      }
    // } 
    // else {
    //   var filesUploaded = '';
    //   for (let n = 0; n < filteredResult.length; n++) {

    //     if (filteredResult[n].fu_schematiclayoutfilename != null && filteredResult[n].fu_schematiclayoutfilename != '') {
    //       if (filesUploaded == '') {
    //         filesUploaded = filteredResult[n].fu_schematiclayoutfilename
    //       } else {
    //         filesUploaded = filesUploaded + " " + filteredResult[n].fu_schematiclayoutfilename
    //       }
    //     }

    //     if (filteredResult[n].fu_hobofilename != null && filteredResult[n].fu_hobofilename != '') {
    //       if (filesUploaded == '') {
    //         filesUploaded = filteredResult[n].fu_hobofilename
    //       } else {
    //         filesUploaded = filesUploaded + " " + filteredResult[n].fu_hobofilename
    //       }
    //     }

    //     if (filteredResult[n].fu_attch1filename != null && filteredResult[n].fu_attch1filename != '') {
    //       if (filesUploaded == '') {
    //         filesUploaded = filteredResult[n].fu_attch1filename
    //       } else {
    //         filesUploaded = filesUploaded + " " + filteredResult[n].fu_attch1filename
    //       }
    //     }

    //     if (filteredResult[n].fu_attch2filename != null && filteredResult[n].fu_attch2filename != '') {
    //       if (filesUploaded == '') {
    //         filesUploaded = filteredResult[n].fu_attch2filename
    //       } else {
    //         filesUploaded = filesUploaded + " " + filteredResult[n].fu_attch2filename
    //       }
    //     }

    //     if (filteredResult[n].fu_attch3filename != null && filteredResult[n].fu_attch3filename != '') {
    //       if (filesUploaded == '') {
    //         filesUploaded = filteredResult[n].fu_attch3filename
    //       } else {
    //         filesUploaded = filesUploaded + " " + filteredResult[n].fu_attch3filename
    //       }
    //     }
    //     console.log("FU ", filesUploaded);
    //       userids.push({
    //         "id": escapeExcelData(filteredResult[n].ed_enquiryid)
    //       });

    //     data.push({
    //       "Transaction Status": escapeExcelData(filteredResult[n].ed_enqstatus),
    //       "Enquiry Number": filteredResult[n].ed_enquirynumber,
    //       "Enquiry Date ": formatDateDD_MM_YYYY(
    //         filteredResult[n].ed_insertedon
    //       ),
    //       "Enquiry Created By": filteredResult[n].username,
    //       "Company/Dealer ": escapeExcelData(filteredResult[n].dm_dealercompanyname),
    //       //"Enquiry Status": filteredResult[n].ed_status,
    //       "Proposal Status": escapeExcelData(filteredResult[n].ed_proposalstatus),
    //       Location: escapeExcelData(filteredResult[n].ed_location_),
    //       "Customer Code": escapeExcelData(filteredResult[n].ed_custcode),
    //       "Customer Name": escapeExcelData(filteredResult[n].ed_custname),
    //       "Location type": escapeExcelData(filteredResult[n].ed_custlocation),
    //       Country: escapeExcelData(filteredResult[n].ed_country),
    //       "HO Address": escapeExcelData(filteredResult[n].ed_hoaddress),
    //       "Tel No": escapeExcelData(filteredResult[n].ed_telno,dataMask),
    //       "Website Address": escapeExcelData(filteredResult[n].ed_webaddress),
    //       "Products Manufactured": escapeExcelData(filteredResult[n].ed_productsmanufactured),
    //       Sector: escapeExcelData(filteredResult[n].ed_sector),
    //       "Tech Person Name": escapeExcelData(filteredResult[n].ed_techname),
    //       "Tech Person Designation": escapeExcelData(filteredResult[n].ed_techdesignation),
    //       "Tech Person Contact No": escapeExcelData(filteredResult[n].ed_techcontactno,dataMask),
    //       "Tech Person Email ID": escapeExcelData(filteredResult[n].ed_techemailid),
    //       "Decision Making Person Name": escapeExcelData(filteredResult[n].ed_dmname),
    //       "Decision Making Person Designation":
    //       escapeExcelData(filteredResult[n].ed_dmdesignation),
    //       "Decision Making Person Contact No": escapeExcelData(filteredResult[n].ed_dmcontactno,dataMask),
    //       "Decision Making Person Email ID": escapeExcelData(filteredResult[n].ed_dmemailid),
    //       // "Files Uploaded": escapeExcelData(filteredResult[n].fu_schematiclayoutfilename),
    //       // "Files Uploaded": escapeExcelData(filteredResult[n].fu_hobofilename),
    //       // "Files Uploaded": escapeExcelData(filteredResult[n].fu_attch1filename),
    //       // "Files Uploaded":escapeExcelData( filteredResult[n].fu_attch2filename),
    //       // "Files Uploaded": escapeExcelData(filteredResult[n].fu_attch3filename),
    //       "Files Uploaded": escapeExcelData(filesUploaded),
    //       Currency: escapeExcelData(filteredResult[n].ed_currencyvalue),
    //       "No. Of Working Days in a Year ": escapeExcelData(filteredResult[n].ed_workingdays),
    //       "Electricity Unit Rate": escapeExcelData(filteredResult[n].ed_electricityunitrate),
    //       "Energy Consumption": escapeExcelData(filteredResult[n].energyconsumption),
    //       "Average iCFM": escapeExcelData(filteredResult[n].avgicfm),
    //       "Solution Type": escapeExcelData(filteredResult[n].solutiontype),
    //       "Proposal Value": escapeExcelData(filteredResult[n].id_price),
    //       "Energy Saved (in kWh)": escapeExcelData(filteredResult[n].engsavedkwh),
    //       "Cost Saved Per Year (INR)":
    //         filteredResult[n].cost_saved_per_year_inr,
    //       "Cost Saved Per Year (USD)":
    //         filteredResult[n].cost_saved_per_year_usd,
    //       "Cost Saved Per Year (local currency)":
    //         filteredResult[n].cost_saved_per_year_local,
    //       "ROI (in Years)": filteredResult[n].ed_roi,
    //       "ROI (in Months)": filteredResult[n].ed_roi_months,
    //     });
    //   }
    // }

    let bodyData = {
      Session:"report",
      Records: userids
    };

    let result = ExcelDownloadLog(bodyData);
    result
      .then((resp) => {
        console.log(resp.status);
        if (resp.message === "Excel download Logged successfully!") {
          const fileName = "Reports";
          const exportType = "xls";
          console.log("Data ", data);
          exportFromJSON({ data, fileName, exportType });
        } else {
          console.log(resp.message);
          alert(resp.message);
        }
      })
      .catch((error) => {
        console.log("Unable to process request");
      })

  };

  const headerName = (field) => {
    var name;
    switch (field) {
      case "ed_enqstatus":
        name = "Transaction Status";
        break;
      case "ed_enquirynumber":
        name = "Enquiry Number";
        break;
      case "ed_insertedon":
        name = "Enquiry Generation Date";
        break;
      case "dm_dealercompanyname":
        name = "Company/Dealer";
        break;
      // case "ed_status":
      //     name = "Enquiry Status";
      //     break;
      case "ed_proposalstatus":
        name = "Proposal Status";
        break;
      case "ed_location_":
        name = "Location";
        break;
      case "ed_custcode":
        name = "Customer Code";
        break;
      case "ed_custname":
        name = "Customer Name";
        break;
      case "ed_custlocation":
        name = "Location Type";
        break;
      case "ed_country":
        name = "Country";
        break;
      case "ed_hoaddress":
        name = "HO Address";
        break;
      case "ed_telno":
        name = "Tel No";
        break;
      case "ed_webaddress":
        name = "Website Address";
        break;
      case "ed_sector":
        name = "Sector";
        break;
      case "ed_techname":
        name = "Technical Person's Name";
        break;
      case "ed_techdesignation":
        name = "Technical Person's Designation";
        break;
      case "ed_techcontactno":
        name = "Technical Person's Contact No.";
        break;
      case "ed_techemailid":
        name = "Technical Person's Email ID";
        break;
      case "ed_dmname":
        name = "Decision Making Person's Name";
        break;
      case "ed_dmdesignation":
        name = "Decision Making Person's Designation";
        break;
      case "ed_dmcontactno":
        name = "Decision Making Person's Contact No.";
        break;
      case "ed_dmemailid":
        name = "Decision Making Person's Email ID";
        break;
      case "fu_schematiclayoutfilename":
        name = "Files Uploaded";
        break;
      case "fu_hobofilename":
        name = "Files Uploaded";
        break;
      case "fu_attch1filename":
        name = "Files Uploaded";
        break;
      case "fu_attch2filename":
        name = "Files Uploaded";
        break;
      case "fu_attch3filename":
        name = "Files Uploaded";
        break;
      case "ed_currencyvalue":
        name = "Currency";
        break;
      case "ed_workingdays":
        name = "No. Of Working Days in a Year";
        break;
      case "ed_electricityunitrate":
        name = "Electricity Unit Rate";
        break;
      case "energyconsumption":
        name = "Energy Consumption";
        break;
      case "avgicfm":
        name = "Average iCFM";
        break;
      case "solutiontype":
        name = "Solution Type";
        break;
      case "id_price":
        name = "Proposal Value";
        break;
      case "engsavedkwh":
        name = "Energy Saved (in kWh)";
        break;
      case "cost_saved_per_year_inr":
        name = "Cost Saved Per Year(INR)";
        break;
      case "cost_saved_per_year_usd":
        name = "Cost Saved Per Year(USD)";
        break;
      case "cost_saved_per_year_local":
        name = "Cost Saved Per Year(Local Currency)";
        break;
      case "ed_roi":
        name = "ROI (in Years)";
        break;
      case "ed_roi_months":
        name = "ROI (in Months)";
        break;
      case "cost_saved_per_year_usd":
        name = "Cost Saved Per Year(USD)";
        break;
      case "cost_saved_per_year_local":
        name = "Cost Saved Per Year (local currency)";
        break;
      case "energyconsumption":
        name = "Energy Consumption";
        break;
      case "username":
        name = "Enquiry Created By";
        break;
      case "solutiontype":
        name = "Solution Type";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const feildName = (header) => {
    var name;
    switch (header) {
      case "Transaction Status":
        name = "ed_enqstatus";
        break;
      case "Enquiry Number":
        name = "ed_enquirynumber";
        break;
      case "Enquiry Generation Date":
        name = "ed_insertedon";
        break;
      case "Enquiry Created By":
        name = "username";
        break;
      case "Company/Dealer":
        name = "dm_dealercompanyname";
        break;
      // case "Enquiry Status":
      //     name = "ed_status";
      //     break;
      case "Proposal Status":
        name = "ed_proposalstatus";
        break;
      case "Location":
        name = "ed_location_";
        break;
      case "Customer Code":
        name = "ed_custcode";
        break;
      case "Customer Name":
        name = "ed_custname";
        break;
      case "Location Type":
        name = "ed_custlocation";
        break;
      case "Country":
        name = "ed_country";
        break;
      case "HO Address":
        name = "ed_hoaddress";
        break;
      case "Tel No":
        name = "ed_telno";
        break;
      case "Website Address":
        name = "ed_webaddress";
        break;
      case "Sector":
        name = "ed_sector";
        break;
      case "Technical Person's Name":
        name = "ed_techname";
        break;
      case "Technical Person's Designation":
        name = "ed_techdesignation";
        break;
      case "Technical Person's Contact No.":
        name = "ed_techcontactno";
        break;
      case "Technical Person's Email ID":
        name = "ed_techemailid";
        break;
      case "Decision Making Person's Name":
        name = "ed_dmname";
        break;
      case "Decision Making Person's Designation":
        name = "ed_dmdesignation";
        break;
      case "Decision Making Person's Contact No.":
        name = "ed_dmcontactno";
        break;
      case "Decision Making Person's Email ID":
        name = "ed_dmemailid";
        break;
      case "Files Uploaded":
        name = "fu_schematiclayoutfilename";
        break;
      case "Files Uploaded":
        name = "fu_hobofilename";
        break;
      case "Files Uploaded":
        name = "fu_attch1filename";
        break;
      case "Files Uploaded":
        name = "fu_attch2filename";
        break;
      case "Files Uploaded":
        name = "fu_attch3filename";
        break;
      case "Currency":
        name = "ed_currencyvalue";
        break;
      case "No. Of Working Days in a Year":
        name = "ed_workingdays";
        break;
      case "Electricity Unit Rate":
        name = "ed_electricityunitrate";
        break;
      case "Energy Consumption":
        name = "energyconsumption";
        break;
      case "Average iCFM":
        name = "avgicfm";
        break;
      case "Solution Type":
        name = "ed_ifc_type";
        break;
      case "Proposal Value":
        name = "id_price";
        break;
      case "Energy Saved (in kWh)":
        name = "engsavedkwh";
        break;
      case "Cost Saved Per Year(INR)":
        name = "cost_saved_per_year_inr";
        break;
      case "Cost Saved Per Year(USD)":
        name = "cost_saved_per_year_usd";
        break;
      case "Cost Saved Per Year(Local Currency)":
        name = "cost_saved_per_year_local";
        break;
      case "ROI (in Years)":
        name = "ed_roi";
        break;
      case "ROI (in Months)":
        name = "ed_roi_months";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const previewDOCRenderer1 = (params) => {
    return (
      <div>
        <span
          size={32}
          className="p-1"
          style={{
            color: "#810055",
            // fontWeight: "300",
          }}
          onClick={() => handleSchematicLayout(params.data)}
        >
          {params.data.fu_schematiclayoutfilename}
        </span>

        <span
          size={32}
          className="p-1"
          style={{
            color: "#810055",
            // fontWeight: "300",
          }}
          onClick={() => handleHoboProject(params.data)}
        >
          {params.data.fu_hobofilename}
        </span>
        <span
          size={32}
          className="p-1"
          style={{
            color: "#810055",
            // fontWeight: "300",
          }}
          onClick={() => handleattachdoc1(params.data)}
        >
          {params.data.fu_attch1filename}
        </span>

        <span
          size={32}
          className="p-1"
          style={{
            color: "#810055",
            // fontWeight: "300",
          }}
          onClick={() => handleattachdoc2(params.data)}
        >
          {params.data.fu_attch2filename}
        </span>
        <span
          size={32}
          className="p-1"
          style={{
            color: "#810055",
            // fontWeight: "300",
          }}
          onClick={() => handleattachdoc3(params.data)}
        >
          {params.data.fu_attch3filename}
        </span>
      </div>
    );
  };

  // const previewDOCRenderer2 = (params) => {
  //     return (
  //         <div>
  //             <span
  //                 size={32}
  //                 className="p-1"
  //                 style={{
  //                     color: "blue",
  //                     fontWeight: "300",
  //                 }}
  //                 onClick={() => handleattachdoc2(params.data)}
  //             >
  //             </span>
  //         </div>
  //     );
  // };

  // const previewDOCRenderer3 = (params) => {
  //     return (
  //         <div>
  //             <span
  //                 size={32}
  //                 className="p-1"
  //                 style={{
  //                     color: "blue",
  //                     fontWeight: "300",
  //                 }}
  //                 onClick={() => handleattachdoc3(params.data)}
  //             >
  //             </span>
  //         </div>
  //     );
  // };

  const statusRenderer = (params) => {
    console.log("Params ", params);
    // let status_color = params.value ? "#D262E6" : params.value ? "#4285F0" : params.value ? "#25C36E" : params.value ? "#FF6565" : "";
    let status_color;
    if (params.value == "Pending with Regional Manager") {
      status_color = "#D262E6";
    } else if (params.value == "Pending to Approve") {
      status_color = "#4285F0";
    } else if (params.value == "Pending for Proposal Status") {
      status_color = "#BE596C";
    } else if (params.value == "Approved by Manager") {
      status_color = "#25C36E";
    } else if (
      params.value == "Rejected by Regional Engineer" ||
      params.value == "Rejected by Manager" ||
      params.value == "Rejected by HO Engineer"
    ) {
      status_color = "#F2BF60";
    } else if (params.value == "Pending with Regional Engineer") {
      status_color = "#D262E6";
    } else if (params.value == "Pending with Regional In-charge") {
      status_color = "#4285F0";
    } else if (params.value == "Pending with Regional Incharge") {
      status_color = "#4285F0";
    } else if (params.value == "Pending with Manager") {
      status_color = "#06A4A5";
    } else if (params.value == "Pending with HO Engineer") {
      status_color = "#FF6565";
    } else if (params.value == "In-Process") {
      status_color = "#0693E3";
    } else if (params.value == "Created") {
      status_color = "#7ABE35";
    } else if (params.value == "Closed") {
      status_color = "#A7A7AB";
    } else {
      status_color = "";
    }
    return (
      <>
        <div
          className="status-color"
          style={{ backgroundColor: status_color }}
        ></div>
        <span
          style={{
            color: status_color,
            // fontWeight: "600",
          }}
          className="ms-3"
        >
          {/* {params.value ? "Pending with Regional Manager" : params.value ? "Pending to Approve" : params.value ? "Approved" : params.value ? "Rejected" : "" } */}
          {params.value ? params.value : ""}
        </span>
      </>
    );
  };

  const contains = (target, lookingFor) => {
    return target && target.indexOf(lookingFor) >= 0;
  };

  const [columnDefs] = useState([
    {
      field: "ed_enqstatus",
      cellRenderer: statusRenderer,
      cellStyle: { paddingLeft: 0, display: "flex", alignItems: "center" },
      resizable: true,
      headerName: "Transaction Status",
      filter: "agTextColumnFilter",
      tooltipField: "ed_enqstatus",
      minWidth: 170,
    },
    {
      field: "ed_enquirynumber",
      resizable: true,
      headerName: "Enquiry Number",
      filter: "agTextColumnFilter",
      tooltipField: "ed_enquirynumber",
      minWidth: 186,
    },
    {
      field: "ed_insertedon",
      resizable: true,
      cellRenderer: (params) => {
        return params.value && (formatDateDD_MM_YYYY(params.value) || "");
      },
      headerName: "Enquiry Generation Date",
      filter: "agDateColumnFilter",
      tooltipField: "ed_insertedon",
      minWidth: 170,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          // const dateCell = new Date(cellValue);
          // if (dateCell < filterLocalDateAtMidnight) {
          //   return -1;
          // } else if (dateCell > filterLocalDateAtMidnight) {
          //   return 1;
          // }
          // return 0;
          const dateAsString = formatDateDD_MM_YYYY(cellValue);
          console.log("dateAsString : ", dateAsString);
          if (dateAsString == null) {
            return 0;
          }
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = dateAsString.split("-");
          const year = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const day = Number(dateParts[0]);
          const dateCell = new Date(year, month, day);
          console.log("dateCell", dateCell);
          if (dateCell < filterLocalDateAtMidnight) {
            console.log("dateCell < filterLocalDateAtMidnight");
            return -1;
          } else if (dateCell > filterLocalDateAtMidnight) {
            console.log("dateCell > filterLocalDateAtMidnight");
            return 1;
          }
          console.log("Returning 0");
          return 0;
        },
      },
    },
    {
      field: "username",
      resizable: true,
      headerName: "Enquiry Created By",
      filter: "agTextColumnFilter",
      tooltipField: "username",
      minWidth: 120,
    },
    {
      field: "dm_dealercompanyname",
      resizable: true,
      headerName: "Company/Dealer",
      filter: "agTextColumnFilter",
      tooltipField: "dm_dealercompanyname",
      minWidth: 120,
    },
    // {
    //     field: "ed_status",
    //     resizable: true,
    //     headerName: "Enquiry Status",
    //     filter: "agTextColumnFilter",
    //     tooltipField: "ed_status",
    //     minWidth: 120,
    // },
    {
      field: "ed_proposalstatus",
      resizable: true,
      headerName: "Proposal Status",
      filter: "agTextColumnFilter",
      tooltipField: "ed_proposalstatus",
      minWidth: 120,
    },
    {
      field: "ed_location_",
      resizable: true,
      headerName: "Location",
      filter: "agTextColumnFilter",
      tooltipField: "ed_country",
      minWidth: 120,
    },

    {
      field: "ed_custcode",
      resizable: true,
      headerName: "Customer Code",
      filter: "agTextColumnFilter",
      tooltipField: "ed_custcode",
      minWidth: 170,
    },
    {
      field: "ed_custname",
      resizable: true,
      headerName: "Customer Name",
      filter: "agTextColumnFilter",
      tooltipField: "ed_custname",
      minWidth: 170,
    },
    {
      field: "ed_custlocation",
      resizable: true,
      headerName: "Location Type",
      filter: "agTextColumnFilter",
      tooltipField: "ed_custlocation",
      minWidth: 170,
    },
    {
      field: "ed_country",
      resizable: true,
      headerName: "Country",
      filter: "agTextColumnFilter",
      tooltipField: "ed_country",
      minWidth: 170,
    },
    {
      field: "ed_hoaddress",
      resizable: true,
      headerName: "HO Address",
      filter: "agTextColumnFilter",
      tooltipField: "ed_hoaddress",
      minWidth: 170,
    },
    {
      field: "ed_telno",
      resizable: true,
      headerName: "Tel No",
      filter: "agTextColumnFilter",
      tooltipField: "ed_telno",
      minWidth: 170,
    },
    {
      field: "ed_webaddress",
      resizable: true,
      headerName: "Website Address",
      filter: "agTextColumnFilter",
      tooltipField: "ed_webaddress",
      minWidth: 170,
    },
    {
      field: "ed_productsmanufactured",
      resizable: true,
      headerName: "Products Manufactured",
      filter: "agTextColumnFilter",
      tooltipField: "ed_productsmanufactured",
      minWidth: 170,
    },
    {
      field: "ed_sector",
      resizable: true,
      headerName: "Sector",
      filter: "agTextColumnFilter",
      tooltipField: "ed_sector",
      minWidth: 170,
    },
    {
      field: "ed_techname",
      resizable: true,
      headerName: "Technical Person's Name",
      filter: "agTextColumnFilter",
      tooltipField: "ed_techname",
      minWidth: 170,
    },
    {
      field: "ed_techdesignation",
      resizable: true,
      headerName: "Technical Person's Designation",
      filter: "agTextColumnFilter",
      tooltipField: "ed_techdesignation",
      minWidth: 170,
    },
    {
      field: "ed_techcontactno",
      resizable: true,
      headerName: "Technical Person's Contact No.",
      filter: "agTextColumnFilter",
      tooltipField: "ed_techcontactno",
      minWidth: 170,
    },
    {
      field: "ed_techemailid",
      resizable: true,
      headerName: "Technical Person's Email ID",
      filter: "agTextColumnFilter",
      tooltipField: "ed_techemailid",
      minWidth: 170,
    },
    {
      field: "ed_dmname",
      resizable: true,
      headerName: "Decision Making Person's Name",
      filter: "agTextColumnFilter",
      tooltipField: "ed_dmname",
      minWidth: 170,
    },
    {
      field: "ed_dmdesignation",
      resizable: true,
      headerName: "Decision Making Person's Designation",
      filter: "agTextColumnFilter",
      tooltipField: "ed_dmdesignation",
      minWidth: 170,
    },
    {
      field: "ed_dmcontactno",
      resizable: true,
      headerName: "Decision Making Person's Contact No.",
      filter: "agTextColumnFilter",
      tooltipField: "ed_dmcontactno",
      minWidth: 170,
    },
    {
      field: "ed_dmemailid",
      resizable: true,
      headerName: "Decision Making Person's Email ID",
      filter: "agTextColumnFilter",
      tooltipField: "ed_dmemailid",
      minWidth: 170,
    },
    // {
    //   field: "fu_schematiclayoutfilename",
    //   cellRenderer: previewDOCRenderer1,
    //   resizable: true,
    //   headerName: "Files Uploaded",
    //   filter: "agTextColumnFilter",
    //   tooltipField: "fu_schematiclayoutfilename",
    //   minWidth: 170,
    // },
    // {
    //   field: "fu_hobofilename",
    //   cellRenderer: previewDOCRenderer1,
    //   resizable: true,
    //   headerName: "Files Uploaded",
    //   filter: "agTextColumnFilter",
    //   tooltipField: "fu_hobofilename",
    //   minWidth: 170,
    // },
    {
      field: "fu_attch1filename",
      cellRenderer: previewDOCRenderer1,
      resizable: true,
      headerName: "Files Uploaded",
      filter: "agTextColumnFilter",
      tooltipField: "fu_attch1filename",
      minWidth: 170,
    },
    // {
    //     field: "fu_attch2filename",
    //     cellRenderer: previewDOCRenderer1,
    //     resizable: true,
    //     headerName: "Files Uploaded",
    //     filter: "agTextColumnFilter",
    //     tooltipField: "fu_attch2filename",
    //     width: 170,
    // },
    // {
    //     field: "fu_attch3filename",
    //     cellRenderer: previewDOCRenderer1,
    //     resizable: true,
    //     headerName: "Files Uploaded",
    //     filter: "agTextColumnFilter",
    //     tooltipField: "fu_attch3filename",
    //     width: 170,
    // },
    {
      field: "ed_currencyvalue",
      resizable: true,
      headerName: "Currency",
      filter: "agTextColumnFilter",
      tooltipField: "ed_currencyvalue",
      minWidth: 170,
    },
    {
      field: "ed_workingdays",
      resizable: true,
      headerName: "No. Of Working Days in a Year",
      filter: "agTextColumnFilter",
      tooltipField: "ed_workingdays",
      minWidth: 170,
    },
    {
      field: "ed_electricityunitrate",
      resizable: true,
      headerName: "Electricity Unit Rate",
      filter: "agTextColumnFilter",
      tooltipField: "ed_electricityunitrate",
      minWidth: 170,
    },
    {
      field: "energyconsumption",
      resizable: true,
      cellRenderer: (params) => { return params.value && parseFloat(params.value).toFixed(4)},
      headerName: "Energy Consumption",
      filter: "agTextColumnFilter",
      tooltipField: "energyconsumption",
      minWidth: 170,
    },
    {
      field: "avgicfm",
      resizable: true,
      cellRenderer: (params) => { return params.value && parseFloat(params.value).toFixed(4)},
      headerName: "Average iCFM",
      filter: "agTextColumnFilter",
      tooltipField: "id_avg_icfm",
      minWidth: 170,
    },
    {
      field: "solutiontype",
      resizable: true,
      headerName: "Solution Type",
      filter: "agTextColumnFilter",
      tooltipField: "solutiontype",
      minWidth: 170,
    },
    {
      field: "id_price",
      resizable: true,
      cellRenderer: (params) => {
        console.log(params);
        return (
          params.value &&
          (params.data.ed_currencyvalue === "INR"
            ? validINRCurrency(params.value) || ""
            : validUSDCurrency(params.value) || "")
        );
      },
      headerName: "Proposal Value",
      filter: "agTextColumnFilter",
      tooltipField: "id_price",
      minWidth: 170,
    },
    {
      field: "engsavedkwh",
      resizable: true,
      headerName: "Energy Saved (in kWh)",
      filter: "agTextColumnFilter",
      tooltipField: "engsavedkwh",
      minWidth: 170,
    },
    {
      field: "cost_saved_per_year_inr",
      resizable: true,
      cellRenderer: (params) => {
        console.log(params);
        return params.value && (validINRCurrency(params.value) || "");
      },
      headerName: "Cost Saved Per Year (INR)",
      filter: "agTextColumnFilter",
      tooltipField: "cost_saved_per_year_inr",
      minWidth: 170,
    },
    {
      field: "cost_saved_per_year_usd",
      resizable: true,
      cellRenderer: (params) => {
        console.log(params);
        return params.value && (validUSDCurrency(params.value) || "");
      },
      headerName: "Cost Saved Per Year (USD)",
      filter: "agTextColumnFilter",
      tooltipField: "cost_saved_per_year_usd",
      minWidth: 170,
    },
    {
      field: "cost_saved_per_year_local",
      resizable: true,
      cellRenderer: (params) => {
        console.log(params);
        return (
          params.value &&
          (params.data.ed_currencyvalue === "INR"
            ? validINRCurrency(params.value) || ""
            : validUSDCurrency(params.value) || "")
        );
      },
      headerName: "Cost Saved Per Year (Local Currency)",
      filter: "agTextColumnFilter",
      tooltipField: "cost_saved_per_year_local",
      minWidth: 170,
    },
    {
      field: "ed_roi",
      resizable: true,
      headerName: "ROI (in Years)",
      filter: "agTextColumnFilter",
      tooltipField: "",
      minWidth: 170,
    },
    {
      field: "ed_roi_months",
      resizable: true,
      headerName: "ROI (in Months)",
      filter: "agTextColumnFilter",
      tooltipField: "",
      minWidth: 170,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: true,
      sortable: true,
      textAlign: "center",
      filterParams: {
        buttons: ["reset", "apply"],
        closeOnApply: true,
        defaultJoinOperator: "OR",
      },
    };
  }, []);

  // BOC Vikas K on 19/09/2022
  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredResult(props.tableData);
    }, 100);
  }, [filteredResult1]);
  // EOC Vikas K on 19/09/2022

  return (
    <>

      <div className="bread-crumb d-none d-md-block d-lg-block">
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/#" active>
            Reports
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="place-center">
        <div className="d-md-none d-lg-none ">
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/#" active>
              Reports
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="app-content">
        <div className="action-area">
          <div className="global-search-container">
            <GolbalSearch filterFunc={handleGlobalSearch} />
            <img
              src={Export}
              alt="export"
              className="ms-2 p-1"
              onClick={handleExport}
            />
          </div>
        </div>
        <div className="d-none mt-3 d-flex" id="filterDisplay">
          {filteredCol &&
            filteredCol.map((item, index) => {
              return (
                <div key={`FilterCol${index}`} className="filter-col pr-0 me-2">
                  <span className="ps-2 btn-text">{item}</span>
                  <div onClick={() => resetFilter(gridRef, item, feildName)}>
                    <BsX size={32} />
                  </div>
                </div>
              );
            })}
          <div>
            <Button
              variant="outline-primary"
              className="ms-2 lh-1 clear-all-btn"
              onClick={() => {
                resetAllFilter(gridRef);
              }}
            >
              Clear All
            </Button>
          </div>
        </div>
        <div id="myGrid" className="d-none d-md-block d-lg-block">
          {props.tableData.length > 0 && (
            <div className="ag-theme-material" style={{ width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                rowData={filteredResult}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                headerHeight={56}
                rowHeight={56}
                pagination={true}
                paginationPageSize={10}
                onFilterChanged={(params) => {
                  //console.log("FD1 ", filteredData);
                  FilterChange(params, setFilteredCol, headerName);
                  // ExportFilterData(
                  //   params,
                  //   setFilteredCol,
                  //   headerName,
                  //   setFilteredData,
                  //   filteredData
                  // );

                  //console.log("Filter change data ", filteredData);
                }}
                enableBrowserTooltips={true}
                domLayout="autoHeight"
              ></AgGridReact>
            </div>
          )}
        </div>
        <div className="d-md-none d-lg-none table-container1 reportStyle">
          <Customtable
            header={[
              {
                id: "ed_enqstatus",
                name: "Status",
                class: "",
              },
              {
                id: "ed_enquirynumber",
                name: "Enquiry No.",
                class: "",
              },
              {
                id: "ed_insertedon",
                name: "Enquiry Generation Date",
                type: "date",
                class: "",
              },
              {
                id: "username",
                name: "Enquiry Created By",
                class: "",
              },
              {
                id: "dm_dealercompanyname",
                name: "Company/Dealer",
                class: "",
              },
              {
                id: "ed_proposalstatus",
                name: "Proposal Status",
                class: "",
              },
              {
                id: "ed_location_",
                name: "Location",
                class: "",
              },
              {
                id: "ed_custcode",
                name: "Customer Code",
                class: "",
              },
              {
                id: "ed_custname",
                name: "Customer Name",
                class: "",
              },
              {
                id: "ed_custlocation",
                name: "Location Type",
                class: "",
              },
              {
                id: "ed_country",
                name: "Customer Country",
                class: "",
              },
              {
                id: "ed_hoaddress",
                name: "HO Address",
                class: "",
              },
              {
                id: "ed_telno",
                name: "Tel No",
                class: "",
              },
              {
                id: "ed_webaddress",
                name: "Website Address",
                class: "",
              },
              {
                id: "ed_productsmanufactured",
                name: "Products Manufactured",
                class: "",
              },
              {
                id: "ed_sector",
                name: "Sector",
                class: "",
              },
              {
                id: "ed_techname",
                name: "Technical Person's Name",
                class: "",
              },
              {
                id: "ed_techdesignation",
                name: "Technical Person's Designation",
                class: "",
              },
              {
                id: "ed_techcontactno",
                name: "Technical Person's Contact No.",
                class: "",
              },
              {
                id: "ed_techemailid",
                name: "Technical Person's Email ID",
                class: "",
              },
              {
                id: "ed_dmname",
                name: "Decision Making Person's Name",
                class: "",
              },
              {
                id: "ed_dmdesignation",
                name: "Decision Making Person's Designation",
                class: "",
              },
              {
                id: "ed_dmcontactno",
                name: "Decision Making Person's Contact No.",
                class: "",
              },
              {
                id: "ed_dmemailid",
                name: "Decision Making Person's Email ID",
                class: "",
              },

              {
                id: "fu_schematiclayoutfilename",
                name: "Files Uploaded",
                class: "",
                type: "link",
              },
              {
                id: "fu_hobofilename",
                name: "",
                class: "",
                type: "link",
              },
              {
                id: "fu_attch1filename",
                name: "",
                class: "",
                type: "link",
              },
              {
                id: "fu_attch2filename",
                name: "",
                class: "",
                type: "link",
              },
              {
                id: "fu_attch3filename",
                name: "",
                class: "",type: "link",
              },
              {
                id: "ed_currencyvalue",
                name: "Currency",
                class: "",
              },
              {
                id: "ed_workingdays",
                name: "No. Of Working Days in a Year",
                class: "",
              },
              {
                id: "ed_electricityunitrate",
                name: "Electricity Unit Rate",
                class: "",
              },
              {
                id: "energyconsumption",
                name: "Energy Consumption",
                class: "",
              },
              {
                id: "avgicfm",
                name: "Average iCFM",
                class: "",
              },
              {
                id: "solutiontype",
                name: "Solution Type",
                class: "",
              },
              {
                id: "id_price",
                name: "Proposal Value",
                class: "",
              },
              {
                id: "engsavedkwh",
                name: "Energy Saved (in kWh)",
                class: "",
              },
              {
                id: "cost_saved_per_year_inr",
                name: "Cost Saved Per Year (INR)",
                class: "",
              },
              {
                id: "cost_saved_per_year_usd",
                name: "Cost Saved Per Year (USD)",
                class: "",
              },
              {
                id: "cost_saved_per_year_local",
                name: "Cost Saved Per Year (Local Currency)",
                class: "",
              },
              {
                id: "ed_roi_months",
                name: "ROI (in Months)",
                class: "",
              },
              {
                id: "ed_roi",
                name: "ROI (in Years)",
                class: "",
              },
            ]}
            highlightKey="ed_enqstatus"
            enableEdit={false}
            enableDelete={false}
            // editFunction={(data) =>
            //   history.push({
            //     pathname: "/newenquiry",
            //     data: data
            //   })
            // }
            //deleteFunction={(params) => handleDelete(params.data)}
            data={filteredResult}
          />
        </div>
        {/* <Modal.Footer className="form-body p-2 justify-content-start modal-footer-report">
                    <div id="actionButtons" >
                        <Button type="submit" onClick={handleExport}>

                            <span >Generate Excel</span>
                        </Button>
                    </div>
                </Modal.Footer> */}
      </div>
      {/* {showForm && (
        <AddEditCustomerMaster
          show={showForm}
          action={action}
          rowdata={row_Data}
          customers={customers}
          setReload={setReload}
          reload={reload}
          onHide={() => setShowForm(false)}
          allRecs={customers}
        />
      )} */}

      {showForm1 && 
        <Pic_Preview
          show={showForm1}
          data={datapic}
          onHide={() => setShowForm1(false)}
        />
      }
      {downloadFlag && downloadFileDirect(datapic[0])

      }
    </>
  );
};
export default AllSolutionHoc(Reports);
