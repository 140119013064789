import { useEffect, useState } from "react";
import { Col, Form, Row, Button, Modal, Alert } from "react-bootstrap";
import axios from "../../utils/axios";
import {
  validDecimalDigits,
  isNumberWithDecimal,
  validINRCurrency,
} from "../../functions/validations";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useHistory, withRouter } from "react-router-dom";
import {
  deleteIfcUpc,
  updateIFC_Details,
} from "../../Services/UpcIfcSolution-service";
import {
  getCorrectionfactor
} from "../../Services/enquiry-customer-service";
import { setErrorMessage } from "../../redux/redux/global/action";
import { getAllProductModels } from "../../../src/Services/product-service";
import { getSumOfCompressorHeader } from "../../Services/compressor-house-service";
import WarningModal from "../Modals/warningModal";
import { store } from "../../redux/store";
import Loader from "../utility/Loader";
const IFCDetails = ({
  ifcCalculations,
  setIfcCalculations,
  // ifcUpcDetail,
  // setIfcUpcDetail,
  action,
  setAction,
  NodeCode,
  setNodeCode,
  IsDisabled,
  ...props
}) => {
  //console.log("ifc_details_props", props, ifcUpcDetail, IsDisabled);
  //console.log("ifc_details_props_ifcCalculations", ifcCalculations);
 // let type = "IFC";
 console.log("propsIFC",props);
 let type = props.NodeType;
   
 const [product, setProduct] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [specificPower, setSpecificPower] = useState("");
  const [loading, setLoading] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [showwarning, setshowwarning] = useState(false);
  const ifcupccalc = store.getState().global?.userDetails.ram_ifcupccalc;
  const [ifcObj, setIfcObj] = useState({
    chavgIcfm: "",
    avgIcfm: "",
    // inletPressure: "",
    // setPressure: "",
    totalworkinghours: "",
  });
  useEffect(() => {
    (async () => {
      if (NodeCode) {
        setLoading(true);
        setIfcObj({
          avgIcfm: "",
          // inletPressure: "",
          // setPressure: "",
          totalworkinghours: "",
        });
        ifcObj.chavgIcfm = "";
        ifcObj.avgIcfm = "";
        // ifcObj.inletPressure = "";
        // ifcObj.setPressure = "";
        ifcObj.totalworkinghours = "";

        //console.log("ifcObj.setPressure=", ifcCalculations.id_set_pressure);

        await fetchConversionDetails();
        await getspecificPower();
        setLoading(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [NodeCode]);

  useEffect(() => {
    setIfcObj((prevState) => ({
      ...prevState,
      inletPressure: ifcCalculations?.id_inlet_pressure,
      setPressure: ifcCalculations?.id_set_pressure,
    }));
  }, [ifcCalculations]);

  const [error, setError] = useState({
    avgIcfm: {},
    inletPressure: {},
    setPressure: {},
  });

  const history = useHistory();
  let enquiryid = props.enquiryid;
  let amb_temp = props?.amb_temp;
  let amb_temp_c = amb_temp[0]?.ed_ambienttemp_c;
  let amb_temp_f = amb_temp[0]?.ed_ambienttemp_f;
  let workingdays = amb_temp[0]?.ed_workingdays;
  let electricityunitrate = amb_temp[0]?.ed_electricityunitrate;
  let Patm = 14.7;
  let W14_7 = 0.0807;
  //let sum_ch_energy_consumption_per_day =  props.CHObj.ch_energy_consumption_per_day;
  let sum_ch_avg_icfm = "";

  const fetchProduct = async () => {
    const resProduct = await getAllProductModels(type);
    console.log("products:::: ", resProduct);
    setProduct(resProduct);
  };

  const fetchConversionDetails = async () => {
    //console.log("inside fetch conv detailsss");
    await axios
      .get(
        `/appDetail/getConversionRate/${props.enquiryid}/${type}/${NodeCode}`
      )
      .then((response) => {
        //console.log("responseee----", response);
        //console.log(response.status);
        if (response.status === 200) {
          let temp = response.data.data;
          //console.log(temp);
          temp = temp.split(",");

          //console.log("total----", temp[0]);
          setIfcObj((prevState) => ({
            ...prevState,
            avgIcfm: temp[0],
            totalworkinghours: temp[1],
            chavgIcfm: temp[2],
          }));

          // setIfcObj((prevState) => ({
          //   ...prevState,
          //   inletPressure: ifcCalculations?.id_inlet_pressure,
          //   setPressure: ifcCalculations?.id_set_pressure,
          // }));
          // setIfcCalculations((prevState) => ({
          //   ...prevState,
          //   id_product_model: ifcCalculations?.id_product_model,
          //   id_nominalFlow:ifcCalculations?.id_nominalflow,
          // }));
        }
      })
      .catch((e) => {
        //console.log("soln connectn--", e);
      })
      .finally(() => {});
  };

  const getspecificPower = async () => {
    const resSum = await getSumOfCompressorHeader(enquiryid, "IFC", NodeCode);
    //console.log("getspecificPower");
    // sum_ch_avg_icfm=resSum?.ch_avg_icfm
    setSpecificPower(resSum?.data.nd_power);
  };

  const fetchCorrectionfactor = async (inlet) => {
    const res = await getCorrectionfactor(inlet);
    //console.log("getspecificPower");
    // sum_ch_avg_icfm=resSum?.ch_avg_icfm
    console.log(res?.data[0])
    console.log("fetchCorrectionfactor",inlet)

    setIfcCalculations((prevState) => ({
      ...prevState,
      id_correctionfactor: Number(res?.data[0])
    }));  
  };

  const handleChange = (e) => {
    //console.log("handlechange", e.target.name, e.target.value);
    //console.log("ifcObj", ifcObj);
    const { name, value } = e.target;
    setIfcObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // let index = ifcUpcDetail.findIndex((obj) => obj.id_ifc_code === NodeCode);
    if (e.target.name === "setPressure") {
      setIfcCalculations((prevState) => ({
        ...prevState,
        id_set_pressure: value,
      }));
      // ifcUpcDetail[index].id_set_pressure = value;
    }
    if (e.target.name === "inletPressure") {
      setIfcCalculations((prevState) => ({
        ...prevState,
        id_inlet_pressure: value,
      }));
      console.log("value",value);
      
      if((value==null)||(value==undefined)||(value=="")){
        console.log("inlet")
        setIfcCalculations((prevState) => ({
          ...prevState,
          id_correctionfactor: 0
        }));
      }
      else{
      let cf = fetchCorrectionfactor(value);
      console.log("CorrectionFactor",cf);
      }
      
           
                          
      // ifcUpcDetail[index].id_inlet_pressure = value;
    }

    //calculations
  };
  const handleCalculate = (e) => {
    let actual_icfm = Number(
      (ifcObj.avgIcfm * Patm) / (ifcCalculations.id_inlet_pressure * 1 + Patm)
    );

    setIfcCalculations((prevState) => ({
      ...prevState,
      id_avg_icfm: actual_icfm,
    }));
  };
  const handleAdd = async (e) => {
    //console.log("UpdateforCalculation", props.ifcN2App);
    let flag = "UpdateforCalculation";
    if (props.ifcN2App === "No") {
      flag = "UpdateWithIFC";
    } else if (props.ifcN2App === "Yes") {
      flag = "UpdateWithIFCN2";
    } else {
      flag = "";
    }

    if (action === "Edit") {
      //console.log("ifcUpcDetail12345", ifcUpcDetail);
      let result = await updateIFC_Details(
        [],
        flag,
        enquiryid,
        ifcObj,
        ifcCalculations,
        // props.ifcN2App,
        specificPower,
        Patm,
        W14_7,
        amb_temp_c,
        amb_temp_f,
        NodeCode
      );
      //console.log("result----", result);
      if (
        result.data.message.includes("fails to match the required pattern") ||
        result.data.message.includes("must be")
      ) {
        let s = result.data.message;
       s = s.split(':')[0];
       //console.log("In Weird Pattern", s);

        setShowMessage(s);
        setshowwarning(true);
      } else if (
        result &&
        (!result.data.message.includes("fails to match the required pattern") ||
          !result.data.message.includes("must be"))
      ) {
        //console.log(result.data.message);
        //setMsg(result.data.message);
        setMsg(result.data.message);
        setShow(true);
        setShow1(false);
        props.setNodeUpdateStatus(true);
        // setNodeCode("");
      } else {
        //console.log(result.data.message);
        setAlertMessage(result.data.message);
        setShow1(false);
        props.setNodeUpdateStatus(true);
        // alert(result.message);
        // props.setReload(!props.reload);
      }
    } else if (action === "Delete") {
      let isError = false;
      let result = await deleteIfcUpc(enquiryid, type);
      //console.log("result for delete", result);
      if (result) {
        setMsg(result.message);
        setShow(true);
        setShow1(false);
      } else {
        isError = true;
        //console.log(result.message);
        setAlertMessage(result.message);
        setShow1(false);
        //setShowSuccess(true);
        return isError;
      }
    }
  };
  const handleSubmit = async (e) => {
    //console.log("handleSubmitaction", action);
    let ip = ifcCalculations.id_inlet_pressure;
    let sp = ifcCalculations.id_set_pressure;

    let isError = false;

    if (Number(sp) !== 0 || Number(ip) !== 0) {
      let ip_sp = Number(sp) >= Number(ip);
      //console.log(ip_sp);
      if (ip_sp) {
        //console.log("smaller");
        setAlertMessage(
          "IFC Set pressure(psig) should be smaller than IFC Inlet pressure(psig)"
        );
        isError = true;
        return isError;
      }
    }

    if (action === "Edit") {
      if (
        ifcCalculations.id_inlet_pressure === "" ||
        ifcCalculations.id_inlet_pressure === null
      ) {
        isError = true;
        error.inletPressure = {
          status: true,
          message: "IFC Inlet Pressure Required",
        };
      }
      if (
        ifcCalculations.id_set_pressure === "" ||
        ifcCalculations.id_set_pressure === null
      ) {
        isError = true;
        error.setPressure = {
          status: true,
          message: "IFC Set Pressure Required",
        };
      }
      setError((prevState) => ({
        ...prevState,
        ...error,
      }));
      if (error.inletPressure.status || error.setPressure.status) {
        isError = true;
        return isError;
      } else setShow1(true);
    } else if (
      ifcCalculations.id_inlet_pressure <= ifcCalculations.id_set_pressure
    ) {
      let isError = true;
      error.setPressure = {
        status: true,
        message: "inlet > set",
      };
      setError((prevState) => ({
        ...prevState,
        ...error,
      }));
      if (error.inletPressure.status || error.setPressure.status) {
        isError = true;
      }
      return isError;
    } else {
      setShow1(true);
    }
  };

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setUser",
    });
  };
  const handleCloseSuccess = () => {
    props.onHide();
    setShow1(false);
    setShow(false);
    setAction("");
  };
  const handleClose = () => {
    setShow1(false);
    setAction("Edit");
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 5000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  useEffect(() => {
    //console.log("in ue1");
    if(props.NodeType!="")
    {
    fetchProduct();
    getspecificPower();
    }
    console.log(props.enqstatus)
    // if((props.enqstatus=="Closed" && ifcCalculations.id_correctionfactor==null)){
    //   setIfcCalculations((prevState) => ({
    //     ...prevState,
    //     id_correctionfactor: 1
    //   }));
    // }
    if((ifcCalculations.id_inlet_pressure==null)||(ifcCalculations.id_inlet_pressure==undefined)||(ifcCalculations.id_inlet_pressure=="")){
      setIfcCalculations((prevState) => ({
        ...prevState,
        id_correctionfactor: ""
      }));
    }
    else{
    if((props.enqstatus=="Created")){
      console.log("not closed")
    fetchCorrectionfactor(ifcCalculations.id_inlet_pressure);
    }
    }
    //fetchConversionDetails();
  }, [props.pageid,props.NodeType]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={show}
        handleClose={handleCloseSuccess}
      />
      <WarningModal
        // clickFunction={modalOnClick}
        message={showMessage}
        show={showwarning}
        handleClose={() => {
          setshowwarning(false);
        }}
      />
      <ConfirmAlertModal
        handleYes={handleAdd}
        action={action}
        formName={props.formName}
        show={show1}
        handleClose={handleClose}
      />
      <Modal {...props} size="lg" backdrop="static" style={{ zIndex: 0 }}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>IFC Details</Modal.Title>
        </Modal.Header>
        <fieldset id="ifc_Dets" disabled={IsDisabled === false ? true : false}>
          <Form id="ifcDetails" name="ifcDetails">
            {/* <div className="action-area">
            &nbsp;{" "}
            <Button
              type="button"
              class="btn btn-primary btn-sm"
              style={{ width: "200px" }}
              onClick={() => {
                setAction("Delete");
                handleSubmit();
              }}
            >
              Delete IFC Details
            </Button>
          </div> */}
            <Modal.Body
              className={ifcupccalc ? "modalDefaultHeight" : "modalHeight"}
              id="ifcModal"
            >
              <span>
                {" "}
                {alertMessage && (
                  <Alert className="alertBox" variant="warning">
                    {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                    {alertMessage}
                  </Alert>
                )}
              </span>
              {loading ? (
                <Loader />
              ) : (
                <div className="p-2">
                  <Row className="gx-3 mt-2">
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>
                          Product Model Nos.(Godrej IFC Models)
                        </Form.Label>
                        <Form.Select
                          name="id_product_model"
                          value={ifcCalculations.id_product_model}
                          onChange={(e) => {
                            for (let i = 0; i < product.length; i++) {
                              if (
                                product[i].pm_prodmodelno === e.target.value
                              ) {
                                setIfcCalculations((prevState) => ({
                                  ...prevState,
                                  id_product_model: e.target.value,
                                  id_nominalflow: product[i].pm_capacity,
                                }));
                                // let index = ifcUpcDetail.findIndex(
                                //   (obj) => obj.id_ifc_code === NodeCode
                                // );
                                // ifcUpcDetail[index].id_product_model =
                                //   e.target.value;
                                // ifcUpcDetail[index].id_nominalflow =
                                //   product[i].pm_capacity;
                              }
                            }

                            // setIfcCalculations((prevState) => ({
                            //   ...prevState,
                            //   id_product_model: e.target.value,
                            // }));
                          }}
                          className="form-select-IFC"
                        >
                          <option value="">
                            {ifcCalculations.id_product_model}
                          </option>

                          {product?.map((value, index) => (
                            <option value={value.pm_prodmodelno}>
                              {value.pm_prodmodelno}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Product Type</Form.Label>
                        <Form.Control
                          id="type"
                          name="type"
                          type="text"
                          value={type}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Capacity/Nominal Flow(CFM)</Form.Label>
                        <Form.Control
                          id="id_nominalFlow"
                          name="id_nominalFlow"
                          type="text"
                          value={ifcCalculations.id_nominalflow}
                          readOnly
                          // onChange={(e) => {
                          //   setIfcCalculations((prevState) => ({
                          //     ...prevState,
                          //     id_nominalflow: e.target.value,
                          //   }));
                          // }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="gx-3 mt-2">
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Average iCFM (CFM)</Form.Label>
                        <Form.Control
                          type="text"
                          name="avgICFM"
                          maxLength="50"
                          value={parseFloat(ifcObj.avgIcfm).toFixed(4)}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>IFC Inlet Pressure (psig)</Form.Label>
                        <Form.Control
                          id="inletPressure"
                          name="inletPressure"
                          type="text"
                          value={ifcCalculations.id_inlet_pressure}
                          onChange={(e) => {
                            handleChange(e);
                            //handleCalculate();
                          }}
                          maxLength="100"
                          onInput={(e) => {
                            {
                              let error = isNumberWithDecimal(e.target.value);
                              if (error.status) {
                                setError((prevState) => ({
                                  ...prevState,
                                  inletPressure: error,
                                }));
                              } else {
                                error = validDecimalDigits(e.target.value);
                                setError((prevState) => ({
                                  ...prevState,
                                  inletPressure: error,
                                }));
                              }
                            }
                            handleCalculate(e);
                          }}
                          className={
                            error.inletPressure.status
                              ? "placeHolderInput inputText text-field-with-error"
                              : "placeHolderInput inputText"
                          }
                        />
                        {error.inletPressure.status && (
                          <Form.Text className="text-danger">
                            {error.inletPressure.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>IFC Set Pressure (psig)</Form.Label>
                        <Form.Control
                          id="setPressure"
                          name="setPressure"
                          type="text"
                          value={ifcCalculations.id_set_pressure}
                          onChange={handleChange}
                          maxLength="100"
                          onInput={(e) => {
                            {
                              let error = isNumberWithDecimal(e.target.value);
                              if (error.status) {
                                setError((prevState) => ({
                                  ...prevState,
                                  setPressure: error,
                                }));
                              } else {
                                error = validDecimalDigits(e.target.value);
                                setError((prevState) => ({
                                  ...prevState,
                                  setPressure: error,
                                }));
                              }
                            }
                          }}
                          className={
                            error.setPressure.status
                              ? "placeHolderInput inputText text-field-with-error"
                              : "placeHolderInput inputText"
                          }
                        />
                        {error.setPressure.status && (
                          <Form.Text className="text-danger">
                            {error.setPressure.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <div hidden={!ifcupccalc}>
                    <Row className="gx-3 mt-2">
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>
                            P<sub>atm</sub> (psig)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="patm"
                            maxLength="50"
                            value="14.7"
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>
                            W<sub>14.7</sub> (lbs/cft)
                          </Form.Label>
                          <Form.Control
                            id="w14.7"
                            name="w14.7"
                            type="text"
                            value="0.0807"
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Form.Label>Specific Power (kW/CFM)</Form.Label>

                      {/* <Col>
                  <Form.Group>
                    <Form.Control
                    disabled = {IsDisabled === false ? true : false}
                      id="sum_ch_energy_consumption_per_day"
                      name="sum_ch_energy_consumption_per_day"
                      type="text"
                      value={Number(sum_ch_energy_consumption_per_day).toFixed(
                        4
                      )}
                      readOnly
                    />
                  </Form.Group>
                </Col> */}
                      <Col>
                        <Form.Group>
                          <Form.Control
                            id="sum_ch_avg_icfm"
                            name="sum_ch_avg_icfm"
                            type="text"
                            value={Number(specificPower).toFixed(4)}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Form.Label>Ambient Temperature (°F)</Form.Label>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="amb_tempF"
                            maxLength="50"
                            value={amb_temp_f}
                          />
                        </Form.Group>
                      </Col>
                      <Form.Label>Ambient Temperature (°C)</Form.Label>

                      <Col>
                        <Form.Group>
                          <Form.Control
                            id="amb_tempC"
                            name="amb_tempC"
                            type="text"
                            value={amb_temp_c}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Form.Label> Actual CFM currently used (CFM)</Form.Label>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="ifc_actual_avg_icfm"
                            maxLength="50"
                            value={
                              (ifcCalculations.id_avg_icfm = Number(
                                (ifcObj.avgIcfm * Patm) /
                                  (ifcCalculations.id_inlet_pressure * 1 + Patm)
                              ).toFixed(4))
                            }
                            //value={ifcCalculations.id_avg_icfm}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col>
                        <Form.Label>
                          {" "}
                          Weight of air at 32<sup>o</sup>F And 14.7 psig, W
                          <sub>14.7</sub> (lbs/cft)
                        </Form.Label>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="W14_7"
                            maxLength="50"
                            value={W14_7}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col>
                        <Form.Label>
                          Weight of air at temperature T And Pressure
                          P.Considering Temp 104<sup>o</sup>F And IFC Upstream
                          Pressure, W1 (lbs/cft)
                        </Form.Label>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="ifc_W1"
                            maxLength="50"
                            value={
                              (ifcCalculations.id_air_wt_w1 = Number(
                                W14_7 /
                                  ((((460 + amb_temp_f * 1) / (460 + 32)) *
                                    Patm) /
                                    (ifcCalculations.id_inlet_pressure * 1 +
                                      Patm))
                              ).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col>
                        <Form.Label>
                          Weight of air at temperature T And Pressure
                          P.Considering Temp 104<sup>o</sup>F And IFC Upstream
                          Pressure, W2 (lbs/cft)
                        </Form.Label>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="ifc_W2"
                            maxLength="50"
                            value={
                              (ifcCalculations.id_air_wt_w2 = Number(
                                W14_7 /
                                  ((((460 + amb_temp_f * 1) / (460 + 32)) *
                                    Patm) /
                                    (ifcCalculations.id_set_pressure * 1 +
                                      Patm))
                              ).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col>
                        <Form.Label>
                          The Mass volume of air saved due to pressure
                          optimization is Diference in weight (lbs/cft)
                        </Form.Label>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="ifc_diffInWt"
                            maxLength="50"
                            value={
                              (ifcCalculations.id_mass_volume = Number(
                                ifcCalculations.id_air_wt_w1 * 1 -
                                  ifcCalculations.id_air_wt_w2 * 1
                              ).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    
                    {props.ifcN2App === "Yes" ? (
                      <Row className="gx-3 mt-2">
                        <Col>
                          <Form.Label>Nitrogen Flow Saved (lbs/cft)</Form.Label>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="ifc_nitrogenSaved"
                              maxLength="50"
                              value={
                                (ifcCalculations.id_saved_nitrogen_flow =
                                  Number(
                                    (ifcCalculations.id_mass_volume *
                                      ifcCalculations.id_avg_icfm) /
                                      W14_7
                                  ).toFixed(4))
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : (
                      <div></div>
                    )}
                    <Row className="gx-3 mt-2">
                        <Col>
                          <Form.Label>Correction Factor</Form.Label>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              name="id_correctionfactor"
                              maxLength="50"
                              value={Number(ifcCalculations.id_correctionfactor)}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                    </Row>
                    <Row className="gx-3 mt-2">
                      <Col md={4}>
                        {props.ifcN2App === "Yes" ? (
                          <Form.Group>
                            <Form.Label>
                              Compressed Air Flow Saved (CFM)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="ifc_airFlowSaved"
                              maxLength="50"
                              value={
                                (ifcCalculations.id_saved_compresed_air_flow =
                                  Number(
                                    ifcCalculations.id_saved_nitrogen_flow * 2 * ifcCalculations.id_correctionfactor
                                  ).toFixed(4))
                              }
                            />
                          </Form.Group>
                        ) : (
                          <Form.Group>
                            <Form.Label>
                              Compressed Air Flow Saved (CFM)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="ifc_airFlowSaved"
                              maxLength="50"
                              value={
                                (ifcCalculations.id_saved_compresed_air_flow =
                                  Number(
                                    (ifcCalculations.id_mass_volume *
                                      ifcCalculations.id_avg_icfm * ifcCalculations.id_correctionfactor) /
                                      W14_7
                                  ).toFixed(4))
                              }
                            />
                          </Form.Group>
                        )}
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>
                            % Compressed Air Flow Saving (%)
                          </Form.Label>
                          <Form.Control
                            id="ifc_airFlowinPercent"
                            name="ifc_airFlowinPercent"
                            type="text"
                            value={
                              (ifcCalculations.id_saved_compressed_air_flow_per =
                                Number(
                                  (ifcCalculations.id_saved_compresed_air_flow /
                                    ifcObj.chavgIcfm) *
                                    100
                                ).toFixed(4))
                            }
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Energy Saved (in kWh)</Form.Label>
                          <Form.Control
                            id="ifc_energySaved"
                            name="ifc_energySaved"
                            type="text"
                            value={
                              (ifcCalculations.id_eng_saved_kwh = Number(
                                ifcCalculations.id_saved_compresed_air_flow *
                                  specificPower
                              ).toFixed(4))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="gx-3 mt-2">
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>
                            Energy saved per day (kWh/day)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="ifc_energySavedPerDay"
                            maxLength="50"
                            value={
                              (ifcCalculations.id_eng_saved_per_day = Number(
                                ifcCalculations.id_eng_saved_kwh *
                                  ifcObj.totalworkinghours
                              ).toFixed(4))
                            } //.toFixed(4)
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>
                            Energy saved per year (kWh/year)
                          </Form.Label>
                          <Form.Control
                            id="ifc_energySavedPerYear"
                            name="ifc_energySavedPerYear"
                            type="text"
                            value={
                              (ifcCalculations.id_eng_saved_per_year = Number(
                                ifcCalculations.id_eng_saved_per_day *
                                  workingdays
                              ).toFixed(4))
                            }
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>
                            Cost saving per year ({props.currency})
                          </Form.Label>
                          <Form.Control
                            id="ifc_costSavedPerYear"
                            name="ifc_costSavedPerYear"
                            type="text"
                            value={validINRCurrency(
                              (ifcCalculations.id_cost_saving_per_year = Number(
                                ifcCalculations.id_eng_saved_per_day *
                                  workingdays *
                                  electricityunitrate
                              ).toFixed(4))
                            )}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <br />
                </div>
              )}
            </Modal.Body>

            {action === "Edit" ? (
              <Modal.Footer className="justify-content-start">
                <Button
                  hidden={IsDisabled === false ? true : false}
                  type="button"
                  class="btn btn-primary btn-sm"
                  style={{ width: "80px" }}
                  onClick={handleSubmit}
                >
                  {action === "Edit" ? "Update" : "Save"}
                </Button>
                <Button
                  class="btn btn-primary btn-sm"
                  variant="outline-primary"
                  onClick={props.onHide}
                  style={{ width: "80px" }}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            ) : (
              <div></div>
            )}
          </Form>
        </fieldset>
      </Modal>
    </>
  );
};

export default IFCDetails;
