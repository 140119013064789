import { useState, useEffect, useMemo } from "react";
import PaginationComp from "../utility/PaginationComp";

const CustomPopUpTable = ({
  data,
  type,
  masterName,
  setSelectedCard,
  ...props
}) => {
  const [tableData, setTableData] = useState([]);
  const [header, setHeader] = useState([]);
  const [temp, setTemp] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isChecked, setIsChecked] = useState(props.locationData);
  const [key , setKey] = useState("");
  const [flag , setFlag] = useState(true);
  const [radioCheck, setRadioCheck] = useState(props.paramvalue);
  let PageSize = 5;
  

  useEffect(() => {
    if (data?.length > 0) {
      setTableData([...data]);
    }

    // if (props.locationData?.length > 0 && data?.length > 0) {
    //   let temp = data.filter((value) =>
    //     props.locationData.includes(String(value.lm_loccode))
    //   );
    //   setTemp(temp);
    // }

    if ((masterName == "Air Compressor Make" || masterName == "Air Compressor Type" || masterName == "Lub/Non Lub") && data?.length > 0) {
      let temp = data.filter((value) => {
        if (props.paramvalue.includes(value[props.paramkey])) {
          console.log("matched found", value);
          setTemp([value]);
        }
      }
      );
    }
  }, [data]);
  
  useEffect(()=>{
    if (props.locationData?.length > 0 && data?.length > 0 && flag===true) {
      let temp = data.filter((value) =>
        props.locationData.includes(String(value.lm_loccode))
      );
      setTemp(temp);
      setFlag(false);
    }
  },[data]);

  useEffect(() => {
    switch (masterName) {
      case "Dealer Company":
        setHeader([
          {
            id: "dm_dealercode",
            name: "Company Code",
          },
          {
            id: "dm_dealercompanyname",
            name: "Company Name",
          },
          {
            id: "dm_dealercategory",
            name: "Dealer Category",
          },
        ]);

        break;
      case "User Role":
        setHeader([
          {
            id: "ram_rolecode",
            name: "Role Code",
          },
          {
            id: "ram_rolename",
            name: "Role Name",
          },
        ]);
        setKey("ram_id");
        break;
      case "Location":
        setHeader([
          {
            id: "lm_loccode",
            name: "Location Code",
          },
          {
            id: "lm_loc",
            name: "Location",
          },
          {
            id: "lm_loctype",
            name: "Location Type",
          },
          {
            id: "lm_country",
            name: "Country",
          },
        ]);
        setKey("lm_loccode");
        break;
      case "Country Master":
        setHeader([
          {
            id: "lm_country",
            name: "Country",
          },
          {
            id: "lm_loctype",
            name: "Location Type",
          },
        ]);
        setKey("lm_loccode");
        break;
      case "ENE User":
        setHeader([
          {
            id: "empid",
            name: "Employee Code",
          },
          {
            id: "emailid",
            name: "Email ID",
          },
          {
            id: "empname",
            name: "Employee Name",
          },
        ]);
        setKey("empid");
        break;
      case "Customer":
        setHeader([
          {
            id: "cm_custcode",
            name: "Customer Code",
          },
          {
            id: "cm_custname",
            name: "Name",
          },
          {
            id: "cm_loctype",
            name: "Location Type",
          },
          {
            id: "cm_productsmanufactured",
            name: "Products",
          },
        ]);
        setKey("cm_custid");
        break;
      case "Air Compressor Make":
        setHeader([
          {
            id: "comp_parameter",
            name: "Parameter",
          },
          {
            id: "comp_parametervalue",
            name: "Parameter Value",
          },
          {
            id: "comp_default",
            name: "Default",
          },
        ]);
        setKey("comp_parametervalue");
        break;
      case "Lub/Non Lub":
        setHeader([
          {
            id: "comp_parameter",
            name: "Parameter",
          },
          {
            id: "comp_parametervalue",
            name: "Parameter Value",
          },
          {
            id: "comp_default",
            name: "Default",
          },
        ]);
        setKey("comp_parametervalue");
        break;
      case "Air Compressor Type":
        setHeader([
          {
            id: "comp_parameter",
            name: "Parameter",
          },
          {
            id: "comp_parametervalue",
            name: "Parameter Value",
          },
          {
            id: "comp_default",
            name: "Default",
          },
        ]);
        setKey("comp_parametervalue");
        break;
      default:
        setHeader([]);
    }
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return tableData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, tableData, PageSize]);

  const setChecked = (e, item) => {
    if (e.target.checked) {
      setTemp([...temp, item]);
      setIsChecked([...isChecked, String(item.lm_loccode)]);
    } else {
      let arr = temp.filter((value) => value.lm_loccode !== item.lm_loccode);
      setTemp(arr);
      let checkArray = isChecked.filter((value) => value !== String(item.lm_loccode));
      setIsChecked(checkArray);
    }
  };

  const setRadio = (e, item) => {

    if (e.target.checked) {
      setTemp([item]);
      setRadioCheck(item[props.paramkey].toString());
    } else {
      let arr = temp.filter((value) => value.lm_loccode !== item.lm_loccode);
      setTemp(arr);
    }
  };

  useEffect(() => {
    // if (temp?.length > 0) setSelectedCard(temp);
    setSelectedCard(temp);
  }, [temp]);

  console.log("currentTableData",currentTableData,data);

  return (
    <>
      <div className="tableWrapperCustomPopupModal mobileTableModal">
        <div className="table-body tableBodyCompressorModal" id="DataTable">
          {data?.length > 0 &&
            currentTableData.map((item, index) => {
              return (
                <div className="outerTabDiv">
                  <div
                    className={
                      "table-row1 mobileTableRow " +
                      (item.show ? "showLess" : "showFull")
                    }
                    // key={`row_${item.lm_loccode}`}
                    key={`row_${item[key]}`}
                    id={`row_${index}`}
                  >
                    <div className="table-cell">
                      {/* <div className="span-text">Select</div> */}
                      {type === "single" ? (
                        <div className="form-check align-text1">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            defaultChecked={
                              radioCheck?.includes(item[props.paramkey])
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setRadio(e, item);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="form-check align-text1">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            defaultChecked={
                              isChecked?.includes(String(item.lm_loccode))
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setChecked(e, item);
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {header.length > 0 &&
                      header.map((value) => {
                        return (
                          <div className="table-cell">
                            <div className="span-text">{value.name}</div>
                            <div className="align-text1">{item[value.id]}</div>
                          </div>
                        );
                      })}
                  </div>
                  {/* {!item.edit && (
                    <div className="showButton">
                      {!item?.show && (
                        <span
                          onClick={() => {
                            let temp = [...tableData];
                            temp[index].show = true;
                            setTableData([...temp]);
                          }}
                        >
                          Show More
                        </span>
                      )}
                      {item?.show === true && (
                        <span
                          onClick={() => {
                            let temp = [...tableData];
                            temp[index].show = false;
                            setTableData([...temp]);
                          }}
                        >
                          Show Less
                        </span>
                      )}
                    </div>
                  )} */}
                </div>
              );
            })}

          <PaginationComp
            currentPage={currentPage}
            totalCount={tableData.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
          <br />
        </div>
      </div>
    </>
  );
};

export default CustomPopUpTable;
