import axios from "../utils/axios";
//const API_URL = "http://localhost:5009/compressorhouse"; //process.env.REACT_APP_COMP_HOUSE_API_URL;
const API_URL = process.env.REACT_APP_COMP_HOUSE_API_URL;

export async function getAllCompressorHouse(activeHeader) {
  console.log(activeHeader);
  console.log(
    activeHeader.active_ch_comp_house_id,
    activeHeader.active_enquiry_id
  );

  let url = `${API_URL}/line/${activeHeader.active_enquiry_id}/${activeHeader.active_ch_comp_house_id}`;
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getCompHouseName(enquiryID) {
  let url = `${API_URL}/name/${enquiryID}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllCompressorHouseHeader(enquiryID) {
  console.log(enquiryID);
  let url = `${API_URL}/header/${enquiryID}`;
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
///header/getSumOfCompressorHeader
export async function getSumOfCompressorHeader(enquiryID, val,NodeCode) {
  let url = `${API_URL}/header/getSumOfCompressorHeader/${enquiryID}/${val}/${NodeCode}`;
  console.log("inside sum-- ", url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp.data;
}

export async function getUpcAvgIcfm(enquiryID) {
  let url = `${API_URL}/header/getUpcAvgIcfm/${enquiryID}`;
  console.log("inside avgIcfm-- ", url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp.data.data;
}
export const AddCompLineData = async (CP) => {
  console.log(CP);
  //  let url = API_URL / line;
  let url = `${API_URL}/line`;
  const response = await axios
    .post(url, JSON.stringify(CP), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
export const AddCompHeaderData = async (CP) => {
  console.log(CP);
  let ch_enquiry_id = CP.ch_enquiry_id;
  let ch_comp_house_id = CP.ch_comp_house_id;
  let ch_avg_icfm = CP.ch_avg_icfm;
  let ch_energy_consumption_per_day = CP.ch_energy_consumption_per_day;
  let ch_controlsettings_unit = CP.ch_controlsettings_unit;
  let ch_capacity_unit = CP.ch_capacity_unit;
  let ch_power_unit = CP.ch_power_unit;
  const cpObj = {
    ch_enquiry_id,
    ch_comp_house_id,
    ch_avg_icfm,
    ch_energy_consumption_per_day,
    ch_controlsettings_unit,
    ch_capacity_unit,
    ch_power_unit,
  };
  console.log(cpObj);
  let url = `${API_URL}/header`;
  const response = await axios
    .post(url, JSON.stringify(cpObj), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//getMaxCompHouseHeaderId
export async function getMaxCompHouseHeaderId() {
  let url = `${API_URL}/maxCHid`;
  console.log("reached here" + url);
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const UpdateCompressorLineData = async (CP) => {
  console.log(CP);
  //JSON.stringify(item)
  //let url = `${API_URL}/line/cl_line_id/${CP.cl_line_id}`;
  let url = `${API_URL}/line`;
  //alert(url +JSON.stringify(item.Parameter) + JSON.stringify(item.CompressorId));
  const response = await axios
    .put(url, JSON.stringify(CP), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateCompressorHeaderData = async (enq, CHid) => {
  //alert("cp" + JSON.stringify(enq))
  //alert("cp" + JSON.stringify(CHid))
  const cp = { enq, CHid };
  //console.log(CP);
  //JSON.stringify(item)
  //let url = `${API_URL}/line/cl_line_id/${CP.cl_line_id}`;
  alert(cp);
  let url = `${API_URL}/header/enq/${enq}/chid/${CHid}`;
  //alert(url +JSON.stringify(item.Parameter) + JSON.stringify(item.CompressorId));
  const response = await axios
    .put(url, JSON.stringify(cp), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//deleteLine
export async function deleteLine(line) {
  console.log(line);
  //console.log(UserID, UserName);
  let url = `${API_URL}/line/cl_line_id/${line.cl_line_id}`;
  const resp = await axios
    .delete(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return resp.data;
}

export async function getAllCompressorApplication(enquiryID) {
  let url = `${API_URL}/compapp/${enquiryID}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp.data;
}
