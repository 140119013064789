import axios from "../../utils/axios";
import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { logDOM } from "@testing-library/react";
export default function AllSolutionApprovalHoc(ComposedComponent) {
  class HOC extends Component {
    state = {
      loading: false,
      tableData: [],
      offset: 0,
      //BOC Vikas K on 13/09/2022
      // limit: 10,
      limit: 200000,
      //EOC Vikas K on 13/09/2022
    };
    getData = () => {
      this.setState({
        loading: true,
      });
      axios
        .get(
          // `/dashboard/latestsolutionapproval?year=${new Date().getFullYear()}&limit=${
            `/dashboard/latestsolution?type=getAllLatestSolutionForApproval&limit=${
            this.state.limit
          }&offset=${this.state.offset}`
        )
        .then((res) => {
          let data = res?.data.data.Data;
          console.log("solutionData---------------", res);
          this.setState({
            tableData: [...data],
          });
        })
        .catch((err) => {
          this.setState({
            tableData: [],
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    };
    componentDidMount() {
      this.getData();
    }
    renderLoading = () => {
      return (
        <ComposedComponent
          {...this.props}
          getData={this.getData}
          offset={this.state.offset}
          tableData={this.state.tableData}
        />
      );
    };
    render() {
      return <>{this.renderLoading()}</>;
    }
  }
  const mapStateToProps = createStructuredSelector({
    // account: makeSelectMyAccount(),
    // dimension: makeSelectDeviceDimension(),
    // device:makeSelectDevice(),
    // user:makeSelectUserDetails()
  });
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  return compose(withConnect, memo)(HOC);
}
