import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_CUSTOMER_API_URL;

console.log(API_URL);
export const getAllCustomers = async (e) => {
  //return axios.get(API_URL).then((response) => response.json());
  const response = await axios
    .get(API_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//active customers
export async function getActiveCustomers(params) {
  console.log("in allaccus");
  let url = API_URL + "/active/" + params;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
//export async function getAll() {
export const getAll = async (e) => {
  let url = API_URL;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
export async function getmaxcustId() {
  let url = API_URL + "/maxcustId";
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
export const AddNewCustomer = async (customer) => {
  console.log(JSON.stringify(customer));
  let url = API_URL;
  const response = await axios
    .post(url, JSON.stringify(customer))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response?.data || "";
};

export const UpdateCustomer = async (customer) => {
  console.log(JSON.stringify(customer) + JSON.stringify(customer.cm_custcode));
  let url = `${API_URL}/update/custCode/${customer.cm_custcode}`;
  const response = await axios
    .post(url, customer)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function deleteCustomer(cm_custcode) {
  //console.log("deleteUser");
  //console.log(UserID, UserName);
  let url = `${API_URL}/custCode/${cm_custcode}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return resp.data;
}

export async function getcustName(ed_custname) {
  let url = `${API_URL}/custName/${ed_custname}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}
