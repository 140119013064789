import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import godrejLogo from "../../styles/images/Godrej-colour-logo.svg";
import godrejLeftImage from "../../styles/images/loginImg.jpg"
import eyeIcon from "../../styles/images/eye-icon.svg";
import eyeIconBlue from "../../styles/images/eye-icon-blue.svg";
import AEMLogo from "../../styles/images/AEM-LogoLogin.svg";
//import AEMLogo from "../../styles/images/AEM-Logo.svg";
import { useHistory, withRouter } from "react-router-dom";
import UpdatePwdModal from "../../Components/Modals/updatePwdModal";
import {
  otpValidator,
  passwordValidator,
  stringType,
} from "../../utils/validator";
import { compose } from "redux";
import axios from "../../utils/axios";
import encryptData from "../../utils/encryption";

function ForgotPage(props) {
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [formValue, setFormValue] = useState({
    userName: "",
    otp: "",
    password: "",
    rePassword: "",
  });
  const [formValueError, setFormValueError] = useState({
    userNameError: "",
    otpError: "",
    passwordError: "",
    rePasswordError: "",
  });
  const [passwordToggleNew, setPasswordToggleNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const history = useHistory();
  const onChange = (e) => {
    let newValues = { ...formValue, [e.target.name]: e.target.value };
    setFormValue({ ...newValues });
  };
  const rePasswordValidator = (e) => {
    if (e.target.value !== formValue.password) {
      return "Entered value doesn't match with password";
    } else {
      return null;
    }
  };
  const onValidator = (e) => {
    switch (e.target.name) {
      case "userName":
        return stringType(e.target.value, "User Name");
      case "otp":
        return otpValidator(e.target.value, "OTP");
      case "password":
        return passwordValidator(e.target.value, "password");
      case "rePassword":
        return rePasswordValidator(e);
      default:
        return null;
    }
  };
  const onSubmitForm = async () => {
    let data = { ...formValue };
    await Object.keys(data).forEach(async (key) => {
      let e = await {
        target: {
          name: `${key}`,
          value: `${data[key]}`,
        },
      };
      await setFormValueError((prevState) => {
        return { ...prevState, [`${key}Error`]: onValidator(e) };
      });
    });
  };
  const validateForm = () => {
    let isError = false;
    Object.values({ ...formValue }).forEach((value) => {
      if (value === "") {
        isError = true;
      }
    });
    if (formValue.password !== formValue.rePassword) {
      isError = true;
    }
    if (!isError) {
      onSubmitForm();
      setErrorMessage([]);
    }
    return isError;
  };
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleUpdate = async () => {
    let isError = await validateForm();
    if (!isError) {
      setLoading(true);
      let body = {
        user: formValue.userName,
        otp: formValue.otp,
        password: formValue.password,
      };
      body = encryptData(JSON.stringify(body), "enc");
      axios
        .post(
          "/authentication/changePassword",
          { body: body },
          {
            headers: {},
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setShow(true);
          }
        })
        .catch((e) => {
          if (e?.response?.data?.message) {
            let temp = [];
            temp.push(e?.response?.data?.message);
            setErrorMessage([...temp]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      onSubmitForm();
    }
  };
  const sendOtp = (value) => {
    let body = {
      user: value,
    };
    axios
      .post("/authentication/forgotPassword", body, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          let temp = formValue;
          temp.otp = "";
          setFormValue(temp);
          setAlertMessage("OTP Sent Successfully");
        }
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          let temp = [...errorMessage];
          temp.push(e?.response?.data?.message);
          setErrorMessage([...temp]);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setDealer",
    });
  }
  useEffect(() => {
    let temp = [];
    Object.values(formValueError).map((value) => {
      if (value) {
        temp.push(value);
      }
    });
    setErrorMessage([...temp]);
  }, [formValueError]);
  useEffect(() => {
    if (props?.location?.data) {
      // sendOtp(props?.location?.data);
      setAlertMessage("OTP Sent Successfully");
      let temp = formValue;
      temp.userName = props?.location?.data;
      setFormValue(temp);
    }
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [alertMessage]);
  return (
    <div className="login-container">
      {alertMessage && (
        <Alert className="alertBoxLogin" variant="success">
          {alertMessage}
        </Alert>
      )}
     
      <Row className="place-right">
        <Col xs={1} md={6} className="d-none d-md-block d-lg-block">
          <div className="loginLeftImage">
            <img
              src={godrejLeftImage}
              alt="godrejLeftImage"
              // className="LoginRightImageGodrej mt-5"
            />
          </div>
        </Col>
        <Col
          xs={12}
          md={4}
          //   className="d-flex align-items-center justify-content-center"
        >
          <div className="LoginRightImage text-center px-4">
            <div>
              <img
                src={godrejLogo}
                alt="godrejLogo"
                className="LoginRightImageGodrej loginImgRight1 mt-4"
              />
            </div>
            <div>
              <img
                src={AEMLogo}
                alt="AEMLogo"
                className="LoginRightImageAem loginImgRight2 mt-4"
              />
            </div>
            
            <div className="mt-3">
              {errorMessage.length > 0 && (
                <div className="loginErrorDiv">
                  <div className="loginErrorDivHeader">Update Failed</div>
                  {errorMessage.map((value) => {
                    return (
                      <div key={value} className="loginErrorDivBody">
                        {value}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="LoginRightRadioDiv mt-2">
                <div className="LoginForgotTitle">Forgot/Change Password</div>
              </div>
              <div className="LoginRightInput mt-2">
                <Form.Label htmlFor="inputPassword5">
                  User ID / Email ID / Mobile No..
                </Form.Label>
                <Form.Control
                  type="text"
                  className="placeHolderInput inputText"
                  name="userName"
                  value={formValue.userName}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="LoginRightInput mt-3">
                <Form.Label htmlFor="inputPassword5">OTP</Form.Label>
                <Form.Control
                  type="text"
                  name="otp"
                  className="placeHolderInput inputText"
                  value={formValue.otp}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="textright">
                <div
                  onClick={() => sendOtp(props?.location?.data)}
                  className="LoginRightRadioDivTitleBlue"
                >
                  Resend OTP?
                </div>
              </div>
              <div className="LoginRightInput mt-3">
                <Form.Label htmlFor="inputPassword5">New Password</Form.Label>
                <div className="inputPassword">
                  <Form.Control
                    type={passwordToggleNew ? "text" : "password"}
                    name="password"
                    className="placeHolderInput inputText"
                    value={formValue.password}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    id="inputPassword5"
                  />
                  <div className="inputPasswordEyeIcon">
                    <img
                      onClick={() => setPasswordToggleNew(!passwordToggleNew)}
                      src={passwordToggleNew ? eyeIcon : eyeIconBlue}
                      alt="eyeIcon"
                    />
                  </div>
                </div>
              </div>
              <div className="LoginRightInput mt-4">
                <Form.Label htmlFor="inputPassword5">
                  Retype New Password
                </Form.Label>
                <div className="inputPassword">
                  <Form.Control
                    type={passwordToggle ? "text" : "password"}
                    name="rePassword"
                    className="placeHolderInput inputText"
                    value={formValue.rePassword}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    id="inputPassword5"
                  />
                  <div className="inputPasswordEyeIcon">
                    <img
                      onClick={() => setPasswordToggle(!passwordToggle)}
                      src={passwordToggle ? eyeIcon : eyeIconBlue}
                      alt="eyeIcon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="LoginRightInput mt-3 pb-3">
              <Button
                onClick={() => handleUpdate()}
                disabled={
                  !formValue.userName ||
                  !formValue.otp ||
                  !formValue.password ||
                  !formValue.rePassword
                }
                className={`${
                  !formValue.userName ||
                  !formValue.otp ||
                  !formValue.password ||
                  !formValue.rePassword
                    ? "forgotButton disabledButton"
                    : "forgotButton"
                }`}
              >
                Update
              </Button>
            </div>
            <div className="textright">
              <div
                onClick={() => {
                  history.push("/");
                }}
                className="LoginRightRadioDivTitleBlue"
              >
                Back to login page?
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <UpdatePwdModal
        clickFunction={modalOnClick}
        message={`Great! You’ve Updated Password successfully for user id - ${formValue.userName}`}
        show={show}
        handleClose={handleClose}
      />
    </div>
  );
}
export default compose(withRouter)(ForgotPage);
