//not in use
const API_URL = "http://localhost:5009/aem/api/airTreatment"; //process.env.REACT_APP_AIR_TREATMENT_API_URL;
const axios = require("axios");


//to fetch airtreatment headers
export async function getAirTreatmentHeaderData(enquiryID) {
  //alert(enquiryID);
  console.log(enquiryID);
  let url = `${API_URL}/header/ah_enquiry_id/${enquiryID}`;
  //alert(url)
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

//to fetch airlines for activeheader
export async function getAirTreatmentLineData(activeHeader) {
  console.log(activeHeader);
  console.log(
    activeHeader.active_ah_compressor_id,
    activeHeader.active_ah_enquiry_id
  );

  let url = `${API_URL}/line/al_enquiry_id/${activeHeader.active_ah_enquiry_id}/al_compressor_id/${activeHeader.active_ah_compressor_id}`;
  // return fetch(url).then((response) => response.json());
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const AddAirHeaderData = async (CP) => {
  console.log(CP);
  let url = `${API_URL}/header`;
  const response = await axios
    .post(url, JSON.stringify(CP), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const AddAirLineData = async (CP) => {
  console.log(CP);
  //  let url = API_URL / line;
  let url = `${API_URL}/line`;
  const response = await axios
    .post(url, JSON.stringify(CP), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//upldate line

export const UpdateAirLineData = async (CP) => {
  console.log(CP);
  let url = `${API_URL}/line/al_line_id/${CP.al_line_id}`;
  const response = await axios
    .put(url, JSON.stringify(CP), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//deleteLine
export async function deleteLine(line) {
  console.log(line);
  //console.log(line.al_line_id);
  //console.log(UserID, UserName);
  let url = `${API_URL}/line/al_line_id/${line}`;
  alert(url);
  const resp = await axios
    .delete(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return resp.data;
}
