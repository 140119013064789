import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import deviceReducer from "./redux/device/reducers";
import globalReducer from "./redux/global/reducers";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["global"],
};

const reducers = combineReducers({
	global: globalReducer,
	device: deviceReducer,
});

export default persistReducer(persistConfig, reducers);
