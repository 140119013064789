import { useState, useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Edit from "../../icons/EditIcon.svg";
import Delete from "../../icons/DeleteIcon.svg";
import Scrollbar from "smooth-scrollbar";
import PaginationComp from "../utility/PaginationComp";
import moment from "moment";
import { downloadFileDirect } from "../../utils/presignUpload";
import Pic_Preview from "../../Components/Masters/ProductMaster/Pic_Preview";

const Customtable = ({
  data,
  header,
  enableEdit,
  enableDelete,
  editFunction,
  deleteFunction,
  highlightKey,
  ...props
}) => {
  const [showForm1, setShowForm1] = useState(false);
  const [datapic, setdatapic] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showmoreless, setshowmoreless] = useState([]);
  let PageSize = 5;
  console.log("highlightKey", highlightKey);

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (data?.length > 0) {
      setTableData([...data]);
    }
  }, [data]);
  const getLocalTimeCus = (date) => {
    const localDate = moment(date).format("DD/MM/YYYY");
    if (localDate === "Invalid date" || !date) return "N/A";
    return localDate;
  };
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return tableData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, tableData, PageSize]);

  const findStatusColor = (status) => {
    let status_color;
    switch (status) {
      case "Pending with Regional Manager":
        status_color = "#D262E6";
        break;
      case "Pending to Approve":
        status_color = "#4285F0";
        break;
      case "Pending for Proposal Status":
        status_color = "#BE596C";
        break;
      case "Approved by Manager":
        status_color = "#25C36E";
        break;
      case "Rejected by Regional Engineer":
        status_color = "#F2BF60";
        break;
      case "Rejected by Manager":
        status_color = "#F2BF60";
        break;
      case "Rejected by HO Engineer":
        status_color = "#F2BF60";
        break;
      case "Pending with Regional Engineer":
        status_color = "#D262E6";
        break;
      case "Pending with Regional In-charge":
        status_color = "#4285F0";
        break;
      case "Pending with Regional Incharge":
        status_color = "#4285F0";
        break;
      case "Pending with Manager":
        status_color = "#06A4A5";
        break;
      case "Pending with HO Engineer":
        status_color = "#FF6565";
        break;
      case "In-Process":
        status_color = "#0693E3";
        break;
      case "Created":
        status_color = "#7ABE35";
        break;
      case "Closed":
        status_color = "#A7A7AB";
        break;
      default:
        status_color = "";
    }
    return status_color;
  };

  const IsPicPreview = (url) => {
    console.log("inside IsPicPreview", url);
    if (url == null || url == undefined || url == "undefined") {
      return "";
    }
    if (
      url.includes(".JPG") ||
      url.includes(".JPEG") ||
      url.includes(".jpg") ||
      url.includes(".jpeg") ||
      url.includes(".bmp") ||
      url.includes(".png")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnClick = (value, item) => {
    console.log("Inside handleOnClick : Value : ", value, " item : ", item);
    if (IsPicPreview(item[value.id])) {
      setdatapic(item[value.id]);
      setShowForm1(true);
    } else {
      downloadFileDirect(item[value.id]);
    }
  };
  console.log("currentTableData", currentTableData);
  return (
    <>
      <div className="tableWrapperCompressorModal mobileTableModal">
        <div className="table-body tableBodyCompressorModal" id="DataTable">
          {currentTableData?.length > 0 &&
            currentTableData.map((item, index) => {
              let color = findStatusColor(item.ed_enqstatus);
              return (
                <div className="outerTabDiv">
                  <div
                    className={
                      "statusHighlight" +
                      `${
                        highlightKey
                          ? item[highlightKey] == true
                            ? "Green"
                            : "Grey"
                          : ""
                      }`
                    }
                    style={{
                      background: color,
                    }}
                  ></div>
                  <div
                    className={
                      "table-row mobileTableRow " +
                      (showmoreless.includes(index) ? "showLess" : "showFull")
                    }
                    key={`row_${index}`}
                    id={`row_${index}`}
                  >
                    {header.length > 0 &&
                      header.map((value) => {
                        return (
                          <div
                            className={
                              "table-cell " +
                              value.class +
                              ` ${
                                value.type == "boolean"
                                  ? item[value.id] == true
                                    ? "tableCellGreen"
                                    : "tableCellGrey"
                                  : " "
                              }`
                            }
                          >
                            {value.name != "" && item[value.id] != "" && (
                              <>
                                <span>{value.name}</span>
                                <div class="align-text">
                                  {value.type == "boolean" ? (
                                    item[value.id] == true ? (
                                      value.btrue
                                    ) : (
                                      value.bfalse
                                    )
                                  ) : value.type == "date" ? (
                                    getLocalTimeCus(item[value.id])
                                  ) : value.type == "link" ? (
                                    <span
                                      className="p-1"
                                      style={{
                                        color: "blue",
                                        width: "100%",
                                      }}
                                      onClick={() => handleOnClick(value, item)}
                                    >
                                      {" "}
                                      {item[value.id]}{" "}
                                    </span>
                                  ) : (
                                    item[value.id]
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    <div className="editControlCustom">
                      {enableEdit && (
                        // <BiEdit
                        //   size={32}
                        //   className="p-1"
                        //   color={"blue"}
                        //   onClick={() => editFunction(item)}
                        // />
                        <img
                          src={Edit}
                          alt="search"
                          onClick={() => editFunction(item)}
                        />
                      )}
                      {enableDelete && (
                        // <BiTrash
                        //   size={32}
                        //   className="p-1"
                        //   color={"blue"}
                        //   //onClick={() => handleDelete(item)}
                        //   onClick={() => deleteFunction(item)}
                        // />
                        <img
                          src={Delete}
                          alt="delete"
                          onClick={() => deleteFunction(item)}
                        />
                      )}
                    </div>
                  </div>
                  {!item.edit &&
                    highlightKey != "ucm_status" &&
                    highlightKey != "comp_status" &&
                    highlightKey != "mr_status" &&
                    highlightKey != "lm_status" && (
                      <div className="showButton">
                        {!showmoreless.includes(index) && (
                          <span
                            onClick={() => {
                              // let temp = [...tableData];
                              // temp[index].show = true;
                              // setTableData([...temp]);

                              let temp = [...showmoreless];
                              temp.push(index);
                              setshowmoreless([...temp]);
                            }}
                          >
                            Show More
                          </span>
                        )}
                        {showmoreless.includes(index) && (
                          <span
                            onClick={() => {
                              // let temp = [...tableData];
                              // temp[index].show = false;
                              // setTableData([...temp]);

                              let temp = [...showmoreless];
                              const element = temp.indexOf(index);
                              if (element > -1) {
                                temp.splice(element, 1);
                              }
                              setshowmoreless([...temp]);
                            }}
                          >
                            Show Less
                          </span>
                        )}
                      </div>
                    )}
                </div>
              );
            })}
          {showForm1 && (
            <Pic_Preview
              show={showForm1}
              data={datapic}
              onHide={() => setShowForm1(false)}
            />
          )}
          <PaginationComp
            currentPage={currentPage}
            totalCount={tableData.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
          <br />
        </div>
      </div>
    </>
  );
};

export default Customtable;
