import axios from "axios";
//import axios from "../../utils/axios";
import Pic_Preview from "../../src/Components/Masters/ProductMaster/Pic_Preview"

const uploadPresigned = async (file = {}, url, setPercent = () => { }) => {
    let returnObject = ""
    console.log("uploadPresignedfile", file);
    await axios.put(url, file, {
        headers: {
            "Content-Type": file.type,
        },
        onUploadProgress: (e) => {
            setPercent(Math.round((e.loaded * 100) / e.total))
        },
    })
        .then((res) => {
            returnObject = res
        })
        .catch((e) => {
            returnObject = e
        });
    return returnObject
};

export const uploadPresignedImg = async (file = {}, url) => {
    let returnObject = ""
    await axios.put(url, file, {
        headers: {
            "Content-Type": file.type,
        },
    })
        .then((res) => {
            returnObject = res
        })
        .catch((e) => {
            returnObject = e
        });
    return returnObject
};

export const uploadPresignedMulti = (file = [], url, setPercent = () => { }) => {
    let array = [];
    let temp = {}
    console.log("inside multi----file-------", file);
    if (Array.isArray(file)) {
        file.map((item, index) => {
            console.log("inside multi-----------", item);
            return array.push(
                new Promise((resolve, reject) => {
                    axios.put(url, item, {
                        headers: {
                            "Content-Type": item.type,
                        },
                        onUploadProgress: (e) => {
                            temp["updPercent" + index] = Math.round((e.loaded * 100) / e.total)
                            setPercent(temp)
                        },
                    })
                        .then((res) => {
                            resolve(res)
                        })
                        .catch((e) => {
                            reject(e)
                        });
                })
            );
        });
    }
    return Promise.all(array);
};


export const downloadFileDirect = (fileToDownload)=>{
  console.log('Inside downloadFileDirect');
    let key = "";
    if(fileToDownload==null || fileToDownload== undefined || fileToDownload=='undefined') {
        return false;
    }
    console.log('fileToDownload',fileToDownload);
    if (fileToDownload.includes(".net/")) {
        key = fileToDownload.split(".net/")[1];
      } else if (fileToDownload.includes(".com/")) {
        key = fileToDownload.split(".com/")[1];
      } else {
        key = fileToDownload.replace(/\s+/g, "");
      }
      console.log(key);
    let url = process.env.REACT_APP_READ_PRESIGNED_ENDPOINT;
  console.log(url + "/" + key);
  axios
    .get(url + "/" + key)
    .then(function (response) {
      console.log('response :', response);
     if (
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".pdf") ||
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".txt")
      ) {
        downloadFile(response.data.url,fileToDownload);
      } else if (
        response.data.url
          .substring(0, response.data.url.indexOf("?"))
          .includes(".hproj")) {
        downloadFile(response.data.url,fileToDownload, '.hproj');
      }
      else {
        downloadFile(response.data.url,fileToDownload);
      }

      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export const downloadFile = (url,fileName, fileExtension) => {
  console.log('URL : ',url,'File Name : ',fileName,' File Extension : ',fileExtension);
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
            const blobUrl = window.URL.createObjectURL(xmlHttp.response);
            const e = document.createElement('a');
            e.href = blobUrl;
            console.log('blobUrl', blobUrl);
const fName = fileName.substring(fileName.lastIndexOf('/')+1,fileName.length);
            if (fileExtension != null && fileExtension != undefined && fileExtension != 'undefined') {
                e.download = blobUrl.substr(blobUrl.lastIndexOf('/') + 1) + fileExtension;
            }
            else {
                e.download = fName;  //blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
            }
            console.log(e.download);
            document.body.appendChild(e);
            e.click();
            document.body.removeChild(e);
        }
    };
    xmlHttp.responseType = 'blob';
    xmlHttp.open('GET', url, true);
    xmlHttp.send(null);
};

export default uploadPresigned;