import axios from "../utils/axios";
const API_URL = process.env.REACT_APP_FILEUPLOAD_API_URL;

//const API_URL = "http://localhost:5001/dealer"


export async function getAllFiles() {
  let url = API_URL;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getFilesByID(fu_enquiryid) {
  let url = `${API_URL}/Fu_EnquiryId/${fu_enquiryid}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}
export const EnrollNewFiles = async (Files) => {
  console.log(JSON.stringify(Files));
  let url = API_URL;
  const response = await axios
    .post(url, JSON.stringify(Files))
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//   export async function deleteFiles(ProdCategory,ProdModelNo) {

//     let url = `${API_URL}/pm_prodcategory/${ProdCategory}/pm_prodmodelno/${ProdModelNo}`;
//     const resp = await axios
//       .delete(url)
//       .then(function (response) {
//         return response;
//       })
//       .catch(function (error) {
//         return error.message;
//       });

//     //console.log(resp);
//     return resp;
//   }
// , {
//   headers: {
//     "Content-Type": "application/json",
//   },
// }


