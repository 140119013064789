import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Col, Row, Alert } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import PlusIcon from "../../styles/images/white-plus.svg";
import Scrollbar from "smooth-scrollbar";
import {
  AddNewappDetail,
  getAppDetail,
} from "../../Services/enquiry-customer-service";
import ApplicationForm from "./Forms/ApplicationForm";
import CommonModal from "../Modals/commonModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import axios from "../../utils/axios";

const ApplicationDets = forwardRef((props, ref) => {
  const [showAppForm, setshowAppForm] = useState(false);
  const [active, setActive] = useState(0);
  // useEffect(() => {
  //   let node = document.querySelector("#application-form-" + active);
  //   node && Scrollbar.init(node);
  //   Scrollbar.init(document.querySelector("#ApplicationDets"));
  // }, [active]);
  let enquiryID = props.enquiryid
    ? props.enquiryid
    : localStorage.getItem("enqkey");

  // let enquiryID = props.enquiryid;
  console.log(enquiryID, props.IsDisabled);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [showMessage, setShowMessage] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [show2, setShow2] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [listDot, setListDot] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  let sequence_no = [];

  const [apps, setApps] = useState([
    {
      ad_enquiry_id: enquiryID,
      ad_appid: "",
      ad_sequencenumber: 1,
      ad_applicationcode: "APL1",
      ad_applicationname: "",
      ad_maxflowrequired: "",
      ad_maxflowrunit: "",
      ad_avgflowrequired: "",
      ad_avgflowunit: "",
      ad_pressurerequired: "",
      ad_pressureunit: "",
      ad_workinghours: "",
      ad_n2application: false,
      ad_created_on: "",
      ad_created_by: "",
      ad_modified_on: "",
      ad_modified_by: "",
    },
  ]);

  const [appDetail, setAppDetail] = useState([
    {
      ad_enquiry_id: enquiryID,
      ad_sequencenumber: 1,
      ad_applicationcode: "APL1",
      ad_applicationname: "",
      ad_maxflowrequired: "",
      ad_maxflowrunit: "",
      ad_avgflowrequired: "",
      ad_avgflowunit: "",
      ad_pressurerequired: "",
      ad_pressureunit: "",
      ad_workinghours: "",
      ad_n2application: false,
      ad_appid: "",
      ad_created_by: "",
      ad_created_on: "",
      ad_modified_by: "",
      ad_modified_on: "",
    },
  ]);
  useImperativeHandle(ref, () => ({
    runValidate() {
      let temp = validateApplication();
      return temp;
    },
  }));
  useEffect(() => {
    getAplDets(enquiryID);
  }, [props?.enquiryid, props?.IsDisabled]);

  useEffect(() => {
    getAplDets(enquiryID);
  }, [reload]);
  const validateApplication = () => {
    let error = false;
    let temp = [...appDetail];
    temp.forEach((value, index) => {
      if (!value.ad_appid) {
        temp[index].error = true;
        error = true;
      }
    });
    setAppDetail([...temp]);
    return error;
  };
  const handleAddClick = (id) => {
    if (!validateApplication()) {
      setActive(id);

      setAppDetail((prevState) => [
        ...prevState,
        {
          ad_enquiry_id: enquiryID,
          ad_sequencenumber: appDetail.length + 1,
          ad_applicationcode: "APL" + (appDetail.length + 1),
          ad_applicationname: "",
          ad_maxflowrequired: "",
          ad_maxflowrunit: "",
          ad_avgflowrequired: "",
          ad_avgflowunit: "",
          ad_pressurerequired: "",
          ad_pressureunit: "",
          ad_workinghours: "",
          ad_n2application: false,
          // ad_appid: "",
          // ad_created_by: "",
          // ad_created_on: "",
          // ad_modified_by: "",
          // ad_modified_on: ""
        },
      ]);
    }
  };

  const getAplDets = async (enquiryID) => {
    let data = await getAppDetail(enquiryID);
    console.log("data", data);

    if (data?.data.length == 0 || !data) {
      setAppDetail([
        {
          ad_enquiry_id: enquiryID,
          ad_sequencenumber: 1,
          ad_applicationcode: "APL1",
          ad_applicationname: "",
          ad_maxflowrequired: "",
          ad_maxflowrunit: "",
          ad_avgflowrequired: "",
          ad_avgflowunit: "",
          ad_pressurerequired: "",
          ad_pressureunit: "",
          ad_workinghours: "",
          ad_n2application: false,
          ad_appid: "",
          ad_created_by: "",
          ad_created_on: "",
          ad_modified_by: "",
          ad_modified_on: "",
        },
      ]);
      props.setDeleteNumber(data?.data?.length || 0);
      console.log("new");
    } else {
      setApps(data.data);
      setAppDetail(data?.data);
      // getSeqNo(enquiryID);
      console.log("appDetail.length ", appDetail.length);
      setListDot(true);
      let seq_num = 0;
      for (let i = 0; i <= data.data.length - 1; i++) {
        console.log(data.data[i].ad_sequencenumber);
        sequence_no.push(data.data[i].ad_sequencenumber);
        console.log(sequence_no);
      }
      seq_num = Math.max(...sequence_no);
      console.log(Math.max(...sequence_no));

      console.log("not new");
      props.setDeleteNumber(data?.data?.length || 0);
      if (props.IsDisabled === true) {
        setAppDetail((prev) => {
          return [
            ...prev,
            {
              ad_enquiry_id: enquiryID,
              ad_sequencenumber: seq_num + 1,
              ad_applicationcode: "APL" + (seq_num + 1),
              ad_applicationname: "",
              ad_maxflowrequired: "",
              ad_maxflowrunit: "",
              ad_avgflowrequired: "",
              ad_avgflowunit: "",
              ad_pressurerequired: "",
              ad_pressureunit: "",
              ad_workinghours: "",
              ad_n2application: false,
            },
          ];
        });
      }
    }
  };

  const DeleteApplication = () => {
    let data = {
      enquiry_id: appDetail[active]?.ad_enquiry_id,
      comp_house_id: 0,
      application_id: appDetail[active]?.ad_appid,
      net_conn_id: 0,
    };
    console.log("delete app data----", data);
    console.log(
      "delete data-application---",
      appDetail,
      "active asdasd",
      active
    );
    if (appDetail[active]?.ad_enquiry_id && appDetail[active]?.ad_appid) {
      axios
        .post(`/maintenance/compAppNetwork/delete`, {
          //.delete(`/aem/api/maintenance/compAppNetwork`, {
          data: {
            enquiry_id: appDetail[active]?.ad_enquiry_id,
            comp_house_id: 0,
            application_id: appDetail[active]?.ad_appid,
            net_conn_id: 0,
          },
        })
        .then((response) => {
          console.log(response);
          // if (response.status === 200) {

          // setshowCompForm(true);
          // setShow1(false);
          console.log(response.data);
          console.log(response.data.message);
          setShowMessage(
            `Great! You’ve Deleted App ${appDetail[active].ad_applicationname} successfully`
          );
          if (response.data.message === "Application deleted successfully!") {
            setShow1(false);
            setReload(!reload);
            setShow(true);
            getAplDets(appDetail[active]?.ad_enquiry_id);
          } else {
            alert(response.data.message);
            //props.setAlertMessage(response.data.message);
          }
        })
        .catch((e) => {
          console.log("after app delete- error----------", e);
          console.error(e);
          //alert(e);
          if (e == "Error: Request failed with status code 400") {
            //alert("You cannot delete applicationdetails with network connection!");
            setAlertMessage(
              "You cannot delete applicationdetails with network connection!"
            );
          }
        })
        .finally(() => {});
    } else if (
      appDetail[active]?.ad_appid === "" ||
      appDetail[active]?.ad_appid === undefined
    ) {
      let temp = [...appDetail];
      temp.splice(active, 1);
      setAppDetail([...temp]);
    }
    props.setModule(`Application ${appDetail[active]?.ad_applicationname}`);
    console.log(appDetail[active].ad_applicationname);
  };

  const handleDelete = () => {
    console.log("item: ");
    console.log(appDetail[active]);
    let item = appDetail[active];
    let ans = window.confirm("Do you want to delete app?");
    if (ans) {
      if (item?.ad_appid) {
        axios
          .delete(`maintenance/compAppNetwork/`, {
            data: {
              enquiry_id: item?.ad_enquiry_id,
              comp_house_id: 0,
              application_id: item?.ad_appid,
              net_conn_id: 0,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setShowMessage("Great! You’ve Deleted App successfully");
              setShow1(false);
              setReload(!reload);
              setShow(true);
            }
          })
          .catch((e) => {
            console.log("after delete- error----------", e);
          })
          .finally(() => {});
      }
    }
  };

  const deleteMethod = () => {
    // handleDelete(deleteData);
  };
  useEffect(() => {
    if (appDetail?.length) {
      props.addApplicationNode(appDetail);
    } else {
      props.addApplicationNode(appDetail);
    }
  }, [appDetail]);

  useEffect(() => {
    if (!listDot) {
      return;
    }
    setActive(appDetail.length);
  }, [listDot]);

  useEffect(() => {
    console.log("delete-app-------------");
    DeleteApplication();
  }, [props.deleteKey]);
  useEffect(() => {
    console.log("delete-app-name-------------");
    props.setModule(`Application ${appDetail[active]?.ad_applicationname}`);
  }, [active]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);
  return (
    <>
      <CommonModal
        // clickFunction={modalOnClick}
        message={showMessage}
        show={show}
        handleClose={() => {
          setShow(false);
        }}
      />
      <ConfirmAlertModal
        action="Delete"
        handleYes={deleteMethod()}
        formName={deleteMessage}
        show={show1}
        handleClose={() => {
          setShow1(false);
        }}
      />
      <div id="ApplicationDets" style={{ paddingBottom: "16px"}}>
        <div>
          {alertMessage && (
            <Alert className="alertBox" variant="warning">
              {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
              {alertMessage}
            </Alert>
          )}
        </div>
        <ul id="app-add-btns" className="m-0 list-unstyled">
          {appDetail?.length > 0 &&
            appDetail.map((item, id) => (
              <div key={"list-" + id}>
                <li id={"list-item-" + id} className="mt-3">
                  <Row className="p-0 m-0" style={{ position: "relative" }}>
                    <Col
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      className="p-0 d-flex align-items-center justify-content-center"
                    >
                      <div
                        className={
                          active === id
                            ? showAppForm
                              ? "d-none"
                              : "list-bullet active"
                            : "list-bullet"
                        }
                      ></div>
                    </Col>
                    <Col
                      xs={11}
                      sm={11}
                      md={11}
                      lg={11}
                      id={"app-add-btn-" + id}
                      // onClick={() => {
                      //   handleAddClick(id);
                      // }}
                      onClick={() => {
                        setActive(id);
                        setshowAppForm(!showAppForm);
                      }}
                      className={`
                        ${
                          showAppForm && active === id
                            ? "app-add-btn active"
                            : "app-add-btn"
                        } ${item?.error === true ? "errorBorder" : ""}`}
                    >
                      <span>
                        {" "}
                        {appDetail[id].ad_applicationname === ""
                          ? "Application"
                          : appDetail[id].ad_applicationname}
                      </span>
                      {id + 1 <= appDetail.length ? (
                        <div className="dot prev"></div>
                      ) : (active === id && !showAppForm) || active !== id ? (
                        ""
                      ) : (
                        <div className="dot active"></div>
                      )}
                    </Col>
                    {id + 1 < appDetail.length ? (
                      ""
                    ) : (active === id && !showAppForm) || active !== id ? (
                      <img
                        // onClick={() => togglePassword()}
                        hidden={props.IsDisabled === false ? true : false}
                        src={PlusIcon}
                        alt="plusIcon"
                        onClick={() => {
                          handleAddClick(id + 1);
                          setshowAppForm(!showAppForm);
                        }}
                        style={{
                          position: "absolute",
                          width: "46px",
                          right: "0px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Row>
                </li>
                <ApplicationForm
                  IsDisabled={props.IsDisabled}
                  appDetail={appDetail}
                  setAppDetail={setAppDetail}
                  active={active}
                  setActive={setActive}
                  showAppForm={showAppForm}
                  setshowAppForm={setshowAppForm}
                  id={id}
                  setReload={setReload}
                  getAplDets={getAplDets}
                  edges={props.edges}
                  enquiryID={enquiryID}
                  reload={reload}
                  setModule={props.setModule}
                  addApplicationNode={props.addApplicationNode}
                  setShow1={async (value) => {
                    await setDeleteData(value);
                    setDeleteMessage("Delete App");
                    setShow1(true);
                  }}
                />
              </div>
            ))}
        </ul>
      </div>
    </>
  );
});

export default ApplicationDets;
